import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { CompaniesService } from '../companies.service';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { MustMatch } from '../../helpers/must-match.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { companiesVM, userloginVM } from '../../models/companiesVM';
import { ToastrService } from 'ngx-toastr';
import { countryVM } from '../../models/countryListVM';
import { NgxSpinnerService } from "ngx-spinner";
import { domainVM } from 'src/app/models/domainVM';
import { DomainService } from 'src/app/domain/domain.service';
@Component({
  selector: 'app-add-new-companies',
  templateUrl: './add-new-companies.component.html',
  styleUrls: ['./add-new-companies.component.css']
})

export class AddNewCompaniesComponent implements OnInit {
  public isPasswordMatch: boolean = false;
  private companiesVM: companiesVM;
  private userLoginVM: userloginVM;
  public countryList: countryVM[];
  public domainList: domainVM[];
  companiesForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  contactNumberPattern = "^[0-9]{10,20}$";
  selectedCompnyId: any;
  isEdit = false;

  constructor(
    private formBuilder: FormBuilder,
    private companiesService: CompaniesService,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private domainService: DomainService
  ) { }

  ngOnInit() {
    this.SpinnerService.show();

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.companiesForm = this.formBuilder.group({
      id: [''],
      companyName: ['', TrimValidators.required],
      email: ['', [TrimValidators.required, Validators.email]],
      address: [''],
      countryId: [''],
      state: [''],
      city: [''],
      contactNumber: ['', [Validators.minLength(7), Validators.maxLength(20)]],
      personName: ['', TrimValidators.required],
      userEmail: ['', [TrimValidators.required, Validators.email]],
      userPassword: ['', this.id ? '' : TrimValidators.required],
      confirmPassword: ['', this.id ? '' : TrimValidators.required],
      userId: [''],
      companyDescription: [''],
      domainId: ['']
    }, {
      validator: MustMatch('userPassword', 'confirmPassword')
    });

    this.getDomain();

    if (this.id) {
      this.isEdit = true;
      this.editCompanies(this.id)
    }
    this.getCountryList();
  }

  get f() { return this.companiesForm.controls; }

  private getCountryList() {
    this.companiesService.getCountryList().pipe(first())
      .subscribe(p => {
        this.countryList = p;
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  getDomain() {
    this.domainService.getAll().pipe(first())
      .subscribe(res => {
        this.domainList = res;
        // if (this.domainList && this.domainList.length > 0) {
        //   this.selectedDomainId = this.domainList[0].id;
        // }
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  editCompanies(companyId: string = null): void {

    if (companyId) {
      this.companiesService.getById(companyId).pipe(first()).subscribe(userData => {

        if (userData != null) {
          this.companiesVM = userData[0];
          this.userLoginVM = userData[0].companyUser[0];

          this.selectedCompnyId = this.companiesVM.id;

          this.companiesForm.setValue({
            id: this.companiesVM.id,
            companyName: this.companiesVM.companyName,
            email: this.companiesVM.email,
            address: this.companiesVM.address,
            countryId: this.companiesVM.countryId,
            state: this.companiesVM.state,
            city: this.companiesVM.city,
            contactNumber: this.companiesVM.contactNumber,
            userId: this.userLoginVM != null ? this.userLoginVM.id : null,
            personName: this.userLoginVM != null ? this.userLoginVM.firstName : null,
            userEmail: this.userLoginVM != null ? this.userLoginVM.email : null,
            userPassword: '',
            confirmPassword: '',
            companyDescription: this.companiesVM.companyDescription,
            domainId: this.companiesVM.domainId != null ? this.companiesVM.domainId : ""
          })

        }
      });
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.companiesForm.invalid) {
      return;
    }
    this.loading = true;

    if (this.id) {

      this.companiesForm.value.countryId = this.companiesForm.value.countryId.toString();
      this.companiesForm.value.domainId = this.companiesForm.value.domainId || null
      this.companiesService.update(this.companiesForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/companies']).then(() => {
                this.toastr.success(data['message']);
              });
            }
            else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
    else {
      this.companiesService.create(this.companiesForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/companies']).then(() => {
                this.toastr.success(data['message']);
              });
            }
            else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  onAddCompanyCancelClick() {
    this.router.navigate(['/companies']);
  }

}
