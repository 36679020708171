import { Component, OnInit, Input } from '@angular/core';
import { AlertService, AuthService } from '../../services';
import { UserService } from '../../users/user.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisService } from './../analysis.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { PagerService } from '../../helpers/pager.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { debug } from 'util';
import { Sort, SortDirection } from '@angular/material';

declare var $: any;
@Component({
  selector: 'app-analysis-prioritised-chemicals',
  templateUrl: './analysis-prioritised-chemicals.component.html',
  styleUrls: ['./analysis-prioritised-chemicals.component.css']
})

export class AnalysisPrioritisedChemicalsComponent implements OnInit {
  companyId: any;
  isCompanyAdmin = false;
  @Input() dataMessageResults: any[];

  public chemicalList: any;
  public exportList: any;

  loading = false;
  public filter: string = '';
  public pager: any = {};
  public pagedItems: any[];
  public TotalRecord: any;
  public isDesc: boolean = false;
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  topic: any;
  sub: any;
  k: any;
  t: any;
  currentPage: any = 1;
  public customrSort: Sort;
  public direction: SortDirection = null;

  constructor(private analysisService: AnalysisService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,) { }

  ngOnInit() {

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }
    this.RetrieveChemicalList('', 1, 10000, '', '', true);
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean) {
    this.SpinnerService.show();
    this.analysisService.AllChemicalListWithParamater(filter, startRow, endRow, sortColumn, sortOrder).subscribe(data => {
      this.chemicalList = data["Table"];
      this.dataMessageResults = this.chemicalList

      // this.customrSort.active = "Three way ranking (raw scores)";
      // this.customrSort.direction = "desc"
      const sortState: Sort = { active: 'Three way ranking (raw scores)', direction: 'desc' };
      this.sort(sortState);
      this.SpinnerService.hide();
    }, error => {
      //alert("We faced some technical issues");
    });
  }

  getHeaders() {
    let headers: string[] = [];
    if (this.dataMessageResults) {
      this.dataMessageResults.forEach((value) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }
    return headers;
  }

  search(type) {

    var sortDirection = 'asc';
    var keyword = $('.searchInput').val();
    if (type == "search") {
      if (keyword != null && keyword != "") {
        this.RetrieveChemicalList(keyword, this.pager.startIndex, this.pager.startIndex + 10, this.sortColumn, this.sortDirection, true);
      }
      else if (keyword.length == 0) {
        this.RetrieveChemicalList('', 1, 10, '', '', true);
      }
    }
    else {
      $('.searchInput').val('');
      this.RetrieveChemicalList('', 1, 10, '', '', true);
    }
  }

  GridHideShow(type) {
    if (type == 1) {
      this.router.navigate(['/analysis'])
    }
    else if (type == 2) {
      this.router.navigate(['/analysis-emerging-concern'])
    }
  }

  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.dataMessageResults.length, page);
    this.pagedItems = this.dataMessageResults.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.dataMessageResults.slice();
    if (!sort.active || sort.direction === '') {
      this.dataMessageResults = data;
      return;
    }
    this.dataMessageResults = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ChemicalName':
          return compare(a["ChemicalName"].toLowerCase(), b["ChemicalName"].toLowerCase(), isAsc);
        case 'Toxicity Combined Score (Raw)':
          return compare(parseFloat(a["Toxicity Combined Score (Raw)"]), parseFloat(b["Toxicity Combined Score (Raw)"]), isAsc);
        case 'Exposure Combined Score (Raw)':
          return compare(parseFloat(a["Exposure Combined Score (Raw)"]), parseFloat(b["Exposure Combined Score (Raw)"]), isAsc);
        case 'Emerging Concern Score (raw)':
          return compare(parseFloat(a["Emerging Concern Score (raw)"]), parseFloat(b["Emerging Concern Score (raw)"]), isAsc);
        case 'Chemical Ranking (Tox, Exp, Em Con) (weighted scores)':
          return compare(parseFloat(a["Chemical Ranking (Tox, Exp, Em Con) (weighted scores)"]), parseFloat(b["Chemical Ranking (Tox, Exp, Em Con) (weighted scores)"]), isAsc);
        case 'Chemical ranking (Toxicity and Exposure) (weighted)':
          return compare(parseFloat(a["Chemical ranking (Toxicity and Exposure) (weighted)"]), parseFloat(b["Chemical ranking (Toxicity and Exposure) (weighted)"]), isAsc);
        case 'Three way ranking (raw scores)':
          return compare(parseFloat(a["Three way ranking (raw scores)"]), parseFloat(b["Three way ranking (raw scores)"]), isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
