import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { userloginVM } from '../models/userloginVM';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertDataService {
  

  constructor(private http: HttpClient) { }


  getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}alert`);
    }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}alert/getAlertDataById/` + id);
  }
    getChemicalList() {
        return this.http.get<any[]>(`${environment.apiUrl}alert/ChemicalList`);
    }

    getParameterList() {
      return this.http.get<any[]>(`${environment.apiUrl}alert/ParameterList`);
    }

    getConditionList() {
      return this.http.get<any[]>(`${environment.apiUrl}alert/ConditionList`);
    }

    create(data: FormData) {
      return this.http.post<any>(`${environment.apiUrl}alert/CreateAlert`, data);
      //{
      //  title: data["title"],
      //  conditionType: data["conditionType"],
      //  thresholdValue: data["thresholdValue"],
      //  chemicalId: data["chemicalId"],
      //  parameterId: data["parameterId"],
      //  companyId: data["companyId"]
      //}
      
    }

  update(data: FormData) {
    return this.http.post(`${environment.apiUrl}alert/updateAlert`, data);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}alert/deletebyid/` + id);
  }
}
