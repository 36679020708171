import { Component, OnInit,Input, Directive, ElementRef } from '@angular/core';
import { parametercategoriesVM } from '../models/parametercategoriesVM';
import { AlertService } from '../services';
import { ParameterCategoriesService } from '../parameter-categories/parameter-categories.service';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-parameter-categories',
  templateUrl: './parameter-categories.component.html',
  styleUrls: ['./parameter-categories.component.css']
})

export class ParameterCategoriesComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public parameterCategoryList: parametercategoriesVM[];
  loading = false;

  constructor(
    private parameterCategoriesService: ParameterCategoriesService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
   
    this.getParameterCategories();
   
  }

  private getParameterCategories() {
    this.SpinnerService.show(); 
    this.parameterCategoriesService.getAll().pipe(first())
      .subscribe(parameterCategories => {
        this.parameterCategoryList = parameterCategories;
        if (this.parameterCategoryList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide();  
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();  
        });

  }

  //Pagging
  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.parameterCategoryList.length, page);
    this.pagedItems = this.parameterCategoryList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
   
    const data = this.parameterCategoryList.slice();
    if (!sort.active || sort.direction === '') {
      this.parameterCategoryList = data;
      return;
    }
    this.parameterCategoryList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
          case 'categoryName': return compare(a["categoryName"], b["categoryName"], isAsc);
          case 'parentCategoryName': return compare(a["parentCategoryName"], b["parentCategoryName"], isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  } 

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
