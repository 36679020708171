import { Component, OnInit } from '@angular/core';
import { AlertService, AuthService } from '../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ManageChemicalService } from './managechemicalsservice';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-managechemicals',
  templateUrl: './managechemicals.component.html',
  styleUrls: ['./managechemicals.component.css']
})
export class ManagechemicalsComponent implements OnInit {
  public chemicalList: any;
  loading = false;
  public filter: string = '';
  public pager: any = {};
  public pagedItems: any[];
  public TotalRecord: any;
  public isDesc: boolean = false;
  public isLastPageClicked: boolean = false;

  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  sub: any;
  k: any;
  t: any;
  cname: any;
  public noData: boolean = false;
  public withData: boolean = false;
  constructor(
    private manageChemicalService: ManageChemicalService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });
    if (this.k != null && this.k != "" && this.k != undefined) {
      this.filter = this.k;
      $('.searchInput').val(this.k);
    }

    this.RetrieveChemicalList(this.filter, 1, 10, '', '', true);
  }


  onAddChemicalClick(id) {
    if (id != '' && id != "" && id != null) {
      this.router.navigate(['/add-chemical', id, $('.searchInput').val()]);
    }
    else {
      this.router.navigate(['/add-chemical', '', $('.searchInput').val()]);
    }
  }

  ShowChart(t, id) {
    var kVal = '';
    if ($('.searchInput').val() != '') {
      kVal = $('.searchInput').val();
    }
    this.router.navigate(['/social-feed', id, t, kVal])
  }
  ViewChemical(id) {
    this.router.navigate(['/view-chemical-details', id, $('.searchInput').val(), "1"]);
  }

  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteChemicalById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
        (e) =>
          console.log('Chemical dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  deleteChemicalById(id: any) {
    this.manageChemicalService.delete(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.RetrieveChemicalList(this.filter, this.pager.startIndex, this.pager.endIndex, this.sortColumn, this.sortDirection, false);
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  SetPage(page: number, isCallChemicalList: boolean) {

    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    if (this.isLastPageClicked && page == this.pager.totalPages) { return; }

    if (page == this.pager.totalPages) {
      this.isLastPageClicked = true;
    }
    else {
      this.isLastPageClicked = false;
    }

    if (isCallChemicalList && page == this.pager.currentPage) {
      return;
    }

    this.pager = this.manageChemicalService.GetPager((this.chemicalList.length > 0 ? this.chemicalList[0].totalRecord : 0), page, 10);
    this.pagedItems = this.chemicalList.slice(this.pager.startIndex, this.pager.endIndex + 1);



    if (isCallChemicalList) {
      this.RetrieveChemicalList(this.filter, this.pager.startIndex, this.pager.endIndex, this.sortColumn, this.sortDirection, false)
    }
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean) {
    this.SpinnerService.show();
    this.manageChemicalService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder).subscribe(data => {
      this.chemicalList = data
      if (this.chemicalList != null && this.chemicalList.length > 0) {
        this.withData = true;
        this.noData = false;
        if (isPager && this.chemicalList.length > 0) {
          this.SetPage(1, false);
        } else {
          this.pagedItems = [];
        }
      }
      else {
        this.chemicalList = null;
        this.withData = false;
        this.noData = true;
      }
      this.SpinnerService.hide();
    },
      error => {
        //alert("We faced some technical issues");
      });

  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.sortColumn = property;
    this.sortDirection = this.isDesc ? 'asc' : 'desc';
    this.RetrieveChemicalList(this.filter, this.pager.startIndex, this.pager.endIndex, this.sortColumn, this.sortDirection, false);
  };

  search(type) {
    this.isLastPageClicked = false;

    var sortDirection = 'asc';
    var keyword = $('.searchInput').val();
    if (type == 'search') {
      if (keyword != "" && keyword != null) {
        this.filter = keyword;
        this.pager.startIndex = 1;
        this.RetrieveChemicalList(keyword, this.pager.startIndex, this.pager.startIndex + 10, this.sortColumn, this.sortDirection, true);
      }
      else if (keyword.length == 0) {
        this.RetrieveChemicalList('', 1, 10, '', '', true);
      }
    }
    else {
      $('.searchInput').val('');
      this.RetrieveChemicalList('', 1, 10, '', '', true);
    }
  }

}
