import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  sendFeedbackEmail(data: any) {
    return this.http.post<any>(`${environment.apiUrl}Feedback/SendFeedbackEmail`, data);
  }
}
