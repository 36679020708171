import { Component, OnInit } from '@angular/core';
import { chemicalcategoriesVM } from '../models/chemicalcategoriesVM';
import { AlertService } from '../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ChemicalCategoriesService } from './chemical-categories.service';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-chemical-categories',
  templateUrl: './chemical-categories.component.html',
  styleUrls: ['./chemical-categories.component.css']
})
export class ChemicalCategoriesComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public categoryList: chemicalcategoriesVM[];
  loading = false;

  constructor(
    private chemicalCategoriesService: ChemicalCategoriesService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService

  ) { }
  ngOnInit() {
    this.SpinnerService.show();
    this.getCategoryList();
  }


  private getCategoryList() {
    this.chemicalCategoriesService.getAll().pipe(first())
      .subscribe(p => {
        this.categoryList = p;
        if (this.categoryList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }


  onAddCategoryClick() {

    this.router.navigate(['/add-chemical-categories']);
  }


  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteCategoryById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
        //(e) =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  deleteCategoryById(id: any) {
    this.chemicalCategoriesService.delete(id).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getCategoryList();
        } else {
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  } 

  public openActiveInActiveConfirmationDialog(eve: any) {

    const selectEl = eve.target;
    let id = selectEl.value
    let isChecked = eve.currentTarget.checked;
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Update ?')
      .then((confirmed) =>
        confirmed === true ? this.ActiveInActiveById(id) : eve.currentTarget.checked = !isChecked
        //console.log('User confirmed:', confirmed)
      )
      .catch(
        //(e) =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  ActiveInActiveById(id: any) {

    this.chemicalCategoriesService.activeinactive(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['Message']);
          // this.getCategories();
          this.getCategoryList();
        } else {

          this.toastr.error(data['Message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  setCheckBoxValue(value, checkboxId, eve) {
    eve.currentTarget.checked = !eve.currentTarget.checked;
  }

  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.categoryList.length, page);
    this.pagedItems = this.categoryList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.categoryList.slice();
    if (!sort.active || sort.direction === '') {
      this.categoryList = data;
      return;
    }
    this.categoryList = data.sort((a,b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'categories': return compare(a.categoryName, b.categoryName, isAsc);
        case 'parentCategoryName': return compare(a["parentCategoryName"], b["parentCategoryName"], isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

  Search(parameter: any) { 
    this.categoryList = this.categoryList.filter(it => { return it.categoryName.toLowerCase().includes(parameter.toLowerCase()); });
    this.setPage(this.currentPage);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
