import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { weightCategoryVM } from '../models/weightCategoryVM';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })

export class WeightCategoryService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}WeightCategory`);
  }

  create(Data: any[]) {
    return this.http.post<any>(`${environment.apiUrl}WeightCategory/updateWeightCategory`, Data);
  }


  saveCompanyWeight(Data: any[]) {
    return this.http.post<any>(`${environment.apiUrl}WeightCategory/updateCompanyWeight`, Data);
  }


  getCompanySDMList() {
    let companyId =localStorage.getItem('CompanyId');
    return this.http.get<any>(`${environment.apiUrl}WeightCategory/getCompanySDMList/` + companyId);
  }

}
