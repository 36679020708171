import { BrowserModule, } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NavSidebarMenuComponent } from './nav-sidebarmenu/nav-sidebarmenu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { AuthGuard } from '../app/helpers';
import { InterceptedHttpProvider } from './helpers/intercepted-http';
import { AuthService } from './services/auth.service';
import { UsersComponent } from './users/users.component';
import { AlertComponent } from './directives/alert.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ParameterCategoriesComponent } from './parameter-categories/parameter-categories.component';
import { ChemicalParameterComponent } from './chemical-parameter/chemical-parameter.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { ViewallchemicalsComponent } from './viewallchemicals/viewallchemicals.component';
import { ViewchemicaldetailsComponent } from './managechemicals/viewchemicaldetails/viewchemicaldetails.component';
import { AddchemicalComponent } from './managechemicals/addchemical/addchemical.component';
import { ManagechemicalsComponent } from './managechemicals/managechemicals.component';
import { SdmManagementComponent } from './sdm-management/sdm-management.component';
import { EditSdmManagementComponent } from './sdm-management/edit-sdm-management/edit-sdm-management.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AddNewAlertComponent } from './alerts/add-new-alert/add-new-alert.component';
import { DataRequestComponent } from './data-request/data-request.component';
import { AddDataRequestComponent } from './data-request/add-data-request/add-data-request.component'
import { CompaniesComponent } from './companies/companies.component';
import { AddNewCompaniesComponent } from './companies/add-new-companies/add-new-companies.component';
import { ViewCompanyComponent } from './companies/view-company/view-company.component';
import { ViewCompanyOntarioComponent } from './companies/view-branch/view-branch.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChemicalCategoriesComponent } from './chemical-categories/chemical-categories.component';
import { AddChemicalCategoriesComponent } from './chemical-categories/add-chemical-categories/add-chemical-categories.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagerService } from '../app/helpers/pager.service';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatSliderModule, MatSortModule, MatStepperModule } from '@angular/material';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { FilterPipe } from '../../src/app/helpers/filter.pipe';
import { DateFilterPipe } from '../../src/app/helpers/date.pipe';
import { ReportFilterPipe } from '../../src/app/helpers/report.pipe';
import { CustomeDirective } from '../../src/app/helpers/CustomeDirective';
import { CompanySdmManagementComponent } from './company/company-sdm-management/company-sdm-management.component';
import { CompanyFilter } from '../../src/app/helpers/companyFilter.pipe';
import { Role } from '../app/models/role';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { GroupByPipe } from '../../src/app/helpers/GroupBy.pipe';
import { PopoverModule } from "ngx-smart-popover";
import { CompanyBranchComponent } from './companies/company-branch/company-branch.component';
import { AddCompanyBranchComponent } from './companies/add-company-branch/add-company-branch.component';
import { AddBranchUserComponent } from './companies/add-branch-user/add-branch-user.component';
import { NineDigitDecimaNumberDirective } from '../app/helpers/nine-digit-decima-number.directive';
import { ThreeDigitDecimaNumberDirective } from '../app/helpers/Three-digit-decima-number.directive';
import { CommonNameRequestComponent } from './common-name-request/common-name-request.component';
import { AddCommonNameRequestComponent } from './common-name-request/add-common-name-request/add-common-name-request.component';
import { CompanyAlertComponent } from './company-alert/company-alert.component';
import { AddCompanyAlertComponent } from './company-alert/add-company-alert/add-company-alert.component';
import { ViewCompanyAlertComponent } from './view-company-alert/view-company-alert.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { AnalysisEmergingConcernComponent } from './analysis/analysis-emerging-concern/analysis-emerging-concern.component';
import { AnalysisPrioritisedChemicalsComponent } from './analysis/analysis-prioritised-chemicals/analysis-prioritised-chemicals.component';
import { DataTablesModule } from 'angular-datatables';
import { Observable, of } from 'rxjs';
import { SocialFeedComponent } from './social-feed/social-feed.component';
import { AppHttpInterceptor } from '../app/AppHttpInterceptor';
import { GeneratedAlertComponent } from './generated-alert/generated-alert.component';
import { SdmSliderComponent } from './sdm-slider/sdm-slider.component';
import { CompanySdmSliderComponent } from './company/company-sdm-slider/company-sdm-slider.component';
import { AnalysisDashboardComponent } from './analysis/analysis-dashboard/analysis-dashboard.component';
import { NewAnalysisComponent } from './analysis/new-analysis/new-analysis.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { A11yModule } from '@angular/cdk/a11y';
//import { LoaderInterceptor } from '../app/loader-interceptor';
import { CompanyChemicalRequestComponent } from './company-chemical-request/company-chemical-request.component';
import { TesttourComponent } from './testtour/testtour.component';
import { JoyrideModule, JoyrideStepComponent } from 'ngx-joyride';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { ReleaseHistoryComponent } from './release-history/release-history.component';
import { AddReleaseHistoryComponent } from './release-history/add-release-history/add-release-history.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ViewReleaseHistoryComponent } from './release-history/view-release-history/view-release-history.component';
import { ViewDetailReleaseHistoryComponent } from './release-history/view-detail-release-history/view-detail-release-history.component';
import { FirstTimeLoginChangePasswordComponent } from './users/first-time-login-change-password/first-time-login-change-password.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DomainComponent } from './domain/domain.component';
import { CitationPostComponent } from './citation-post/citation-post.component';
import { MonitoringManagementComponent } from './monitoring-management/monitoring-management.component';
import { ViewCompanyUserAlertsComponent } from './view-company-user-alerts/view-company-user-alerts.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ThirtySevenDigitDecimaNumberDirective } from './helpers/thirtyseven-digit-decima-number.directive';
import { ECDataComponent } from './ecdata/ecdata.component';
import { AnalysisDataReportAlertComponent } from './analysis/analysis-data-report-alert/analysis-data-report-alert.component';
import { AnalysisDataReportAlertDetailsComponent } from './managechemicals/analysis-data-report-alert-details/analysis-data-report-alert-details.component';
import { TreeModule } from 'primeng/tree';
import { TreeDragDropService } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { AnalysisEnhancedReportComponent } from './analysis/analysis-enhanced-report/analysis-enhanced-report.component';
import { FaqComponent } from './faq/faq.component';
import { TwoDigitDecimalNumberDirective } from './helpers/two-digit-decimal-number.directive';
import { ThreeDigitDecimalDirective } from './helpers/three-digit-decimal.directive';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    NavSidebarMenuComponent,
    HomeComponent,
    LoginComponent,
    UsersComponent,
    AlertComponent,
    AddUserComponent,
    ConfirmationDialogComponent,
    ParameterCategoriesComponent,
    ChemicalParameterComponent,
    AnalysisComponent,
    ViewallchemicalsComponent,
    //ViewSocialmediafeedsComponent,
    ViewchemicaldetailsComponent,
    AddchemicalComponent,
    ManagechemicalsComponent,
    SdmManagementComponent,
    EditSdmManagementComponent,
    AlertsComponent,
    AddNewAlertComponent,
    DataRequestComponent,
    CompaniesComponent,
    AddNewCompaniesComponent,
    ViewCompanyComponent,
    ViewCompanyOntarioComponent,
    DashboardComponent,
    AddChemicalCategoriesComponent,
    ChemicalCategoriesComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    FilterPipe,
    DateFilterPipe,
    CustomeDirective,
    CompanySdmManagementComponent,
    CompanyFilter,
    GroupByPipe,
    NineDigitDecimaNumberDirective,
    ThreeDigitDecimaNumberDirective,
    TwoDigitDecimalNumberDirective,
    ThreeDigitDecimalDirective,
    CompanyBranchComponent,
    AddCompanyBranchComponent,
    AddBranchUserComponent,
    AddDataRequestComponent,
    CommonNameRequestComponent,
    AddCommonNameRequestComponent,
    CompanyAlertComponent,
    AddCompanyAlertComponent,
    ViewCompanyAlertComponent,
    AnalysisEmergingConcernComponent,
    AnalysisPrioritisedChemicalsComponent,
    SocialFeedComponent,
    GeneratedAlertComponent,
    SdmSliderComponent,
    CompanySdmSliderComponent,
    AnalysisDashboardComponent,
    NewAnalysisComponent,
    ReportFilterPipe,
    CompanyChemicalRequestComponent,
    TesttourComponent,
    UserActivityComponent,
    ReleaseHistoryComponent,
    AddReleaseHistoryComponent,
    ViewReleaseHistoryComponent,
    ViewDetailReleaseHistoryComponent,
    FirstTimeLoginChangePasswordComponent,
    DomainComponent,
    CitationPostComponent,
    MonitoringManagementComponent,
    ViewCompanyUserAlertsComponent,
    FeedbackComponent,
    ThirtySevenDigitDecimaNumberDirective,
    ECDataComponent,
    AnalysisDataReportAlertComponent,
    AnalysisDataReportAlertDetailsComponent,
    AnalysisEnhancedReportComponent,
    FaqComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    JoyrideStepComponent
  ],
  imports: [
    NgbModule,
    BrowserModule.withServerTransition({
      appId: 'ng-cli-universal'
    }),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    MatSortModule,
    NgxSpinnerModule,
    AngularMultiSelectModule,
    PopoverModule,
    GoogleChartsModule,
    DataTablesModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    MatIconModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatTableModule,
    DragDropModule,
    ScrollingModule,
    CdkTableModule,
    CdkTreeModule,
    A11yModule,
    CKEditorModule,
    NgApexchartsModule,
    TreeModule,
    ContextMenuModule,
    JoyrideModule.forRoot(),
    RouterModule.forRoot([
      { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'parameter-categories', component: ParameterCategoriesComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'chemical-parameter', component: ChemicalParameterComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-user', component: AddUserComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-user/:id', component: AddUserComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'login', component: LoginComponent, },
      { path: 'analysis', component: AnalysisComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-emerging-concern', component: AnalysisEmergingConcernComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-emerging-concern/:type/:k', component: AnalysisEmergingConcernComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-emerging-concern/:urlChemicalId', component: AnalysisEmergingConcernComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-prioritised-chemicals', component: AnalysisPrioritisedChemicalsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      // Change to landing page
      // { path: 'analysis-dashboard', component: AnalysisDashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-dashboard-classic', component: AnalysisDashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-dashboard', component: AnalysisDataReportAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'new-analysis', component: NewAnalysisComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },

      { path: 'view-all-chemical', component: ViewallchemicalsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'view-all-chemical/:k', component: ViewallchemicalsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'view-chemical-details/:id/:k', component: ViewchemicaldetailsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'view-chemical-details/:id/:k/:type', component: ViewchemicaldetailsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'view-chemical-details/:id', component: ViewchemicaldetailsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'add-chemical', component: AddchemicalComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'add-chemical/:id', component: AddchemicalComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'add-chemical/:id/:k', component: AddchemicalComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'manage-chemicals', component: ManagechemicalsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'manage-chemicals/:k', component: ManagechemicalsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'sdm-management', component: SdmManagementComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'edit-sdm-management', component: EditSdmManagementComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'alerts', component: AlertsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'add-new-alert', component: AddNewAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'add-new-alert/:id', component: AddNewAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users] } },

      { path: 'company-chemical-request', component: CompanyChemicalRequestComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },

      { path: 'company-alert', component: CompanyAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'add-company-alert', component: AddCompanyAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'add-company-alert/:id', component: AddCompanyAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'view-company-alert', component: ViewCompanyAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },

      { path: 'data-request', component: DataRequestComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users, Role.SuperAdmin, Role.Elemental] } },
      { path: 'add-data-request', component: AddDataRequestComponent, data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'add-data-request/:id', component: AddDataRequestComponent, data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'common-name-request', component: CommonNameRequestComponent, data: { roles: [Role.CompanyAdmin, Role.Users, Role.SuperAdmin, Role.Elemental] } },
      { path: 'add-common-name-request', component: AddCommonNameRequestComponent, data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'add-common-name-request/:id', component: AddCommonNameRequestComponent, data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-new-companies', component: AddNewCompaniesComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-new-companies/:id', component: AddNewCompaniesComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'view-company/:id', component: ViewCompanyComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'view-branch/:id', component: ViewCompanyOntarioComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin] } },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'chemical-categories', component: ChemicalCategoriesComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-chemical-categories', component: AddChemicalCategoriesComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-chemical-categories/:id', component: AddChemicalCategoriesComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'company-sdm-management', component: CompanySdmManagementComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users] } },
      { path: 'company-branch/:id', component: CompanyBranchComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin] } },
      { path: 'add-company-branch', component: AddCompanyBranchComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin] } },
      { path: 'add-company-branch/:id', component: AddCompanyBranchComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin] } },
      { path: 'add-branch-user', component: AddBranchUserComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin] } },

      { path: 'add-branch-user/:branchId', component: AddBranchUserComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin] } },
      { path: 'add-branch-user/:branchId/:id', component: AddBranchUserComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin] } },
      { path: 'social-feed/:id/:t', component: SocialFeedComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'social-feed/:id/:t/:k', component: SocialFeedComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'generated-alert', component: GeneratedAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'sdm-slider', component: SdmSliderComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'user-activity', component: UserActivityComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'release-history', component: ReleaseHistoryComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-release-history', component: AddReleaseHistoryComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'add-release-history/:id', component: AddReleaseHistoryComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'view-release-history', component: ViewReleaseHistoryComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'view-detail-release-history/:id', component: ViewDetailReleaseHistoryComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'update-password', component: FirstTimeLoginChangePasswordComponent, canActivate: [AuthGuard] },

      { path: 'citation-post', component: CitationPostComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'monitoring-management', component: MonitoringManagementComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      { path: 'view-company-user-alerts', component: ViewCompanyUserAlertsComponent, canActivate: [AuthGuard], data: { roles: [Role.CompanyAdmin, Role.Users] } },

      { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'ecdata', component: ECDataComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin] } },
      // { path: 'analysis-data-report-alert', component: AnalysisDataReportAlertComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-data-report-alert-details', component: AnalysisDataReportAlertDetailsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'analysis-data-report-alert-details/:id', component: AnalysisDataReportAlertDetailsComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'enhanced-report', component: AnalysisEnhancedReportComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
      { path: 'faq', component: FaqComponent, canActivate: [AuthGuard], data: { roles: [Role.Elemental, Role.SuperAdmin, Role.CompanyAdmin, Role.Users] } },
    ])
  ],
  providers: [
    AuthGuard,
    AuthService,
    ConfirmationDialogService,
    PagerService,
    NgxSpinnerService,
    TreeDragDropService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

