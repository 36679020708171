import { Component, OnInit } from '@angular/core';
import { AlertService, AuthService } from '../../services';
import { UserService } from '../../users/user.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisService } from './../analysis.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { PagerService } from '../../helpers/pager.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleChartsModule } from 'angular-google-charts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageChemicalService } from '../../managechemicals/managechemicalsservice';

declare var google: any;
import * as _ from 'lodash';
declare var $: any;
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-new-analysis',
  templateUrl: './new-analysis.component.html',
  styleUrls: ['./new-analysis.component.css']
})
export class NewAnalysisComponent implements OnInit {

  analysisForm: FormGroup;
  companyId: any;
  isCompanyAdmin = false;
  sub: any;
  k: any;
  id: any;

  type: any;
  urlChemicalId: any;
  isShow: boolean = false;
  selectedChemicalId = "";
  public chemicalList: any;
  public allChemicalDataList: any;
  public EmergingList: any;
  public EmergingChemicalList: any;
  public MultiEmergingList: any;

  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  public filter: string = '';
  AllCommonName: string = null;
  public commonNameList: any[] = [];
  chemicalId: any;
  dropdownList = [];
  multiDropdownList = [];
  selectedItems = [];
  selectedItems1 = [];
  dropdownSettings = {};
  public socialURL: string = null;
  getGoogle() {
    return google;
  }
  public pushIds: [];
  public selected?: boolean
  firstCommonName: string = null;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  constructor(private analysisService: AnalysisService,
    //private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private chemicalService: ManageChemicalService,
    //private pagerService: PagerService,
    //private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService) {

    this.getGoogle(),
      google.charts.load('current', { packages: ['corechart', 'table'] }
      );
  }

  ngOnInit() {

    //this.firstFormGroup = this.formBuilder.group({
    //  firstCtrl: ['', Validators.required]
    //});
    //this.secondFormGroup = this.formBuilder.group({
    //  secondCtrl: ['', Validators.required]
    //});

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.type = params['type'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.urlChemicalId = params['urlChemicalId'];
    });

    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select ChemicalName",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "form-control",
      badgeShowLimit: 3,
      searchBy: ['itemName', 'name', 'casnumber'],
      searchPlaceholderText: 'Search by Display Name, Synonyms, Cas-No',
      showCheckbox: false
    };
    this.selectedItems = [];
    this.getSocialURL();
    this.RetrieveChemicalList('', 1, 1000, '', '', true, this.companyId, true);
    google.charts.load('current', { packages: ['corechart', 'table'] });
    $("#chart_noDate").html("No Chart Data Found.");
    $("#chart_multi").html("");
  }

  backButton() {
    if (this.type == "2") {
      this.router.navigate(['/view-all-chemical', this.k]);
    }
    else {
      this.router.navigate(['/analysis']);
    }
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {

    this.SpinnerService.hide();
    this.analysisService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.allChemicalDataList = data;
      for (var obj in this.allChemicalDataList) {
        let newName = {
          id: this.allChemicalDataList[obj].id,
          itemName: this.allChemicalDataList[obj].chemicalName,
          name: this.allChemicalDataList[obj].commonName,
          casnumber: this.allChemicalDataList[obj].casnumber
        };
        this.multiDropdownList.push(newName);
      };

    },
      error => {
        //alert("We faced some technical issues");
      });
  }

  DropdownBtnAdd(val) {
    var indexSel = this.selectedItems.indexOf(val);
    if (indexSel == -1) {
      this.selectedItems.push(val);
    }
  }

  RemoveDataFromList(item) {
    var foundIndex;
    for (var i = 0; i < this.selectedItems.length; i++) {
      if (this.selectedItems[i].id == item.id) {
        foundIndex = i;
      }
    }
    if (foundIndex !== -1) {
      this.selectedItems.splice(foundIndex, 1);
    }
  }

  getCommonNameList(chemicalId: string = null) {
    this.chemicalService.getCommonNameList(chemicalId).pipe(first())
      .subscribe(p => {
        this.commonNameList = p;

        for (var obj in this.commonNameList) {
          this.AllCommonName = this.AllCommonName + this.commonNameList[obj].id + ",";
          let newName = {
            id: this.commonNameList[obj].id,
            itemName: this.commonNameList[obj].name
          };
          this.dropdownList.push(newName);
        };
        this.AllCommonName = this.AllCommonName.substring(0, this.AllCommonName.length - 1);

        if (this.commonNameList.length > 1) {
          this.SpinnerService.show();
          //this.GetMultiSocialDataList(chemicalId, this.AllCommonName.toString());
        }
        else {
          this.SpinnerService.show();
          //this.GetSocialDataList(chemicalId, this.AllCommonName.toString());
        }
      },
        error => {
          //this.toastr.error(error.message);
        });
  }

  search(event) {
    var keyword = event.target.value;
    $('.searchInput').val(keyword);
    if (keyword.length >= 3) {
      this.RetrieveChemicalList(keyword, 1, 10, this.sortColumn, this.sortDirection, true, this.companyId, true);
      this.isShow = true;
    }
    else {
      this.chemicalList = null;
      this.isShow = false;
    }

  }

  SelectedChemical(id, chemicalName) {

    $("#chemicalName").val("");
    this.selectedChemicalId = null;
    this.selectedChemicalId = id;
    this.chemicalId = id;
    // this.GetEmergingDataList(id, null)
    $("#chemicalName").val(chemicalName);
    this.isShow = false;
    //this.GetEmergingDataList(id);
  }

  //onItemSelect(item: any) {
  //  $(".c-input").val("");
  //  this.multiDropdownList = [];
  //  for (var obj in this.allChemicalDataList) {
  //    let newName = {
  //      id: this.allChemicalDataList[obj].id,
  //      itemName: this.allChemicalDataList[obj].chemicalName,
  //      name: this.allChemicalDataList[obj].commonName,
  //      casnumber: this.allChemicalDataList[obj].casnumber
  //    };
  //    this.multiDropdownList.push(newName);
  //  };
  //}

  onSubmit() {
    let selectedChemicalId = "";
    //let fromDate = $('#fromDate').val();
    //let toDate = $('#toDate').val();
    if (this.selectedItems.length >= 1) {

      for (let i = 0; i < this.selectedItems.length; i++) {
        selectedChemicalId = selectedChemicalId + this.selectedItems[i]["id"] + ",";
      }
      selectedChemicalId = selectedChemicalId.substring(0, selectedChemicalId.length - 1);

      //this.GetEmergingDataList(selectedChemicalId.toString());
    }
    else {
      if (this.selectedItems.length == 0) {
        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_multi").html("");
        $("#chart_single").html("");
        $("#chart_chemical").html("");
        selectedChemicalId = this.selectedItems[0]["id"];
        //this.GetEmergingDataList(selectedChemicalId.toString());
      }


    }

  }

  onclear() {
    this.selectedItems = [];

    $("#chart_noDate").html("No Chart Data Found.");
    $("#chart_multi").html("");
    $("#chart_single").html("");
    $("#chart_chemical").html("");

    //this.GetSocialDataList(this.id, this.firstCommonName.toString(), null, null);
  }

  getSocialURL(): void {

    this.analysisService.getSocialURL().pipe(first()).subscribe(url => {
      this.socialURL = url;
    },
      error => {
        // this.toastr.error(error.message);
      });

  }

  GridHideShow(type) {
    if (type == 1) {
      this.router.navigate(['/analysis'])
    }
    else if (type == 2) {
      this.router.navigate(['/analysis-emerging-concern'])
    }
    else if (type == 3) {
      this.router.navigate(['/analysis-prioritised-chemicals'])
    }
  }

}
