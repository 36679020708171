import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './services';
import { NgxSpinnerService } from "ngx-spinner";

import * as introJs from 'intro.js/intro.js';
import { UserRoles } from './models/enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'HazEL';
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;

  /*introJS = introJs();*/
  constructor(private router: Router, public auth: AuthService,
    private SpinnerService: NgxSpinnerService, private route: ActivatedRoute,) {
    this.SpinnerService.show();
    // console.log(location.pathname);

    //if (location.pathname.toLowerCase() != '/reset-password' && !this.auth.isLoggednIn()) {
    //  if (this.route.snapshot.queryParams['returnUrl'] != null && this.route.snapshot.queryParams['returnUrl'] != "") {
    //    this.router.navigate(['/login'], { queryParams: { returnUrl: this.route.snapshot.queryParams['returnUrl'] } });
    //  }
    //  else {
    //    this.router.navigate(['/login']);
    //  }
    //  return;
    //} 

    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (location.pathname == "/") {
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
      if (this.auth.isLoggednIn()) {
        if (environment.isTrial) {
          this.router.navigate(['/analysis-dashboard']);
        }
        else {
          if (this.isSuperAdmin || this.isElemental) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/analysis-dashboard']);
          }
        }
      }
    }

    this.SpinnerService.hide();

    //this.introJS.setOptions({
    //  steps: [
    //    {
    //      intro: "Hello world!"
    //    },
    //    {
    //      element: document.querySelector('#step1'),
    //      intro: "This is a tooltip."
    //    },
    //    {
    //      element: document.querySelectorAll('#step2')[0],
    //      intro: "Ok, wasn't that fun?",
    //      position: 'right'
    //    },
    //    {
    //      element: '#step3',
    //      intro: 'More features, more fun.',
    //      position: 'left'
    //    },
    //    {
    //      element: '#step4',
    //      intro: "Another step.",
    //      position: 'bottom'
    //    },
    //    {
    //      element: '#step5',
    //      intro: 'Get it, use it.'
    //    }
    //  ]
    //});

  }
  ngOnInit() {

    /* this.introJS.start();*/

  }

  logout() {
    this.auth.logout();
  }

}
