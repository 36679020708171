import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisService } from './../analysis/analysis.service';
import { AlertService, AuthService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageChemicalService } from '../managechemicals/managechemicalsservice';
import { first } from 'rxjs/operators';
import { chemicalVM } from '../models/chemicalparameterVM';
import { debug } from 'util';
import { HeaderRowOutlet } from '@angular/cdk/table';
import { GoogleChartsModule } from 'angular-google-charts';
declare var google: any;
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-social-feed',
  templateUrl: './social-feed.component.html',
  styleUrls: ['./social-feed.component.css']
})
export class SocialFeedComponent implements OnInit {

  companyId: any;
  isCompanyAdmin = false;
  sub: any;
  k: any;
  id: any;
  t: any;
  urlCommonName: any;
  singleChart: any;
  multiChart: any;
  isMulti = false;
  isSingle = false;
  showChart = false;
  public commonNameList: any[] = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  chemicalName: any;
  public chemicalList: any;
  firstCommonName: string = null;
  AllCommonName: string = null;
  public EmergingList: any;
  public MultiEmergingList: any;

  public dynamicThis = this;
  public socialURL: string = null;

  getGoogle() {
    return google;
  }
  constructor(

    private analysisService: AnalysisService,
    //private alertService: AlertService,
    //private confirmationDialogService: ConfirmationDialogService,
    private chemicalService: ManageChemicalService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    //private pagerService: PagerService,
    //private toastr: ToastrService,
    //private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.getGoogle(),
    google.charts.load('current', { packages: ['corechart', 'table'] });
  }

  ngOnInit() {
    this.getGoogle();
    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });

    this.sub = this.route.params.subscribe(params => {
      this.t = params['t'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }
    this.getSocialURL();
    this.getCommonNameList(this.id);

    if (this.id) {
      this.getChemical(this.id)
    }

    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Synonyms",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "form-control"
    };
    //google.charts.load('current', { packages: ['corechart'] });
    google.charts.load('current', { packages: ['corechart', 'table'] });
  }

  //GetEmergingDataList() {
  //  this.SpinnerService.show();
  //  this.analysisService.getAllEmerging().subscribe(data => {
  //    this.EmergingList = data;
  //    this.getEmergingData(this.EmergingList);
  //    this.SpinnerService.hide();
  //  },
  //    error => {
  //      alert("We faced some technical issues");
  //    });
  //}

  GetSocialDataList(chemicalId: string = null, commonNameId: string = null, fromDate: string = null, toDate: string = null): void {
    this.SpinnerService.show();
    this.analysisService.getAllSocial(chemicalId, commonNameId, fromDate, toDate).subscribe(data => {
      this.getGoogle();
      $("#chart_multi").html("");
      var chartWidth = $(".content_btm").width() - 50;
      var optionsEmerging = {
        tooltip: { isHtml: true },

        legend: { position: 'Left' },
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Value'
        },
        colors: ['#F5190B', '#080808', '#AFFF33']
      };

      this.EmergingList = data;
      if (this.EmergingList.length < 1) {
        $("#chart_noDate").html("No Chart Data Found");
        $("#emergingConcern_chart").html("");
      }
      else {
        $("#chart_noDate").html("");
      }
      var data1 = new google.visualization.DataTable();
      data1.addColumn('string', "date");
      data1.addColumn('number', "Negative");
      data1.addColumn('number', "Neutral");
      data1.addColumn('number', "Positive");


      var Chart = new google.visualization.LineChart(document.getElementById('emergingConcern_chart'));

      var recordData = [];
      var currentDate = null;
      $.each(this.EmergingList, function (key, value) {
        if (currentDate != value.date) {
          let currentData = _.filter(data, function (el) {
            return el.date == value.date;
          });
          let positiveValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 3;
          });
          let negativeValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 1;
          });
          let nutralValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 2;
          });
          currentDate = value.date;
          var pCount = positiveValue.length > 0 ? positiveValue[0].positiveCount : 0;
          var neCount = negativeValue.length > 0 ? negativeValue[0].negativeCount : 0;
          var nuCount = nutralValue.length > 0 ? nutralValue[0].neutralCount : 0;
          recordData.push([value.date, neCount, nuCount, pCount]);
        }
      });

      data1.addRows(recordData);
      if (this.EmergingList.length > 0) {
        Chart.draw(data1, optionsEmerging);
      }
      google.visualization.events.addListener(Chart, 'select', selectHandler);

      var currentThis = this;

      function selectHandler() {
        var selection = Chart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];

          let currentData = _.filter(data, function (el) {
            var str = data1.getFormattedValue(item.row, 0);
            return el.date == str;
          });

          if (currentData != null && currentData.length > 0) {
            let lineData = _.filter(currentData, function (el) {
              return el.citationSentimentId == item.column;
            });
            if (lineData != null && lineData.length > 0) {
              var commonNameKeyWordId = lineData[0].commonNameKeywordId;
              var citationTypeId = lineData[0].citationTypeId;
              var citationSentimentId = lineData[0].citationSentimentId;
              var recordDate1 = lineData[0].date;
              var date = recordDate1.split("/");
              var newDate = date[2] + '-' + date[1] + '-' + date[0];
              
              var href = currentThis.socialURL.replace("{0}", commonNameKeyWordId).replace("{1}", citationTypeId).replace("{2}", citationSentimentId).replace("{3}", newDate);
            }
            else {
              var commonNameKeyWordId = currentData[0].commonNameKeywordId;
              var citationTypeId = currentData[0].citationTypeId;
              var citationSentimentId = item.column;
              var recordDate1 = currentData[0].date;
              var date = recordDate1.split("/");
              var newDate = date[2] + '-' + date[1] + '-' + date[0];

              var href = currentThis.socialURL.replace("{0}", commonNameKeyWordId).replace("{1}", citationTypeId).replace("{2}", citationSentimentId).replace("{3}", newDate);
            }

          }
        }
        if (message == '') {
          message = 'nothing';
        }

        window.open(href, '_blank');
      }


      //if (this.EmergingList.length > 0) {
      //  this.singleChart.draw(data1, optionsEmerging);
      //}

      this.SpinnerService.hide();
    },
      error => {
        // alert("We faced some technical issues");
      });
  }

  GetMultiSocialDataList(chemicalId: string = null, commonNameId: string = null, fromDate: string = null, toDate: string = null): void {

    this.analysisService.getMultiSocial(chemicalId, commonNameId, fromDate, toDate).subscribe(data => {
      this.getGoogle();
      $("#emergingConcern_chart").html("");
      var chartWidth = $(".content_btm").width() - 50;
      var optionsMultiEmerging = {
        title: 'Social Feed Chart',
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Value'
        }
      };

      this.MultiEmergingList = data;
      var cnList = this.dropdownList;
      if (this.MultiEmergingList.length < 1) {
        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_multi").html("");
      }
      else {
        $("#chart_noDate").html("");
      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      this.MultiEmergingList.forEach((value) => {
        Object.keys(value).forEach((key) => {
          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })
        count = count + 1;
      })

      let mainValue = [];
      let dataList: string[] = [];

      this.MultiEmergingList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);
      })
      // Add data.
      data1.addRows(mainValue);
      var multiChart = new google.visualization.LineChart(document.getElementById('chart_multi'));
      if (this.MultiEmergingList.length > 0) {
        multiChart.draw(data1, optionsMultiEmerging);
      }

      google.visualization.events.addListener(multiChart, 'select', selectHandler);

      var cid = "";
      var currentClass = this;
      function selectHandler() {
        var selection = multiChart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(cnList, function (el) { return el.itemName == str; });
          cid = selectedDate[0].id;
          let newName = { id: cid, itemName: str };
          currentClass.selectedItems = [];
          currentClass.selectedItems.push(newName);
        }
        currentClass.GetSocialDataList(currentClass.id, cid.toString());
      }
      this.SpinnerService.hide();

    }, error => {
      // alert("We faced some technical issues");
    });
  }

  getChemical(chemicalId: string = null): void {
    if (chemicalId) {
      this.chemicalService.getById(chemicalId).pipe(first()).subscribe(userData => {
        this.chemicalList = userData;
        this.chemicalName = this.chemicalList[0]["chemicalName"];
        this.SpinnerService.hide();
      },
        error => {
          // this.toastr.error(error.message);
        });
    }
  }

  getCommonNameList(chemicalId: string = null) {

    this.chemicalService.getCommonNameList(chemicalId).pipe(first())
      .subscribe(p => {
        $("#emergingConcern_chart").html("");
        this.commonNameList = p;

        for (var obj in this.commonNameList) {
          this.AllCommonName = this.AllCommonName + this.commonNameList[obj].id + ",";
          if (obj == "0") { this.firstCommonName = this.commonNameList[obj].id }

          let newName = { id: this.commonNameList[obj].id, itemName: this.commonNameList[obj].name };
          this.dropdownList.push(newName);
          this.selectedItems.push(newName);
        };
        if (this.AllCommonName != null) {
          this.AllCommonName = this.AllCommonName.substring(0, this.AllCommonName.length - 1);
          if (this.commonNameList.length > 1) {
            this.isMulti = true;
            this.SpinnerService.show();
            this.GetMultiSocialDataList(this.id, this.AllCommonName.toString());
          }
          else {
            this.isSingle = true;
            this.SpinnerService.show();
            this.GetSocialDataList(this.id, this.AllCommonName.toString());
          }
        }
        else {
          $("#chart_noDate").html("No Chart Data Found.");
          $("#chart_multi").html("");
          $("#emergingConcern_chart").html("");
        }
      },
        error => {
          //this.toastr.error(error.message);
        });
  }

  //getEmergingData(data) {
  //  if (data != null && data.length > 0) {
  //    this.showChart = true;
  //    var recordData = [];
  //    $.each(data, function (key, value) {
  //      recordData.push([value.date, value.positiveCount, value.neutralCount, value.negativeCount]);
  //    });
  //    this.dataEmerging = recordData;
  //  }
  //  else {
  //    this.showChart = false;
  //  }
  //}

  onSubmit() {
    let d = this.selectedItems;
    let commonNameId = "";
    //let fromDate = $('#fromDate').val();
    //let toDate = $('#toDate').val();
    if (this.selectedItems.length > 1) {

      for (let i = 0; i < this.selectedItems.length; i++) {
        commonNameId = commonNameId + this.selectedItems[i]["id"] + ",";
      }
      commonNameId = commonNameId.substring(0, commonNameId.length - 1);

      this.GetMultiSocialDataList(this.id, commonNameId.toString(), null, null)
    }
    else {
      commonNameId = this.selectedItems[0]["id"];
      this.GetSocialDataList(this.id, commonNameId.toString(), null, null);

    }

  }

  onclear() {
    let commonNameId = $('#commonName').val(this.firstCommonName);
    //let fromDate = $('#fromDate').val();
    //let toDate = $('#toDate').val();
    this.selectedItems = [];

    $("#chart_noDate").html("No Chart Data Found.");
    $("#chart_multi").html("");
    $("#emergingConcern_chart").html("");

    //this.GetSocialDataList(this.id, this.firstCommonName.toString(), null, null);
  }

  backButton() {
    var kval = '';
    if (this.k != '' && this.k != undefined) {
      kval = this.k;
    }
    if (this.t == 1) {
      this.router.navigate(['/manage-chemicals',kval]);
    }
    else if (this.t == 3) {
      this.router.navigate(['/analysis-emerging-concern', this.id]);
    }
    else {
      this.router.navigate(['/view-all-chemical', kval]);
    }
  }


  getSocialURL(): void {

    this.analysisService.getSocialURL().pipe(first()).subscribe(url => {
      this.socialURL = url;
    },
      error => {
        // this.toastr.error(error.message);
      });

  }

}
