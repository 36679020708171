import { ThirtySevenDigitDecimaNumberDirective } from "../helpers/thirtyseven-digit-decima-number.directive";

export class chemicalparameterVM {
  ParentName: string;
  ParameterId: string;
  ParameterName: string;
  ParameterCode: string;
  IsNumeric: boolean;
  ParameterType: number;
  ChemicalId: string;
  ChemicalParameterId: string;
  ParameterValue: string;
  UnitOfMeasureId: string;
  Source: string;
  Comments: string;
  ModelSpecies: string;
  CreatedDate: string;
  SourceCompany: string;
  SourceUser: string
}
export class newchemicalparameterVM {
  parentName: string;
  ParameterId: string;
  ParameterName: string;
  ParameterCode: string;
  IsNumeric: boolean;
  ParameterType: number;
  ChemicalId: string;
  ChemicalParameterId: string;
  ParameterValue: string;
  UnitOfMeasureId: string;
  Source: string;
  Comments: string;
  ModelSpecies: string;
  CreatedDate: string;
  SourceCompany: string;
  SourceUser: string;
  parameterlookuplist: string[];
  LookUpId: number;
  domainId: string;
  isMultiDomainParameter: boolean;
  domainName: string;
}

export class chemicalParameterPostModel {
  chemicalId: string;
  caseNo: string;
  chemicalName: string;
  molecularWeight: string;
  source: string;
  commonName: string;
  oldCommonName: string;
  chemicalCategoryPost: chemicalCategoryPostVM[];
  parameterValue: chemicalParameterValuePostVM[];
  isChangeType: number;
  chemicalCategoryList: string;
  isPrivate: boolean;
  companyId: string;
  comments: string;
}


export class chemicalParameterValuePostModel {
  cpid: string;
  pid: string;
  val: string;
  s: string;
  c: string;
  m: string;
  cid: string;
  changeType: number;
}

export class chemicalCategoryPostVM {
  id: string;
}

export class chemicalParameterValuePostVM {
  cpid: string;
  pid: string;
  val: string;
  s: string;
  c: string;
  m: string;
  did: string;
}

export class chemicalVM {
  id: string;
  casnumber: string;
  chemicalName: string;
  molecularWeight: string;
  source: string;
  isPrivate: boolean;
  commonNameKeyword: any[];
  chemicalCategoryJoin: chemicalCategoryPostVM[];
}


export class parameterHistoryVM {
  parameterName: string;
  parameterValue: string;
  sourceCompany: string;
  sourceUser: string;
  comments: string;
  valueModelSpecies: string;
  createdDate: string;
  domainName: string;
}

export class ExportChemicalParameterVM {
  ParameterId: string;
  ParameterName: string;
  ParameterValue: string;
  Source: string;
  Comments: string;
  ModelSpecies: string;
}
export class AddExportVM {
  parentName: string;
  parameterId: string;
  parameterName: string;
  parameterCode: string;
  isNumeric: boolean;
  parameterType: number;
  chemicalId: string;
  chemicalParameterId: string;
  parameterValue: string;
  unitOfMeasureId: string;
  source: string;
  comments: string;
  modelSpecies: string;
  createdDate: string;
  sourceCompany: string;
  sourceUser: string
}

export class ChangedParameterVM {
  Id: string;
  Casnumber: string;
  chemicalId: string;
  parameterId: string;
  CurrentValue: string;
  NewValue: string;
  companyId: string;
  SelectedChemicalName: string;
  comment: string;
  reference: string;
  domainId: string;
}

export class ecDataVM {
  id: string;
  chemicalId: string;
  casnumber: string;
  criterion1: boolean;
  criterion2: boolean;
  createdDate: string;
  publishedDate: string;
  doi:string;
}

export class ecScoreHistoryVM {
  id: string;
  chemicalId: string;
  ecscore: number;
  createdDate: string;
  labelCreatedDate: string;
  publishedDate: string;
  doi: string;
  orderId:number;
}