import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { ActivatedRoute, Router } from '@angular/router';
import { ChemicalValueRequestVM } from '../../models/chemicalValueRequestVM';
import { ToastrService } from 'ngx-toastr';
import { ChemicalValueRequestService } from '../data-request.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service'
import { PagerService } from '../../helpers/pager.service'
import { NgxSpinnerService } from "ngx-spinner";
import { DropChemicalParameterListVm } from '../../models/chemicalValueRequestVM';
declare var $: any;
import * as _ from 'lodash';

@Component({
  selector: 'app-add-data-request',
  templateUrl: './add-data-request.component.html',
  styleUrls: ['./add-data-request.component.css']
})
export class AddDataRequestComponent implements OnInit {
  private postRequestVM: any;
  public dataRequestList: ChemicalValueRequestVM[];
  public parameterList: DropChemicalParameterListVm[];
  public parameterValueList: DropChemicalParameterListVm[];
  public selectedParameterList: DropChemicalParameterListVm[];
  public chemicalList: any;
  public pager: any = {};
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  public pagedItems: any[];
  public filter: string = '';
  isShow: boolean = false;
  selectedChemicalId = "";
  currentValue: any;
  options: any;
  isReadOnly: boolean = true;
  isEdit = false;
  companyId: any;
  requestForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  isDropdown: boolean = false;
  constructor(
    private chemicalValueRequestService: ChemicalValueRequestService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.SpinnerService.show();
    this.companyId = localStorage.getItem("CompanyId");
    // this.getParameterList(this.id);
    this.RetrieveChemicalList('', 1, 10, '', '', true, this.companyId, true);
    this.requestForm = this.formBuilder.group({
      id: [''],
      parameterId: ['', TrimValidators.required],
      currentValue: [''],
      newValue: ['', TrimValidators.required],
      companyId: [],
      chemicalId: ['', TrimValidators.required],
      comment: [''],
      reference: ['']
    }
    );

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id) {
      this.isEdit = true;

      this.editRequest(this.id)
    }
  }

  get f() { return this.requestForm.controls; }

  private getParameterList(cid: string, pid: string) {
    this.chemicalValueRequestService.getParameterList(cid).pipe(first())
      .subscribe(p => {
        this.parameterList = p;

        this.parameterValueList = _.filter(this.parameterList, function (el) {
          return el.id == pid && el.lookUpId != null;
        });
        if ((this.parameterValueList != null && this.parameterValueList.length > 0) && (this.parameterValueList != null && this.parameterValueList[0].parameterlookuplist != null)) {
          this.isDropdown = true;
        }

      },
        error => {
          this.toastr.error(error.message);
        });
  }

  editRequest(Id: string = null): void {
    if (Id) {
      this.chemicalValueRequestService.getById(Id).pipe(first()).subscribe(requestData => {

        if (requestData != null) {

          this.postRequestVM = requestData;
          this.requestForm.setValue({
            id: this.postRequestVM.id,
            newValue: this.postRequestVM.newValue,
            currentValue: this.postRequestVM.currentValue,
            chemicalId: this.postRequestVM.chemicalId,
            parameterId: this.postRequestVM.parameterId,
            companyId: this.postRequestVM.companyId,
            comment: this.postRequestVM.comment,
            reference: this.postRequestVM.reference,
          })

          $("#chemicalName").val("");

          $("#chemicalName").val(this.postRequestVM.selectedChemicalName);
          this.selectedChemicalId = null;
          this.selectedChemicalId = this.postRequestVM.chemicalId;
          this.getParameterList(this.postRequestVM.chemicalId, this.postRequestVM.parameterId);
        }
      });
    }
  }

  onSubmit() {

    this.submitted = true;
    if (this.requestForm.invalid) {
      return;
    }
    this.loading = true;
    this.requestForm.value.companyId = localStorage.getItem('CompanyId');
    this.requestForm.value.chemicalId = this.selectedChemicalId;

    if (this.id) {
      this.chemicalValueRequestService.update(this.requestForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/data-request']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
    else {
      this.chemicalValueRequestService.create(this.requestForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/data-request']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  onAddRequestCancelClick() {

    this.router.navigate(['/data-request']);
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {
    this.SpinnerService.hide();
    this.chemicalValueRequestService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.chemicalList = data
    },
      error => {
        //alert("We faced some technical issues");
      });
  }

  search(event) {

    var keyword = event.target.value;
    $('.searchInput').val(keyword);
    if (keyword.length >= 3) {
      this.RetrieveChemicalList(keyword, 1, 10, this.sortColumn, this.sortDirection, true, this.companyId, true);
      this.isShow = true;
    }
    else {
      this.chemicalList = null;
      this.isShow = false;
    }

  }

  SelectedChemical(id, chemicalName) {
    $("#chemicalName").val("");
    this.selectedChemicalId = null;
    this.selectedChemicalId = id;
    this.getParameterList(id, null)
    $("#chemicalName").val(chemicalName);
    this.isShow = false;
  }

  onChangeEvent(evt) {
    const selectEl = event.target;
    this.currentValue = selectEl["options"][selectEl['selectedIndex']].getAttribute('data_val');
    this.isReadOnly = true;

    this.parameterValueList = _.filter(this.parameterList, function (el) {
      return el.id == evt.currentTarget.value && el.lookUpId != null;
    });
    if ((this.parameterValueList != null && this.parameterValueList.length > 0) && (this.parameterValueList != null && this.parameterValueList[0].parameterlookuplist != null)) {
      this.isDropdown = true;
    }
    this.requestForm.value.newValue = null;
  }
}

