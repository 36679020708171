import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReleaseHistoryService } from './release-history.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { PagerService } from '../helpers/pager.service';

@Component({
  selector: 'app-release-history',
  templateUrl: './release-history.component.html',
  styleUrls: ['./release-history.component.css']
})
export class ReleaseHistoryComponent implements OnInit {

  public releaseHistoryList: any[];
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;

  constructor(private spinnerService: NgxSpinnerService, private releaseHistoryService: ReleaseHistoryService,
    private toastr: ToastrService, private confirmationDialogService: ConfirmationDialogService,
    private pagerService: PagerService
  ) { }

  ngOnInit() {
    this.getAllReleaseHistoryList();
  }

  private getAllReleaseHistoryList() {
    this.spinnerService.show();
    this.releaseHistoryService.getAll().pipe(first())
      .subscribe(res => {
        this.releaseHistoryList = res;
        if (this.releaseHistoryList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.spinnerService.hide();
        });
  }

  public openPublishConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to publish ?')
      .then((confirmed) =>
        confirmed === true ? this.publishReleaseHistory(id) : ""
      )
      .catch(
        //(e) =>
      );
  }

  publishReleaseHistory(id: any) {
    this.releaseHistoryService.publish(id).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
        this.toastr.success(data['message']);
        this.getAllReleaseHistoryList();
      } else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.error.message);
      });
  }

  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteById(id) : ""
      )
      .catch(
        //(e) =>
      );
  }

  deleteById(id: any) {
    this.releaseHistoryService.delete(id).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
        this.toastr.success(data['message']);
        this.getAllReleaseHistoryList();
      } else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.error.message);
      });
  }

  setPage(page: number) {
    this.spinnerService.show();
    this.pager = this.pagerService.getPager(this.releaseHistoryList.length, page);
    this.pagedItems = this.releaseHistoryList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.spinnerService.hide();
  }

}
