import { Component, OnInit, Input, Directive, ElementRef, Inject, Injectable } from '@angular/core';
import { chemicalparameterVM, chemicalCategoryPostVM, chemicalParameterPostModel, chemicalParameterValuePostVM, chemicalVM, parameterHistoryVM, newchemicalparameterVM, chemicalParameterValuePostModel, ecDataVM, ecScoreHistoryVM } from '../../models/chemicalparameterVM';
import { AlertService } from '../../services';
import { ManageChemicalService } from '../managechemicalsservice';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from '../../helpers/pager.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { UserRoles } from '../../../app/models/enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

import 'rxjs/add/operator/toPromise';
declare var google: any;
import * as _ from 'lodash';
declare var $: any;
import { ChemicalValueRequestService } from '../../../../src/app/data-request/data-request.service';
import { TrimValidators } from '../../helpers/custom-validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPreferencesVM } from '../../models/userActivityFilterVM';
import { Guid } from 'guid-typescript';
import { Sort } from '@angular/material';
//import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-viewchemicaldetails',
  templateUrl: './viewchemicaldetails.component.html',
  styleUrls: ['./viewchemicaldetails.component.css']
})

export class ViewchemicaldetailsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public parameterList: newchemicalparameterVM[];
  public keyList: newchemicalparameterVM[];
  public weightedList: newchemicalparameterVM[];
  public calculatedList: newchemicalparameterVM[];
  public chemicalParameterPostModel: chemicalParameterValuePostModel;
  public ecScoreData: newchemicalparameterVM;
  public ecDataList: ecDataVM[];
  public ecScoreHistoryList: ecScoreHistoryVM[];
  public newECScoreCalculation: ecScoreHistoryVM[];
  public keyValuePairs: Array<{ key: number, value: number }> = [];


  public userPreferencesVM: UserPreferencesVM;
  public isECExists: boolean = false;
  sub: any;
  id: any;
  k: any;
  type: any;
  companyId: any;
  loginType: string = "";
  isAdmin = false;
  public commanNameString: string = "";
  public selectedItems: string = "";
  public counters: number = 0;
  public chemicalData: any;
  public parameterHistory: parameterHistoryVM[];
  isKey = true;
  isWeighted = false;
  isCalculated = false;
  isHistoricData = false;
  defultParameter = 1;
  isUp = false;
  isDown = true;
  isHistoryUp = false;
  isHistoryDown = true;
  isCompanyLogin = false;
  isAdminOrElemental = false;
  isShowCommonName = true;
  isShowComments = true;
  isUpComments = false;
  isDownComments = true;
  public editParameterModel = null;
  public viewParameterModel = null;
  public parameterRequestModel: any = null;
  public iserror = false;
  public parameterNewValue: number = null;
  parameterlookuplist: string[];
  isValueChange = false;
  isOtherChange = false;
  requestForm: FormGroup;
  valueRequestForm: FormGroup;
  loading = false;
  submitted = false;
  isNumeric = false;
  isLink = false;
  currentDate: any;
  date: any;
  public parameterName: string = null;
  public parameterCurrentValue: number = null;

  displayDate: string = null;
  displayUser: string = null;
  displayCompany: string = null;
  displayVal: string = null;
  displayRef: string = null;
  displayComment: string = null;
  displayModel: string = null;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;

  public toxicityParaCount: number = 0;
  public exposureParaCount: number = 0;

  public toxicityRed: number = environment.toxicityRed;
  public toxicityOrange: number = environment.toxicityOrange;
  public toxicityGreen: number = environment.toxicityGreen;
  public exposureRed: number = environment.exposureRed;
  public exposureOrange: number = environment.exposureOrange;
  public exposureGreen: number = environment.exposureGreen;
  public doiUrl: string = environment.doiUrl;

  getGoogle() {
    return google;
  }

  constructor(
    private chemicalService: ManageChemicalService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private chemicalValueRequestService: ChemicalValueRequestService,
    private modalService: NgbModal,
    //private datepie: DatePipe
  ) {
    for (let i = 30; i <= 360; i += 30) {
      this.keyValuePairs.push({ key: i, value: i });
    }
  }

  ngOnInit() {
    this.companyId = localStorage.getItem("CompanyId");
    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
      this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
    }
    this.SpinnerService.show();
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.type = params['type'];
    });
    this.companyId = localStorage.getItem('CompanyId');
    this.loginType = localStorage.getItem('userType');
    this.loginType = this.loginType.replace(/"/g, "");
    if (this.loginType == 'SuperAdmin' || this.loginType == 'Elemental') {
      this.isAdmin = true;
    }

    if (this.id) {

      this.getAllParameter(this.id);
      this.getChemical(this.id)
      this.getECScore();
      this.getECDataAndECScoreHistory();

    }
    if (this.companyId != null && this.companyId != "null") {
      this.isCompanyLogin = true;
    }

    this.dtOptions = {
      paging: false,
      info: false,
      search: {
        "caseInsensitive": true
      }
    };

    (function ($) {
      $(document).ready(function () {
        $("[data-toggle=popover]").popover({
          html: true,
          content: function () {
            return $('#popover-content').html();
          }
        });
      });
    });

    this.requestForm = this.formBuilder.group({
      id: [''],
      parameterId: [''],
      currentValue: [''],
      newValue: ['', TrimValidators.required],
      companyId: [localStorage.getItem('CompanyId')],
      chemicalId: ['']
    });

    this.valueRequestForm = this.formBuilder.group({
      viewValueId: [''],
      viewSourceLink: [''],
      viewsourceCompany: [''],
      viewsourceUser: [''],
      viewcomment: [''],
      viewmodel: [''],
      viewdate: [''],
      viewParameterValue: [''],
      viewParameterId: [''],
      viewChemicalId: [''],
      viewChemicalParameterId: [''],
      viewIsNumeric: ['']
    });

    this.showHistoryData('show');
    this.showCommonName('hide');
    this.showComments('hide');
  }

  private getAllParameter(id: string) {
    if (this.isCompanyAdmin || this.isUsers) {
      this.chemicalService.getAllParameterByCompany(id, this.defultParameter, this.companyId).pipe(first())
        .subscribe(parameter => {
          //this.parameterList = parameter;
          var selectedDomainId = localStorage.getItem("domainId");
          this.parameterList = parameter.filter(x => x.domainId == null || x.domainId == selectedDomainId);
          this.parameterList = this.parameterList.filter(x => x["parameterCode"] != "EC4" && x["parameterCode"] != "EC12");
          this.filterParameterList();
          this.SpinnerService.hide();
        },
          error => {
            this.toastr.error(error.message);
          });
    }
    else {
      this.chemicalService.getAllParameter(id, this.defultParameter, this.companyId).pipe(first())
        .subscribe(parameter => {
          this.parameterList = parameter;
          this.parameterList = this.parameterList.filter(x => x["parameterCode"] != "EC4" && x["parameterCode"] != "EC12");
          this.filterParameterList();
          this.SpinnerService.hide();
        },
          error => {
            this.toastr.error(error.message);
          });
    }
  }

  filterParameterList() {
    let data = _.filter(this.parameterList, function (el) {
      return el.parameterValue != null && el.parameterValue != "";
    });

    this.keyList = _.filter(this.parameterList, function (el) {
      return el.parameterType == 1 && el.parameterValue != null;
    });

    this.keyList = this.keyList.filter(x => x["parameterCode"] != "EP40");

    this.isECExists = this.keyList.filter(x => x.parentName == "Emerging Concern").length <= 0 ? true : false;

    if (this.isAdmin) {
      this.counters = data.length;
    } else {
      this.counters = this.keyList.length;
    }

    this.weightedList = _.filter(this.parameterList, function (el) {
      return el.parameterType == 1 && el.parameterValue != null;
    });

    this.calculatedList = _.filter(this.parameterList, function (el) {
      return el.parameterType == 1 && el.parameterValue != null;
    });

    this.toxicityParaCount = this.parameterList.filter(x => x.parentName == "Toxicity" && x["parameterValue"] != null && x["parameterValue"] != "").length;
    this.exposureParaCount = this.parameterList.filter(x => x.parentName == "Exposure" && x["parameterValue"] != null && x["parameterValue"] != "").length;
  }

  getClass(type: string) {
    if (type === 'Toxicity') {
      if (this.toxicityParaCount <= this.toxicityRed) {
        return 'redCircle'
      }
      else if (this.toxicityRed < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityOrange) {
        return 'orangeCircle'
      }
      else if (this.toxicityOrange < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityGreen) {
        return 'greenCircle'
      }
    }
    else if (type === 'Exposure') {
      if (this.exposureParaCount <= this.exposureRed) {
        return 'redCircle'
      }
      else if (this.exposureRed < this.exposureParaCount && this.exposureParaCount <= this.exposureOrange) {
        return 'orangeCircle'
      }
      else if (this.exposureOrange < this.exposureParaCount && this.exposureParaCount <= this.exposureGreen) {
        return 'greenCircle'
      }
    }
  }

  getTooltip(type: string): string {
    if (type === 'Toxicity') {
      if (this.toxicityParaCount <= this.toxicityRed) {
        return 'High uncertainty';
      } else if (this.toxicityRed < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityOrange) {
        return 'Moderate uncertainty';
      } else if (this.toxicityOrange < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityGreen) {
        return 'Low uncertainty';
      }
    } else if (type === 'Exposure') {
      if (this.exposureParaCount <= this.exposureRed) {
        return 'High uncertainty';
      } else if (this.exposureRed < this.exposureParaCount && this.exposureParaCount <= this.exposureOrange) {
        return 'Moderate uncertainty';
      } else if (this.exposureOrange < this.exposureParaCount && this.exposureParaCount <= this.exposureGreen) {
        return 'Low uncertainty';
      }
    }
    return ''; // Default tooltip text if no condition matches
  }

  getChemical(chemicalId: string = null): void {

    if (chemicalId) {
      this.chemicalService.getById(chemicalId).pipe(first()).subscribe(userData => {
        this.chemicalData = userData[0];

        if (this.chemicalData.commonNameKeyword.length > 0) {
          for (let i = 0; i < this.chemicalData.commonNameKeyword.length; i++) {
            if (this.commanNameString != null && this.commanNameString != "") {
              this.commanNameString += ", ";
            }
            this.commanNameString = this.commanNameString + this.chemicalData.commonNameKeyword[i]["title"];
          }
        }

        if (this.chemicalData.categoryDropDownBind.length > 0) {
          for (let k = 0; k < this.chemicalData.categoryDropDownBind.length; k++) {
            if (this.selectedItems != null && this.selectedItems != "") {
              this.selectedItems += ", ";
            }
            this.selectedItems = this.selectedItems + this.chemicalData.categoryDropDownBind[k]["categoryName"];
          }
        }

      });
    }
  }

  getECScore() {
    this.chemicalService.getECScoreByChemicalId(this.id).subscribe(res => {
      this.ecScoreData = new newchemicalparameterVM();
      this.ecScoreData["chemicalId"] = res["chemicalId"];
      this.ecScoreData["parameterName"] = "AI Research";
      this.ecScoreData["parameterValue"] = res["ecscore1"];
      this.ecScoreData["source"] = res["doi"] != null ? environment.doiUrl + res["doi"] : "";
      this.ecScoreData["sourceCompany"] = "";
      this.ecScoreData["sourceUser"] = "";
      this.ecScoreData["comments"] = "";
      this.ecScoreData["modelSpecies"] = "";
      this.ecScoreData["createdDate"] = res["createdDate"];
      this.ecScoreData["parameterId"] = "";
      this.ecScoreData["chemicalParameterId"] = "";
      this.ecScoreData["isNumeric"] = "";
    });
  }

  getECDataAndECScoreHistory() {
    this.chemicalService.getECDataAndECScoreHistoryByChemicalId(this.id).subscribe(res => {
      console.log(res);
      this.ecDataList = res["ecDataList"];
      this.ecScoreHistoryList = res["ecScoreHistoryList"];

      // this.ecDataList = [];
      // for (let index = 0; index < 10; index++) {
      //   const element = res["ecDataList"][0];
      //   this.ecDataList.push(element);
      // }
    });
  }

  openParameterList(type) {
    this.isKey = false;
    this.isWeighted = false;
    this.isCalculated = false;
    $("#key").removeClass();
    $("#weighted").removeClass();
    $("#calculated").removeClass();
    if (type == 'key') {
      this.isKey = true;
      $("#key").addClass("btn btn_primary");
      $("#weighted").addClass("btn btn_bordered");
      $("#calculated").addClass("btn btn_bordered");
    }
    if (type == 'weighted') {
      this.isWeighted = true;
      $("#weighted").addClass("btn btn_primary");
      $("#key").addClass("btn btn_bordered");
      $("#calculated").addClass("btn btn_bordered");
    }
    if (type == 'calculated') {
      this.isCalculated = true;
      $("#calculated").addClass("btn btn_primary");
      $("#weighted").addClass("btn btn_bordered");
      $("#key").addClass("btn btn_bordered");
    }
  }

  viewHistoryData() {
    this.isHistoricData = !this.isHistoricData;
    if (this.id && this.isHistoricData) {
      this.chemicalService.getAllParameterHistory(this.id).pipe(first()).subscribe(Data => {
        this.parameterHistory = Data;

      });
    }
  }

  showPopUp(e) {

    var data = e.currentTarget;
    // $(this).val(); 

    $("[data-toggle=popover]").popover({
      html: true,
      content: function () {
        return $('#' + data.id).html();
      }
    });

  }

  backButton() {
    if (this.isAdmin) {
      if (this.type == "2") {
        this.router.navigate(['/view-all-chemical', this.k]);
      }
      else {
        this.router.navigate(['/manage-chemicals', this.k]);
      }
    }
    else {
      // this.router.navigate(['/analysis-dashboard']);
      this.router.navigate(['/analysis-dashboard-classic']);
    }


  }

  showCommonName(type) {
    if (type == "show") {
      this.isUp = true;
      this.isDown = false;
      this.isShowCommonName = false;
    }
    else {
      this.isUp = false;
      this.isDown = true;
      this.isShowCommonName = true;
    }
  }

  showComments(type) {
    if (type == "show") {
      this.isUpComments = true;
      this.isDownComments = false;
      this.isShowComments = false;
    }
    else {
      this.isUpComments = false;
      this.isDownComments = true;
      this.isShowComments = true;
    }
  }

  showHistoryData(type) {
    if (type == "show") {
      this.isHistoryUp = true;
      this.isHistoryDown = false;

      if (this.id) {
        this.chemicalService.getAllParameterHistory(this.id).pipe(first()).subscribe(Data => {
          this.parameterHistory = Data;
          // this.date = this.parameterHistory[0]["createdDate"];
        });
      }
    }
    else {
      this.isHistoryUp = false;
      this.isHistoryDown = true;
    }
  }

  EditParameter(data) {
    this.submitted = false;
    this.editParameterModel = data;
    this.parameterlookuplist = [];
    this.parameterName = this.editParameterModel.parameterName;
    this.parameterCurrentValue = this.editParameterModel.parameterValue;
    this.requestForm.value.parameterId = this.editParameterModel.parameterId;
    this.requestForm.value.currentValue = this.editParameterModel.parameterValue;
    this.requestForm.value.companyId = localStorage.getItem('CompanyId');
    this.requestForm.value.chemicalId = this.editParameterModel.chemicalId;
    this.requestForm.value.newValue = '';
    if (this.editParameterModel.lookUpId != null) {
      this.parameterlookuplist = this.editParameterModel.parameterlookuplist;
    }

    $('#txtNewValue').val('');
    $('#editParamterModelPopup').modal('show');
  }

  ViewParameter(data) {
    this.isLink = false;
    this.parameterlookuplist = [];
    this.submitted = false;
    this.viewParameterModel = data;
    this.parameterName = this.viewParameterModel.parameterName;

    if (this.viewParameterModel.lookUpId != null) {
      this.parameterlookuplist = this.viewParameterModel.parameterlookuplist;
    }

    this.valueRequestForm.setValue({
      viewValueId: null,
      viewSourceLink: this.viewParameterModel.source,
      viewsourceCompany: this.viewParameterModel.sourceCompany,
      viewsourceUser: this.viewParameterModel.sourceUser,
      viewcomment: this.viewParameterModel.comments,
      viewmodel: this.viewParameterModel.modelSpecies,
      viewdate: this.viewParameterModel.createdDate,
      viewParameterValue: this.viewParameterModel.parameterValue,
      viewParameterId: this.viewParameterModel.parameterId,
      viewChemicalId: this.viewParameterModel.chemicalId,
      viewChemicalParameterId: this.viewParameterModel.chemicalParameterId,
      viewIsNumeric: this.viewParameterModel.isNumeric
    })
    this.isNumeric = this.viewParameterModel.isNumeric;
    this.displayCompany = this.viewParameterModel.sourceCompany;
    this.displayUser = this.viewParameterModel.sourceUser;
    this.displayDate = this.viewParameterModel.createdDate;
    var measureValue = this.viewParameterModel.uomTitle != null ? this.viewParameterModel.uomTitle : "";
    this.displayVal = this.viewParameterModel.parameterValue + " " + measureValue;
    if (this.viewParameterModel.parameterName.toLowerCase() == "readily biodegradable") {
      this.displayVal = this.viewParameterModel.parameterValue == 1 ? "Yes" : "No";
    }

    this.displayRef = this.viewParameterModel.source;
    this.displayComment = this.viewParameterModel.comments;
    this.displayModel = this.viewParameterModel.modelSpecies;
    if (this.displayRef != "" && this.displayRef != null) {
      if (this.displayRef.toLowerCase().startsWith("http") || this.displayRef.toLowerCase().startsWith("https") || this.displayRef.toLowerCase().startsWith("www")) {
        this.isLink = true;
      }
    }

    $('#ViewParamterModelPopup').modal('show');
  }

  onSubmit() {
    this.submitted = true;
    if (this.requestForm.invalid) {
      return;
    }
    this.submitted = false;
    this.requestForm.value.parameterId = this.editParameterModel.parameterId;
    this.requestForm.value.currentValue = this.editParameterModel.parameterValue;
    this.requestForm.value.companyId = localStorage.getItem('CompanyId');
    this.requestForm.value.chemicalId = this.editParameterModel.chemicalId;

    this.chemicalValueRequestService.create(this.requestForm.value).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
        this.toastr.success(data['message']);

        if (this.keyList != null) {
          var d = _.find(this.keyList, { parameterId: this.editParameterModel.parameterId.toLowerCase() })
          d.parameterValue = this.requestForm.value.newValue;
          //this.keyList = d;
        }
        else {
          this.keyList = _.filter(this.parameterList, function (el) {
            return el.parameterType == 1 && el.parameterValue != null;
          });
          var d = _.find(this.keyList, { parameterId: this.editParameterModel.parameterId })
          d.parameterValue = this.requestForm.value.newValue;
          //this.keyList = d;
        }

        $('#editParamterModelPopup').modal('hide');
        this.editParameterModel = null;
      }
      else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.message);
      });
  }

  onValueSubmit() {
    this.submitted = true;
    if (this.valueRequestForm.invalid) {
      return;
    }
    this.submitted = false;
    this.chemicalParameterPostModel = new chemicalParameterValuePostModel();

    this.chemicalParameterPostModel.s = this.valueRequestForm.value.viewSourceLink;
    this.chemicalParameterPostModel.c = this.valueRequestForm.value.viewcomment;
    this.chemicalParameterPostModel.m = this.valueRequestForm.value.viewmodel;
    let paramValue = 0;
    if (this.isNumeric) {
      if (this.valueRequestForm.value.viewParameterValue != "" && this.valueRequestForm.value.viewParameterValue != null) {
        paramValue = this.valueRequestForm.value.viewParameterValue;
      }
    }
    else {
      paramValue = this.valueRequestForm.value.viewParameterValue;
    }
    this.chemicalParameterPostModel.val = paramValue.toString();
    this.chemicalParameterPostModel.pid = this.valueRequestForm.value.viewParameterId;
    this.chemicalParameterPostModel.cid = this.valueRequestForm.value.viewChemicalId;
    this.chemicalParameterPostModel.cpid = this.valueRequestForm.value.viewChemicalParameterId;
    if (this.isValueChange) {
      this.chemicalParameterPostModel.changeType = 1;
    }
    else if (this.isOtherChange) {
      this.chemicalParameterPostModel.changeType = 2;
    }
    else {
      this.chemicalParameterPostModel.changeType = 0;
    }

    this.chemicalService.savePopUpValue(this.chemicalParameterPostModel).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
        this.toastr.success(data['message']);

        //if (this.keyList != null) {
        //  var d = _.find(this.keyList, { parameterId: this.viewParameterModel.parameterId.toLowerCase() })
        //  d.parameterValue = this.valueRequestForm.value.viewParameterValue;
        //  //this.keyList = d;
        //}
        //else {
        //  this.keyList = _.filter(this.parameterList, function (el) {
        //    return el.parameterType == 1 && el.parameterValue != null;
        //  });
        //  var d = _.find(this.keyList, { parameterId: this.viewParameterModel.parameterId })
        //  d.parameterValue = this.valueRequestForm.value.viewParameterValue;
        //this.keyList = d;
        //}

        this.getAllParameter(this.valueRequestForm.value.viewChemicalId);
        $('#ViewParamterModelPopup').modal('hide');
        this.viewParameterModel = null;

      }
      else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.message);
      });


  }
  valueParameter() {
    this.isValueChange = true;
  }
  otherParameter() {
    this.isOtherChange = true;
  }

  onAddChemicalClick(id) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
      return;
    }
    if (id != '' && id != "" && id != null) {
      this.router.navigate(['/add-chemical', id, '']);
    }
    else {
      this.router.navigate(['/add-chemical', '', '']);
    }
  }

  renderECScoreChart() {
    var optionsECScore = {
      tooltip: { isHtml: true },
      legend: 'none',
      width: 744,
      height: 300,
      hAxis: {
        title: "Date of calculation",
      },
      vAxis: {
        title: "EC Score"
      }
    };

    var dataTable = new google.visualization.DataTable();
    dataTable.addColumn("string", "Date");
    dataTable.addColumn("number", "ECScore");
    console.log('this.ecScoreHistoryList', this.ecScoreHistoryList)
    var mainValue = [];
    this.ecScoreHistoryList.forEach((value) => {
      let subValue = [];
      subValue.push(value.labelCreatedDate);
      subValue.push(value.ecscore);
      mainValue.push(subValue);
    });
    dataTable.addRows(mainValue);

    var ecScoreChart = new google.visualization.LineChart(document.getElementById("ecScoreChart"));
    ecScoreChart.draw(dataTable, optionsECScore);
  }

  async popupECScore(content, data) {
    this.getGoogle();

    this.modalService.open(content, {
      ariaLabelledBy: 'modelPopupLabelECScore',
      windowClass: "modelPopupLabelECScore",
    }).result.then((result) => {
      if (result == "Yes") {

      }
      else if (result == "No") {

      }
    }, (reason) => {
      console.log(reason);
    });

    //this.renderECScoreChart();
    await this.getUserPreferences();
    //this.newECScoreChart();
  }

  newECScoreChart() {
    this.calculateECScores();
    var optionsECScore = {
      tooltip: { isHtml: true },
      legend: 'none',
      width: 744,
      height: 300,
      hAxis: {
        title: "Date of calculation",
      },
      vAxis: {
        title: "EC Score"
      }
    };

    var dataTable = new google.visualization.DataTable();
    dataTable.addColumn("string", "Date");
    dataTable.addColumn("number", "ECScore");
    var mainValue = [];
    this.newECScoreCalculation.forEach((value) => {
      let subValue = [];
      subValue.push(value.labelCreatedDate);
      subValue.push(value.ecscore);
      mainValue.push(subValue);
    });
    dataTable.addRows(mainValue);

    var ecScoreChart = new google.visualization.LineChart(document.getElementById("ecScoreChart"));
    ecScoreChart.draw(dataTable, optionsECScore);
  }
  keyValuePairsUpdate() {
    this.newECScoreChart();
  }
  calculateECScores() {
    this.newECScoreCalculation = [];
    const nval = Math.floor(this.userPreferencesVM.span / this.userPreferencesVM.frequency);

    for (let i = 1; i <= nval; i++) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - this.userPreferencesVM.frequency * (i - 1));
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - this.userPreferencesVM.period);
      let crit1 = 0;
      let crit2 = 0;
      let count = 0;

      for (const ec of this.ecDataList) {
        const ecPublishedDate = new Date(ec.publishedDate);
        if (ecPublishedDate >= startDate && ecPublishedDate < endDate) {
          crit1 += ec.criterion1 ? 1 : 0;
          crit2 += ec.criterion2 ? 1 : 0;
          count++;
        }
      }

      const EC_score = (crit1 + crit2) / count / 2;
      const labelCreatedDate = startDate.toLocaleDateString('en-GB') + ' to ' + endDate.toLocaleDateString('en-GB');

      this.newECScoreCalculation.push({
        id: '', // Assign proper values
        chemicalId: '', // Assign proper values
        ecscore: EC_score,
        createdDate: new Date().toISOString(), // Assign proper values
        labelCreatedDate: labelCreatedDate,
        publishedDate: '', // Assign proper values
        doi: '', // Assign proper values
        orderId: i
      });
    }

    this.newECScoreCalculation.sort((a, b) => {
      return b.orderId - a.orderId;
    });
  }
  resetDefaultParams() {
    this.userPreferencesVM = new UserPreferencesVM();
    this.userPreferencesVM.period = 180;
    this.userPreferencesVM.frequency = 30;
    this.userPreferencesVM.span = 360;

    this.newECScoreChart();
  }
  saveUserPreferences() {

    this.chemicalService.saveUserPreferences(this.userPreferencesVM).pipe(first())
      .subscribe(data => {
        var test = this.modalService.hasOpenModals();
        if (data['status'] === true) {
          this.SpinnerService.hide();
          this.toastr.success(data['message']);
          this.newECScoreChart();
        }
        else {
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.message);
        });
  }
  async getUserPreferences() {
    this.resetDefaultParams();
    return new Promise<void>((resolve, reject) => {
      this.chemicalService.getUserPreferences().pipe(first())
        .subscribe(data => {
          if (data['status'] === true) {
            this.SpinnerService.hide();
            if (data['responseDetail']) {
              //this.userPreferencesVM = new UserPreferencesVM();
              this.userPreferencesVM = data['responseDetail']
            }
            else {
              this.resetDefaultParams();
            }
          }
          else {
            this.toastr.error(data['message']);
          }
          this.newECScoreChart();
          resolve();
        },
          error => {
            this.toastr.error(error.message);
          });

    });
  }
  validateInteger(event: KeyboardEvent): void {
    // Get the input value and concatenate the pressed key
    const input = (event.target as HTMLInputElement).value + event.key;

    // Check if the input matches the integer pattern
    if (!/^\d*$/.test(input)) {
      // Prevent the default behavior if the input is not an integer
      event.preventDefault();
    }
  }

  sort(sort: Sort) {
    const data = this.ecDataList.slice();
    if (!sort.active || sort.direction === '') {
      this.ecDataList = data;
      return;
    }
    this.ecDataList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'doi': return compare(a.doi, b.doi, isAsc);
        case 'criterion1': return compare(a.criterion1, b.criterion1, isAsc);
        case 'criterion2': return compare(a.criterion2, b.criterion2, isAsc);
        default: return 0;
      }
    });
  }

}

function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
