import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { chemicalcategoriesVM } from '../models/chemicalcategoriesVM';
import { Guid } from 'guid-typescript';

@Injectable({ providedIn: 'root' })

export class ChemicalCategoriesService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<chemicalcategoriesVM[]>(`${environment.apiUrl}ChemicalCategories`);
  }

  getById(id: string) { 
    return this.http.get(`${environment.apiUrl}ChemicalCategories/GetById/` + id);
  }

  create(data:FormData) {
    return this.http.post<any>(`${environment.apiUrl}ChemicalCategories/createchemicalcategory`,data);
  }

  update(chemicalCategories: FormData) {
    return this.http.post(`${environment.apiUrl}ChemicalCategories/updatecategories`, chemicalCategories);
  }

  getCategoryList() {
    return this.http.get<any[]>(`${environment.apiUrl}ChemicalCategories/CategoryList`);
  }

  GetByCategoryId(id: string) {
    return this.http.get<chemicalcategoriesVM[]>(`${environment.apiUrl}ChemicalCategories/ParameterList/` + id)
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}ChemicalCategories/deletebyid/` + id);
  }

  activeinactive(id: string) {
    return this.http.get(`${environment.apiUrl}ChemicalCategories/activeinactive/` + id);
  }
}
