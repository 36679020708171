import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { companyBranchDataVM, companyBranchVM } from '../../models/companiesVM';
import { AlertService, AuthService } from '../../services';
import { CompaniesService } from '../companies.service';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { countryVM } from '../../models/countryListVM';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-add-company-branch',
  templateUrl: './add-company-branch.component.html',
  styleUrls: ['./add-company-branch.component.css']
})
export class AddCompanyBranchComponent implements OnInit {

  public branchVM: companyBranchDataVM;
  public countryList: countryVM[];
  branchForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  companyId: any;
  isEdit = false;
  constructor(
    private formBuilder: FormBuilder,
    private companiesService: CompaniesService,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {

    this.SpinnerService.show();

    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId == "00000000-0000-0000-0000-000000000000") {
      this.companyId = localStorage.getItem("AdminCompanyId");
    }

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.branchForm = this.formBuilder.group({
      id: [''],
      branchName: ['', TrimValidators.required],
      address: [''],
      countryId: [''],
      state: [''],
      city: [''],
      companyId: [''],
    });

    if (this.id) {
      this.isEdit = true;
      this.editBranch(this.id)
    }
    this.getCountryList();
  }

  get f() { return this.branchForm.controls; }

  private getCountryList() {
    this.companiesService.getCountryList().pipe(first())
      .subscribe(p => {
        this.countryList = p;
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }


  editBranch(branchId: string = null): void {

    if (branchId) {

      this.companiesService.getBranchById(branchId).pipe(first()).subscribe(userData => {

        if (userData != null) {
          this.branchVM = userData;

          this.branchForm.setValue({
            id: this.branchVM.id,
            branchName: this.branchVM.branchName,
            address: this.branchVM.address,
            countryId: this.branchVM.countryId,
            state: this.branchVM.state,
            city: this.branchVM.city,
            companyId: this.companyId
          })

        }
      });
    }
  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.branchForm.invalid) {
      return;
    }
    this.loading = true;

    if (this.id) {
      this.branchForm.value.countryId = this.branchForm.value.countryId.toString();
      this.branchForm.value.companyId = this.companyId;
      this.companiesService.updateCompanyBranch(this.branchForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/company-branch', this.companyId]).then(() => {
                this.toastr.success(data['message']);
              });
            }
            else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
    else {
      this.branchForm.value.countryId = this.branchForm.value.countryId.toString();
      this.branchForm.value.companyId = this.companyId;
      this.companiesService.createCompanyBranch(this.branchForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/company-branch', this.companyId]).then(() => {
                this.toastr.success(data['message']);
              });
            }
            else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  backButton() {
    this.router.navigate(['/company-branch', this.companyId]);
  }

  onAddBranchCancelClick() {
    this.router.navigate(['/company-branch', this.companyId]);
  }

}
