import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TrimValidators } from '../../helpers/custom-validators';
import { AuthService } from '../../services';
import { ReleaseHistoryService } from '../release-history.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { releaseHistoryVM } from '../../models/releaseHistoryVM';
import * as  ClassicEditor from '../../ckeditor/build/ckeditor';
import * as  customBuildEditor from '../../ckeditor/build/ckeditor';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
// import Editor from ckeditor
//  import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
// declare module '@ckeditor/ckeditor5-build-classic' {
//   const ClassicEditorBuild: any;

//   export = ClassicEditorBuild;
// }



@Component({
  selector: 'app-add-release-history',
  templateUrl: './add-release-history.component.html',
  styleUrls: ['./add-release-history.component.css']
})
export class AddReleaseHistoryComponent implements OnInit {

  public releaseHistoryModel: releaseHistoryVM = {} as releaseHistoryVM;
  releaseHistoryForm: FormGroup;
  public Editor = customBuildEditor;
  //  customEditor = Editor;
  public configOption: any;
  ckeConfig: any;
  loading = false;
  submitted = false;
  id: any;
  isEdit = false;
  // plugin = [Base64UploadAdapter];

  // config = {
  //   toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'undo', 'redo', '|', 'imageUpload', 'MediaEmbed'],  
  //   plugins: [ClassicEditor.Base64UploadAdapter]
  // }
  // TheUploadAdapterPlugin(editor) {
  //   console.info(editor)
  //   editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
  //     return new UploadAdapter(loader, '/image');
  //   };
  // }

  constructor(private authService: AuthService, private formBuilder: FormBuilder,
    private releaseHistoryService: ReleaseHistoryService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService
  ) {
    //this.Editor.defaultConfig = this.config;
  }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.releaseHistoryForm = this.formBuilder.group({
      id: [''],
      title: ['', TrimValidators.required],
      description: [''],
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    if (this.id) {
      this.isEdit = true;
      this.editReleaseHistory(this.id)
    }

    this.configOption = {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|',
        'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo']
    }

    // config = {
    //   toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'undo', 'redo', '|', 'imageUpload', 'MediaEmbed'],  
    //   plugins: [ClassicEditor.Base64UploadAdapter]
    // }

    //     this.Editor.builtinPlugins = [
    //       ClassicEditor.Base64UploadAdapter
    // ]

    // this.ckeConfig = {
    //   toolbar: {
    //     items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 
    //     'numberedList', '|', 'insertTable', '|', 'undo', 'redo', 'imageUpload',
    //     ' classicEditor', 'blockQuote', 'list', 'mediaEmbed', 'pasteFromOffice',
    //     'fontFamily', 'todoList', 'youtube'
    //   ]
    //   },
    //   table: {
    //     contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    //   },
    // };

  }

  get f() { return this.releaseHistoryForm.controls; }

  onSubmit() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.releaseHistoryForm.invalid) {
      return;
    }

    this.loading = true;

    this.releaseHistoryModel = {} as releaseHistoryVM;
    this.releaseHistoryModel.title = this.releaseHistoryForm.value.title;
    this.releaseHistoryModel.description = this.releaseHistoryForm.value.description;

    if (this.id) {
      this.releaseHistoryModel.id = this.id;
      this.releaseHistoryService.update(this.releaseHistoryModel)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/release-history']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    } else {
      this.releaseHistoryService.create(this.releaseHistoryModel)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/release-history']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  editReleaseHistory(id: string = null): void {
    if (id) {
      this.releaseHistoryService.getById(id).pipe(first()).subscribe(res => {
        if (res != null) {
          this.releaseHistoryModel = res as releaseHistoryVM;
          this.releaseHistoryForm.setValue({
            id: this.releaseHistoryModel.id,
            title: this.releaseHistoryModel.title,
            description: this.releaseHistoryModel.description
          })
        }
      });
    }
  }

}


class UploadAdapter {

  loader;  // your adapter communicates to CKEditor through this
  url;

  constructor(loader, url) {
    this.loader = loader;
    this.url = url;
    console.log('Upload Adapter Constructor', this.loader, this.url);
  }

  upload() {
    return new Promise((resolve, reject) => {
      console.log('UploadAdapter upload called', this.loader, this.url);
      console.log('the file we got was', this.loader.file);
      resolve({ default: 'http://localhost:8080/image/1359' });
    });
  }

  abort() {
    console.log('UploadAdapter abort');
  }
}
