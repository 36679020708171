import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SocialFeedVM } from '../models/socialfeedVM';
import { chemicalparameterVM, chemicalParameterPostModel, ExportChemicalParameterVM, chemicalParameterValuePostModel, ChangedParameterVM } from '../models/chemicalparameterVM';
import { Guid } from 'guid-typescript';
import { ChemicalVM } from '../models/chemicalVM';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { debug } from 'util';
import { UserPreferencesVM } from '../models/userActivityFilterVM';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({ providedIn: 'root' })

export class ManageChemicalService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  constructor(private http: HttpClient) { }

  //getAll() {
  //  return this.http.get<ChemicalVM[]>(`${environment.apiUrl}ManageChemical`);
  //}

  getByNumber(casnumber: string) {
    return this.http.get<SocialFeedVM[]>(`${environment.apiUrl}SocialFeed/ViewSocialMedia/` + casnumber);
  }

  getCategoryList() {
    return this.http.get<any[]>(`${environment.apiUrl}ChemicalCategories/CategoryList`);
  }

  getCategoryListForManageChemical() {
    return this.http.get<any[]>(`${environment.apiUrl}ChemicalCategories/CategoryListForAll`);
  }

  getCommonNameList(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}ManageChemical/CommonNameList/` + id);
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}ManageChemical/GetById/` + id);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}ManageChemical/deletebyid/` + id);
  }

  public RetrieveChemicalList(filter: string, StartRow: number, EndRow: number, SortColumn: string, SortOrder: string) {
    return this.http.post(`${environment.apiUrl}ManageChemical/chemicalList`,
      { filter: filter, StartRow: StartRow, EndRow: EndRow, SortColumn: SortColumn, SortOrder: SortOrder }
    );
  }
  getAllParameter(id: string, type: number, companyId: string) {
    return this.http.get<any[]>(`${environment.apiUrl}ManageChemical/GetAllParameter?id=` + id + '&type=' + type + '&cId=' + companyId);
  }

  getAllParameterByCompany(id: string, type: number, companyId: string) {
    return this.http.get<any[]>(`${environment.apiUrl}ManageChemical/GetAllParameterByCompany?id=` + id + '&type=' + type + '&cId=' + companyId);
  }

  create(data: chemicalParameterPostModel) {
    return this.http.post(`${environment.apiUrl}ManageChemical/createchemical`, data);
  }

  update(data: chemicalParameterPostModel) {
    return this.http.post(`${environment.apiUrl}ManageChemical/updatechemical`, data);
  }

  createparameterchangerequest(data: ChangedParameterVM[]) {
    return this.http.post(`${environment.apiUrl}datarequest/CreateDataRequestForCompany`, data);
  }

  getAllParameterHistory(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}ManageChemical/GetParameterHistoryById/` + id);
  }


  public AllChemicalListWithParamater(filter: string, StartRow: number, EndRow: number, SortColumn: string, SortOrder: string, isShowAll: boolean) {
    return this.http.post(`${environment.apiUrl}ManageChemical/allChemicalList`,
      { filter: filter, StartRow: StartRow, EndRow: EndRow, SortColumn: SortColumn, SortOrder: SortOrder, IsShowAll: isShowAll }
    );
  }

  savePopUpValue(model: chemicalParameterValuePostModel) {
    return this.http.post(`${environment.apiUrl}ManageChemical/saveChemicalParameterValuePopup`,
      {
        c: model.c, changeType: model.changeType, cid: model.cid, cpid: model.cpid, m: model.m,
        s: model.s, val: model.val, pid: model.pid
      }
    );
  }

  getDrinkingGuidelineValues() {
    return this.http.get<any[]>(`${environment.apiUrl}ManageChemical/GetDrinkingGuidelineValues`);
  }

  getECScoreByChemicalId(chemicalId: string) {
    return this.http.get(`${environment.apiUrl}ManageChemical/GetECScoreByChemicalId/` + chemicalId);
  }

  getECDataAndECScoreHistoryByChemicalId(chemicalId: string) {
    return this.http.get(`${environment.apiUrl}ManageChemical/GetECDataAndHistoryByChemicalId/` + chemicalId);
  }

  saveUserPreferences(data: UserPreferencesVM) {
    return this.http.post(`${environment.apiUrl}UserActivity/SaveUserPreferences`, data);
  }

  getUserPreferences() {
    return this.http.get(`${environment.apiUrl}UserActivity/GetUserPreferences/`);
  }

  getAllCategoryForHierarchy() {
    return this.http.get<any[]>(`${environment.apiUrl}ChemicalCategories/GetAllCategoryForHierarchy`);
  }


  GetPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    let startIndex = ((currentPage - 1) * pageSize) + 1;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1) + 1;
    // create an array of pages to ng-repeat in the pager control
    let pages = Array();
    for (let i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
