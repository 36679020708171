import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SocialFeedVM } from '../models/socialfeedVM';
import { chemicalparameterVM, chemicalParameterPostModel } from '../models/chemicalparameterVM';
import { Guid } from 'guid-typescript';
import { ChemicalVM } from '../models/chemicalVM';

@Injectable({ providedIn: 'root' })
export class AnalysisService {

  constructor(private http: HttpClient) { }

  public getAllParameter(filter: string, StartRow: number, EndRow: number, SortColumn: string, SortOrder: string) {
    return this.http.post(`${environment.apiUrl}ManageChemical/getallparameterforchart`,
      { filter: filter, StartRow: StartRow, EndRow: EndRow, SortColumn: SortColumn, SortOrder: SortOrder }
    );
  }

  getAllEmerging(chemicalId: string,type:string) {
    return this.http.post(`${environment.apiUrl}ManageChemical/getallemergingdata`,
      { chemicalId: null, commonNameId: chemicalId, fromDate: null, toDate: null,type:type }
    );
  }

  getAllSocial(chemicalId: string, commonName: string, fromDate: string, toDate: string) {
    return this.http.post(`${environment.apiUrl}ManageChemical/getallsocialdata`,
      { chemicalId: chemicalId, commonNameId: commonName, fromDate: fromDate, toDate: toDate}
    );
  }

  getMultiSocial(chemicalId: string, commonName: string, fromDate: string, toDate: string) {
    return this.http.post(`${environment.apiUrl}ManageChemical/getsocialdataforallcommonname`,
      { chemicalId: chemicalId, commonNameId: commonName, fromDate: fromDate, toDate: toDate }
    );
  }

  public AllChemicalListWithParamater(filter: string, StartRow: number, EndRow: number, SortColumn: string, SortOrder: string) {
    return this.http.post(`${environment.apiUrl}ManageChemical/prioritisedChemicalList`,
      { filter: filter, StartRow: StartRow, EndRow: EndRow, SortColumn: SortColumn, SortOrder: SortOrder }
    );
  }

  public RetrieveChemicalList(filter: string, StartRow: number, EndRow: number, SortColumn: string, SortOrder: string, CompanyId: string, IsPrivate: boolean) {
    return this.http.post(`${environment.apiUrl}ManageChemical/chemicalListForCompany`,
      { filter: filter, StartRow: StartRow, EndRow: EndRow, SortColumn: SortColumn, SortOrder: SortOrder, CompanyId: CompanyId, IsPrivate: IsPrivate }
    );
  }


  getSocialURL() {
    return this.http.get<any>(`${environment.apiUrl}ManageChemical/getsocialurl`);
  }

  GetPager(totalItems: number, currentPage: number = 1, pageSize: number = 10000) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    }
    else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      }
      else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      }
      else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = ((currentPage - 1) * pageSize) + 1;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1) + 1;

    // create an array of pages to ng-repeat in the pager control
    let pages = Array();
    for (let i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };

  }

}
