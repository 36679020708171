import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { weightCategory,weightageView } from '../models/weightCategorySlider';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class SdmSliderService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}WeightCategory/GetAllWeightsForSdmSlider`);
  }

  create(Data: any[]) {
    return this.http.post<any>(`${environment.apiUrl}WeightCategory/updateWeightCategory`, Data);
  }

  saveSliderWeight(Data: weightCategory[]) {
    return this.http.post<any>(`${environment.apiUrl}WeightCategory/updateSliderWeightCategory`, Data);
  }

  saveCompanyWeight(Data: any[]) {
    return this.http.post<any>(`${environment.apiUrl}WeightCategory/updateSliderCompanyWeight`, Data);
  }

  getCompanySDMList() {
    let companyId = localStorage.getItem('CompanyId');
    return this.http.get<any>(`${environment.apiUrl}WeightCategory/getSliderCompanySDMList/` + companyId);
  }
}
