import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { parametercategoriesVM } from '../models/parametercategoriesVM';

@Injectable({ providedIn: 'root' })

export class ParameterCategoriesService {
  constructor(private http: HttpClient) { }

  getAll() { 
    return this.http.get<parametercategoriesVM[]>(`${environment.apiUrl}parametercategories`);
  }

  //getById(id: number) {
  //  return this.http.get(`${environment.apiUrl}userlogin/GetById/` + id);
  //}

  //create(user: FormData) {
  //  return this.http.post(`${environment.apiUrl}userlogin/createuser`, user);
  //}

  //update(user: FormData) {
  //  return this.http.post(`${environment.apiUrl}userlogin/updateuser`, user);
  //}


  //delete(id: number) {
  // 
  //  return this.http.delete(`${environment.apiUrl}userlogin/deletebyid/` + id);
  //}
}
