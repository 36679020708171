import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { socialMonitoringBlacklistChemicalsVM } from '../models/socialMonitoringBlacklistChemicalsVM';
import { socialMonitoringDefaultChemicalsVM } from '../models/socialMonitoringDefaultChemicalsVM';

@Injectable({
  providedIn: 'root'
})
export class MonitoringManagementService {

  constructor(private http: HttpClient) { }

  /* Social Monitoring Default Chemicals api call */

  getDefaultChemicalsAll() {
    return this.http.get<any[]>(`${environment.apiUrl}SocialMonitoringDefaultChemicals/GetAll`);
  }

  getDefaultChemicalById(id: string) {
    return this.http.get(`${environment.apiUrl}SocialMonitoringDefaultChemicals/GetById/` + id);
  }

  createDefaultChemical(data: socialMonitoringDefaultChemicalsVM) {
    return this.http.post<any>(`${environment.apiUrl}SocialMonitoringDefaultChemicals/CreateSocialMonitoringDefaultChemicals`, data);
  }

  updateDefaultChemical(data: socialMonitoringDefaultChemicalsVM) {
    return this.http.post(`${environment.apiUrl}SocialMonitoringDefaultChemicals/UpdateSocialMonitoringDefaultChemicals`, data);
  }

  deleteDefaultChemical(id: string) {
    return this.http.get(`${environment.apiUrl}SocialMonitoringDefaultChemicals/DeleteById/` + id);
  }


  /* Social Monitoring Blacklist Chemicals api call */

  getBlacklistChemicalsAll() {
    return this.http.get<any[]>(`${environment.apiUrl}SocialMonitoringBlacklistChemicals/GetAll`);
  }

  getBlacklistChemicalById(id: string) {
    return this.http.get(`${environment.apiUrl}SocialMonitoringBlacklistChemicals/GetById/` + id);
  }

  createBlacklistChemical(data: socialMonitoringBlacklistChemicalsVM) {
    return this.http.post<any>(`${environment.apiUrl}SocialMonitoringBlacklistChemicals/CreateSocialMonitoringBlacklistChemicals`, data);
  }

  updateBlacklistChemical(data: socialMonitoringBlacklistChemicalsVM) {
    return this.http.post(`${environment.apiUrl}SocialMonitoringBlacklistChemicals/UpdateSocialMonitoringBlacklistChemicals`, data);
  }

  deleteBlacklistChemical(id: string) {
    return this.http.get(`${environment.apiUrl}SocialMonitoringBlacklistChemicals/DeleteById/` + id);
  }

}
