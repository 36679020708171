import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { userloginVM } from '../models/userloginVM';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<userloginVM>;
  public currentUser: Observable<userloginVM>;


  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<userloginVM>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): userloginVM {
    return this.currentUserSubject.value;
  }

  login(Email: string, Password: string) {

    return this.http.post<any>(`${environment.apiUrl}userlogin/authenticate`, { email: Email, password: Password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));

        //  localStorage.setItem('userType', JSON.stringify(user.roleName));

        localStorage.setItem('userType', JSON.stringify(user.code));

        localStorage.setItem('orderBy', JSON.stringify(user.orderBy));
        if (user.companyId != null) {
          localStorage.setItem('CompanyId', user.companyId);
        }
        else {
          localStorage.setItem('CompanyId', '00000000-0000-0000-0000-000000000000');
        }

        localStorage.setItem('UserRoleId', user.userRoleId);
        localStorage.setItem('userId', user.id);

        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('LoggedInUser');
    localStorage.removeItem('userType');
    localStorage.removeItem('orderBy');
    localStorage.removeItem('CompanyId');
    localStorage.removeItem('UserRoleId');
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  forgotPassword(Email: string) {
    return this.http.post<any>(`${environment.apiUrl}userlogin/forgotpassword`, { forgotemail: Email })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  confirmPassword(Password: string, UserId: string) {

    return this.http.post<any>(`${environment.apiUrl}userlogin/ResetPassword`, { password: Password, UserId: UserId })
      .pipe(map(data => {
        return data;
      }));
  }

  LoadResetPasswordPage(id: string) {
    return this.http.get<any>(`${environment.apiUrl}userlogin/ResetPassword/` + encodeURIComponent(id));
  }

  ChangePassword(Password: string, uid: string) {
    return this.http.post<any>(`${environment.apiUrl}userlogin/ChangePassword`, { password: Password, id: uid })
      .pipe(map(data => {
        return data;
      }));
  }

  updatePasswordForFirstTimeLogin(model: any) {
    return this.http.post<any>(`${environment.apiUrl}userlogin/UpdatePasswordForFirstTimeLogin`, model)
      .pipe(map(data => {
        return data;
      }));
  }

  validateTwoFactorAuthCode(email: string, authCode: string) {
    return this.http.post<any>(`${environment.apiUrl}userlogin/ValidateTwoFactorAuthCode`, { email: email, twoFactorAuthCode: authCode })
      .pipe(map(data => {
        return data;
      }));
  }

  resendTwoFactorAuthCode(Email: string) {
    return this.http.post<any>(`${environment.apiUrl}userlogin/ResendTwoFactorAuthCode`, { email: Email })
      .pipe(map(user => {
        return user;
      }));
  }
}
