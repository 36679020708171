import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { ChemicalCategoriesService } from '../chemical-categories.service';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { ActivatedRoute, Router } from '@angular/router';
import { chemicalcategoriesVM } from '../../models/chemicalcategoriesVM';
import { chemicalcategoryListVM } from '../../models/chemicalcategoryListVM';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
@Component({
  selector: 'app-add-chemical-categories',
  templateUrl: './add-chemical-categories.component.html',
  styleUrls: ['./add-chemical-categories.component.css']
})
export class AddChemicalCategoriesComponent implements OnInit {

  private chemicalCategoriesVM: chemicalcategoriesVM;
  public categoryList: chemicalcategoryListVM[];
  chemicalCategoriesForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  isEdit = false;

  constructor(
    private formBuilder: FormBuilder,
    private chemicalCategoriesService: ChemicalCategoriesService,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {   
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.chemicalCategoriesForm = this.formBuilder.group({
      id:[''],
      categoryName: ['', TrimValidators.required],
      description: [''],
      parentCategoryId:['']
    }
    );

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id) {
      this.isEdit = true;
      this.editChemicalCategories(this.id)
    }

    this.getCategoryList();

  }


  private getCategoryList() {
    this.chemicalCategoriesService.getCategoryList().pipe(first())
      .subscribe(p => {  
        this.categoryList = p;
        if (this.id != null) {
          //this.categoryList;
          var id = this.id;
          this.categoryList = _.filter(this.categoryList, function (el) {
            return el.id != id;
          });
        }
      },
        error => {
          this.toastr.error(error.message);
        });
  }


  get f() { return this.chemicalCategoriesForm.controls; }


  editChemicalCategories(Id: string = null): void {
    this.getCategoryList();

    if (Id) {
      this.chemicalCategoriesService.getById(Id).pipe(first()).subscribe(categoryData => {

        if (categoryData['responseDetail'] !=null) {
          this.chemicalCategoriesVM = categoryData['responseDetail']
         // this.chemicalCategoriesForm.setValue(this.chemicalCategoriesVM)
          this.chemicalCategoriesForm.setValue({
            id: this.chemicalCategoriesVM.id,
            categoryName: this.chemicalCategoriesVM.categoryName,
            parentCategoryId: this.chemicalCategoriesVM.parentCategoryId ,
            description: this.chemicalCategoriesVM.description
          })
        }

      });
    }
  }



  onSubmit() {
  
    this.submitted = true;
    // stop here if form is invalid
    if (this.chemicalCategoriesForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.id) {
      
      this.chemicalCategoriesService.update(this.chemicalCategoriesForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/chemical-categories']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
           
          });
    } else {
      this.chemicalCategoriesService.create(this.chemicalCategoriesForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/chemical-categories']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  onAddCategoryCancelClick() { 
    this.router.navigate(['/chemical-categories']);
  }
}
