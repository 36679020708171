import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { manageuserVM } from '../../models/manageuserVM';
import { AlertService, AuthService } from '../../services';
import { UserService } from '../user.service';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { MustMatch } from '../../helpers/must-match.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { userloginVM } from '../../models/userloginVM';
import { UserRoleListVM } from '../../models/userRoleList';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  private userVM: userloginVM;
  public userRoleList: UserRoleListVM[];
  userForm: FormGroup;
  loading = false;
  submitted = false;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  sub: any;
  id: any;
  public currentUserRoleOrder: string;
  public isPasswordMatch: boolean = false;
  isEdit = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.SpinnerService.show();
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.userForm = this.formBuilder.group({
      id: [''],
      firstName: ['', TrimValidators.required],
      email: ['', [TrimValidators.required, Validators.pattern(this.emailPattern)]],
      password: ['', this.id ? '' : TrimValidators.required],
      confirmPassword: ['', this.id ? '' : TrimValidators.required],
      userRoleId: ['']
    }, { validator: MustMatch('password', 'confirmPassword') });

    if (this.id) {
      this.isEdit = true;
      this.editUser(this.id)
    }

    this.getUserRoleList();
  }

  private getUserRoleList() {
    this.currentUserRoleOrder = JSON.parse(localStorage.getItem('orderBy'));
    this.userService.getUserRoleList(this.currentUserRoleOrder).pipe(first())
      .subscribe(p => {
        this.userRoleList = p;
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  get f() { return this.userForm.controls; }
  
  editUser(userId: number = null): void {
    if (userId) {
      this.userService.getById(userId).pipe(first()).subscribe(userData => {
        if (userData['status'] === true) {
          this.userVM = userData['responseDetail']

          this.userForm.setValue({
            id: this.userVM.id,
            firstName: this.userVM.firstName,
            email: this.userVM.email,
            password: '',//this.userVM.password,
            confirmPassword: '',// this.userVM.password,
            userRoleId: this.userVM.userRoleId,
          })
        }
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.id) {
      this.userService.update(this.userForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              //this.dialogRef.close(true);
              this.toastr.success(data['message']);
              this.router.navigate(['/users'])
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
              // this.dialogRef.close(false);
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
            // this.dialogRef.close(false);
          });
    } else {

      this.userService.create(this.userForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.toastr.success(data['message']);
              this.router.navigate(['/users']);
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  onAddUserCancelClick() {
    this.router.navigate(['/users']);
  }


}
