import { Component, OnInit, Input } from '@angular/core';
import { AlertService, AuthService } from '../../services';
import { UserService } from '../../users/user.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisService } from './../analysis.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { PagerService } from '../../helpers/pager.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleChartsModule } from 'angular-google-charts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageChemicalService } from '../../managechemicals/managechemicalsservice';
declare var google: any;
import * as _ from 'lodash';
declare var $: any;
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-analysis-emerging-concern',
  templateUrl: './analysis-emerging-concern.component.html',
  styleUrls: ['./analysis-emerging-concern.component.css']
})


export class AnalysisEmergingConcernComponent implements OnInit {
  analysisForm: FormGroup;
  companyId: any;
  isCompanyAdmin = false;
  sub: any;
  k: any;
  id: any;

  type: any;
  urlChemicalId: any;
  isShow: boolean = false;
  selectedChemicalId = "";
  public chemicalList: any;
  public allChemicalDataList: any;
  public EmergingList: any;
  public EmergingChemicalList: any;
  public MultiEmergingList: any;
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  public filter: string = '';
  AllCommonName: string = null;
  public commonNameList: any[] = [];
  chemicalId: any;
  dropdownList = [];
  multiDropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  public socialURL: string = null;
  getGoogle() {
    return google;
  }
  public pushIds: [];
  public selected?: boolean
  firstCommonName: string = null;

  constructor(private analysisService: AnalysisService,
    //private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private chemicalService: ManageChemicalService,
    //private pagerService: PagerService,
    //private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,) {

    this.getGoogle(),
      google.charts.load('current', { packages: ['corechart', 'table'] }
      );
  }

  ngOnInit() {

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.type = params['type'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.urlChemicalId = params['urlChemicalId'];
    });

    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select ChemicalName",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "form-control",
      badgeShowLimit: 3,
      searchBy: ['itemName', 'name', 'casnumber'],
      searchPlaceholderText: 'Search by Display Name, Synonyms, Cas-No'
    };
    this.selectedItems = [];
    this.getSocialURL();
    this.RetrieveChemicalList('', 1, 1000, '', '', true, this.companyId, true);
    google.charts.load('current', { packages: ['corechart', 'table'] });
    $("#chart_noDate").html("No Chart Data Found.");
    $("#chart_multi").html("");
  }

  GetEmergingDataList(chemicalId: string = null) {
    this.SpinnerService.show();
    this.analysisService.getAllEmerging(chemicalId, "1").subscribe(data => {
      $("#chart_multi").html("");
      $("#chart_single").html("");
      this.getGoogle();
      var chartWidth = $(".content_btm").width() - 50;
      var optionsEmerging = {
        tooltip: { isHtml: true },
        legend: { position: 'Left' },
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Time'
        },
        vAxis: {
          title: 'Score'
        }
      };

      this.EmergingChemicalList = data;
      if (this.EmergingChemicalList.length < 1) {

        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_chemical").html("");
        $("#chart_multi").html("");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");

      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      this.EmergingChemicalList.forEach((value) => {

        Object.keys(value).forEach((key) => {

          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })

        count = count + 1;
      })

      let mainValue = [];
      let dataList: string[] = [];

      this.EmergingChemicalList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);

      })
      // Add data.
      data1.addRows(mainValue);
      var chartChemical = new google.visualization.LineChart(document.getElementById('chart_chemical'));
      if (this.EmergingChemicalList.length > 0) {
        chartChemical.draw(data1, optionsEmerging);
      }

      google.visualization.events.addListener(chartChemical, 'select', selectHandler);

      var cid = "";
      var currentClass = this;
      function selectHandler() {
        var selection = chartChemical.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(currentClass.allChemicalDataList, function (el) {

            return el.chemicalName == str;
          });
          cid = selectedDate[0].id;

        }
        currentClass.chemicalId = cid;
        currentClass.getCommonNameList(cid);
        //currentClass.GetSocialDataList(currentClass.id, cid.toString());

      }

      this.SpinnerService.hide();

    },
      error => {
        // alert("We faced some technical issues");
      });
    this.SpinnerService.hide();
  }

  GridHideShow(type) {
    if (type == 1) {
      this.router.navigate(['/analysis'])
    }
    else if (type == 3) {
      this.router.navigate(['/analysis-prioritised-chemicals'])
    }
  }

  backButton() {
    if (this.type == "2") {
      this.router.navigate(['/view-all-chemical', this.k]);
    }
    else {
      this.router.navigate(['/analysis']);
    }
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {

    this.SpinnerService.hide();
    this.analysisService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.allChemicalDataList = data;
      for (var obj in this.allChemicalDataList) {
        let newName = {
          id: this.allChemicalDataList[obj].id,
          itemName: this.allChemicalDataList[obj].chemicalName,
          name: this.allChemicalDataList[obj].commonName,
          casnumber: this.allChemicalDataList[obj].casnumber
        };
        this.multiDropdownList.push(newName);
      };

    },
      error => {
        //alert("We faced some technical issues");
      });
  }



  getCommonNameList(chemicalId: string = null) {
    this.chemicalService.getCommonNameList(chemicalId).pipe(first())
      .subscribe(p => {
        this.commonNameList = p;

        for (var obj in this.commonNameList) {
          this.AllCommonName = this.AllCommonName + this.commonNameList[obj].id + ",";
          let newName = {
            id: this.commonNameList[obj].id,
            itemName: this.commonNameList[obj].name
          };
          this.dropdownList.push(newName);
        };
        this.AllCommonName = this.AllCommonName.substring(0, this.AllCommonName.length - 1);

        if (this.commonNameList.length > 1) {
          this.SpinnerService.show();
          this.GetMultiSocialDataList(chemicalId, this.AllCommonName.toString());
        }
        else {
          this.SpinnerService.show();
          this.GetSocialDataList(chemicalId, this.AllCommonName.toString());
        }
      },
        error => {
          //this.toastr.error(error.message);
        });
  }

  GetSocialDataList(chemicalId: string = null, commonNameId: string = null, fromDate: string = null, toDate: string = null): void {
    this.SpinnerService.show();
    this.analysisService.getAllSocial(chemicalId, commonNameId, fromDate, toDate).subscribe(data => {
      this.getGoogle();
      $("#chart_multi").html("");
      $("#chart_chemical").html("");
      var chartWidth = $(".content_btm").width() - 50;
      var optionsEmerging = {
        tooltip: { isHtml: true },

        legend: { position: 'Left' },
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Value'
        },
        colors: ['#F5190B', '#080808', '#AFFF33']
      };

      this.EmergingList = data;
      if (this.EmergingList.length < 1) {
        $("#chart_noDate").html("No Chart Data Found");
        $("#chart_chemical").html("");
        $("#chart_multi").html("");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");
      }
      var data1 = new google.visualization.DataTable();
      data1.addColumn('string', "date");
      data1.addColumn('number', "Negative");
      data1.addColumn('number', "Neutral");
      data1.addColumn('number', "Positive");



      var Chart = new google.visualization.LineChart(document.getElementById('chart_single'));

      var recordData = [];
      var currentDate = null;
      $.each(this.EmergingList, function (key, value) {
        if (currentDate != value.date) {
          let currentData = _.filter(data, function (el) {
            return el.date == value.date;
          });
          let positiveValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 3;
          });
          let negativeValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 1;
          });
          let nutralValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 2;
          });
          currentDate = value.date;
          var pCount = positiveValue.length > 0 ? positiveValue[0].positiveCount : 0;
          var neCount = negativeValue.length > 0 ? negativeValue[0].negativeCount : 0;
          var nuCount = nutralValue.length > 0 ? nutralValue[0].neutralCount : 0;
          recordData.push([value.date, neCount, nuCount, pCount]);
        }
      });

      data1.addRows(recordData);
      if (this.EmergingList.length > 0) {
        Chart.draw(data1, optionsEmerging);
      }
      //google.visualization.events.addListener(Chart, 'select', selectHandler);

      var currentThis = this;

      function selectHandler() {
        var selection = Chart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];

          let currentData = _.filter(data, function (el) {
            var str = data1.getFormattedValue(item.row, 0);
            return el.date == str;
          });

          if (currentData != null && currentData.length > 0) {
            let lineData = _.filter(currentData, function (el) {
              return el.citationSentimentId == item.column;
            });
            if (lineData != null && lineData.length > 0) {
              var commonNameKeyWordId = lineData[0].commonNameKeywordId;
              var citationTypeId = lineData[0].citationTypeId;
              var citationSentimentId = lineData[0].citationSentimentId;
              var recordDate1 = lineData[0].date;
              var date = recordDate1.split("/");
              var newDate = date[2] + '-' + date[1] + '-' + date[0];

              var href = currentThis.socialURL.replace("{0}", commonNameKeyWordId).replace("{1}", citationTypeId).replace("{2}", citationSentimentId).replace("{3}", newDate);
            }
            else {
              var commonNameKeyWordId = currentData[0].commonNameKeywordId;
              var citationTypeId = currentData[0].citationTypeId;
              var citationSentimentId = item.column;
              var recordDate1 = currentData[0].date;
              var date = recordDate1.split("/");
              var newDate = date[2] + '-' + date[1] + '-' + date[0];

              var href = currentThis.socialURL.replace("{0}", commonNameKeyWordId).replace("{1}", citationTypeId).replace("{2}", citationSentimentId).replace("{3}", newDate);
            }

          }
        }
        if (message == '') {
          message = 'nothing';
        }

        window.open(href, '_blank');
      }


      //if (this.EmergingList.length > 0) {
      //  this.singleChart.draw(data1, optionsEmerging);
      //}

      this.SpinnerService.hide();
    },
      error => {
        // alert("We faced some technical issues");
      });
  }

  GetMultiSocialDataList(chemicalId: string = null, commonNameId: string = null, fromDate: string = null, toDate: string = null): void {

    this.analysisService.getMultiSocial(chemicalId, commonNameId, fromDate, toDate).subscribe(data => {
      this.getGoogle();
      $("#chart_single").html("");
      $("#chart_chemical").html("");
      var chartWidth = $(".content_btm").width() - 50;
      var optionsMultiEmerging = {
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Value'
        }
      };

      this.MultiEmergingList = data;
      var cnList = this.dropdownList;
      if (this.MultiEmergingList.length < 1) {

        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_chemical").html("");
        $("#chart_multi").html("");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");

      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      this.MultiEmergingList.forEach((value) => {

        Object.keys(value).forEach((key) => {

          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })

        count = count + 1;
      })

      let mainValue = [];
      let dataList: string[] = [];

      this.MultiEmergingList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);

      })
      // Add data.
      data1.addRows(mainValue);
      var multiChart = new google.visualization.LineChart(document.getElementById('chart_multi'));
      if (this.MultiEmergingList.length > 0) {
        multiChart.draw(data1, optionsMultiEmerging);
      }

      google.visualization.events.addListener(multiChart, 'select', selectHandler);

      var cid = "";
      var currentClass = this;
      function selectHandler() {
        var selection = multiChart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(cnList, function (el) {

            return el.itemName == str;
          });
          cid = selectedDate[0].id;

        }
        currentClass.GetSocialDataList(currentClass.chemicalId, cid.toString());

      }

      this.SpinnerService.hide();

    },
      error => {
        // alert("We faced some technical issues");
      });
  }

  search(event) {
    var keyword = event.target.value;
    $('.searchInput').val(keyword);
    if (keyword.length >= 3) {
      this.RetrieveChemicalList(keyword, 1, 10, this.sortColumn, this.sortDirection, true, this.companyId, true);
      this.isShow = true;
    }
    else {
      this.chemicalList = null;
      this.isShow = false;
    }

  }

  SelectedChemical(id, chemicalName) {

    $("#chemicalName").val("");
    this.selectedChemicalId = null;
    this.selectedChemicalId = id;
    this.chemicalId = id;
    // this.GetEmergingDataList(id, null)
    $("#chemicalName").val(chemicalName);
    this.isShow = false;
    this.GetEmergingDataList(id);
  }


  //onItemSelect(item: any) {
  //  $(".c-input").val("");
  //  this.multiDropdownList = [];
  //  for (var obj in this.allChemicalDataList) {
  //    let newName = {
  //      id: this.allChemicalDataList[obj].id,
  //      itemName: this.allChemicalDataList[obj].chemicalName,
  //      name: this.allChemicalDataList[obj].commonName,
  //      casnumber: this.allChemicalDataList[obj].casnumber
  //    };
  //    this.multiDropdownList.push(newName);
  //  };
  //}

  onSubmit() {
    let selectedChemicalId = "";
    //let fromDate = $('#fromDate').val();
    //let toDate = $('#toDate').val();
    if (this.selectedItems.length >= 1) {

      for (let i = 0; i < this.selectedItems.length; i++) {
        selectedChemicalId = selectedChemicalId + this.selectedItems[i]["id"] + ",";
      }
      selectedChemicalId = selectedChemicalId.substring(0, selectedChemicalId.length - 1);

      this.GetEmergingDataList(selectedChemicalId.toString());
    }
    else {
      if (this.selectedItems.length == 0) {
        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_multi").html("");
        $("#chart_single").html("");
        $("#chart_chemical").html("");
        selectedChemicalId = this.selectedItems[0]["id"];
        this.GetEmergingDataList(selectedChemicalId.toString());
      }
    }
  }

  onclear() {
    this.selectedItems = [];

    $("#chart_noDate").html("No Chart Data Found.");
    $("#chart_multi").html("");
    $("#chart_single").html("");
    $("#chart_chemical").html("");

    //this.GetSocialDataList(this.id, this.firstCommonName.toString(), null, null);
  }

  getSocialURL(): void {

    this.analysisService.getSocialURL().pipe(first()).subscribe(url => {
      this.socialURL = url;
    },
      error => {
        // this.toastr.error(error.message);
      });

  }

  onAddChemicalClick(id) {
    //if (id != '' && id != "" && id != null) {
    //  this.router.navigate(['/add-chemical', id]);
    //}
    //else {
    //  this.router.navigate(['/add-chemical', '']);
    //}
  }

}
