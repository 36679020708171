import { Component, OnInit } from '@angular/core';
import { weightCategory, weightageView } from '../models/weightCategorySlider';
import { AlertService } from '../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { SdmSliderService } from './sdm-slider.service';
import { parse } from 'url';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-sdm-slider',
  templateUrl: './sdm-slider.component.html',
  styleUrls: ['./sdm-slider.component.css']
})
export class SdmSliderComponent implements OnInit {

  sliderVal: any = 50;
  public weightCategoryList: weightCategory[];
  public weightageView: weightageView = new weightageView();
  loading = false;

  constructor(
    private weightCategoryService: SdmSliderService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getWeightCategoryList();
  }

  public getWeightCategoryList() {
    this.weightCategoryService.getAll().pipe(first())
      .subscribe(p => {
        this.weightCategoryList = p;
        for (var i = 0; i < this.weightCategoryList.length; i++) {
          if (this.weightCategoryList[i].code == "SC7") {
            this.weightageView.SC7_Id = this.weightCategoryList[i].id;
            this.weightageView.SC7_Code = this.weightCategoryList[i].code;
            this.weightageView.SC7_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC7_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC8") {
            this.weightageView.SC8_Id = this.weightCategoryList[i].id;
            this.weightageView.SC8_Code = this.weightCategoryList[i].code;
            this.weightageView.SC8_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC8_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC9") {
            this.weightageView.SC9_Id = this.weightCategoryList[i].id;
            this.weightageView.SC9_Code = this.weightCategoryList[i].code;
            this.weightageView.SC9_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC9_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC12") {
            this.weightageView.SC12_Id = this.weightCategoryList[i].id;
            this.weightageView.SC12_Code = this.weightCategoryList[i].code;
            this.weightageView.SC12_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC12_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC13") {
            this.weightageView.SC13_Id = this.weightCategoryList[i].id;
            this.weightageView.SC13_Code = this.weightCategoryList[i].code;
            this.weightageView.SC13_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC13_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC14") {
            this.weightageView.SC14_Id = this.weightCategoryList[i].id;
            this.weightageView.SC14_Code = this.weightCategoryList[i].code;
            this.weightageView.SC14_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC14_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC15") {
            this.weightageView.SC15_Id = this.weightCategoryList[i].id;
            this.weightageView.SC15_Code = this.weightCategoryList[i].code;
            this.weightageView.SC15_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC15_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC16") {
            this.weightageView.SC16_Id = this.weightCategoryList[i].id;
            this.weightageView.SC16_Code = this.weightCategoryList[i].code;
            this.weightageView.SC16_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC16_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC17") {
            this.weightageView.SC17_Id = this.weightCategoryList[i].id;
            this.weightageView.SC17_Code = this.weightCategoryList[i].code;
            this.weightageView.SC17_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC17_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC24") {
            this.weightageView.SC24_Id = this.weightCategoryList[i].id;
            this.weightageView.SC24_Code = this.weightCategoryList[i].code;
            this.weightageView.SC24_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC24_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC25") {
            this.weightageView.SC25_Id = this.weightCategoryList[i].id;
            this.weightageView.SC25_Code = this.weightCategoryList[i].code;
            this.weightageView.SC25_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC25_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SC26") {
            this.weightageView.SC26_Id = this.weightCategoryList[i].id;
            this.weightageView.SC26_Code = this.weightCategoryList[i].code;
            this.weightageView.SC26_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SC26_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG7") {
            this.weightageView.SG7_Id = this.weightCategoryList[i].id;
            this.weightageView.SG7_Code = this.weightCategoryList[i].code;
            this.weightageView.SG7_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG7_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG8") {
            this.weightageView.SG8_Id = this.weightCategoryList[i].id;
            this.weightageView.SG8_Code = this.weightCategoryList[i].code;
            this.weightageView.SG8_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG8_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG9") {
            this.weightageView.SG9_Id = this.weightCategoryList[i].id;
            this.weightageView.SG9_Code = this.weightCategoryList[i].code;
            this.weightageView.SG9_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG9_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG10") {
            this.weightageView.SG10_Id = this.weightCategoryList[i].id;
            this.weightageView.SG10_Code = this.weightCategoryList[i].code;
            this.weightageView.SG10_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG10_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG11") {
            this.weightageView.SG11_Id = this.weightCategoryList[i].id;
            this.weightageView.SG11_Code = this.weightCategoryList[i].code;
            this.weightageView.SG11_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG11_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG12") {
            this.weightageView.SG12_Id = this.weightCategoryList[i].id;
            this.weightageView.SG12_Code = this.weightCategoryList[i].code;
            this.weightageView.SG12_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG12_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG24") {
            this.weightageView.SG24_Id = this.weightCategoryList[i].id;
            this.weightageView.SG24_Code = this.weightCategoryList[i].code;
            this.weightageView.SG24_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG24_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SG25") {
            this.weightageView.SG25_Id = this.weightCategoryList[i].id;
            this.weightageView.SG25_Code = this.weightCategoryList[i].code;
            this.weightageView.SG25_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SG25_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK8") {
            this.weightageView.SK8_Id = this.weightCategoryList[i].id;
            this.weightageView.SK8_Code = this.weightCategoryList[i].code;
            this.weightageView.SK8_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK8_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK9") {
            this.weightageView.SK9_Id = this.weightCategoryList[i].id;
            this.weightageView.SK9_Code = this.weightCategoryList[i].code;
            this.weightageView.SK9_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK9_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK12") {
            this.weightageView.SK12_Id = this.weightCategoryList[i].id;
            this.weightageView.SK12_Code = this.weightCategoryList[i].code;
            this.weightageView.SK12_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK12_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK13") {
            this.weightageView.SK13_Id = this.weightCategoryList[i].id;
            this.weightageView.SK13_Code = this.weightCategoryList[i].code;
            this.weightageView.SK13_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK13_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK16") {
            this.weightageView.SK16_Id = this.weightCategoryList[i].id;
            this.weightageView.SK16_Code = this.weightCategoryList[i].code;
            this.weightageView.SK16_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK16_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK17") {
            this.weightageView.SK17_Id = this.weightCategoryList[i].id;
            this.weightageView.SK17_Code = this.weightCategoryList[i].code;
            this.weightageView.SK17_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK17_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK18") {
            this.weightageView.SK18_Id = this.weightCategoryList[i].id;
            this.weightageView.SK18_Code = this.weightCategoryList[i].code;
            this.weightageView.SK18_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK18_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK19") {
            this.weightageView.SK19_Id = this.weightCategoryList[i].id;
            this.weightageView.SK19_Code = this.weightCategoryList[i].code;
            this.weightageView.SK19_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK19_IsLock = false;
          }
          else if (this.weightCategoryList[i].code == "SK20") {
            this.weightageView.SK20_Id = this.weightCategoryList[i].id;
            this.weightageView.SK20_Code = this.weightCategoryList[i].code;
            this.weightageView.SK20_Weightage = this.weightCategoryList[i].weightage;
            this.weightageView.SK20_IsLock = false;
          }
        }
        console.log(this.weightageView);
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  OnSliderChange(value, DependentSlider, currentModel) {
    var deparray = [];
    var totalSum = 0;
    var splittedVal = DependentSlider.split(',');
    for (var j = 0; j < splittedVal.length; j++) {
      var modelVal = splittedVal[j].split('_')[0];
      if (this.weightageView[modelVal + "_IsLock"]) {
        totalSum = totalSum + this.weightageView[splittedVal[j]];
      }
      else {
        deparray.push(splittedVal[j]);
      }
    }
    if ((totalSum + value) > 100) {
      var depSliderSplit = DependentSlider.split(',');
      var totalValForRemainingSlider = depSliderSplit.reduce((acc, cur) => acc + (this.weightageView[cur]), 0);
      return this.weightageView[currentModel] = 100 - totalValForRemainingSlider;
    }
    else {
      var newArr = deparray.join(',');
      var remainingVal = 100 - (value + totalSum);
      var depSlider = newArr.split(',');
      var totalValForRemainingSlider;
      if (deparray.length == 0) {
        return this.weightageView[currentModel] = 100 - totalSum;
      }
      
      totalValForRemainingSlider = depSlider.reduce((acc, cur) => acc + this.weightageView[cur], 0);
      totalValForRemainingSlider = totalValForRemainingSlider == 0 ? 1 : totalValForRemainingSlider;
      var k = 0;
      var sum = 0;
      for (var i = 0; i < depSlider.length; i++) {
        var weightVal = this.weightageView[depSlider[i]] == 0 ? 1 : this.weightageView[depSlider[i]];
        var intVal = Number.isInteger((weightVal * remainingVal) / totalValForRemainingSlider);

        if (!intVal) {
          k++;
        }

        if (k == depSlider.length) {
          this.weightageView[depSlider[i]] = 100 - (value + sum);
        }
        else {
          this.weightageView[depSlider[i]] = Math.round((weightVal * remainingVal) / totalValForRemainingSlider);
        }

        if (i <= depSlider.length - 2) {
          sum = sum + this.weightageView[depSlider[i]];
        }
      }

    }
  }

  SaveWeightData() {
    this.CalculateWeightForTwoWay();
    this.PrepareListForSaveWeight();
    this.weightCategoryService.saveSliderWeight(this.weightCategoryList)
      .pipe(first())
      .subscribe(
        data => {
          if (data['status'] === true) {
            this.toastr.success(data['message']);
            this.getWeightCategoryList();
          } else {
            this.loading = false;
          }
        },
        error => {
          //  this.toastr.error(error.message);
          this.loading = false;
          // this.dialogRef.close(false);
        });
  }

  ToggleLock(e, model) {
    if ($(e.target).hasClass("fa-unlock")) {
      $(e.target).removeClass("fa-unlock");
      $(e.target).addClass("fa-lock");
      $(e.target.previousSibling).prop("disabled", true);
      $(e.target.previousSibling.previousSibling).prop("disabled", true);
      this.weightageView[model] = true;
    } else {
      $(e.target).removeClass("fa-lock");
      $(e.target).addClass("fa-unlock");
      $(e.target.previousSibling).prop("disabled", false);
      $(e.target.previousSibling.previousSibling).prop("disabled", false);
      this.weightageView[model] = false;
    }
  }

  CalculateWeightForTwoWay() {
    var totalSum = this.weightageView.SC24_Weightage + this.weightageView.SC25_Weightage;
    this.weightageView.SG24_Weightage = Math.round((this.weightageView.SC24_Weightage * 100) / totalSum);
    this.weightageView.SG25_Weightage = Math.floor((this.weightageView.SC25_Weightage * 100) / totalSum);
  }

  PrepareListForSaveWeight() {
    for (var i = 0; i < this.weightCategoryList.length; i++) {
      if (this.weightCategoryList[i].code == "SC7") {
        this.weightCategoryList[i].weightage = this.weightageView.SC7_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC8") {
        this.weightCategoryList[i].weightage = this.weightageView.SC8_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC9") {
        this.weightCategoryList[i].weightage = this.weightageView.SC9_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC12") {
        this.weightCategoryList[i].weightage = this.weightageView.SC12_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC13") {
        this.weightCategoryList[i].weightage = this.weightageView.SC13_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC14") {
        this.weightCategoryList[i].weightage = this.weightageView.SC14_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC15") {
        this.weightCategoryList[i].weightage = this.weightageView.SC15_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC16") {
        this.weightCategoryList[i].weightage = this.weightageView.SC16_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC17") {
        this.weightCategoryList[i].weightage = this.weightageView.SC17_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC24") {
        this.weightCategoryList[i].weightage = this.weightageView.SC24_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC25") {
        this.weightCategoryList[i].weightage = this.weightageView.SC25_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SC26") {
        this.weightCategoryList[i].weightage = this.weightageView.SC26_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG7") {
        this.weightCategoryList[i].weightage = this.weightageView.SG7_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG8") {
        this.weightCategoryList[i].weightage = this.weightageView.SG8_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG9") {
        this.weightCategoryList[i].weightage = this.weightageView.SG9_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG10") {
        this.weightCategoryList[i].weightage = this.weightageView.SG10_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG11") {
        this.weightCategoryList[i].weightage = this.weightageView.SG11_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG12") {
        this.weightCategoryList[i].weightage = this.weightageView.SG12_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG24") {
        this.weightCategoryList[i].weightage = this.weightageView.SG24_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SG25") {
        this.weightCategoryList[i].weightage = this.weightageView.SG25_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK8") {
        this.weightCategoryList[i].weightage = this.weightageView.SK8_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK9") {
        this.weightCategoryList[i].weightage = this.weightageView.SK9_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK12") {
        this.weightCategoryList[i].weightage = this.weightageView.SK12_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK13") {
        this.weightCategoryList[i].weightage = this.weightageView.SK13_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK16") {
        this.weightCategoryList[i].weightage = this.weightageView.SK16_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK17") {
        this.weightCategoryList[i].weightage = this.weightageView.SK17_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK18") {
        this.weightCategoryList[i].weightage = this.weightageView.SK18_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK19") {
        this.weightCategoryList[i].weightage = this.weightageView.SK19_Weightage;
      }
      else if (this.weightCategoryList[i].code == "SK20") {
        this.weightCategoryList[i].weightage = this.weightageView.SK20_Weightage;
      }
    }
  }

}
