import { Component, OnInit } from '@angular/core';
import { AuthService, AuthenticationService } from '../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ECDataService } from './ecdata.service';
import { ToastrService } from 'ngx-toastr';
import { userloginVM } from '../models';
import { UserRoles } from '../models/enum';
import { first } from 'rxjs/operators';
import { PagerService } from '../helpers/pager.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { environment } from 'src/environments/environment';
import { Sort } from '@angular/material';

@Component({
  selector: 'app-ecdata',
  templateUrl: './ecdata.component.html',
  styleUrls: ['./ecdata.component.css']
})
export class ECDataComponent implements OnInit {

  public currentUserCode: string;
  currentUser: userloginVM;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;
  public ecDataList: any[];
  pager: any = {};
  public pagedItems: any[];
  currentPage: any = 1;
  public doiUrl: string = environment.doiUrl;

  constructor(private authService: AuthService, private authenticationService: AuthenticationService,
    private spinnerService: NgxSpinnerService, private ecDataService: ECDataService, private toastr: ToastrService,
    private pagerService: PagerService, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    if (this.authService.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }

    this.getECDataList();
  }

  getECDataList() {
    this.spinnerService.show();
    this.ecDataService.getAll().pipe(first())
      .subscribe(res => {
        console.log(res);
        this.ecDataList = res;
        if (this.ecDataList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.spinnerService.hide();
        });
  }

  setPage(page: number) {
    this.spinnerService.show();
    this.pager = this.pagerService.getPager(this.ecDataList.length, page);
    this.pagedItems = this.ecDataList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.spinnerService.hide();
  }

  deleteAllECData() {
    this.ecDataList = this.ecDataList.filter(x => x.isDeleted)
    if (this.ecDataList.length <= 0) {
      this.toastr.error("Atleast one record needs to select.");
      return;
    }
    else {
      this.ecDataService.deleteECDataList(this.ecDataList).pipe(first()).subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getECDataList();
        } else {
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
    }
  }

  public saveAllECData() {

    this.ecDataList = this.ecDataList.filter(x => x.casrn != null && x.casrn != undefined && x.casrn != "")
    if (this.ecDataList.length <= 0) {
      this.toastr.error("Atleast one CAS NO. is required.");
      return;
    }
    else {
      this.ecDataService.saveECDataList(this.ecDataList).pipe(first()).subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getECDataList();
        } else {
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
    }
  }

  public saveData(ecdata: any) {
    console.log(ecdata);

    if (ecdata && !ecdata.casrn) {
      this.toastr.error("CAS NO. is required.");
      return;
    }

    this.ecDataService.saveECData(ecdata).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
        this.toastr.success(data['message']);
        this.getECDataList();
      } else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.error.message);
      });
  }

  public openIgnoreConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to ignore?')
      .then((confirmed) =>
        confirmed === true ? this.ignoreById(id) : ""
      )
      .catch(
      //(e) =>
    );
  }

  ignoreById(id: any) {
    this.ecDataService.ignore(id).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
        this.toastr.success(data['message']);
        this.getECDataList();
      } else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.error.message);
      });
  }

  importChemicalsFromECData() {
    this.spinnerService.show();
    this.ecDataService.importChemicalsFromECData().pipe(first()).subscribe(data => {
      this.spinnerService.hide();
      if (data['status'] === true) {
        this.toastr.success(data['message']);
      } else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.error.message);
        this.spinnerService.hide();
      });
  }

  sort(sort: Sort) {
    const data = this.ecDataList.slice();
    if (!sort.active || sort.direction === '') {
      this.ecDataList = data;
      return;
    }
    this.ecDataList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'doi': return compare(a.doi, b.doi, isAsc);
        case 'createdDate': return compare(a.createdDate, b.createdDate, isAsc);
        default: return 0;
      }
    });
    this.setPage(this.currentPage);
  }

}

function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
