import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { weightCategory, weightageView } from '../../models/weightCategorySlider';
import { reportVM } from '../../models/reportVM';
import { Observable } from 'rxjs/internal/Observable';
import { ChemicalVM, ECScoreForChart } from 'src/app/models/chemicalVM';

@Injectable({
  providedIn: 'root'
})
export class AnalysisDashboardService {

  constructor(private http: HttpClient) { }


  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}Report`);
  }

  getAllByCompany() {
    let companyId = localStorage.getItem('CompanyId');
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let userId = currentUser.id;
    return this.http.get<any[]>(`${environment.apiUrl}Report/GetAllByCompany?companyId=` + companyId + '&userId=' + userId);
  }

  getAllReportByCompanyAndUser() {
    let companyId = localStorage.getItem('CompanyId');
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let userId = currentUser.id;
    return this.http.get<any[]>(`${environment.apiUrl}Report/GetAllReportByCompanyAndUser?companyId=` + companyId + '&userId=' + userId);
  }

  getById(id: string) {
    return this.http.get<reportVM>(`${environment.apiUrl}Report/GetById/` + id);
  }

  getHistoryById(id: string) {
    return this.http.get<reportVM>(`${environment.apiUrl}Report/GetReportHistoryById/` + id);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}Report/deletebyid/` + id);
  }

  create(Data: reportVM) {
    Data.companyId = localStorage.getItem('CompanyId');
    return this.http.post<any[]>(`${environment.apiUrl}Report/CreateAnalysisReport`, Data);
  }

  createCopy(Data: reportVM) {
    Data.companyId = localStorage.getItem('CompanyId');
    return this.http.post<any[]>(`${environment.apiUrl}Report/CreateAnalysisCopy`, Data);
  }

  updateReportFav(Data: reportVM) {
    Data.companyId = localStorage.getItem('CompanyId');
    return this.http.post<reportVM>(`${environment.apiUrl}Report/UpdateReportFav`, Data);
  }

  updateReportShare(Data: reportVM) {
    Data.companyId = localStorage.getItem('CompanyId');
    return this.http.post<reportVM>(`${environment.apiUrl}Report/UpdateReportShare`, Data);
  }

  getDefaultAnalysisData() {
    let companyId = localStorage.getItem('CompanyId');
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let userId = currentUser.id;
    let reportId = localStorage.getItem("reportId");
    if (!reportId) { reportId = ""; }
    return this.http.get<reportVM>(`${environment.apiUrl}Report/GetDefaultCompanyAnalysisData/?companyId=` + companyId + '&userId=' + userId + '&reportId=' + reportId);
  }

  getAllParameterValues(Data: reportVM) {
    Data.companyId = localStorage.getItem('CompanyId');
    return this.http.post<reportVM>(`${environment.apiUrl}Report/getChemicalsParameters`, Data
    );
  }

  getChemicalsECScore(Data: ChemicalVM[]) {
    return this.http.post<ECScoreForChart[]>(`${environment.apiUrl}Report/getChemicalsECScore`, Data
    );
  }

  getAllParameter() {
    let companyId = localStorage.getItem('CompanyId');
    return this.http.get<any[]>(`${environment.apiUrl}Report/getAllParameters`);
  }

  downloadExcel(id: string, basestr: string) {
    let formData: FormData;
    formData = new FormData();
    formData.append('imageFile', basestr);
    formData.append('reportId', id);
    return this.http.post<any[]>(`${environment.apiUrl}TestPdf/CreateImage`, formData);
  }

  testApi() {
    return this.http.get<any[]>(`${environment.apiUrl}TestPdf/CreateCoverImage`);
  }

  downloadPdf(reportVM: any): Promise<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    let formData = new FormData();
    formData.append('reportId', reportVM.reportId);
    formData.append('base64Str', reportVM.base64Str);
    return this.http.post<any>(`${environment.apiUrl}TestPdf/DownloadPdf`, formData).toPromise();
  }

  downloadExcelBlob(id: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<any>(`${environment.apiUrl}TestPdf/DownloadExcel?reportId=` + id, httpOptions);
  }

  getReportStructure() {
    return this.http.get<any>(`${environment.apiUrl}Report/GetReportStructure/`);
  }

  generateEnhancedReport(data: any) {
    data.companyId = localStorage.getItem('CompanyId');
    return this.http.post(`${environment.apiUrl}Report/GenerateEnhancedReport`, data, {
      observe: 'events',
      responseType: 'blob'
    });
  }

  addChemicalToPortfolio(data: any) {
    return this.http.post<any>(`${environment.apiUrl}Report/AddChemicalToReport`, data);
  }

  getAnnualProgramByUserIdAndReportId(data: any) {
    return this.http.get<any>(`${environment.apiUrl}AnnualProgram/GetByUserIdAndReportId/?userId=` + data.userId + '&reportId=' + data.reportId + '&currentYear=' + data.currentYear);
  }

  createAnnualProgram(data: any) {
    return this.http.post<any[]>(`${environment.apiUrl}AnnualProgram/CreateAnnualProgram`, data);
  }

}
