import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { AuthService } from '../app/services/auth.service'



@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private auth: AuthService) {

  }
  headers = new Headers({
    'Content-Type': 'application/json',
    'Token': localStorage.getItem("LoggedInUser")
  });
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with jwt token if availableok
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }
    if (this.router.url.indexOf("login") >= 1 || this.router.url.indexOf("reset-password") >= 1) {
      return next.handle(request).catch(err => {
        // onError
        console.log(err);
        if (err instanceof HttpErrorResponse) {

          console.log(err.status);
          console.log(err.statusText);
          if (err.status === 401) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('LoggedInUser');
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
            window.location.reload();
            //window.location.href = "/login?returnUrl="+this.router.url;
          }
        }
        return Observable.throw(err);
      }) as any;
    }
    else {
      var isLogin = localStorage.getItem('LoggedInUser');
      if (isLogin != null && isLogin != "") {
        return next.handle(request).catch(err => {
          // onError
          console.log(err);
          if (err instanceof HttpErrorResponse) {

            console.log(err.status);
            console.log(err.statusText);
            if (err.status === 401) {
              localStorage.removeItem('currentUser');
              localStorage.removeItem('LoggedInUser');
              
              this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
              window.location.reload();
              //window.location.href = "/login?returnUrl=" + this.router.url;
            }
          }
          return Observable.throw(err);
        }) as any;
      }
      else
      {
        //this.auth.logout();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('LoggedInUser');
        var u = location.pathname;
        
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        window.location.reload();
        // this.auth.Errorlogout(this.router.url);
      // this.router.navigateByUrl('/login',this.router.url);
        //window.location.replace("/login?returnUrl=" + this.router.url);
        
      }
    }
  }
  //intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  //  console.log("intercepted request ... ");

  //  // Clone the request to add the new header.
  //  var url = this.router.url;
    
  //  if (url.indexOf('login') > 0) {
  //    const authReq = req;
  //    return next.handle(authReq)
  //      .catch(err => {
  //        // onError
  //        console.log(err);
  //        if (err instanceof HttpErrorResponse) {
  //          console.log(err.status);
  //          console.log(err.statusText);
  //          if (err.status === 401) {
  //            window.location.href = "/login";
  //          }
  //        }
  //        return Observable.throw(err);
  //      }) as any;
  //  }
  //  else {
  //    const authReq = req.clone({ headers: req.headers.set("Token", localStorage.getItem("LoggedInUser")) });
  //    return next.handle(authReq)
  //      .catch(err => {
  //        // onError
  //        console.log(err);
  //        if (err instanceof HttpErrorResponse) {
  //          console.log(err.status);
  //          console.log(err.statusText);
  //          if (err.status === 401) {
  //            window.location.href = "/login";
  //          }
  //        }
  //        return Observable.throw(err);
  //      }) as any;
  //  }
    
  //  //const authReq = req;


  //  //send the newly created request
   
  //}
}
