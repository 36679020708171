import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrimValidators } from 'src/app/helpers/custom-validators';
import { AuthService } from 'src/app/services';
import { AnalysisDashboardService } from '../analysis-dashboard/analysis-dashboard.service';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { reportPurposeVM, reportStructureVM } from 'src/app/models/reportStructureVM';
import { CompaniesService } from 'src/app/companies/companies.service';
import { companiesVM } from 'src/app/models/companiesVM';
import { enhancedReportVM } from 'src/app/models/enhancedReportVM';
import { reportVM } from 'src/app/models/reportVM';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analysis-enhanced-report',
  templateUrl: './analysis-enhanced-report.component.html',
  styleUrls: ['./analysis-enhanced-report.component.css']
})
export class AnalysisEnhancedReportComponent implements OnInit {

  enhancedReportForm: FormGroup;
  loading = false;
  submitted = false;
  reportStructureData: any;
  reportPurposeList: reportPurposeVM[];
  reportStructureList: reportStructureVM[];
  companyId: any;
  private companiesVM: companiesVM;
  public reportData: reportVM = new reportVM();
  reportStructureSelectedValue: any;
  reportPurposeSelectedValue: any;
  public analysisReportData: reportVM = new reportVM();


  constructor(private authService: AuthService, private formBuilder: FormBuilder,
    private analysisDashboardService: AnalysisDashboardService, private companiesService: CompaniesService,
    private spinnerService: NgxSpinnerService, private toastrService: ToastrService, private router: Router) { }

  ngOnInit() {

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.enhancedReportForm = this.formBuilder.group({
      reportName: ['', TrimValidators.required],
      reportPurpose: ['', TrimValidators.required],
      companyDescription: ['', TrimValidators.required],
      portfolioDescription: ['', TrimValidators.required],
      reportStructure: ['', TrimValidators.required],
    });

    this.companyId = localStorage.getItem("CompanyId");
    this.reportData = JSON.parse(localStorage.getItem("analysisReportData"));
    if (this.reportData && !this.reportData.id) {
      this.reportData = JSON.parse(localStorage.getItem("reportData"));
    }

    this.getCompanyById();
  }

  getCompanyById(): void {

    if (this.companyId) {
      this.companiesService.getById(this.companyId).pipe(first()).subscribe(res => {
        if (res != null) {
          this.companiesVM = res[0];
        }

        this.getReportStructure();
      });
    }
  }

  getReportStructure() {
    this.reportStructureList = [];
    this.reportPurposeList = [];
    this.analysisDashboardService.getReportStructure().pipe(first()).subscribe(result => {
      this.reportStructureData = result;
      if (this.reportStructureData && this.reportStructureData.responseDetail) {

        if (this.reportStructureData.responseDetail.report_templates) {
          this.reportStructureData.responseDetail.report_templates.forEach((element, index) => {
            this.reportStructureList.push({ id: index.toString(), name: element.name });

            if (element.name == "standard") {
              this.reportStructureSelectedValue = index.toString();
            }
          });
        }

        if (this.reportStructureData.responseDetail.use_cases) {
          this.reportStructureData.responseDetail.use_cases.forEach((element, index) => {
            this.reportPurposeList.push({ id: index.toString(), name: element });

            if (index == 0) {
              this.reportPurposeSelectedValue = index.toString();
            }
          });
        }

        this.enhancedReportForm.setValue({
          reportName: this.reportData ? this.reportData.reportName : '',
          reportPurpose: this.reportPurposeSelectedValue,
          companyDescription: this.companiesVM.companyDescription,
          portfolioDescription: this.companiesVM.companyDescription,
          reportStructure: this.reportStructureSelectedValue
        });
      }
    });
  }

  get f() { return this.enhancedReportForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.enhancedReportForm.invalid) {
      return;
    }
    this.loading = true;

    var enhancedReportData = {} as enhancedReportVM;
    enhancedReportData.reportName = this.enhancedReportForm.value.reportName;
    enhancedReportData.reportPurpose = this.enhancedReportForm.value.reportPurpose;
    enhancedReportData.companyDescription = this.enhancedReportForm.value.companyDescription;
    enhancedReportData.portfolioDescription = this.enhancedReportForm.value.portfolioDescription;
    enhancedReportData.reportStructure = this.enhancedReportForm.value.reportStructure;
    enhancedReportData.companyName = this.companiesVM.companyName;
    enhancedReportData.portfolioName = this.reportData.reportName;
    enhancedReportData.numberOfPortfolioAnalysis = "1";
    enhancedReportData.reportData = this.reportData;

    this.spinnerService.show();
    this.analysisDashboardService.generateEnhancedReport(enhancedReportData)
      .subscribe(
        result => {
          let data = result as HttpResponse<Blob>;
          const downloadedFile = new Blob([data.body as BlobPart], { type: data.body ? data.body.type : "" });
          if (downloadedFile && downloadedFile.type && downloadedFile.type != "application/json") {
            var downloadURL = window.URL.createObjectURL(downloadedFile);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = enhancedReportData.reportName + ".docx";
            link.click();

            this.toastrService.success("Analysis enhanced report generated.");
            this.loading = false;
            this.spinnerService.hide();
          }
          else if (downloadedFile && downloadedFile.type && downloadedFile.type == "application/json") {
            this.toastrService.error("Error in analysis enhanced report generating.");
            this.loading = false;
            this.spinnerService.hide();
          }
        },
        error => {
          this.loading = false;
          this.spinnerService.hide();
          this.toastrService.error(error.message);
        });
  }

  gotoDataPage(id: any = '', action: string = '') {
    if (this.reportData != null && this.reportData.id != null) {
      localStorage.setItem("action", action);
      this.router.navigate(['/analysis-data-report-alert-details', id]);
    }
  }

}
