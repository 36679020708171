import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { parametersVM } from '../models/ParameterVM';
import { chemicalcategoryListVM } from '../models/chemicalcategoryListVM';

@Injectable({ providedIn: 'root' })

export class ChemicalParameterService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<parametersVM[]>(`${environment.apiUrl}Parameter/getall`);
  }

  getCategoryList() {
    return this.http.get<any[]>(`${environment.apiUrl}Parameter/CategoryList`);
  }

  GetByCategoryId(id:string) {
    return this.http.get<parametersVM[]>(`${environment.apiUrl}Parameter/ParameterList/`+id)
  }

  //getById(id: number) {
  //  return this.http.get(`${environment.apiUrl}userlogin/GetById/` + id);
  //}

  //create(user: FormData) {
  //  return this.http.post(`${environment.apiUrl}userlogin/createuser`, user);
  //}

  //update(user: FormData) {
  //  return this.http.post(`${environment.apiUrl}userlogin/updateuser`, user);
  //}


  //delete(id: number) {
  // 
  //  return this.http.delete(`${environment.apiUrl}userlogin/deletebyid/` + id);
  //}
}
