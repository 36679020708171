import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';
import { debug } from 'util';
import { userloginVM } from '../models/userloginVM';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { TrimValidators } from '../helpers/custom-validators';
import { ToastrService } from 'ngx-toastr';

//@Component({
//  selector: 'app-reset-password',
//  templateUrl: './reset-password.component.html',
//  styleUrls: ['./reset-password.component.css']
//})

  @Component({ templateUrl: './reset-password.component.html' })
export class ResetPasswordComponent implements OnInit {
  public user: userloginVM;
  parameter: any;
  isUserAvaible = false;
  ResetPasswordForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error: string = '';
  loading: boolean;
  isPasswordMatch: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private toastr: ToastrService
  ) {
    this.parameter = this.activatedRoute.queryParams["value"]["id"]
    //if (this.authenticationService.currentUserValue) {
    //  this.router.navigate(['/']);
    //}
  }

  ngOnInit() {
    this.LoadResetPasswordPage();
    this.ResetPasswordForm = this.formBuilder.group({
      password: ['', TrimValidators.required],
      cpassword: ['', TrimValidators.required]
    });
  }

  get cp() { return this.ResetPasswordForm.controls; }

    LoadResetPasswordPage() {
    this.authenticationService.LoadResetPasswordPage(this.parameter).pipe(first())
      .subscribe(data => {
        this.user = data.responseDetail
        this.isUserAvaible = data.status
        if (data.status == false) {
          this.router.navigate(['/']);
        }
      },
      error => {});
  }

    onConfirmPasswordSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.ResetPasswordForm.invalid) {
      return;
    }
    if (this.cp.cpassword.value != this.cp.password.value) {
      this.isPasswordMatch = true;
      return;
    }
      this.authenticationService.confirmPassword(this.cp.password.value, this.user.encryptedUserId)
      .pipe(first())
      .subscribe(
        data =>
        {
          if (data.issuccess) {
            this.toastr.success(data.message);
            this.router.navigate(['/']);
            //this.auth.sendToken(this.fp.forgotemail.value);
          }
          else {
            this.toastr.error(data.message);
            this.loading = false;
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

}
