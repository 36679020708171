import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AnalysisService } from 'src/app/analysis/analysis.service';
import { UserRoles } from 'src/app/models/enum';
import { AuthService } from 'src/app/services';
import { ManageChemicalService } from '../managechemicalsservice';
import { first } from 'rxjs/operators';
import { chemicalParameterValuePostModel, ecDataVM, ecScoreHistoryVM, newchemicalparameterVM, parameterHistoryVM } from 'src/app/models/chemicalparameterVM';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
declare var google: any;
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPreferencesVM } from 'src/app/models/userActivityFilterVM';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Sort } from '@angular/material';
import { ReportDownloadPdfVM, reportVM } from 'src/app/models/reportVM';
import { AnalysisDashboardService } from 'src/app/analysis/analysis-dashboard/analysis-dashboard.service';
declare var $: any;

@Component({
  selector: 'app-analysis-data-report-alert-details',
  templateUrl: './analysis-data-report-alert-details.component.html',
  styleUrls: ['./analysis-data-report-alert-details.component.css']
})
export class AnalysisDataReportAlertDetailsComponent implements OnInit {

  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewPort: CdkVirtualScrollViewport;
  @ViewChild('contentCategory', { static: true }) templatePopupCategory: TemplateRef<any>;

  dtOptions: DataTables.Settings = {};
  sub: any;
  id: any;
  companyId: any;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;
  loginType: string = "";
  isAdmin = false;

  multiDropdownList = [];
  public allChemicalDataList: any;
  filteredChemicalOptions: any[] = [];

  public chemicalData: any;
  public commanNameString: string = "";
  public selectedItems: string = "";
  isShowCommonName = true;
  isShowComments = true;
  public parameterHistory: parameterHistoryVM[];

  defultParameter = 1;
  public parameterList: newchemicalparameterVM[];
  public keyList: newchemicalparameterVM[];
  public weightedList: newchemicalparameterVM[];
  public calculatedList: newchemicalparameterVM[];
  public chemicalParameterPostModel: chemicalParameterValuePostModel;
  public counters: number = 0;
  public isECExists: boolean = false;
  public toxicityParaCount: number = 0;
  public exposureParaCount: number = 0;
  isKey = true;
  isWeighted = true;
  isCalculated = true;
  isValueChange = false;
  isOtherChange = false;
  isAddChemicalToPortfolio: boolean = false;

  requestForm: FormGroup;
  valueRequestForm: FormGroup;
  isLink = false;
  submitted = false;
  parameterlookuplist: string[];
  public editParameterModel = null;
  public viewParameterModel = null;
  public parameterName: string = null;
  public parameterCurrentValue: number = null;
  isNumeric = false;
  displayCompany: string = null;
  displayUser: string = null;
  displayDate: string = null;
  displayVal: string = null;
  displayRef: string = null;
  displayComment: string = null;
  displayModel: string = null;

  public ecScoreData: newchemicalparameterVM;
  public ecDataList: ecDataVM[];
  public ecScoreHistoryList: ecScoreHistoryVM[];
  public newECScoreCalculation: ecScoreHistoryVM[];
  public userPreferencesVM: UserPreferencesVM;
  public reportData: reportVM = new reportVM();
  reportExcelExportUrl: string;

  public categoryList: any[] = [];
  public filterCategoryList: any[] = [];

  public toxicityRed: number = environment.toxicityRed;
  public toxicityOrange: number = environment.toxicityOrange;
  public toxicityGreen: number = environment.toxicityGreen;
  public exposureRed: number = environment.exposureRed;
  public exposureOrange: number = environment.exposureOrange;
  public exposureGreen: number = environment.exposureGreen;
  public doiUrl: string = environment.doiUrl;

  getGoogle() {
    return google;
  }

  constructor(private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private analysisService: AnalysisService,
    private chemicalService: ManageChemicalService,
    private reportService: AnalysisDashboardService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }

    this.reportData = JSON.parse(localStorage.getItem('reportData'));
    var action = localStorage.getItem("action");
    if (action == "ADDCHEMICALTOPORTFOLIO") {
      this.isAddChemicalToPortfolio = true;
    }

    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
      this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
    }

    this.loginType = localStorage.getItem('userType');
    this.loginType = this.loginType.replace(/"/g, "");
    if (this.loginType == 'SuperAdmin' || this.loginType == 'Elemental') {
      this.isAdmin = true;
    }

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
    if (this.id) {
      var data = { id: this.id };
      this.GetChemicalDetails(data, "");
    }

    this.dtOptions = {
      paging: false,
      info: false,
      search: {
        "caseInsensitive": true
      }
    };

    this.valueRequestForm = this.formBuilder.group({
      viewValueId: [''],
      viewSourceLink: [''],
      viewsourceCompany: [''],
      viewsourceUser: [''],
      viewcomment: [''],
      viewmodel: [''],
      viewdate: [''],
      viewParameterValue: [''],
      viewParameterId: [''],
      viewChemicalId: [''],
      viewChemicalParameterId: [''],
      viewIsNumeric: ['']
    });

    this.showHistoryData('show');
    this.showCommonName('hide');
    this.showComments('hide');

    this.getAllCategoryForHierarchy();
  }

  private getAllCategoryForHierarchy() {
    this.chemicalService.getAllCategoryForHierarchy().pipe(first())
      .subscribe(res => {
        this.categoryList = res;
        // console.log(this.categoryList);
        this.filterCategoryList = Object.assign([], this.categoryList);
      },
        error => {
        });
  }

  GetChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {
    this.SpinnerService.show();
    this.analysisService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.multiDropdownList = [];
      this.allChemicalDataList = data;

      this.multiDropdownList = this.allChemicalDataList.map((m) => {
        return {
          id: m.id,
          itemName: m.chemicalName,
          name: m.commonName,
          casnumber: m.casnumber,
          isChecked: false,
          isValid: m.isValid,
          categoryName: m.categoryName
        }
      });
      this.filteredChemicalOptions = Object.assign([], this.multiDropdownList);
      this.SpinnerService.hide();

      // setTimeout(() => {
      //   const selectedIndex = this.filteredChemicalOptions.findIndex(element => element.id === this.id);
      //   if (selectedIndex > -1) {
      //     this.viewPort.scrollToIndex(selectedIndex, 'smooth');
      //   }
      // }, 1000);
    },
      error => {
        this.SpinnerService.hide();
      });
  }

  onSearch(searchTerm: string) {
    if (searchTerm.trim().length == 0) {
      this.filteredChemicalOptions = this.multiDropdownList.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) || (item.categoryName && item.categoryName.toLowerCase().includes(searchTerm.toLowerCase())) || item.casnumber.includes(searchTerm.toLowerCase()) || (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    else {
      let searchTermSplit = searchTerm.split(";").map(item => item.trim());
      var tempFilteredChemicalOptions = [];
      for (var i = 0; i < searchTermSplit.length; i++) {
        var value = searchTermSplit[i];
        if (value) {
          var tmpFilteredChemicalOptions = this.multiDropdownList.filter(item =>
            item.itemName.toLowerCase().includes(value.toLowerCase()) || (item.categoryName && item.categoryName.toLowerCase().includes(value.toLowerCase())) || item.casnumber.includes(value.toLowerCase()) || (item.name && item.name.toLowerCase().includes(value.toLowerCase()))
          );
          tempFilteredChemicalOptions = tempFilteredChemicalOptions.concat(tmpFilteredChemicalOptions);
        }
      }
      this.filteredChemicalOptions = tempFilteredChemicalOptions;
    }
  }

  GetChemicalDetails(data, value) {
    this.SpinnerService.show();
    if (data) {
      this.id = data.id;
      if (value == "ChemicalDetail") {
        this.isAddChemicalToPortfolio = false;
      }
      this.getAllParameter(this.id);
      this.getChemical(this.id)
      this.getECScore();
      this.getECDataAndECScoreHistory();
      this.showHistoryData('show');
    }
  }

  private getAllParameter(id: string) {
    if (this.isCompanyAdmin || this.isUsers) {
      this.chemicalService.getAllParameterByCompany(id, this.defultParameter, this.companyId).pipe(first())
        .subscribe(parameter => {
          //this.parameterList = parameter;
          var selectedDomainId = localStorage.getItem("domainId");
          this.parameterList = parameter.filter(x => x.domainId == null || x.domainId == selectedDomainId);
          this.parameterList = this.parameterList.filter(x => x["parameterCode"] != "EC4" && x["parameterCode"] != "EC12");
          this.filterParameterList();
          this.SpinnerService.hide();
        },
          error => {
            this.SpinnerService.hide();
            this.toastr.error(error.message);
          });
    }
    else {
      this.chemicalService.getAllParameter(id, this.defultParameter, this.companyId).pipe(first())
        .subscribe(parameter => {
          this.parameterList = parameter;
          this.parameterList = this.parameterList.filter(x => x["parameterCode"] != "EC4" && x["parameterCode"] != "EC12");
          this.filterParameterList();
          this.SpinnerService.hide();
        },
          error => {
            this.SpinnerService.hide();
            this.toastr.error(error.message);
          });
    }
  }

  getChemical(chemicalId: string = null): void {
    this.commanNameString = "";
    this.selectedItems = "";
    if (chemicalId) {
      this.chemicalService.getById(chemicalId).pipe(first()).subscribe(userData => {
        this.chemicalData = userData[0];

        if (this.chemicalData.commonNameKeyword.length > 0) {
          for (let i = 0; i < this.chemicalData.commonNameKeyword.length; i++) {
            if (this.commanNameString != null && this.commanNameString != "") {
              this.commanNameString += ", ";
            }
            this.commanNameString = this.commanNameString + this.chemicalData.commonNameKeyword[i]["title"];
          }
        }

        if (this.chemicalData.categoryDropDownBind.length > 0) {
          for (let k = 0; k < this.chemicalData.categoryDropDownBind.length; k++) {
            if (this.selectedItems != null && this.selectedItems != "") {
              this.selectedItems += ", ";
            }
            this.selectedItems = this.selectedItems + this.chemicalData.categoryDropDownBind[k]["categoryName"];
          }
        }

      });
    }
  }

  filterParameterList() {
    let data = this.parameterList.filter(p => p["parameterValue"] != null && p["parameterValue"] != "");
    this.keyList = this.parameterList.filter(p => p["parameterType"] == 1 && p["parameterValue"] != null);

    this.keyList = this.keyList.filter(x => x["parameterCode"] != "EP40");

    this.isECExists = this.keyList.filter(x => x.parentName == "Emerging Concern").length <= 0 ? true : false;

    if (this.isAdmin) {
      this.counters = data.length;
    } else {
      this.counters = this.keyList.length;
    }

    this.toxicityParaCount = this.parameterList.filter(x => x.parentName == "Toxicity" && x["parameterValue"] != null && x["parameterValue"] != "").length;
    this.exposureParaCount = this.parameterList.filter(x => x.parentName == "Exposure" && x["parameterValue"] != null && x["parameterValue"] != "").length;
  }

  openParameterList(type) {
    this.isKey = false;
    this.isWeighted = false;
    this.isCalculated = false;
    $("#key").removeClass();
    $("#weighted").removeClass();
    $("#calculated").removeClass();
    if (type == 'key') {
      this.isKey = true;
      $("#key").addClass("btn btn_primary");
      $("#weighted").addClass("btn btn_bordered");
      $("#calculated").addClass("btn btn_bordered");
    }
    if (type == 'weighted') {
      this.isWeighted = true;
      $("#weighted").addClass("btn btn_primary");
      $("#key").addClass("btn btn_bordered");
      $("#calculated").addClass("btn btn_bordered");
    }
    if (type == 'calculated') {
      this.isCalculated = true;
      $("#calculated").addClass("btn btn_primary");
      $("#weighted").addClass("btn btn_bordered");
      $("#key").addClass("btn btn_bordered");
    }
  }

  EditParameter(data) {
    this.submitted = false;
    this.editParameterModel = data;
    this.parameterlookuplist = [];
    this.parameterName = this.editParameterModel.parameterName;
    this.parameterCurrentValue = this.editParameterModel.parameterValue;
    this.requestForm.value.parameterId = this.editParameterModel.parameterId;
    this.requestForm.value.currentValue = this.editParameterModel.parameterValue;
    this.requestForm.value.companyId = localStorage.getItem('CompanyId');
    this.requestForm.value.chemicalId = this.editParameterModel.chemicalId;
    this.requestForm.value.newValue = '';
    if (this.editParameterModel.lookUpId != null) {
      this.parameterlookuplist = this.editParameterModel.parameterlookuplist;
    }

    $('#txtNewValue').val('');
    $('#editParamterModelPopup').modal('show');
  }

  ViewParameter(data) {
    this.isLink = false;
    this.parameterlookuplist = [];
    this.submitted = false;
    this.viewParameterModel = data;
    this.parameterName = this.viewParameterModel.parameterName;

    if (this.viewParameterModel.lookUpId != null) {
      this.parameterlookuplist = this.viewParameterModel.parameterlookuplist;
    }

    this.valueRequestForm.setValue({
      viewValueId: null,
      viewSourceLink: this.viewParameterModel.source,
      viewsourceCompany: this.viewParameterModel.sourceCompany,
      viewsourceUser: this.viewParameterModel.sourceUser,
      viewcomment: this.viewParameterModel.comments,
      viewmodel: this.viewParameterModel.modelSpecies,
      viewdate: this.viewParameterModel.createdDate,
      viewParameterValue: this.viewParameterModel.parameterValue,
      viewParameterId: this.viewParameterModel.parameterId,
      viewChemicalId: this.viewParameterModel.chemicalId,
      viewChemicalParameterId: this.viewParameterModel.chemicalParameterId,
      viewIsNumeric: this.viewParameterModel.isNumeric
    })
    this.isNumeric = this.viewParameterModel.isNumeric;
    this.displayCompany = this.viewParameterModel.sourceCompany;
    this.displayUser = this.viewParameterModel.sourceUser;
    this.displayDate = this.viewParameterModel.createdDate;
    var measureValue = this.viewParameterModel.uomTitle != null ? this.viewParameterModel.uomTitle : "";
    this.displayVal = this.viewParameterModel.parameterValue + " " + measureValue;
    if (this.viewParameterModel.parameterName.toLowerCase() == "readily biodegradable") {
      this.displayVal = this.viewParameterModel.parameterValue == 1 ? "Yes" : "No";
    }

    this.displayRef = this.viewParameterModel.source;
    this.displayComment = this.viewParameterModel.comments;
    this.displayModel = this.viewParameterModel.modelSpecies;
    if (this.displayRef != "" && this.displayRef != null) {
      if (this.displayRef.toLowerCase().startsWith("http") || this.displayRef.toLowerCase().startsWith("https") || this.displayRef.toLowerCase().startsWith("www")) {
        this.isLink = true;
      }
    }

    $('#ViewParamterModelPopup').modal('show');
  }

  getClass(type: string) {
    if (type === 'Toxicity') {
      if (this.toxicityParaCount <= this.toxicityRed) {
        return 'redCircle'
      }
      else if (this.toxicityRed < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityOrange) {
        return 'orangeCircle'
      }
      else if (this.toxicityOrange < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityGreen) {
        return 'greenCircle'
      }
    }
    else if (type === 'Exposure') {
      if (this.exposureParaCount <= this.exposureRed) {
        return 'redCircle'
      }
      else if (this.exposureRed < this.exposureParaCount && this.exposureParaCount <= this.exposureOrange) {
        return 'orangeCircle'
      }
      else if (this.exposureOrange < this.exposureParaCount && this.exposureParaCount <= this.exposureGreen) {
        return 'greenCircle'
      }
    }
  }

  getTooltip(type: string): string {
    if (type === 'Toxicity') {
      if (this.toxicityParaCount <= this.toxicityRed) {
        return 'High uncertainty';
      } else if (this.toxicityRed < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityOrange) {
        return 'Moderate uncertainty';
      } else if (this.toxicityOrange < this.toxicityParaCount && this.toxicityParaCount <= this.toxicityGreen) {
        return 'Low uncertainty';
      }
    } else if (type === 'Exposure') {
      if (this.exposureParaCount <= this.exposureRed) {
        return 'High uncertainty';
      } else if (this.exposureRed < this.exposureParaCount && this.exposureParaCount <= this.exposureOrange) {
        return 'Moderate uncertainty';
      } else if (this.exposureOrange < this.exposureParaCount && this.exposureParaCount <= this.exposureGreen) {
        return 'Low uncertainty';
      }
    }
    return ''; // Default tooltip text if no condition matches
  }

  renderECScoreChart() {
    var optionsECScore = {
      tooltip: { isHtml: true },
      legend: 'none',
      width: 744,
      height: 300,
      hAxis: {
        title: "Date of calculation",
      },
      vAxis: {
        title: "EC Score"
      }
    };

    var dataTable = new google.visualization.DataTable();
    dataTable.addColumn("string", "Date");
    dataTable.addColumn("number", "ECScore");
    console.log('this.ecScoreHistoryList', this.ecScoreHistoryList)
    var mainValue = [];
    this.ecScoreHistoryList.forEach((value) => {
      let subValue = [];
      subValue.push(value.labelCreatedDate);
      subValue.push(value.ecscore);
      mainValue.push(subValue);
    });
    dataTable.addRows(mainValue);

    var ecScoreChart = new google.visualization.LineChart(document.getElementById("ecScoreChart"));
    ecScoreChart.draw(dataTable, optionsECScore);
  }

  getECScore() {
    this.chemicalService.getECScoreByChemicalId(this.id).subscribe(res => {
      this.ecScoreData = new newchemicalparameterVM();
      this.ecScoreData["chemicalId"] = res["chemicalId"];
      this.ecScoreData["parameterName"] = "EC score (last 6 months)";
      this.ecScoreData["parameterValue"] = res["ecscore1"];
      this.ecScoreData["source"] = res["doi"] != null ? environment.doiUrl + res["doi"] : "";
      this.ecScoreData["sourceCompany"] = "";
      this.ecScoreData["sourceUser"] = "";
      this.ecScoreData["comments"] = "";
      this.ecScoreData["modelSpecies"] = "";
      this.ecScoreData["createdDate"] = res["createdDate"];
      this.ecScoreData["parameterId"] = "";
      this.ecScoreData["chemicalParameterId"] = "";
      this.ecScoreData["isNumeric"] = "";
    });
  }

  getECDataAndECScoreHistory() {
    this.chemicalService.getECDataAndECScoreHistoryByChemicalId(this.id).subscribe(res => {
      console.log(res);
      this.ecDataList = res["ecDataList"];
      this.ecScoreHistoryList = res["ecScoreHistoryList"];
    });
  }

  async popupECScore(content, data) {
    this.getGoogle();

    this.modalService.open(content, {
      ariaLabelledBy: 'modelPopupLabelECScore',
      windowClass: "modelPopupLabelECScore",
    }).result.then((result) => {
      if (result == "Yes") {

      }
      else if (result == "No") {

      }
    }, (reason) => {
      console.log(reason);
    });

    await this.getUserPreferences();
  }

  async getUserPreferences() {
    this.resetDefaultParams();
    return new Promise<void>((resolve, reject) => {
      this.chemicalService.getUserPreferences().pipe(first())
        .subscribe(data => {
          if (data['status'] === true) {
            this.SpinnerService.hide();
            if (data['responseDetail']) {
              //this.userPreferencesVM = new UserPreferencesVM();
              this.userPreferencesVM = data['responseDetail']
            }
            else {
              this.resetDefaultParams();
            }
          }
          else {
            this.toastr.error(data['message']);
          }
          this.newECScoreChart();
          resolve();
        },
          error => {
            this.toastr.error(error.message);
          });

    });
  }

  newECScoreChart() {
    this.calculateECScores();
    var optionsECScore = {
      tooltip: { isHtml: true },
      legend: 'none',
      width: 744,
      height: 300,
      hAxis: {
        title: "Date of calculation",
      },
      vAxis: {
        title: "EC Score"
      }
    };

    var dataTable = new google.visualization.DataTable();
    dataTable.addColumn("string", "Date");
    dataTable.addColumn("number", "ECScore");
    var mainValue = [];
    this.newECScoreCalculation.forEach((value) => {
      let subValue = [];
      subValue.push(value.labelCreatedDate);
      subValue.push(value.ecscore);
      mainValue.push(subValue);
    });
    dataTable.addRows(mainValue);

    var ecScoreChart = new google.visualization.LineChart(document.getElementById("ecScoreChart"));
    ecScoreChart.draw(dataTable, optionsECScore);
  }

  calculateECScores() {
    this.newECScoreCalculation = [];
    const nval = Math.floor(this.userPreferencesVM.span / this.userPreferencesVM.frequency);

    for (let i = 1; i <= nval; i++) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - this.userPreferencesVM.frequency * (i - 1));
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - this.userPreferencesVM.period);
      let crit1 = 0;
      let crit2 = 0;
      let count = 0;

      for (const ec of this.ecDataList) {
        const ecPublishedDate = new Date(ec.publishedDate);
        if (ecPublishedDate >= startDate && ecPublishedDate < endDate) {
          crit1 += ec.criterion1 ? 1 : 0;
          crit2 += ec.criterion2 ? 1 : 0;
          count++;
        }
      }

      const EC_score = crit1 * crit2 / (count * count);
      const labelCreatedDate = startDate.toLocaleDateString('en-GB') + ' to ' + endDate.toLocaleDateString('en-GB');

      this.newECScoreCalculation.push({
        id: '', // Assign proper values
        chemicalId: '', // Assign proper values
        ecscore: EC_score,
        createdDate: new Date().toISOString(), // Assign proper values
        labelCreatedDate: labelCreatedDate,
        publishedDate: '', // Assign proper values
        doi: '', // Assign proper values
        orderId: i
      });
    }

    this.newECScoreCalculation.sort((a, b) => {
      return b.orderId - a.orderId;
    });
  }

  resetDefaultParams() {
    this.userPreferencesVM = new UserPreferencesVM();
    this.userPreferencesVM.period = 180;
    this.userPreferencesVM.frequency = 30;
    this.userPreferencesVM.span = 360;
    this.newECScoreChart();
  }

  saveUserPreferences() {
    this.chemicalService.saveUserPreferences(this.userPreferencesVM).pipe(first())
      .subscribe(data => {
        var test = this.modalService.hasOpenModals();
        if (data['status'] === true) {
          this.SpinnerService.hide();
          this.toastr.success(data['message']);
          this.newECScoreChart();
        }
        else {
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  keyValuePairsUpdate() {
    this.newECScoreChart();
  }

  showCommonName(type) {
    if (type == "show") {
      this.isShowCommonName = false;
    }
    else {
      this.isShowCommonName = true;
    }
  }

  showComments(type) {
    if (type == "show") {
      this.isShowComments = false;
    }
    else {
      this.isShowComments = true;
    }
  }

  showHistoryData(type) {
    if (type == "show") {
      if (this.id) {
        this.chemicalService.getAllParameterHistory(this.id).pipe(first()).subscribe(Data => {
          this.parameterHistory = Data;
        });
      }
    }
  }

  valueParameter() {
    this.isValueChange = true;
  }
  otherParameter() {
    this.isOtherChange = true;
  }

  onValueSubmit() {
    this.submitted = true;
    if (this.valueRequestForm.invalid) {
      return;
    }
    this.submitted = false;
    this.chemicalParameterPostModel = new chemicalParameterValuePostModel();

    this.chemicalParameterPostModel.s = this.valueRequestForm.value.viewSourceLink;
    this.chemicalParameterPostModel.c = this.valueRequestForm.value.viewcomment;
    this.chemicalParameterPostModel.m = this.valueRequestForm.value.viewmodel;
    let paramValue = 0;
    if (this.isNumeric) {
      if (this.valueRequestForm.value.viewParameterValue != "" && this.valueRequestForm.value.viewParameterValue != null) {
        paramValue = this.valueRequestForm.value.viewParameterValue;
      }
    }
    else {
      paramValue = this.valueRequestForm.value.viewParameterValue;
    }
    this.chemicalParameterPostModel.val = paramValue.toString();
    this.chemicalParameterPostModel.pid = this.valueRequestForm.value.viewParameterId;
    this.chemicalParameterPostModel.cid = this.valueRequestForm.value.viewChemicalId;
    this.chemicalParameterPostModel.cpid = this.valueRequestForm.value.viewChemicalParameterId;
    if (this.isValueChange) {
      this.chemicalParameterPostModel.changeType = 1;
    }
    else if (this.isOtherChange) {
      this.chemicalParameterPostModel.changeType = 2;
    }
    else {
      this.chemicalParameterPostModel.changeType = 0;
    }

    this.chemicalService.savePopUpValue(this.chemicalParameterPostModel).pipe(first()).subscribe(data => {
      if (data['status'] === true) {
        this.toastr.success(data['message']);
        this.getAllParameter(this.valueRequestForm.value.viewChemicalId);
        $('#ViewParamterModelPopup').modal('hide');
        this.viewParameterModel = null;
      }
      else {
        this.toastr.error(data['message']);
      }
    },
      error => {
        this.toastr.error(error.message);
      });
  }

  CreateDownloadPdf() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      if (this.reportData != null && this.reportData.id != null) {
        this.toastr.success("Report generation started. You will receive the report by email shortly.");
        //this.SpinnerService.show();
        var base64Str = localStorage.getItem("base64Str");
        var reportData: ReportDownloadPdfVM = new ReportDownloadPdfVM();
        reportData.reportId = this.reportData.id;
        reportData.base64Str = base64Str;
        this.reportService.downloadPdf(reportData)
          .then(data => {
          },
            error => {
              //this.toastr.error(error.error.message);
            });
      }
      else {
        this.toastr.info("No analysis available to generate report.");
      }
    }
  }

  CreateDownloadExcel() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      if (this.reportData != null && this.reportData.id != null) {
        this.toastr.success("Report generation started. You will receive the report by email shortly.");
        //this.SpinnerService.show();
        this.reportExcelExportUrl = localStorage.getItem("base64Str");
        this.reportService.downloadExcel(this.reportData.id, this.reportExcelExportUrl).subscribe(paraData => {

          this.reportService.downloadExcelBlob(this.reportData.id)
            .pipe(first())
            .subscribe(data => {
            },
              error => {
                //this.toastr.error(error.error.message);
              });
        },
          error => {
          });
      }
      else {
        this.toastr.info("No analysis available to generate report.");
      }
    }
  }

  CreateEnhancedReport() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      if (this.reportData != null && this.reportData.id != null) {
        // localStorage.setItem("reportData", JSON.stringify(this.reportData));
        this.router.navigate(['/enhanced-report']);
      }
      else {
        this.toastr.info("No analysis available to generate report.");
      }
    }
  }

  onClickAddChemical() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      localStorage.setItem("IsNewInterface", "TRUE");
      this.router.navigate(['/add-chemical', '', '']);
    }
  }

  onClickEditChemical() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
      return;
    }
    if (this.id != "" && this.id != null) {
      localStorage.setItem("IsNewInterface", "TRUE");
      this.router.navigate(['/add-chemical', this.id, '']);
    }
    else {
      // this.router.navigate(['/add-chemical', '', '']);
      this.toastr.info("Please select at least one chemical.");
    }
  }

  onClickAddChemicalToPortfolio() {
    let data = { reportId: this.reportData.id, chemicalId: this.id };
    this.reportService.addChemicalToPortfolio(data).pipe(first())
      .subscribe(res => {
        this.router.navigate(['/analysis-dashboard']);
      });
  }

  onClickCategory() {
    this.popupCategory(this.templatePopupCategory);
  }

  popupCategory(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modelPopupLabelCategory',
      windowClass: 'modalPopupCategory'
    }).result.then((result) => {
      if (result == "Yes") {

      }
      else if (result == "No") {
        this.onSearchCategories("");
      }
    }, (reason) => {
    });
  }

  onSearchCategories(searchText: string) {
    if (searchText.trim().length === 0) {
      this.filterCategoryList = this.categoryList;
    } else {
      this.filterCategoryList = this.filterCategories(this.categoryList, searchText.toLowerCase());
    }
  }

  filterCategories(categories: any[], searchText: string): any[] {
    return categories.reduce((acc, category) => {
      // Check if the category name matches the search text
      const matches = category.categoryName && category.categoryName.toLowerCase().includes(searchText);
      let filteredChildren = [];

      // If the category has children, recursively filter them
      if (category.children && category.children.length > 0) {
        filteredChildren = this.filterCategories(category.children, searchText);
      }

      // If the category matches or has any filtered children, include it in the results
      if (matches || filteredChildren.length > 0) {
        const newCategory = { ...category, children: filteredChildren };
        acc.push(newCategory);
      }

      return acc;
    }, []);
  }

  //Original Code
  // onSearchCategories(searchText: string) {
  //   if (searchText.trim().length == 0) {
  //     this.filterCategoryList = this.categoryList.filter(item => (item.categoryName && item.categoryName.toLowerCase().includes(searchText.toLowerCase())));
  //   }
  //   else {
  //     var tempCategoryList = Object.assign([], this.categoryList);
  //     var filteredCategories = this.filterCategories(tempCategoryList, searchText);
  //     console.log("filteredCategories >>> ", filteredCategories);
  //     this.filterCategoryList = filteredCategories;
  //   }
  // }

  // filterCategories(categories: any[], searchText: string): any[] {
  //   let filteredCategories: any[] = [];

  //   categories.forEach(category => {
  //     if (category.categoryName.toLowerCase().includes(searchText.toLowerCase())) {
  //       filteredCategories.push(category); // push matching category
  //     }

  //     if (category.children && category.children.length > 0) {
  //       var tempCategoryChildren = Object.assign([], category.children);
  //       let filteredChildren = this.filterCategories(tempCategoryChildren, searchText); // Recursive function call
  //       if (filteredChildren.length > 0) {
  //         category.children = filteredChildren; // Update children with filtered results
  //         filteredCategories.push(category); // push category with filtered children
  //       }
  //     }
  //   });

  //   return filteredCategories;
  // }

  toggleNode(node: any) {
    node.isExpanded = !node.isExpanded;
  }

  validateInteger(event: KeyboardEvent): void {
    // Get the input value and concatenate the pressed key
    const input = (event.target as HTMLInputElement).value + event.key;

    // Check if the input matches the integer pattern
    if (!/^\d*$/.test(input)) {
      // Prevent the default behavior if the input is not an integer
      event.preventDefault();
    }
  }

  sort(sort: Sort) {
    const data = this.ecDataList.slice();
    if (!sort.active || sort.direction === '') {
      this.ecDataList = data;
      return;
    }
    this.ecDataList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'doi': return compare(a.doi, b.doi, isAsc);
        case 'criterion1': return compare(a.criterion1, b.criterion1, isAsc);
        case 'criterion2': return compare(a.criterion2, b.criterion2, isAsc);
        default: return 0;
      }
    });
  }

}

function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
