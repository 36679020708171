import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { userloginVM } from '../models/userloginVM';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll(order : number) {
      return this.http.get<userloginVM[]>(`${environment.apiUrl}userlogin/GetAll/` + order);
    }



  getById(id: number) {
    return this.http.get(`${environment.apiUrl}userlogin/GetById/` + id);
  }

  getAllByCompanyId(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}userlogin/GetAllByCompanyId/` + id);
  }

  create(user: FormData) {
        return this.http.post(`${environment.apiUrl}userlogin/createuser`, user);
    }

    update(user: FormData) {
        return this.http.post(`${environment.apiUrl}userlogin/updateuser`, user);
    }

  getUserRoleList(id: string) {
      return this.http.get<any[]>(`${environment.apiUrl}userlogin/UserRoleList/`+id);
    }

    delete(id: number) {  
        return this.http.get(`${environment.apiUrl}userlogin/deletebyid/` + id);
    }

  getByBranchUserId(id: number) {
    return this.http.get(`${environment.apiUrl}userlogin/GetByBranchUserId/` + id);
  }

  createBranchUser(user: FormData) {
    return this.http.post(`${environment.apiUrl}userlogin/createcompanybranchuser`, user);
  }

  updatecompanybranchuser(user: FormData) {
    return this.http.post(`${environment.apiUrl}userlogin/updatecompanybranchuser`, user);
  }
  deleteBranchUser(id: number) {
    return this.http.get(`${environment.apiUrl}userlogin/deleteBranchUser/` + id);
  }
}
