import { Component, OnInit, Input, Directive, ElementRef } from '@angular/core';
import { chemicalparameterVM, chemicalCategoryPostVM, chemicalParameterPostModel, chemicalParameterValuePostVM, chemicalVM, ExportChemicalParameterVM, AddExportVM, newchemicalparameterVM, ChangedParameterVM } from '../../models/chemicalparameterVM';
//import { ChemicalValueRequestVM } from '../../models/chemicalValueRequestVM';
import { AlertService } from '../../services';
import { ManageChemicalService } from '../managechemicalsservice';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from '../../helpers/pager.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrimValidators } from '../../helpers/custom-validators';
import { AuthService } from '../../services/auth.service';
import { UserRoles } from '../../../app/models/enum';
import { Location } from '@angular/common';
declare var $: any;
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainService } from 'src/app/domain/domain.service';
import { domainParameterValueVM, domainVM, multiDomainParameterVM } from 'src/app/models/domainVM';
import { parameterDomainVM } from 'src/app/models/parameterDomainVM';
type AOA = any[][];

@Component({
  selector: 'app-addchemical',
  templateUrl: './addchemical.component.html',
  styleUrls: ['./addchemical.component.css']
})
export class AddchemicalComponent implements OnInit {
  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  companyId: any;
  public categoryList: any[] = [];
  public parameterList: newchemicalparameterVM[];
  public globalParameterList: newchemicalparameterVM[];
  public changedParameterValuesList: ChangedParameterVM[];
  public chemicalList: chemicalVM;
  public chemicalParameterPostModel: chemicalParameterPostModel;
  selectedItems = [];
  dropdownSettings = {};
  loading = false;
  chemicalForm: FormGroup;
  sub: any;
  id: any;
  k: any;
  dropdownList = [];
  submitted = false;
  defultParameterType = 1;
  oldCommanName = "";
  isValueChange = false;
  isOtherChange = false;
  isChangeType = 0;
  isEdit = false;
  public keyList: AddExportVM[];
  public ExportDataList: ExportChemicalParameterVM[];
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  fileUrl: any;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;
  public chemicalData: chemicalVM;
  public domainList: domainVM[];
  public multiDomainParameterList: multiDomainParameterVM[] = [];
  public domainParameterValueList: domainParameterValueVM[];
  public titleParameterName: string;
  public parameterDomainList: parameterDomainVM[];
  public drinkingGuidelineValues: any;
  public parameterControlType: number = 0;

  constructor(
    private chemicalService: ManageChemicalService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private location: Location,
    private domainService: DomainService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.companyId = localStorage.getItem("CompanyId");
    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
      this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
    }
    this.SpinnerService.show();
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });
    this.chemicalForm = this.formBuilder.group({
      id: [''],
      casno: ['', [TrimValidators.required]],
      chemicalName: ['', [TrimValidators.required]],
      molecularWeight: [''],
      source: [''],
      commonName: [''],
      comments: ['']
    });

    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Category",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "form-control"
    };

    this.getDrinkingGuidelineValues();
    this.getParameterDomain();
    if (this.id) {
      this.isEdit = (this.isUsers || this.isCompanyAdmin) ? true : false;
      this.getCategoryList();
      this.getAllParameter(this.id);
      this.editChemical(this.id)
    }
    else {
      this.getCategoryList();
      this.getAllParameter(null);
    }
  }

  get f() { return this.chemicalForm.controls; }

  private getCategoryList() {
    this.chemicalService.getCategoryListForManageChemical().pipe(first())
      .subscribe(p => {
        this.categoryList = p;
        for (var obj in this.categoryList) {
          let newName = {
            id: this.categoryList[obj].id,
            itemName: this.categoryList[obj].categoryName
          };
          this.dropdownList.push(newName);
        };

      },
        error => {
          //this.toastr.error(error.message);
        });
  }

  private getAllParameter(id: string) {
    if (this.isCompanyAdmin || this.isUsers) {
      this.chemicalService.getAllParameterByCompany(id, this.defultParameterType, this.companyId).pipe(first())
        .subscribe(parameter => {
          //this.parameterList = parameter;
          this.globalParameterList = parameter;
          this.parameterList = parameter.filter(x => x.domainId == null && x.parameterCode != "EP40");
          this.SpinnerService.hide();
          this.getDomain();
        },
          error => {
            //this.toastr.error(error.message);
          });
    }
    else {
      this.chemicalService.getAllParameter(id, this.defultParameterType, null).pipe(first())
        .subscribe(parameter => {
          //this.parameterList = parameter;
          this.globalParameterList = parameter;
          this.parameterList = parameter.filter(x => x.domainId == null && x.parameterCode != "EP40");
          this.SpinnerService.hide();
          this.getDomain();
        },
          error => {
            //this.toastr.error(error.message);
          });
    }
  }

  getDomain() {
    this.SpinnerService.show();
    this.domainService.getAll().pipe(first())
      .subscribe(res => {
        this.domainList = res;
        this.multiDomainParameterList = [];
        let multiDomainPara = this.globalParameterList.filter(x => x.isMultiDomainParameter && x.domainId == null);
        if (multiDomainPara && multiDomainPara.length > 0) {
          multiDomainPara.forEach((value) => {
            let multiDomainParameterModel: multiDomainParameterVM = {} as multiDomainParameterVM;
            multiDomainParameterModel.parameterId = value['parameterId'];
            multiDomainParameterModel.controlType = value['controlType'];
            multiDomainParameterModel.domainParameterList = [];

            this.domainList.forEach((item) => {
              let domainParameterValueModel: domainParameterValueVM = {} as domainParameterValueVM;
              domainParameterValueModel.id = item.id;
              domainParameterValueModel.domainName = item.domainName;
              domainParameterValueModel.isActive = item.isActive;

              let domainParameter = this.globalParameterList.find(x => x.domainId == item.id && x['parameterId'] == value['parameterId']);
              if (domainParameter) {
                domainParameterValueModel.parameterId = domainParameter['parameterId'];
                domainParameterValueModel.parameterValue = domainParameter['parameterValue'];
                domainParameterValueModel.oldParameterValue = domainParameter['parameterValue'];
                domainParameterValueModel.source = domainParameter['source'];
                domainParameterValueModel.oldSource = domainParameter['source'];
                domainParameterValueModel.comments = domainParameter['comments'];
                domainParameterValueModel.oldComments = domainParameter['comments'];
              }
              multiDomainParameterModel.domainParameterList.push(domainParameterValueModel);
            });
            this.multiDomainParameterList.push(multiDomainParameterModel);
          });
        }

        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  getParameterDomain() {
    this.SpinnerService.show();
    this.domainService.getParameterDomainAll().pipe(first())
      .subscribe(res => {
        this.parameterDomainList = res;
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  private getDrinkingGuidelineValues() {
    this.chemicalService.getDrinkingGuidelineValues().pipe(first())
      .subscribe(res => {
        this.drinkingGuidelineValues = res;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  editChemical(chemicalId: string = null): void {
    if (chemicalId) {
      this.chemicalService.getById(chemicalId).pipe(first()).subscribe(userData => {
        let Data = userData[0];
        this.chemicalData = userData[0];
        let commanNameString = "";
        if (Data.commonNameKeyword.length > 0) {
          for (let i = 0; i < Data.commonNameKeyword.length; i++) {
            commanNameString = commanNameString + Data.commonNameKeyword[i]["title"] + ";"
          }
          commanNameString = commanNameString.slice(0, -1);
        }
        if (Data.categoryDropDownBind.length > 0) {
          this.selectedItems = [];
          for (let k = 0; k < Data.categoryDropDownBind.length; k++) {
            let newName = {
              id: Data.categoryDropDownBind[k]["id"],
              itemName: Data.categoryDropDownBind[k]["categoryName"]
            };
            this.selectedItems.push(newName);
          }
        }
        this.oldCommanName = commanNameString;
        this.chemicalForm.setValue({
          id: Data["id"],
          casno: Data["casnumber"],
          chemicalName: Data["chemicalName"],
          molecularWeight: Data["molecularWeight"].toString(),
          source: Data["source"],
          commonName: commanNameString,
          comments: Data["comments"]
        })
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.chemicalForm.invalid) {
      return;
    }
    this.SpinnerService.show();
    this.chemicalParameterPostModel = new chemicalParameterPostModel();
    if (this.isValueChange && this.isOtherChange) {
      this.isChangeType = 1;
    }
    else if (this.isOtherChange) {
      this.isChangeType = 2
    }
    else if (this.isValueChange) {
      this.isChangeType = 1;
    }
    this.chemicalParameterPostModel.isChangeType = this.isChangeType;
    this.assignValue();
    if (this.id) {
      this.chemicalParameterPostModel.chemicalId = this.id;
      this.chemicalParameterPostModel.oldCommonName = this.oldCommanName;
      if (this.isCompanyAdmin || this.isUsers) {
        let companyId = localStorage.getItem('CompanyId');
        //this.chemicalParameterPostModel.isPrivate = true;
        this.chemicalParameterPostModel.companyId = companyId;
        this.chemicalParameterPostModel.isPrivate = this.chemicalData.isPrivate;
      }
      this.chemicalService.update(this.chemicalParameterPostModel)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              if (this.isCompanyAdmin || this.isUsers) {
                if (this.chemicalData.isPrivate) {
                  var isNewInterface = localStorage.getItem("IsNewInterface");
                  console.log("isNewInterface >>> ", isNewInterface);
                  if (isNewInterface == "TRUE") {
                    this.router.navigate(['/analysis-data-report-alert-details', this.id]).then(() => {
                      this.toastr.success(data['message']);
                      this.SpinnerService.hide();
                      localStorage.removeItem("IsNewInterface");
                    });
                  }
                  else {
                    this.router.navigate(['/view-chemical-details', this.id]).then(() => {
                      this.toastr.success(data['message']);
                      this.SpinnerService.hide();
                    });
                  }
                }
                else {
                  this.chemicalService.createparameterchangerequest(this.changedParameterValuesList).pipe(first())
                    .subscribe(value => {
                      if (this.isCompanyAdmin || this.isUsers) {
                        var isNewInterface = localStorage.getItem("IsNewInterface");
                        console.log("isNewInterface >>> ", isNewInterface);
                        if (isNewInterface == "TRUE") {
                          this.router.navigate(['/analysis-data-report-alert-details', this.id]).then(() => {
                            this.toastr.success(data['message']);
                            this.SpinnerService.hide();
                            localStorage.removeItem("IsNewInterface");
                          });
                        } else {
                          this.router.navigate(['/view-chemical-details', this.id]).then(() => {
                            this.toastr.success(data['message']);
                            this.SpinnerService.hide();
                          });
                        }
                      }
                    },
                      error => {
                        // this.toastr.error(error.message);
                      });
                }
              }
              else {
                this.router.navigate(['/manage-chemicals', this.k == undefined ? "" : this.k]).then(() => {
                  this.toastr.success(data['message']);
                  this.SpinnerService.hide();
                });
              }
            } else {
              this.toastr.error(data['message']);
              this.SpinnerService.hide();
            }
          },
          error => {
            //this.toastr.error(error.message);
            this.SpinnerService.hide();
          });
    }
    else {
      this.chemicalParameterPostModel.oldCommonName = null;
      if (this.isCompanyAdmin || this.isUsers) {
        let companyId = localStorage.getItem('CompanyId');
        this.chemicalParameterPostModel.isPrivate = true;
        this.chemicalParameterPostModel.companyId = companyId;
      }
      //console.log(this.chemicalParameterPostModel);
      this.chemicalService.create(this.chemicalParameterPostModel)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              if (this.isCompanyAdmin || this.isUsers) {
                var isNewInterface = localStorage.getItem("IsNewInterface");
                console.log("isNewInterface >>> ", isNewInterface);
                if (isNewInterface == "TRUE") {
                  this.router.navigate(['/analysis-dashboard']);
                  localStorage.removeItem("IsNewInterface");
                } else {
                  this.router.navigate(['/analysis-dashboard-classic']);//.then(() => {
                }
                this.toastr.success(data['message']);
                this.SpinnerService.hide();
                //});
              }
              else {
                this.router.navigate(['/manage-chemicals', this.k == undefined ? "" : this.k])//.then(() => {
                this.toastr.success(data['message']);
                this.SpinnerService.hide();
                //});
              }
            } else {
              this.toastr.error(data['message']);
              this.SpinnerService.hide();
            }
          },
          error => {
            //this.toastr.error(error.message);
            this.SpinnerService.hide();
          });
    }
  }

  assignValue() {
    let categoryData = [];
    let parameterData = [];
    this.changedParameterValuesList = [];
    this.chemicalParameterPostModel.caseNo = this.chemicalForm.value["casno"];
    this.chemicalParameterPostModel.chemicalName = this.chemicalForm.value["chemicalName"];
    this.chemicalParameterPostModel.molecularWeight = this.chemicalForm.value["molecularWeight"];
    this.chemicalParameterPostModel.source = this.chemicalForm.value["source"];
    this.chemicalParameterPostModel.commonName = this.chemicalForm.value["commonName"];
    this.chemicalParameterPostModel.comments = this.chemicalForm.value["comments"];
    let chemicalCategoryComma = '';

    for (let i = 0; i < this.selectedItems.length; i++) {
      let chemicalCategory = new chemicalCategoryPostVM();
      chemicalCategory.id = this.selectedItems[i]["id"];
      categoryData.push(chemicalCategory);
      chemicalCategoryComma = chemicalCategoryComma + this.selectedItems[i]["id"] + ","
    }
    chemicalCategoryComma = chemicalCategoryComma.slice(0, -1);

    this.chemicalParameterPostModel.chemicalCategoryPost = categoryData;
    this.chemicalParameterPostModel.chemicalCategoryList = chemicalCategoryComma;
    if (this.parameterList.length > 0) {
      for (let i = 0; i < this.parameterList.length; i++) {
        if (($("#value_" + this.parameterList[i]["parameterCode"]).val() != $("#hvalue_" + this.parameterList[i]["parameterCode"]).val()) ||
          ($("#source_" + this.parameterList[i]["parameterCode"]).val() != $("#hsource_" + this.parameterList[i]["parameterCode"]).val()) ||
          ($("#comment_" + this.parameterList[i]["parameterCode"]).val() != $("#hcomment_" + this.parameterList[i]["parameterCode"]).val()) ||
          ($("#species_" + this.parameterList[i]["parameterCode"]).val() != $("#hspecies_" + this.parameterList[i]["parameterCode"]).val())) {
          let parameterValue = new chemicalParameterValuePostVM();
          let changedParamValue = new ChangedParameterVM();
          //parameterValue.cpid = this.parameterList[i]["chemicalParameterId"] == null ? '' : this.parameterList[i]["chemicalParameterId"];
          parameterValue.pid = this.parameterList[i]["parameterId"];
          changedParamValue.chemicalId = this.id;
          changedParamValue.parameterId = this.parameterList[i]["parameterId"];
          changedParamValue.SelectedChemicalName = this.chemicalParameterPostModel.chemicalName;
          changedParamValue.Casnumber = this.chemicalParameterPostModel.caseNo;
          changedParamValue.companyId = this.companyId;
          //changedParamValue.domainId = null;
          if ($("#value_" + this.parameterList[i]["parameterCode"]).val() != '') {
            parameterValue.val = $("#value_" + this.parameterList[i]["parameterCode"]).val();
            changedParamValue.CurrentValue = $("#hvalue_" + this.parameterList[i]["parameterCode"]).val();
            changedParamValue.NewValue = $("#value_" + this.parameterList[i]["parameterCode"]).val();
          }
          if ($("#comment_" + this.parameterList[i]["parameterCode"]).val() != '') {
            parameterValue.c = $("#comment_" + this.parameterList[i]["parameterCode"]).val();
            changedParamValue.comment = $("#comment_" + this.parameterList[i]["parameterCode"]).val();
          }
          if ($("#species_" + this.parameterList[i]["parameterCode"]).val() != '') {
            parameterValue.m = $("#species_" + this.parameterList[i]["parameterCode"]).val();
          }
          if ($("#source_" + this.parameterList[i]["parameterCode"]).val() != '') {
            parameterValue.s = $("#source_" + this.parameterList[i]["parameterCode"]).val();
            changedParamValue.reference = $("#source_" + this.parameterList[i]["parameterCode"]).val();
          }

          parameterData.push(parameterValue);
          this.changedParameterValuesList.push(changedParamValue);
        }
      }

      /* multiple domain parameter */
      if (this.multiDomainParameterList && this.multiDomainParameterList.length > 0) {
        let filterCompany = this.globalParameterList.filter(x => x['sourceCompany'] != null);

        this.multiDomainParameterList.forEach((value) => {
          if (value.parameterId && value.domainParameterList && value.domainParameterList.length > 0) {
            value.domainParameterList.forEach((item) => {
              if (!this.id) {
                let parameterValueModel = new chemicalParameterValuePostVM();
                parameterValueModel.pid = value.parameterId;
                parameterValueModel.did = item.id;
                parameterValueModel.val = item.parameterValue ? item.parameterValue.toString() : null;
                parameterValueModel.s = item.source;
                parameterValueModel.c = item.comments;
                parameterData.push(parameterValueModel);
              }
              else {
                if ((item.parameterValue != item.oldParameterValue || item.source != item.oldSource || item.comments != item.oldComments)
                  || (!this.chemicalData.isPrivate && (filterCompany == null || filterCompany.length == 0))) {
                  let parameterValueModel = new chemicalParameterValuePostVM();
                  parameterValueModel.pid = value.parameterId;
                  parameterValueModel.did = item.id;
                  parameterValueModel.val = item.parameterValue ? item.parameterValue.toString() : null;
                  parameterValueModel.s = item.source;
                  parameterValueModel.c = item.comments;
                  parameterData.push(parameterValueModel);

                  if (item.parameterValue != item.oldParameterValue || item.source != item.oldSource || item.comments != item.oldComments) {
                    /* create change parameter value object and push object into array */
                    let changedParamValue = new ChangedParameterVM();
                    changedParamValue.chemicalId = this.id;
                    changedParamValue.parameterId = value.parameterId;
                    changedParamValue.SelectedChemicalName = this.chemicalParameterPostModel.chemicalName;
                    changedParamValue.Casnumber = this.chemicalParameterPostModel.caseNo;
                    changedParamValue.companyId = this.companyId;
                    changedParamValue.CurrentValue = item.oldParameterValue ? item.oldParameterValue.toString() : null;
                    changedParamValue.NewValue = item.parameterValue ? item.parameterValue.toString() : null;
                    changedParamValue.reference = item.source;
                    changedParamValue.comment = item.comments;
                    changedParamValue.domainId = item.id;
                    this.changedParameterValuesList.push(changedParamValue);
                  }
                }
              }
            });
          }
        });
      }

    }
    this.chemicalParameterPostModel.parameterValue = parameterData;
  }

  valueParameter() {
    this.isValueChange = true;
  }

  otherParameter() {
    this.isOtherChange = true;
  }

  onFileClick(evt: any) {
    evt.currentTarget.value = null;
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      if (wb != null) {
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
        let newParamList = [];
        //newParamList = Object.assign([], this.parameterList);
        newParamList = JSON.parse(JSON.stringify(this.parameterList));
        if (this.data != null && this.data[0].length == 6 && this.data[0][0] == "ParameterId") {
          for (var i = 1; i < this.data.length; i++) {
            if (this.data[i][0] !== undefined) {
              var param = _.find(newParamList, { 'parameterId': this.data[i][0].toLowerCase() })
              if (param) {
                if (param.isMultiDomainParameter) {
                  this.multiDomainParameterList = [];
                  let multiDomainPara = newParamList.filter(x => x.isMultiDomainParameter && x.domainId == null);
                  if (multiDomainPara && multiDomainPara.length > 0) {
                    multiDomainPara.forEach((value) => {
                      let multiDomainParameterModel: multiDomainParameterVM = {} as multiDomainParameterVM;
                      multiDomainParameterModel.parameterId = value['parameterId'];
                      multiDomainParameterModel.domainParameterList = [];

                      this.domainList.forEach((item) => {
                        let domainParameterValueModel: domainParameterValueVM = {} as domainParameterValueVM;
                        domainParameterValueModel.id = item.id;
                        domainParameterValueModel.domainName = item.domainName;
                        domainParameterValueModel.isActive = item.isActive;

                        let parameterDomainDefault = this.parameterDomainList.find(x => x.parameterId == value['parameterId'] && x.domainId == item.id && x.isDefault);
                        if (parameterDomainDefault) {
                          domainParameterValueModel.parameterId = param.parameterId;
                          domainParameterValueModel.parameterValue = this.data[i][2];
                          domainParameterValueModel.source = this.data[i][4];
                          domainParameterValueModel.comments = this.data[i][3];
                        }
                        multiDomainParameterModel.domainParameterList.push(domainParameterValueModel);
                      });
                      this.multiDomainParameterList.push(multiDomainParameterModel);
                    });
                  }
                }
                else {
                  param.parameterValue = this.data[i][2];
                  param.comments = this.data[i][3];
                  param.source = this.data[i][4];
                  param.modelSpecies = this.data[i][5];
                }
              }
            }
          }
          for (let i = 0; i < newParamList.length; i++) {
            $("#value_" + newParamList[i]["parameterCode"]).val(newParamList[i]["parameterValue"]);
            $("#source_" + newParamList[i]["parameterCode"]).val(newParamList[i]["source"]);
            $("#comment_" + newParamList[i]["parameterCode"]).val(newParamList[i]["comments"]);
            $("#species_" + newParamList[i]["parameterCode"]).val(newParamList[i]["modelSpecies"]);
          }
          this.isValueChange = true;
        }
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  backButton() {
    if (this.isSuperAdmin || this.isElemental) {
      this.router.navigate(['/manage-chemicals', this.k == undefined ? "" : this.k]);
    }
    else {
      // if (this.id) {
      //   this.location.back();
      //   // this.router.navigate(['/view-chemical-details', this.id]);
      // }
      // else {
      //   this.router.navigate(['/analysis-dashboard']);
      // }
      this.location.back();
    }
  }

  popupMultipleDomainParameter(content, data) {
    this.domainParameterValueList = [];
    this.titleParameterName = data.parameterName;
    this.parameterControlType = data.controlType;

    var model = this.multiDomainParameterList.find(x => x.parameterId == data.parameterId);
    if (model) {
      this.domainParameterValueList = model.domainParameterList;
    }

    this.modalService.open(content, {
      ariaLabelledBy: 'modelPopupLabelMultipleDomainParameter',
      windowClass: 'modalMultipleDomainParameter'
    }).result.then((result) => {
      if (result == "Yes") {

      }
      else if (result == "No") {

      }
    }, (reason) => {
    });

  }

}
