import { weightCategory } from './weightCategorySlider';
import { ChemicalVM } from './chemicalVM';
import { socialMonitoringChemicalVM } from './socialMonitoringChemicalVM';

export class reportVM {
  id: string;
  companyId: string;
  reportName: string;
  threeWayPrioritisation: boolean;
  isFavourite: boolean;
  createdDate: string;
  modifiedDate: string;
  createdByStr: string;
  isPublic: boolean;
  chemicalsList: ChemicalVM[];
  weightList: weightCategory[];
  columnList: Column[];
  reportHistoryList: ReportHistory[];
  domainId: string;
  socialMonitoringChemicalList: socialMonitoringChemicalVM[];
}


export class Column {
  id: string;
  reportId: string;
  parameterId: string;
  columnOrder: Number;
}

export class ReportHistory {
  id: string;
  reportId: string;
  companyId: string;
  reportName: string;
  isPublic: boolean;
  createdByStr: string;
  threeWayPrioritisation: boolean;
  isFavourite: boolean;
  createdDate: string;
  modifiedDate: string;
}

export class ReportDownloadPdfVM {
  reportId: string;
  base64Str: string;
}