import { Pipe, PipeTransform } from '@angular/core';

import { weightCategoryVM, companyWeightVM } from '../models/weightCategoryVM';

@Pipe({
  name: 'CompanyFilter'
})

export class CompanyFilter implements PipeTransform {
  public model: companyWeightVM
  public companyWeightList: companyWeightVM[] = [];

  transform(items: any[], filter: any[]): any {
   
    if (!items || !filter) {
      return items;
    }
    this.model = new companyWeightVM();
    this.companyWeightList = [];
    if (items.length > 0) {
      
      var record = items.filter(item => item["weightId"] == filter["id"]);
      if (record != null && record.length > 0) {
        this.model.weightage = record[0]["weightage"]
        this.companyWeightList.push(this.model);

      }
      else {
        this.model.weightage = filter["weightage"]
        this.companyWeightList.push(this.model);
      }
    }
    else {
      this.model.weightage = filter["weightage"]
      this.companyWeightList.push(this.model);
    }
    
    return this.companyWeightList;
  }


}

