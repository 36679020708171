import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertService, AuthService } from '../services';
import { UserService } from '../users/user.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisService } from '../analysis/analysis.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { PagerService } from '../helpers/pager.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleChartsModule } from 'angular-google-charts';
import { AuthenticationService } from '../services/authentication.service';
import { UserRoles } from '../../app/models/enum';
import { userloginVM } from '../models/userloginVM';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatPaginator, MatSort, MatStepperModule, MatTableDataSource } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrimValidators } from '../helpers/custom-validators';
import { ManageChemicalService } from '../managechemicals/managechemicalsservice';
import { weightCategory, weightageView } from '../models/weightCategorySlider';
import { SdmSliderService } from '../sdm-slider/sdm-slider.service';
import { CompanyChemicalRequestService } from './company-chemical-request.service'
import { AnalysisDashboardService } from '../analysis/analysis-dashboard/analysis-dashboard.service';
import { reportVM, Column } from '../models/reportVM';
import { Sort, SortDirection } from '@angular/material';
import { CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-company-chemical-request',
  templateUrl: './company-chemical-request.component.html',
  styleUrls: ['./company-chemical-request.component.css']
})
export class CompanyChemicalRequestComponent implements OnInit {

  public chemicalList: any;
  companyId: any;
  public allChemicalDataList: any;
  public filteredChemicalDataList: any;
  public chemicalRequestList: any;
  public companyChemicalRequestList: any;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public isTrial: boolean = false;
  currentUser: userloginVM;
  public tourcheck: boolean = false;
  public userRole = UserRoles;

  constructor(
    private analysisService: AnalysisService,
    private chemicalRequestService: CompanyChemicalRequestService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    private auth: AuthService,
    private spinnerService: NgxSpinnerService,
    private chemicalService: ManageChemicalService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private weightCategoryService: SdmSliderService,
    private reportService: AnalysisDashboardService
  ) {
    if (this.auth.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }
  }

  ngOnInit() {
    this.companyId = localStorage.getItem("CompanyId");

    this.isTrial = environment.isTrial;

    if (this.isCompanyAdmin || this.isUsers) {
      this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
    }
    else {
      this.GetAllRequestList();
    }
  }

  GetAllRequestList() {
    this.chemicalRequestService.getAll().subscribe(data => {
      this.companyChemicalRequestList = data.filter(x => x.status != 'Rejected');
    },
      error => {
        //alert("We faced some technical issues");
      });
  }

  GetChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {
    this.analysisService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.GetCompanyChemicalRequest();
      this.allChemicalDataList = data;
      this.filteredChemicalDataList = this.allChemicalDataList.filter(x => x.companyId == this.companyId);
    },
      error => {
        //alert("We faced some technical issues");
      });
  }

  GetCompanyChemicalRequest() {
    this.companyChemicalRequestList = [];
    this.chemicalRequestService.getAllByCompany(this.companyId).subscribe(data => {
      this.chemicalRequestList = data;
      for (var i = 0; i < this.filteredChemicalDataList.length; i++) {
        if (this.chemicalRequestList.length > 0) {
          //let matchChemical = this.chemicalRequestList.filter(x => x.chemicalId == this.filteredChemicalDataList[i].id);
          let matchChemical = this.chemicalRequestList.filter(x => x.chemicalId == this.filteredChemicalDataList[i].id);
          if (matchChemical.length > 0) {
            let newMatch = {
              id: matchChemical[0].id,
              chemicalId: matchChemical[0].chemicalId,
              chemicalName: this.filteredChemicalDataList[i].chemicalName,
              casNo: this.filteredChemicalDataList[i].casnumber,
              status: matchChemical[0].status,
              createdDate: matchChemical[0].createdDate,
              companyId: matchChemical[0].companyId,
              companyName: matchChemical[0].companyName,
              userName: matchChemical[0].userName
            };
            this.companyChemicalRequestList.push(newMatch);
          }
          else {
            let newMatch = {
              chemicalId: this.filteredChemicalDataList[i].id,
              chemicalName: this.filteredChemicalDataList[i].chemicalName,
              casNo: this.filteredChemicalDataList[i].casnumber,
              status: 'Company Pending',
              createdDate: this.filteredChemicalDataList[i].createdDate,
              companyId: this.filteredChemicalDataList[i].companyId,
              companyName: this.filteredChemicalDataList[i].companyName,
              userName: this.filteredChemicalDataList[i].userName
            };
            this.companyChemicalRequestList.push(newMatch);
          }
        }
        else {
          let newMatch = {
            chemicalId: this.filteredChemicalDataList[i].id,
            chemicalName: this.filteredChemicalDataList[i].chemicalName,
            casNo: this.filteredChemicalDataList[i].casnumber,
            status: 'Company Pending',
            createdDate: this.filteredChemicalDataList[i].createdDate,
            companyId: this.filteredChemicalDataList[i].companyId,
            companyName: this.filteredChemicalDataList[i].companyName,
            userName: this.filteredChemicalDataList[i].userName
          };
          this.companyChemicalRequestList.push(newMatch);
        }

      }

    },
      error => {
        //alert("We faced some technical issues");
      });
  }


  public openActiveConfirmationDialog(id: any, chemid: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Approve ?')
      .then((confirmed) =>
        confirmed === true ? this.activeById(id, chemid) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  activeById(id: any, chemid: any) {
    this.spinnerService.show();
    this.chemicalRequestService.approve(id, chemid)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
        } else {
          this.toastr.error(data['message']);
        }
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.error.message);
          this.spinnerService.hide();
        });
  }

  public openRemoveConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Reject ?')
      .then((confirmed) =>
        confirmed === true ? this.rejectById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  rejectById(id: any) {
    this.spinnerService.show();
    this.chemicalRequestService.reject(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
        } else {
          this.toastr.error(data['message']);
        }
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.error.message);
          this.spinnerService.hide();
        });
  }

  public openDeleteConfirmationDialog(id: any, chemicalId: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteById(id, chemicalId) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  deleteById(id: any, chemicalId: any) {
    this.spinnerService.show();
    if (id == undefined) {
      id = "";
      this.chemicalRequestService.deleteChemical(chemicalId)
        .pipe(first())
        .subscribe(data => {
          if (data['status'] === true) {
            this.toastr.success(data['message']);
            this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
          } else {
            this.toastr.error(data['message']);
          }
          this.spinnerService.hide();
        },
          error => {
            this.toastr.error(error.error.message);
            this.spinnerService.hide();
          });
    }
    else {
      this.chemicalRequestService.delete(id)
        .pipe(first())
        .subscribe(data => {
          if (data['status'] === true) {
            this.toastr.success(data['message']);
            this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
          } else {
            this.toastr.error(data['message']);
          }
          this.spinnerService.hide();
        },
          error => {
            this.toastr.error(error.error.message);
            this.spinnerService.hide();
          });
    }
  }

  CreateChemicalRequest(Request: any) {
    this.spinnerService.show();
    this.chemicalRequestService.create(Request)
      .pipe(first())
      .subscribe(data => {
        this.spinnerService.hide();
        this.toastr.success(data['message']);
        this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
      },
        error => {
          this.toastr.error(error.error.message);
          this.spinnerService.hide();
        });
  }

  backButton() {
    if (this.isSuperAdmin || this.isElemental) {
      this.router.navigate(['/dashboard']);
    }
    else {
      this.router.navigate(['/analysis-dashboard']);
    }
  }

  GridHideShow(type) {
    if (type == 1) {
      this.router.navigate(['/data-request'])
    }
    if (type == 2) {
      this.router.navigate(['/common-name-request'])
    }
  }

  redirectToAnalysisDashboard() {
    this.router.navigate(['/analysis-dashboard']);
  }


}
