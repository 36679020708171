import { Component, OnInit } from '@angular/core';
import { weightCategoryVM, companyWeightVM, postWeightVM } from '../../models/weightCategoryVM';
import { AlertService } from '../../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { WeightCategoryService } from '../../sdm-management/sdm-management.service';
import { parse } from 'url';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { UserRoles } from '../../../app/models/enum';

@Component({
  selector: 'app-company-sdm-management',
  templateUrl: './company-sdm-management.component.html',
  styleUrls: ['./company-sdm-management.component.css']
})
export class CompanySdmManagementComponent implements OnInit {

  public weightCategoryList: weightCategoryVM[];
  public companyWeightList: companyWeightVM[] = [];
  public weightList: postWeightVM[] = [];
  public tempId = "";
  loading = false;
  IsCompanyDataFound = false;
  public currentUserCode: string;
  public isCompanyAdmin: boolean = false;
  public userRole = UserRoles;
  constructor(
    private weightCategoryService: WeightCategoryService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }


  ngOnInit() {
    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
    }

    this.getCompanyWeightList();
    this.getWeightCategoryList();
  }

  


  private getCompanyWeightList() {

    this.weightCategoryService.getCompanySDMList().pipe(first())
      .subscribe(p => {

        this.companyWeightList = p;
        if (this.companyWeightList.length > 0 && p["message"] == null) {
          this.companyWeightList = p;
          this.IsCompanyDataFound = true;
        }

      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getWeightCategoryList() {
    this.weightCategoryService.getAll().pipe(first())
      .subscribe(p => {
        this.weightCategoryList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }


  SaveRecord(data: [], id) {

    var total = 0;
    var markers = [];
    this.weightList = [];
    var cId = localStorage.getItem('CompanyId');
    for (var i = 0; i < data.length; i++) {
      markers[i] = $("#" + data[i]["id"]).val();
      total += parseFloat($("#" + data[i]["id"]).val());
      let dataList = new postWeightVM();
      dataList.id = data[i]["id"];
      dataList.weightage = parseFloat(markers[i]);
      dataList.companyId = cId;
      this.weightList.push(dataList);
    }
    if (total == 100) {

      this.weightCategoryService.saveCompanyWeight(this.weightList)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.showHideOption(id);
              this.toastr.success(data['message']);
              this.getWeightCategoryList();
              this.getCompanyWeightList();
            } else {
              this.loading = false;
            }
          },
          error => {
            //  this.toastr.error(error.message);
            this.loading = false;
            // this.dialogRef.close(false);
          });
    }
    else {
      this.resetValue(data);
      this.toastr.error("Your Weightage total not equal to 100,Please check.");
    }

  }

  CancelRecord(id, data: []) {
    this.resetValue(data);
    this.showHideOption(id);
  }

  ShowEdit(id) {

    $(".valueClm_" + id).hide();
    $(".insertClm_" + id).show();
    $(".btnEdit_" + id).hide();
    $(".btnSave_" + id).show();

  }

  showHideOption(id) {
    $(".valueClm_" + id).show();
    $(".insertClm_" + id).hide();
    $(".btnEdit_" + id).show();
    $(".btnSave_" + id).hide();
  }

  resetValue(data: []) {
    for (var i = 0; i < data.length; i++) {
      $("#" + data[i]["id"]).val(data[i]["weightage"]);
    }
  }

}
