import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { debug } from 'util';
import { AuthService } from '../services/auth.service';
import { TrimValidators } from '../helpers/custom-validators'
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { UserRoles } from '../../app/models/enum';
import { userloginVM } from '../models/userloginVM';
import { environment } from '../../environments/environment';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  ForgotPasswordForm: FormGroup;
  authCodeForm: FormGroup;
  loading = false;
  submitted = false;
  forgotsubmitted = false;
  authCodeSubmitted = false;
  returnUrl: string;
  sub: any;
  error: '';
  isShow: boolean = false;
  isMainShow: boolean = true;
  isAuthCodeShow: boolean = false;
  isResend = false;
  isAuthCodeMessageShow = false;
  isTimerShow = false;
  timeInterval: number = 1;
  displayTime: string = "";
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  currentUser: userloginVM;
  public userRole = UserRoles;
  public userData: any;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private auth: AuthService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService) {
    //// redirect to home if already logged in
    if (this.auth.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }

      if (!environment.isTrial) {
        if (this.isSuperAdmin || this.isElemental) {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/analysis-dashboard']);
        }
      }
    }
  }

  ngOnInit() {
    if (environment.isTrial) {
      this.loginForm = this.formBuilder.group({
        email: ['', [TrimValidators.required, Validators.pattern(this.emailPattern)]],
        password: ['', TrimValidators.required]
      });
      this.loading = true;
      this.authenticationService.login(environment.userName, environment.password)
        .pipe(first())
        .subscribe(
          data => {
            if (data['token']) {
              if (data.message) {
                this.toastr.success(data.message);
              }
              // if (data.code == "Elemental" || data.code == "SuperAdmin") {
              //   if (this.returnUrl == "/analysis-dashboard") {
              //     this.returnUrl = "/dashboard";
              //   }
              //   this.router.navigate([this.returnUrl]);
              // } else {
              //   this.router.navigate(['/analysis-dashboard']);
              // }

              this.router.navigate(['/analysis-dashboard']);

              this.auth.sendToken(environment.userName);
            }
            else {
              this.toastr.error(data.message);
              this.loading = false;
            }
          },
          error => {
            this.error = error;
            this.loading = false;
          });
    }
    else {
      this.SpinnerService.show();

      this.loginForm = this.formBuilder.group({
        email: ['', [TrimValidators.required, Validators.pattern(this.emailPattern)]],
        password: ['', TrimValidators.required]
      });

      this.ForgotPasswordForm = this.formBuilder.group({
        forgotemail: ['', [TrimValidators.required, Validators.pattern(this.emailPattern)]]
      });

      this.authCodeForm = this.formBuilder.group({
        authcode: ['', [TrimValidators.required]]
      });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';

    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get fp() {
    return this.ForgotPasswordForm.controls;
  }

  get ac() {
    return this.authCodeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.userData = data;
          if (data['token']) {
            // this.toastr.success(data.message);

            if (data.isValidAuthLogin) {
              this.SpinnerService.hide();
              this.isMainShow = false;
              this.isAuthCodeShow = true;
              this.loading = false;
              this.isAuthCodeMessageShow = true;
              this.isResend = true;
              this.isTimerShow = true;
              this.startTimer(this.timeInterval);
              return;
            }

            if (data.isFirstTimeLogin) {
              this.router.navigate(['/update-password']);
            }
            else {
              if (data.code == "Elemental" || data.code == "SuperAdmin") {
                if (this.returnUrl == "/analysis-dashboard") {
                  this.returnUrl = "/dashboard";
                }
                this.router.navigate([this.returnUrl]);
              } else {
                this.router.navigate(['/analysis-dashboard']);
              }
            }
            this.auth.sendToken(this.f.email.value);
            this.SpinnerService.hide();
          }
          else {
            this.toastr.error(data.message);
            this.loading = false;
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  onForgotPasswordSubmit() {
    this.forgotsubmitted = true;
    // stop here if form is invalid
    if (this.ForgotPasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.forgotPassword(this.fp.forgotemail.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.issuccess) {
            this.toastr.success(data.message);
            this.ForgotPassword(2);
            //this.auth.sendToken(this.fp.forgotemail.value);
          }
          else {
            this.toastr.error(data.message);
            this.loading = false;
          }
          //this.router.navigate(['/login']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  ForgotPassword(type) {
    if (type == 1) {
      this.SpinnerService.show();
      this.isShow = true;
      this.isMainShow = false;
      this.ForgotPasswordForm.reset();
      this.SpinnerService.hide();
    }
    else {
      this.SpinnerService.show();
      this.isShow = false;
      this.isMainShow = true;
      this.loginForm.reset();
      this.SpinnerService.hide();
    }
    this.loading = false;
  }

  onAuthCodeSubmit() {
    this.authCodeSubmitted = true;
    // stop here if form is invalid
    if (this.authCodeForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.validateTwoFactorAuthCode(this.f.email.value, this.ac.authcode.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.issuccess) {
            this.toastr.success(data.message);
            if (this.userData.code == "Elemental" || this.userData.code == "SuperAdmin") {
              if (this.returnUrl == "/analysis-dashboard") {
                this.returnUrl = "/dashboard";
              }
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(['/analysis-dashboard']);
            }
            this.auth.sendToken(this.f.email.value);
            this.SpinnerService.hide();
          }
          else {
            this.toastr.error(data.message);
            this.loading = false;
            this.SpinnerService.hide();
          }
        },
        error => {
          this.error = error;
          this.loading = false;
          this.SpinnerService.hide();
        });
  }

  onResendSubmit() {
    this.loading = true;
    this.isResend = true;
    this.isTimerShow = true;
    this.authenticationService.resendTwoFactorAuthCode(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.issuccess) {
            this.toastr.success(data.message);
            this.loading = false;
            this.isAuthCodeMessageShow = true;
            this.SpinnerService.hide();
            this.startTimer(this.timeInterval);
          }
          else {
            this.toastr.error(data.message);
            this.loading = false;
            this.SpinnerService.hide();
            this.startTimer(this.timeInterval);
          }
        },
        error => {
          this.error = error;
          this.loading = false;
          this.SpinnerService.hide();
          this.startTimer(this.timeInterval);
        });
  }

  startTimer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else {
        textSec = statSec;
      }

      this.displayTime = `${textSec}s`;

      if (seconds == 0) {
        this.isResend = false;
        this.isTimerShow = false;
        clearInterval(timer);
      }
    }, 1000);
  }

}
