import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { chemicalRequestVM } from '../models/companyChemicalRequestVM';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CompanyChemicalRequestService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}ChemicalRequest/getAll`);
  }

  getAllByCompany(companyId: string) {
    return this.http.get<any[]>(`${environment.apiUrl}ChemicalRequest/chemicalRequetsListByCompany?companyId=` + companyId);
  }

  create(data: chemicalRequestVM) {
    return this.http.post<any>(`${environment.apiUrl}ChemicalRequest/createChemicalRequest`,  data);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}ChemicalRequest/deletebyid/?id=` + id);
  }

  deleteChemical(chemicalId: string) {
    return this.http.get(`${environment.apiUrl}ChemicalRequest/deletebyChemicalid/?chemicalId=`  + chemicalId);
  }

  approve(id: string, chemid: string) {
    return this.http.get(`${environment.apiUrl}ChemicalRequest/approve/?id=` + id + '&chemId=' + chemid);
  }

  reject(id: string) {
    return this.http.get(`${environment.apiUrl}ChemicalRequest/reject/` + id);
  }

  updateTourChoice(isTourSelected: boolean) {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var userId = currentUser['id'];
    return this.http.get(`${environment.apiUrl}UserLogin/updatetourbyid/?id=` + userId + '&isTourCancelled=' + isTourSelected);
  }
}
