import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalysisService } from '../analysis/analysis.service';
import { CitationPostService } from './citation-post.service';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CompanyAlertDataService } from '../company-alert/company-alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PostCompanyAlertData } from '../models/postCompanyAlertData';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { TrimValidators } from '../helpers/custom-validators';
import { socialMonitoringChemicalVM } from '../models/socialMonitoringChemicalVM';
import { MonitoringManagementService } from '../monitoring-management/monitoring-management.service';
import { socialMonitoringBlacklistChemicalsVM } from '../models/socialMonitoringBlacklistChemicalsVM';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-citation-post',
  templateUrl: './citation-post.component.html',
  styleUrls: ['./citation-post.component.css']
})
export class CitationPostComponent implements OnInit {
  companyId: any;
  public allChemicalDataList: any;
  multiDropdownList = [];
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  isShow: boolean = false;
  public chemicalList: any;
  selectedItems = [];
  dropdownSettings = {};
  chemicalId: any;
  public commonNameList: any[] = [];
  public socialMonitoringBlacklistChemicalList: socialMonitoringBlacklistChemicalsVM[];
  AllCommonName: string = null;
  SearchKeyword: string = null;
  dropdownList = [];
  public MultiEmergingList: any;
  public EmergingList: any;
  public socialURL: string = null;
  public EmergingChemicalList: any;
  public sentimentDetailData: any;
  public sentimentDetail: any;
  public currentStage: any;
  public selectedChemicalId: string = null;
  public selectedCommonNames: string = null;
  public fromDate: any = null;
  public toDate: any = null;
  divTable: boolean = false;
  div_chart_chemical: boolean = false;
  div_chart_multi: boolean = false;
  div_chart_single: boolean = false;
  currentPeriodValue: string = "week";
  public plottedChemicalList = [];
  defaultHide = false;
  selectedChemicalName = [{ chemicalName: '', citationTypeId: '', citationSentimentId: '', recordDate: '' }];

  public sentimentTypeList: any;
  public deviationTypeList: any;
  public companyAlertList: any;
  companyAlertForm: FormGroup;
  loading = false;
  submitted = false;
  id: any;
  public postCompanyAlertDataModel: PostCompanyAlertData = {} as PostCompanyAlertData;
  modelTitle: string;
  public isHeaderChecked: boolean = false;
  public reportData: any;
  public NoDataFound: any;

  @Input() model: Subject<any>;
  @Output() hideShow = new EventEmitter<any>();
  @Output() socialMonitoringList = new EventEmitter<any>();

  getGoogle() {
    return google;
  }

  constructor(private analysisService: AnalysisService,
    private citationPostService: CitationPostService,
    private companyAlertDataService: CompanyAlertDataService,
    private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private monitoringManagementService: MonitoringManagementService) {

  }


  ngOnInit() {
    this.companyId = localStorage.getItem("CompanyId");

    this.companyAlertForm = this.formBuilder.group({
      id: [''],
      companyId: [''],
      chemicalId: [''],
      sentimentType: ['', TrimValidators.required],
      deviationType: ['', TrimValidators.required],
    });

    this.getSentimentTypeList();
    this.getDeviationTypeList();
    this.getAllAlertByCompanyUser();
    this.getBlacklistChemical();

    this.model.subscribe(data => {
      this.reportData = data;
      this.selectedItems = [];
      this.allChemicalDataList = [];
      if (data != null && data.chemicalsList != null && data.chemicalsList.length > 0) {
        var socialMonitoringChemicalList = data.socialMonitoringChemicalList || [];
        let itemCheckedExists = socialMonitoringChemicalList.find(x => x.isChecked == true);
        this.isHeaderChecked = this.isHeaderChecked ? true : itemCheckedExists ? true : false;

        this.onChangeSocialMonitor();
        this.onSubmit();
      }
      else {
        this.isHeaderChecked = false;
      }
    });
  }

  get f() { return this.companyAlertForm.controls; }

  onChangeSocialMonitor(e?) {
    this.allChemicalDataList = [];
    var socialMonitoringChemicalList = this.reportData.socialMonitoringChemicalList || [];

    for (let itemIndex = 0; itemIndex < this.reportData.chemicalsList.length; itemIndex++) {
      const element = this.reportData.chemicalsList[itemIndex];
      let item = socialMonitoringChemicalList.find(x => x.chemicalId == element.id);
      var isProperty = element.hasOwnProperty("isChecked");

      let model: socialMonitoringChemicalVM = {} as socialMonitoringChemicalVM;
      model.id = element.id;
      model.chemicalId = element.id;
      model.chemicalName = element.chemicalName || element.itemName;
      model.isChecked = !this.isHeaderChecked ? false : item != null ? item.isChecked : false;
      model.isDisabled = !this.isHeaderChecked ? true : false;

      if (this.socialMonitoringBlacklistChemicalList.findIndex(x => x.chemicalId == element.id) >= 0) {
        model.isChecked = false;
        model.isDisabled = true;
      }

      this.allChemicalDataList.push(model);
    }

    this.reportData.socialMonitoringChemicalList = this.allChemicalDataList;

    if (e) {
      this.onSubmit();
    }
  }

  onSubmit() {
    let selectedChemicalId = "";
    //let fromDate = $('#fromDate').val();
    //let toDate = $('#toDate').val();
    if (this.allChemicalDataList.length >= 1) {
      this.onGetFromAndToDates();
      for (let i = 0; i < this.allChemicalDataList.length; i++) {
        if (this.socialMonitoringBlacklistChemicalList.findIndex(x => x.chemicalId == this.allChemicalDataList[i]["id"]) < 0 &&
          this.allChemicalDataList[i].isChecked == true) {
          selectedChemicalId = selectedChemicalId + this.allChemicalDataList[i]["id"] + ",";
        }
      }
      selectedChemicalId = selectedChemicalId.substring(0, selectedChemicalId.length - 1);
      this.selectedChemicalId = selectedChemicalId;
      this.GetCitationPostDataList(selectedChemicalId.toString());
    }
    else {
      if (this.allChemicalDataList.length == 0) {
        this.NoDataFound = "No Chart Data Found.";
        // $("#chart_multi").html("");
        // $("#chart_single").html("");
        // $("#chart_chemical").html("");
        this.onSetStage(false, false, false, false, 'search');

        if (this.allChemicalDataList.length > 0) {
          selectedChemicalId = this.allChemicalDataList[0]["id"];
          this.selectedChemicalId = selectedChemicalId;
          this.GetCitationPostDataList(selectedChemicalId.toString());
        }
      }
    }
  }

  // First Chart for Citation Post List
  GetCitationPostDataList(chemicalId: string = null) {
    if (this.selectedChemicalId == null || this.selectedChemicalId == "") {
      this.NoDataFound = "No Chart Data Found.";
      this.onSetStage(false, false, false, false, 'search');
      return;
    }
    this.plottedChemicalList = [];
    this.SpinnerService.show();
    this.onSetStage(false, true, false, false, 'chart_chemical');
    this.citationPostService.getAllEmerging(chemicalId, "1", this.fromDate, this.toDate, this.currentPeriodValue).subscribe(data => {
      // $("#chart_multi").html("");
      // $("#chart_single").html("");
      this.getGoogle();
      var chartWidth = $(".content_btm").width() - 50;
      var optionsEmerging = {
        tooltip: { isHtml: true },
        //legend: { position: 'Left' },
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Date',
        },
        vAxis: {
          title: 'Number of Hits'
        }
      };

      this.EmergingChemicalList = data;
      if (this.EmergingChemicalList.length < 1) {

        this.NoDataFound = "No Chart Data Found.";
        // $("#chart_chemical").html("");
        // $("#chart_multi").html("");
        // $("#chart_single").html("");
        this.onSetStage(false, false, false, false, 'chart_chemical');
      }
      else {
        this.NoDataFound = "";
      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      let mainValue = [];
      let dataList: string[] = [];
      let firstChartData = [];
      if (this.EmergingChemicalList && this.EmergingChemicalList.length > 0) {
        for (let selectedIndex = 0; selectedIndex < this.allChemicalDataList.length; selectedIndex++) {
          const selectedItem = this.allChemicalDataList[selectedIndex];
          if (this.socialMonitoringBlacklistChemicalList.findIndex(x => x.chemicalId == selectedItem.id) >= 0) {
            this.plottedChemicalList.push({ itemName: selectedItem.chemicalName, toolTip: "This chemical is in denial list kindly contact site Admin." });
          }
          else if (!selectedItem.isChecked) {
            this.plottedChemicalList.push({ itemName: selectedItem.chemicalName, toolTip: "This chemical is disabled for social monitoring." });
          }
          else {
            var counter = 0;
            for (let dataIndex = 0; dataIndex < this.EmergingChemicalList.length; dataIndex++) {
              const element = this.EmergingChemicalList[dataIndex];
              if (element[selectedItem.chemicalName] == 0) {
                counter++;
              }
            }

            if (counter == this.EmergingChemicalList.length) {
              this.plottedChemicalList.push({ itemName: selectedItem.chemicalName, toolTip: "No data sorry, we need a day to collect social data" });
            }
          }
        }
        firstChartData = this.EmergingChemicalList;
        for (let index = 0; index < firstChartData.length; index++) {
          this.plottedChemicalList.forEach(element => {
            delete firstChartData[index][element.itemName];
          });
        }
      }

      var chartWidth = this.plottedChemicalList.length > 0 ? $(".tab-pane.active").width() - 300 : $(".content_btm").width() - 50;
      optionsEmerging.width = chartWidth;
      // var optionsEmerging = {
      //   tooltip: { isHtml: true },
      //   //legend: { position: 'Left' },
      //   width: chartWidth,
      //   height: 600,
      //   hAxis: {
      //     title: 'Date'
      //   },
      //   vAxis: {
      //     title: 'Number of Hits'
      //   }
      // };

      this.EmergingChemicalList.forEach((value) => {
        Object.keys(value).forEach((key) => {
          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })
        count = count + 1;
      })

      this.EmergingChemicalList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);
      })
      // Add data.
      data1.addRows(mainValue);
      var chartChemical = new google.visualization.LineChart(document.getElementById('chart_chemical'));

      if (this.EmergingChemicalList.length > 0) {
        chartChemical.draw(data1, optionsEmerging);
      }

      google.visualization.events.addListener(chartChemical, 'select', selectHandler);

      var cid = "";
      var cName = "";
      var currentClass = this;
      function selectHandler() {
        var selection = chartChemical.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(currentClass.allChemicalDataList, function (el) {
            //el.chemicalName == str;
            return el.chemicalName ? el.chemicalName == str : el.itemName && el.itemName == str;
          });
          cid = selectedDate[0].id;
          cName = selectedDate[0].chemicalName || selectedDate[0].itemName;
        }
        currentClass.chemicalId = cid;
        currentClass.getCommonNameList(cid, cName);
      }

      this.SpinnerService.hide();

    },
      error => {
        // alert("We faced some technical issues");
      });
    this.SpinnerService.hide();
  }

  // getTotal(col) {
  //   var test = this.EmergingChemicalList.map(d => d[col]).reduce((colValue, value) => colValue + value, 0);
  //   console.log(test);
  //   return test;
  // }

  getCommonNameList(chemicalId: string = null, chemicalName: string = null) {
    this.SearchKeyword = "";
    this.AllCommonName = "";
    this.citationPostService.getCommonNameList(chemicalId).pipe(first())
      .subscribe(p => {
        this.commonNameList = p;
        for (var obj in this.commonNameList) {
          this.AllCommonName = this.AllCommonName + this.commonNameList[obj].id + ",";
          this.SearchKeyword = this.commonNameList[obj].name;
          let newName = {
            id: this.commonNameList[obj].id,
            itemName: this.commonNameList[obj].name
          };
          this.dropdownList.push(newName);
        };
        this.AllCommonName = this.AllCommonName && this.AllCommonName.substring(0, this.AllCommonName.length - 1);

        if (this.commonNameList.length > 1) {
          this.SpinnerService.show();
          this.GetMultiSocialDataList(chemicalId, this.AllCommonName.toString());
        }
        else {
          !this.SearchKeyword ? this.SearchKeyword = chemicalName : 0 == 0;
          this.SpinnerService.show();
          this.onSetStage(false, false, true, false, 'chart_single');
          this.GetSocialDataList(chemicalId, this.SearchKeyword.toString());
        }
      },
        error => {
          //this.toastr.error(error.message);
        });
  }

  // Second Level Chart for Common names
  GetMultiSocialDataList(chemicalId: string = null, commonNameId: string = null): void {
    this.onGetFromAndToDates();
    this.onSetStage(true, false, false, false, 'chart_multi');
    this.citationPostService.getCommonNamePostCitationList(chemicalId, commonNameId, this.fromDate, this.toDate, this.currentPeriodValue).subscribe(data => {
      this.getGoogle();
      var chartWidth = $(".content_btm").width() - 50;
      var optionsMultiEmerging = {
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Number of Hits'
        }
      };

      this.MultiEmergingList = data;
      var cnList = this.dropdownList;
      if (this.MultiEmergingList.length < 1) {
        //google.visualization.events.addListener(multiChart, 'select', selectHandler);
        this.NoDataFound = "No Chart Data Found.";
        // $("#chart_chemical").html("");
        $("#chart_multi").html("");
        // $("#chart_single").html("");
      }
      else {
        this.NoDataFound = "";
      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      this.MultiEmergingList.forEach((value) => {

        Object.keys(value).forEach((key) => {

          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })

        count = count + 1;
      })

      let mainValue = [];
      let dataList: string[] = [];

      this.MultiEmergingList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);

      })
      // Add data.
      data1.addRows(mainValue);
      //this.div_chart_multi = true;
      var multiChart = new google.visualization.LineChart(document.getElementById('chart_multi'));
      if (this.MultiEmergingList.length > 0) {
        multiChart.draw(data1, optionsMultiEmerging);
      }

      google.visualization.events.addListener(multiChart, 'select', selectHandler);
      var cname = "";
      var currentClass = this;
      function selectHandler() {
        var selection = multiChart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(cnList, function (el) {
            return el.itemName == str;
          });

          cname = selectedDate && selectedDate[0] != undefined ? selectedDate[0].itemName : str;
          currentClass.selectedCommonNames = cname;
        }
        currentClass.GetSocialDataList(currentClass.chemicalId, cname.toString());
      }

      this.SpinnerService.hide();

    },
      error => {
        // alert("We faced some technical issues");
      });
  }

  // Third Sentiment chart
  GetSocialDataList(chemicalId: string = null, commonName: string = null): void {
    this.onGetFromAndToDates();
    this.SpinnerService.show();
    this.onSetStage(false, false, true, false, 'chart_single');
    this.citationPostService.getAllSocialSentimates(commonName, this.fromDate, this.toDate, this.currentPeriodValue).subscribe(data => {
      this.getGoogle();
      // $("#chart_multi").html("");
      // $("#chart_chemical").html("");
      var chartWidth = $(".content_btm").width() - 50;
      var optionsEmerging = {
        tooltip: { isHtml: true },

        //legend: { position: 'Left' },
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Number of Hits'
        },
        colors: ['#F5190B', '#080808', '#568a06']
      };

      this.EmergingList = data;
      if (this.EmergingList.length < 1) {
        this.NoDataFound = "No Chart Data Found.";
        // $("#chart_chemical").html("");
        // $("#chart_multi").html("");
        $("#chart_single").html("");
        //this.div_chart_single = false;
      }
      else {
        this.NoDataFound = "";
      }
      var data1 = new google.visualization.DataTable();
      data1.addColumn('string', "date");
      data1.addColumn('number', "Negative");
      data1.addColumn('number', "Neutral");
      data1.addColumn('number', "Positive");



      var Chart = new google.visualization.LineChart(document.getElementById('chart_single'));

      var recordData = [];
      var currentDate = null;
      $.each(this.EmergingList, function (key, value) {
        if (currentDate != value.date) {
          let currentData = _.filter(data, function (el) {
            return el.date == value.date;
          });
          let positiveValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 3;
          });
          let negativeValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 1;
          });
          let nutralValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 2;
          });
          currentDate = value.date;
          var pCount = positiveValue.length > 0 ? positiveValue[0].positiveCount : 0;
          var neCount = negativeValue.length > 0 ? negativeValue[0].negativeCount : 0;
          var nuCount = nutralValue.length > 0 ? nutralValue[0].neutralCount : 0;
          recordData.push([value.date, neCount, nuCount, pCount]);
        }
      });

      data1.addRows(recordData);
      if (this.EmergingList.length > 0) {
        Chart.draw(data1, optionsEmerging);
      }
      google.visualization.events.addListener(Chart, 'select', selectHandler);
      var currentThis = this;

      function selectHandler() {
        var selection = Chart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];

          let currentData = _.filter(data, function (el) {
            var str = data1.getFormattedValue(item.row, 0);
            return el.date == str;
          });

          if (currentData != null && currentData.length > 0) {
            let lineData = _.filter(currentData, function (el) {
              return el.citationSentimentId == item.column;
            });
            if (lineData != null && lineData.length > 0) {
              // var chemicalName = lineData[0].chemicalName;
              currentThis.selectedChemicalName["chemicalName"] = lineData[0].chemicalName;
              // var citationTypeId = String(lineData[0].citationTypeId);
              currentThis.selectedChemicalName["citationTypeId"] = String(lineData[0].citationTypeId);
              // var citationSentimentId = String(lineData[0].citationSentimentId);
              currentThis.selectedChemicalName["citationSentimentId"] = String(lineData[0].citationSentimentId);
              // var recordDate = lineData[0].date;
              currentThis.selectedChemicalName["recordDate"] = lineData[0].date;
              currentThis.SpinnerService.show();
              currentThis.GetSentimentDetailData(currentThis.selectedChemicalName["chemicalName"],
                currentThis.selectedChemicalName["citationTypeId"],
                currentThis.selectedChemicalName["citationSentimentId"],
                currentThis.selectedChemicalName["recordDate"]);
            }
          }
        }
        if (message == '') {
          message = 'nothing';
        }
      }
      this.SpinnerService.hide();
    },
      error => {
        // alert("We faced some technical issues");
      });
  }

  // Sentiment Data Table
  GetSentimentDetailData(chemicalName: string = null, citationTypeId: string = null, citationSentimentId: string = null, recordDate: string = null) {
    this.sentimentDetailData = [];
    //$("#chart_single").html("");
    //this.divTable = true;
    this.onSetStage(false, false, false, true, 'detail_data');
    this.citationPostService.getAllSocialSentimatesDetailData(chemicalName, citationTypeId, citationSentimentId, recordDate).subscribe(data => {
      this.sentimentDetail = data;
      for (var i = 0; i < this.sentimentDetail.length; i++) {
        let newMatch = {
          sentimentScore: this.sentimentDetail[i].sentimentScore,
          recordDate: this.sentimentDetail[i].recordDate,
          description: this.sentimentDetail[i].description,
          postId: this.sentimentDetail[i].postId,
        };
        this.sentimentDetailData.push(newMatch);
      }
      this.SpinnerService.hide();
    })
  }

  onclear() {
    this.selectedItems = [];
    this.allChemicalDataList = [];

    this.NoDataFound = "No Chart Data Found.";
    $("#chart_multi").html("");
    $("#chart_single").html("");
    $("#chart_chemical").html("");
    this.onSetStage(false, false, false, false, "search");
    //this.divTable = false;
  }

  onback() {
    switch (this.currentStage) {
      case "chart_chemical":
        this.onSetStage(false, false, false, false, "search");
        break;
      case "chart_multi":
        this.onSetStage(false, true, false, false, "chart_chemical");
        break;
      case "chart_single":
        if (this.commonNameList.length > 1) {
          this.onSetStage(true, false, false, false, "chart_multi");
        }
        else {
          this.onSetStage(false, true, false, false, "chart_chemical");
        }
        break;
      case "detail_data":
        this.onSetStage(false, false, true, false, "chart_single");
        break;
      default:
        this.onSetStage(false, false, false, false, "search");
        break;
    }
    this.onPeriodClick(this.currentPeriodValue);
  }

  onSetStage(chartMulti: boolean, chartChemical: boolean, chartSingle: boolean, detailData: boolean, currentStage: string) {
    this.currentStage = currentStage;
    this.divTable = detailData;
    this.div_chart_chemical = chartChemical;
    this.div_chart_multi = chartMulti;
    this.div_chart_single = chartSingle;
  }

  redirectToChart() {

  }

  onGetFromAndToDates() {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate());
    this.toDate = new Date(startDate);
    switch (this.currentPeriodValue) {
      case "week":
        startDate.setDate(startDate.getDate() - 7)
        this.fromDate = startDate;
        break;
      case "month":
        startDate.setDate(startDate.getDate() - 30)
        this.fromDate = startDate;
        break;
      case "6months":
        startDate.setDate(startDate.getDate() - 182)
        this.fromDate = startDate;
        break;
      case "year":
        startDate.setDate(startDate.getDate() - 365)
        this.fromDate = startDate;
        break;

      default:
        startDate.setDate(startDate.getDate() - 7)
        this.fromDate = startDate;
        break;
    }
  }

  onPeriodClick(period) {
    let selectedChemicalId = this.selectedChemicalId;
    this.currentPeriodValue = period.target && period.target.value || period;
    this.onGetFromAndToDates();
    switch (this.currentStage) {
      case "chart_chemical":
        this.GetCitationPostDataList(selectedChemicalId);
        break;
      case "chart_multi":
        this.GetMultiSocialDataList(this.chemicalId, this.AllCommonName.toString());
        break;
      case "chart_single":
        if (this.commonNameList.length > 1)
          this.GetSocialDataList(selectedChemicalId, this.selectedCommonNames.toString());
        else
          this.GetSocialDataList(selectedChemicalId, this.SearchKeyword.toString());
        break;
      case "detail_data":
        this.GetSentimentDetailData(this.selectedChemicalName["chemicalName"],
          this.selectedChemicalName["citationTypeId"],
          this.selectedChemicalName["citationSentimentId"],
          this.selectedChemicalName["recordDate"]);
        break;
      default:
        this.GetCitationPostDataList(selectedChemicalId);
        break;
    }
  }

  onMonitor(e, value) {
    this.hideShow.emit(true);
    this.socialMonitoringList.emit(this.allChemicalDataList);
    this.onSubmit();
  }

  getBlacklistChemical() {
    this.monitoringManagementService.getBlacklistChemicalsAll().pipe(first())
      .subscribe(res => {
        this.socialMonitoringBlacklistChemicalList = res;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  /* Company Alert Start */

  private getSentimentTypeList() {
    this.companyAlertDataService.getSentimentTypeList().pipe(first())
      .subscribe(res => {
        this.sentimentTypeList = res;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getDeviationTypeList() {
    this.companyAlertDataService.getDeviationTypeList().pipe(first())
      .subscribe(res => {
        this.deviationTypeList = res;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  getAllAlertByCompanyUser() {
    this.companyAlertDataService.getAllByCompanyUser(this.companyId).pipe(first())
      .subscribe(res => {
        this.companyAlertList = res;
      });
  }

  onAlertSubmit(chemicalId: String) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.companyAlertForm.invalid) {
      return;
    }

    this.loading = true;
    this.SpinnerService.show();
    this.companyAlertForm.value.companyId = this.companyId;
    if (this.id) {
      this.companyAlertForm.value.id = this.id.toString();
      this.companyAlertDataService.update(this.companyAlertForm.value).pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.toastr.success(data['message']);
              this.getAllAlertByCompanyUser()
            } else {
              this.toastr.error(data['message']);
            }
            this.loading = false;
            this.SpinnerService.hide();
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
            this.SpinnerService.hide();
          });
    } else {
      this.companyAlertForm.value.chemicalId = chemicalId;
      this.companyAlertForm.value.id = "0";
      this.companyAlertDataService.create(this.companyAlertForm.value).pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.toastr.success(data['message']);
              this.getAllAlertByCompanyUser();
            } else {
              this.toastr.error(data['message']);
            }
            this.loading = false;
            this.SpinnerService.hide();
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
            this.SpinnerService.hide();
          });
    }
  }

  public openDeleteConfirmationDialog(id: any) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
      return;
    }
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteById(id) : ""
      )
      .catch(
      //(e) =>
    );
  }

  deleteById(id: any) {
    this.SpinnerService.show();
    this.companyAlertDataService.delete(id).pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getAllAlertByCompanyUser();
        } else {
          this.toastr.error(data['message']);
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.error.message);
          this.SpinnerService.hide();
        });
  }

  editCompanyAlert(companyAlertModel: any): void {
    if (companyAlertModel != null) {
      this.companyAlertForm.setValue({
        id: companyAlertModel.id.toString(),
        companyId: companyAlertModel.companyId,
        chemicalId: companyAlertModel.chemicalId,
        sentimentType: companyAlertModel.sentimentType.toString(),
        deviationType: companyAlertModel.deviationType.toString()
      });
    }
  }

  popupAlert(content, data) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
      return;
    }
    this.id = "";
    this.companyAlertForm.setValue({
      id: "",
      companyId: this.companyId,
      chemicalId: "",
      sentimentType: "",
      deviationType: ""
    });

    this.modelTitle = "Add Alert";
    if (data.id != null && data.id > 0) {
      this.modelTitle = "Edit Alert";
      this.id = data.id;
      this.editCompanyAlert(data);
    }

    let chemicalId = data.id;
    this.modalService.open(content, {
      ariaLabelledBy: 'modelPopupLabelCompanyAlert',
      windowClass: 'modalCompanyAlert'
    }).result.then((result) => {
      if (result == "Yes") {
        this.onAlertSubmit(chemicalId);
      }
      else if (result == "No") {

      }
    }, (reason) => {
      console.log(reason);
    });

  }

  /* Company Alert End */

}