import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReleaseHistoryService } from '../release-history.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, AuthService } from '../../services';
import { userloginVM } from '../../models';
import { UserRoles } from '../../models/enum';


@Component({
  selector: 'app-view-release-history',
  templateUrl: './view-release-history.component.html',
  styleUrls: ['./view-release-history.component.css']
})
export class ViewReleaseHistoryComponent implements OnInit {

  public releaseHistoryList: any[];
  public currentUserCode: string;
  currentUser: userloginVM;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;

  constructor(private authService: AuthService, private authenticationService: AuthenticationService,
    private spinnerService: NgxSpinnerService, private releaseHistoryService: ReleaseHistoryService, private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    if (this.authService.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }

    this.getReleaseHistoryList();
    this.visitReleaseHistoryByUser();
  }

  getReleaseHistoryList() {
    this.spinnerService.show();
    this.releaseHistoryService.getAllByPublish().pipe(first())
      .subscribe(res => {
        this.releaseHistoryList = res;
        if (this.releaseHistoryList.length > 0) {
          //this.setPage(1);
        } else {
          //this.pagedItems = [];
        }
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.spinnerService.hide();
        });
  }

  visitReleaseHistoryByUser() {
    this.spinnerService.show();
    this.releaseHistoryService.visitReleaseHistoryByUser().pipe(first())
      .subscribe(res => {
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.spinnerService.hide();
        });
  }

}
