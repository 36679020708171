export class annualProgramVM {
    id: number;
    companyId: string;
    userId: string;
    reportId: string;
    createdBy: string;
    createdDate: Date | string;
    modifiedBy: string | null;
    modifiedDate: Date | string | null;
    currentYearAnnualProgramList: annualProgramYearVM[];
    previousYearAnnualProgramList: annualProgramYearVM[];
    nextYearAnnualProgramList: annualProgramYearVM[];
}
export class annualProgramYearVM {
    id: number;
    annualProgramId: number;
    chemicalId: string;
    chemicalName: string;
    casrn: string;
    use: string;
    toxicity: number;
    exposure: number;
    emergingConcern: number;
    programYear: number;
    recommendedApplicationRate: number | null;
    actualApplicationRate: number | null;
    unitCost: number | null;
    area: number | null;
    volume: number | null;
    cost: number | null;
    actual: number | null;
    effective: number | null;
}