import { Component, OnInit, Input } from '@angular/core';
import { AlertService, AuthService } from '../services';
import { UserService } from '../users/user.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisService } from './analysis.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { PagerService } from '../helpers/pager.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
declare var google: any;
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent implements OnInit {

  public data = [];

  public chemicalList: any;
  public EmergingList: any;
  loading = false;
  public filter: string = '';
  public pager: any = {};
  public pagedItems: any[];
  public TotalRecord: any;
  public isDesc: boolean = false;
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  companyId: any;
  isCompanyAdmin = false;
  chartData: [];
  dataList: [][];
  getGoogle() {
    return google;
  }

  constructor(
    private analysisService: AnalysisService,
    //private alertService: AlertService,
    //private confirmationDialogService: ConfirmationDialogService,
    //private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    //private pagerService: PagerService,
    //private toastr: ToastrService,
    //private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    google.charts.load('current', { packages: ['corechart', 'table'] });
  }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }
    // google.charts.load('current', { packages: ['corechart', 'table'] });
    //google.charts.load('current', { 'packages': ['corechart', 'controls'] });
    this.RetrieveChemicalList('', 1, 10000, '', '', true);


    // this.data = [[8, 12], [4, 5.5], [11, 14], [4, 5], [3, 3.5], [6.5, 7]];
    //this.columnNames = ['Exposure', 'Toxity'];
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean) {
    this.SpinnerService.show();
    this.analysisService.getAllParameter(filter, startRow, endRow, sortColumn, sortOrder).subscribe(data => {

      this.chemicalList = data;
      this.EmergingList = data;
      this.getGoogle();
      $("#chart_single").html("");

      var chartWidth = $(".content_btm").width() + 50;
      var options = {
        Title: "Research Chart",
        tooltip: { isHtml: true },
        bubble: {
          textStyle: {
            fontSize: 10
          }
        },
        legend: { position: 'none' },
        width: chartWidth,
        height: 750,
        hAxis: {
          title: 'Toxicity'
        },
        vAxis: {
          title: 'Exposure'
        }
      };


      if (this.EmergingList.length < 1) {
        $("#chart_noDate").html("No Chart Data Found");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");
      }
      var data1 = new google.visualization.DataTable();
      data1.addColumn('string', "Name");
      data1.addColumn('number', "Toxicity");
      data1.addColumn('number', "Exposure");
      data1.addColumn('string', "Name");
      data1.addColumn('number', "Exposure");

      var Chart = new google.visualization.BubbleChart(document.getElementById('chart_single'));

      var recordData = [];
      $.each(this.EmergingList, function (key, value) {

        recordData.push([value["ChemicalName"], parseFloat(value["Toxicity Combined Score (Weighted)"]), parseFloat(value["Exposure Combined (Weighted Score)"]), value["ChemicalName"], parseFloat(value["Exposure Combined (Weighted Score)"])]);
      });

      data1.addRows(recordData);
      if (this.EmergingList.length > 0) {
        Chart.draw(data1, options);
      }
      this.SpinnerService.hide();
    },
      error => {
      });
  }


  GridHideShow(type) {
    if (type == 2) {
      this.router.navigate(['/analysis-emerging-concern', "1", ''])
    }
    else if (type == 3) {
      this.router.navigate(['/analysis-prioritised-chemicals'])
    }
  }

}
