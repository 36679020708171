import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { releaseHistoryVM } from '../../models/releaseHistoryVM';
import { AuthenticationService, AuthService } from '../../services';
import { ReleaseHistoryService } from '../release-history.service';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserRoles } from 'src/app/models/enum';
import { userloginVM } from 'src/app/models';

@Component({
  selector: 'app-view-detail-release-history',
  templateUrl: './view-detail-release-history.component.html',
  styleUrls: ['./view-detail-release-history.component.css']
})
export class ViewDetailReleaseHistoryComponent implements OnInit {

  public releaseHistoryModel: releaseHistoryVM = {} as releaseHistoryVM;
  id: any;
  public currentUserCode: string;
  currentUser: userloginVM;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;

  constructor(private authService: AuthService, private authenticationService: AuthenticationService,
    private route: ActivatedRoute, private router: Router, private spinnerService: NgxSpinnerService,
    private releaseHistoryService: ReleaseHistoryService,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    if (this.authService.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    if (this.id) {
      this.getReleaseHistory(this.id)
    }

  }

  getReleaseHistory(id: string = null): void {
    this.spinnerService.show();
    if (id) {
      this.releaseHistoryService.getById(id).pipe(first()).subscribe(res => {
        if (res != null) {
          this.releaseHistoryModel = res as releaseHistoryVM;
        }
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.spinnerService.hide();
        });
    }
  }

}
