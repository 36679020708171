import { Component, OnInit, Input, Directive, ElementRef } from '@angular/core';
import { chemicalparameterVM, chemicalCategoryPostVM, chemicalParameterPostModel, chemicalParameterValuePostVM, chemicalVM, parameterHistoryVM } from "../../app/models/chemicalparameterVM"
import { AlertService, AuthService } from '../../app/services';
import { ManageChemicalService } from '../../app/managechemicals/managechemicalsservice';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../app/confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from '../../app/helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-viewallchemicals',
  templateUrl: './viewallchemicals.component.html',
  styleUrls: ['./viewallchemicals.component.css']
})
export class ViewallchemicalsComponent implements OnInit {
  @Input() dataMessageResults: any[];

  public chemicalList: any;
  public exportList: any;

  loading = false;
  public filter: string = '';
  public pager: any = {};
  public pagedItems: any[];
  public TotalRecord: any;
  public isDesc: boolean = false;
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  companyId: any;
  isCompanyAdmin = false;
  topic: any;
  sub: any;
  k: any;
  t: any;
  public noData: boolean = false;
  public withData: boolean = false;
  public isShowAll: boolean = false;
  public isLastPageClicked: boolean = false;


  constructor(
    private chemicalService: ManageChemicalService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit() {

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }
    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });
    if (this.k != null && this.k != "" && this.k != undefined) {
      this.filter = this.k;
      $('.searchInput').val(this.k);
    }


    this.RetrieveChemicalList(this.filter, 1, 10, '', '', true, this.isShowAll);
  }

  SetPage(page: number, isCallChemicalList: boolean) {

    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    if (this.isLastPageClicked && page == this.pager.totalPages) { return; }

    if (page == this.pager.totalPages) {
      this.isLastPageClicked = true;
    }
    else {
      this.isLastPageClicked = false;
    }

    if (isCallChemicalList && page == this.pager.currentPage) {
      return;
    }

    this.pager = this.chemicalService.GetPager((this.chemicalList.length > 0 ? this.chemicalList[0].TotalRecord : 0), page, 10);
    this.pagedItems = this.chemicalList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    if (isCallChemicalList) {
      this.RetrieveChemicalList(this.filter, this.pager.startIndex, this.pager.endIndex, this.sortColumn, this.sortDirection, false, this.isShowAll)
    }
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, isShowAll: boolean) {
    this.SpinnerService.show();
    this.chemicalService.AllChemicalListWithParamater(filter, startRow, endRow, sortColumn, sortOrder, isShowAll).subscribe(data => {
      this.chemicalList = data;
      if (this.chemicalList != null && this.chemicalList.length > 0) {
        this.withData = true;
        this.noData = false;
        this.dataMessageResults = this.chemicalList
        if (isPager && this.chemicalList.length > 0) {
          this.SetPage(1, false);
        } else {
          this.pagedItems = [];
        }
      }
      else {
        this.chemicalList = null;
        this.withData = false;
        this.noData = true;
      }
      this.SpinnerService.hide();
    },
      error => {
        //alert("We faced some technical issues");
      });

  }

  ExportChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, isShowAll: boolean) {
    this.SpinnerService.show();
    this.chemicalService.AllChemicalListWithParamater(filter, startRow, endRow, sortColumn, sortOrder, isShowAll).subscribe(data => {
      var exportList1 = data;//data["Table"];
      var exList = _.map(exportList1, function (c) {
        return _.omit(c, ['Id']);
      });
      this.chemicalService.exportAsExcelFile(exList, 'Export-AllChemicalData');
      this.SpinnerService.hide();
    },
      error => {
        //alert("We faced some technical issues");
      });
  }

  getHeaders() {
    let headers: string[] = [];
    if (this.dataMessageResults) {
      this.dataMessageResults.forEach((value) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            if (key.toLowerCase() != "id" && key.toLowerCase() != "totalrecord" && key.toLowerCase() != "category" && key.toLowerCase() != "chemical name" && key.toLowerCase() != "common name" && key.toLowerCase() != "molecular weight" && key.toLowerCase() != "cas no.") {
              headers.push(key)
            }
          }
        })
      })
    }
    return headers;
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.sortColumn = property;
    this.sortDirection = this.isDesc ? 'asc' : 'desc';


    this.RetrieveChemicalList(this.filter, this.pager.startIndex, this.pager.endIndex, this.sortColumn, this.sortDirection, false, this.isShowAll);
  };

  viewChemical(id) {
    this.router.navigate(['/view-chemical-details', id, $('.searchInput').val(), "2"]);
  }

  ShowChart(t, id) {
    var kVal = '';
    if ($('.searchInput').val() != '') {
      kVal = $('.searchInput').val();
    }
    this.router.navigate(['/social-feed', id, t, kVal])
  }

  search(type) {
    var sortDirection = 'asc';
    this.isLastPageClicked = false;
    var keyword = $('.searchInput').val();
    if (type == "search") {
      if (keyword != null && keyword != "") {
        this.pager.startIndex = 1;
        this.filter = keyword;
        this.RetrieveChemicalList(keyword, this.pager.startIndex, this.pager.startIndex + 9, this.sortColumn, this.sortDirection, true, this.isShowAll);
      }
      else if (keyword.length == 0) {
        this.RetrieveChemicalList('', 1, 10, '', '', true, this.isShowAll);
      }
    }
    else {
      $('.searchInput').val('');
      this.RetrieveChemicalList('', 1, 10, '', '', true, this.isShowAll);
    }
  }

  exportAsXLSX(): void {
    this.ExportChemicalList('', this.pager.startIndex, this.pager.startIndex + 9, '', '', true, this.isShowAll);
  }
}
