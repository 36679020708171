import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AlertService, AuthService } from '../services';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyAlertDataService } from '../../app/company-alert/company-alert.service';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { GeneratedAlertsVM } from '../models/generatedAlertsVM';
declare var $: any;

@Component({
  selector: 'app-generated-alert',
  templateUrl: './generated-alert.component.html',
  styleUrls: ['./generated-alert.component.css']
})
export class GeneratedAlertComponent implements OnInit {

  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public generatedAlertList: GeneratedAlertsVM[];
  loading = false;
  keyword: any;
  sub: any;
  id: any;
  public noData: boolean = false;
  public withData: boolean = false;
  constructor(
    private companyAlertservice: CompanyAlertDataService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private pagerService: PagerService
  ) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.sub = this.route.params.subscribe(params => {
      this.keyword = params['id'];
    });

    this.viewGeneratedAlertList(this.keyword);
  }

  private viewGeneratedAlertList(keyword: string = null): void {
    this.SpinnerService.show();
    this.companyAlertservice.getAllGeneratedAlert(keyword).pipe(first())
      .subscribe(p => {
        this.generatedAlertList = p;
        if (this.generatedAlertList != null && this.generatedAlertList.length > 0) {
          this.withData = true;
          this.noData = false;
          if (this.generatedAlertList.length > 0) {
            this.setPage(1);
          }
          else {
            this.pagedItems = null;
          }
        }
        else {
          this.generatedAlertList = null;
          this.withData = false;
          this.noData = true;
        }
        this.SpinnerService.hide();
      },
        error => {
          if (error.status == 401) {
            this.authService.logout();
          } else {
            this.toastr.error(error.message);
            this.SpinnerService.hide();
          }
        });
  }

  setPage(page: number) {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      this.SpinnerService.show();
      this.pager = this.pagerService.getPager(this.generatedAlertList.length, page);
      this.pagedItems = this.generatedAlertList.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.currentPage = page;
      this.SpinnerService.hide();
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }

  sort(sort: Sort) {
    const data = this.generatedAlertList.slice();
    if (!sort.active || sort.direction === '') {
      this.generatedAlertList = data;
      return;
    }
    this.generatedAlertList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'companyName': return compare(a.companyName, b.companyName, isAsc);
        case 'alertDate': return compare(a.alertDate, b.alertDate, isAsc);
        case 'status': return compare(a["status"], b["status"], isAsc);
        case 'commonName': return compare(a.commonName, b.commonName, isAsc);
        case 'citatioTypeName': return compare(a["citatioTypeName"], b["citatioTypeName"], isAsc);
        case 'citationSentimentName': return compare(a["citationSentimentName"], b["citationSentimentName"], isAsc);
        case 'thresholdName': return compare(a.thresholdName, b.thresholdName, isAsc);
        case 'periodName': return compare(a.periodName, b.periodName, isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

  search(type) {

    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      var keyword = $('.searchInput').val();
     // $('.searchInput').val(keyword);
      if (type == "search") {
        if (keyword != null && keyword != "") {
          this.viewGeneratedAlertList(keyword);
        }
        else if (keyword.length == 0) {
          this.viewGeneratedAlertList();
        }
      }
      else {
        $('.searchInput').val('');
        this.viewGeneratedAlertList();
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
