import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CitationPostService {

  constructor(private http: HttpClient) { }

  getAllEmerging(chemicalId: string, type: string, fromDate: any = null, toDate: any = null, currentPeriodValue: any = null) {
    return this.http.post(`${environment.apiUrl}ManageChemical/getallcitationpostdata`,
      { chemicalId: chemicalId, fromDate: fromDate, toDate: toDate, type: type, selectedDatePeriodValue: currentPeriodValue, isTrial: environment.isTrial }
    );
  }

  getCommonNameList(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}ManageChemical/CommonNameList/` + id);
  }

  getCommonNamePostCitationList(chemicalId: string, type: string, fromDate: any = null, toDate: any = null, currentPeriodValue: any = null) {
    return this.http.post<any[]>(`${environment.apiUrl}ManageChemical/getCommonNameCitationList`,
      { chemicalId: chemicalId, fromDate: fromDate, toDate: toDate, type: type, selectedDatePeriodValue: currentPeriodValue, isTrial: environment.isTrial }
    );
  }

  getAllSocialSentimates(commonName: string, fromDate: any = null, toDate: any = null, currentPeriodValue: any = null) {
    return this.http.post<any[]>(`${environment.apiUrl}ManageChemical/getallsocialsentimatedata`,
      { commonName: commonName, fromDate: fromDate, toDate: toDate, selectedDatePeriodValue: currentPeriodValue, isTrial: environment.isTrial }
    );
  }

  getAllSocialSentimatesDetailData(chemicalName: string, citationTypeId: string, citationSentimentId: string, recordDate: string) {
    return this.http.post<any[]>(`${environment.apiUrl}ManageChemical/getallsocialsentimatedetaileddata`,
      { chemicalName: chemicalName, citationTypeId: citationTypeId, citationSentimentId: citationSentimentId, recordDateStr: recordDate }
    );
  }
}
