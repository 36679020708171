import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datefilter'
})

export class DateFilterPipe implements PipeTransform {
  
  transform(input: any): any {
    if (!input) {
      return input;
    }    
    var date = new Date(input + 'Z');
    return date;
  }
}

