import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyAlertDataService } from './company-alert.service';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PostCompanyAlertData } from '../models/postCompanyAlertData';

@Component({
  selector: 'app-company-alert',
  templateUrl: './company-alert.component.html',
  styleUrls: ['./company-alert.component.css']
})
export class CompanyAlertComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public companyAlertList: PostCompanyAlertData[];
  loading = false;
  constructor(
    private companyAlertservice: CompanyAlertDataService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private pagerService: PagerService
  ) { }

  ngOnInit() {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      this.SpinnerService.show();
      this.getCompanyAlertList();
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }
  private getCompanyAlertList() {
    this.SpinnerService.show();
    this.companyAlertservice.getAll().pipe(first())
      .subscribe(p => {
        this.companyAlertList = p;
        if (this.companyAlertList.length > 0) {
          this.setPage(1);
        }
        else {
          this.pagedItems = null;
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deletealertById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
        //(e) =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  deletealertById(id: any) {
    this.companyAlertservice.delete(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getCompanyAlertList();
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  setPage(page: number) {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.companyAlertList.length, page);
    this.pagedItems = this.companyAlertList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
      this.SpinnerService.hide();
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }

  sort(sort: Sort) {

    const data = this.companyAlertList.slice();
    if (!sort.active || sort.direction === '') {
      this.companyAlertList = data;
      return;
    }
    this.companyAlertList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'commonName': return compare(a.commonName, b.commonName, isAsc);
        case 'citatioTypeName': return compare(a["citatioTypeName"], b["citatioTypeName"], isAsc);
        case 'citationSentimentName': return compare(a["citationSentimentName"], b["citationSentimentName"], isAsc);
        case 'thresholdName': return compare(a.thresholdName, b.thresholdName, isAsc);
        case 'periodName': return compare(a.periodName, b.periodName, isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
