import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AlertService, AuthService } from '../../services';
import { UserService } from '../../users/user.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisService } from './../analysis.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { PagerService } from '../../helpers/pager.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleChartsModule } from 'angular-google-charts';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatPaginator, MatSort, MatStepperModule, MatTableDataSource } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrimValidators } from '../../helpers/custom-validators';
import { ManageChemicalService } from '../../managechemicals/managechemicalsservice';
import { weightCategory, weightageView } from '../../models/weightCategorySlider';
import { SdmSliderService } from '../../sdm-slider/sdm-slider.service';
import { AnalysisDashboardService } from './analysis-dashboard.service';
import { reportVM, Column, ReportDownloadPdfVM } from '../../models/reportVM';
import { Sort, SortDirection } from '@angular/material';
import { CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { environment } from '../../../environments/environment';
import { JoyrideModule, JoyrideService } from 'ngx-joyride';
import { CompanyChemicalRequestService } from '../../company-chemical-request/company-chemical-request.service';
//import { TourService, IStepOption } from 'ngx-tour-ng-bootstrap';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexXAxis,
  ApexDataLabels,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexLegend,
  ApexGrid,
  ApexMarkers
} from "ng-apexcharts";

declare var google: any;
import * as _ from 'lodash';
import { Guid } from 'guid-typescript';
import { debug } from 'util';
import * as introJs from 'intro.js/intro.js';
import { DomainService } from 'src/app/domain/domain.service';
import { domainVM } from 'src/app/models/domainVM';
import { CitationPostComponent } from 'src/app/citation-post/citation-post.component';
import { Subject } from 'rxjs';
import { ECScoreForChart } from 'src/app/models/chemicalVM';
/*import { setTimeout } from 'timers';*/


declare var $: any;

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  tooltip: any; // to be simple I made it as any, can be replaced with the proper className
  legend: ApexLegend;
  grid: ApexGrid;
  markers: ApexMarkers;
};

@Component({
  selector: 'app-analysis-dashboard',
  templateUrl: './analysis-dashboard.component.html',
  styleUrls: ['./analysis-dashboard.component.css']
})
export class AnalysisDashboardComponent implements OnInit {

  @ViewChild("chart", { static: true }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public xAxisMinVal = 0.0;
  public xAxisMaxVal = 0.0;
  public yAxisMinVal = 0.000;
  public yAxisMaxVal = 0.000;
  public xNoOfTick = 0;
  public yNoOfTick = 0;
  public isShowChart = false;
  public partialDataChemicalList = [];
  public seriesData = [];
  public seriesTooltipData = [];
  public bubbleFillColor = [];

  public seriesDataTemp = [];
  public seriesTooltipDataTemp = [];
  public isChartSimple: boolean = false;

  public isAIData: boolean = false;

  @ViewChild("child", { static: true }) child: CitationPostComponent;


  public getSearchStatus: Subject<any> = new Subject();
  @Output() getSearchStatusChange = new EventEmitter<any>();

  public data = [];
  public weightCategoryList: weightCategory[];
  public weightageView: weightageView = new weightageView();
  public reportData: reportVM = new reportVM();
  public ecScoreForChartList: ECScoreForChart[];
  public reportList: reportVM[];
  public chemicalList: any;
  public EmergingList: any;
  submitted = false;
  showColumnDivVal = false;
  loading = false;
  editMode = false;
  blobData: any;
  directAnalysis = false;
  showReportInput = false;
  tabPreviousIndex: number;
  public filter: string = '';
  public pager: any = {};
  public pagedItems: any[];
  public TotalRecord: any;
  public isDesc: boolean = false;
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  reportColumn: Column = new Column();
  companyId: any;
  isCompanyAdmin = false;
  chartData: [];
  dataList: [][];
  getGoogle() {
    return google;
  }

  analysisForm: FormGroup;
  sub: any;
  k: any;
  id: any;
  isSelected: boolean;
  type: any;
  urlChemicalId: any;
  isShow: boolean = false;
  reportEditInput = false;
  selectedChemicalId = "";
  newAnalysis: boolean = false;
  public allChemicalDataList: any;
  public EmergingChemicalList: any;
  public MultiEmergingList: any;

  AllCommonName: string = null;
  public commonNameList: any[];
  columnList: any[];
  reportColumnList: any[];
  chemicalId: any;
  dropdownList = [];
  multiDropdownList = [];
  selectedItems = [];
  selectedChemicals = [];
  selectedItems1 = [];
  dropdownSettings = {};
  dragdropColumnList: any[] = [];
  public socialURL: string = null;
  public pushIds: [];
  public selected?: boolean
  firstCommonName: string = null;
  defaultHide: boolean = false;
  deleteDisabled: boolean = false;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  ExposureDisabled = false;
  responseData: any;
  reportExportUrl: string;
  reportExcelExportUrl: string;
  public noData: boolean = false;
  public withData: boolean = true;
  @Input() dataMessageResults: any[];
  public exportList: any;
  topic: any;
  t: any;
  currentPage: any = 1;
  public customrSort: Sort = null;
  public direction: SortDirection = null;
  //readonly bufferSize: number = 250;

  //displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  //columnsToDisplay: string[] = this.displayedColumns.slice();
  //data1: PeriodicElement[] = ELEMENT_DATA;
  //displayedColumns: string[] = [];
  columnsToDisplay: string[] = ["Chemical Name", "CAS NO.", "Common Name", "Molecular Weight", "Category"];
  displayedColumns: any[];
  //columnsToDisplay: any[] = [{ "id": "Chemical Name", "isChecked": true }, { "id": "CAS NO.", "isChecked": true }, { "id": "Common Name", "isChecked": true }, { "id": "Molecular Weight", "isChecked": true }, { "id": "Category", "isChecked": true }]
  data1: any[];
  dataSource = new MatTableDataSource();
  filteredChemicalOptions: any[] = [];
  chemicalsCheckedList: any[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('newTable', { read: MatSort, static: true }) sorting: MatSort;
  public introJS = introJs();
  public tourcheck: boolean = false;
  public selectedTrialChemical = [];

  public domainList: domainVM[];
  public selectedDomainId: string;


  public toxicityRed: number = environment.toxicityRed;
  public toxicityOrange: number = environment.toxicityOrange;
  public toxicityGreen: number = environment.toxicityGreen;
  public exposureRed: number = environment.exposureRed;
  public exposureOrange: number = environment.exposureOrange;
  public exposureGreen: number = environment.toxicityRed;

  constructor(
    private analysisService: AnalysisService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private chemicalService: ManageChemicalService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private weightCategoryService: SdmSliderService,
    private reportService: AnalysisDashboardService,
    private readonly joyrideService: JoyrideService,
    //public tourService: TourService
    private chemicalRequestService: CompanyChemicalRequestService,
    private domainService: DomainService

  ) {
    //

    google.charts.load('current', { packages: ['corechart', 'table'] });
    //this.introJS.setOptions({
    //  steps: [
    //    {
    //      element: '#step1',
    //      intro: "Click on the Manage tab",
    //      position: 'top',
    //      onchange: function () {
    //      },
    //      onbeforechange: function () {
    //      }
    //    },
    //    {
    //      element: '#srchChemical',
    //      intro: "Search by chemical name (just start typing), CAS#, or use category (for example, type fungicide and chemicals tagged as fungicides will be listed)",
    //      position: 'right'
    //    },
    //    {
    //      element: '#step3',
    //      intro: 'Select the chemicals you want to compare, and press Add To Chemical Group',
    //      position: 'right'
    //    },
    //    {
    //      element: '#step4',
    //      intro: "Click on the Weighting tab and toggle your weightings for Toxicity, Exposure, and Emerging Concern depending on your research priorities. We have preset these but you control what you want to emphasise.",
    //      position: 'right'
    //    },
    //    {
    //      element: '#step5',
    //      intro: "Click on the Prioritisation tab and toggle your analysis depending on your research priorities.",
    //      position: 'right'
    //    },
    //    {
    //      element: '#analysisVisualization',
    //      intro: "Close the manage tab and view the visualisation of your analysis. The X-axis shows relative toxicity (consider this to represent effects on human health), and the Y-axis shows the relative likelihood of exposure. If there are information gaps, some of your chemicals may not be visually represented here but will show up in the Rank tab.",
    //      position: 'right'
    //    },
    //    {
    //      element: '#step7',
    //      intro: "Click on the Rank tab and view the chemical prioritisation list generated using your toggle factors. You can choose to order these using each of the column headings.",
    //      position: 'right'
    //    },
    //    {
    //      element: '#step8',
    //      intro: "Click on the Table tab and have a look at the data available to populate each chemical in the database. If you know there is more information available but it is not in the tool, you can submit additional information to our administrators for addition to the database once it's been approved by our moderators. To do that, click on the Add chemical tab under Chemicals in the Manage Tray and use the templates provided.",
    //      position: 'right'

    //    },
    //    {
    //      element: '#step9',
    //      intro: "When you add a chemical to the database,  <br /> it remains private to your organisation unless <br /> you click Make Public, in which case it will be <br /> approved by our moderators and then added to the <br /> chemical database for all users to view. <br /> <div class='analysis-pb' *ngIf='!isTrial'> Do not show this again  <input type='checkbox' [(ngModel)]='tourcheck' id='chkIsTourSelected' style='float: left; margin- right: 10px;'></div>",
    //      position: 'right'
    //    }
    //  ],
    //  showStepNumbers: true,
    //  showBullets: false,
    //  showProgress: true,
    //  exitOnOverlayClick: false
    //})

    //this.getGoogle();

    /* Set Chart Default options */
    this.isShowChart = false;
    this.chartOptions = {
      series: [],
      chart: {
        height: 550,
        type: "bubble",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
      },
      legend: {
        show: false
      }
    };

  }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    //this.LoadParameters();
    var currentURL = this.route.url;
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var isTourOpted = currentUser['isTourOpted'];
    this.defaultHide = false;
    this.reportData.threeWayPrioritisation = true;
    this.SetColumns();
    this.isSelected = true;
    this.SpinnerService.show();
    this.getDomain();
    this.GetAllReportList();
    this.ShowColumnsDiv();
    this.LoadCompanyAnalysisDefaultData();
    this.showColumnDivVal = false;
    this.getWeightCategoryList();
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });

    this.analysisForm = this.formBuilder.group({
      reportname: [this.reportData.reportName, [TrimValidators.required]]
    });

    this.companyId = localStorage.getItem("CompanyId");
    if (this.companyId && this.companyId != "null") {
      this.isCompanyAdmin = true;
    }
    //google.charts.load('current', { packages: ['corechart', 'table'] });
    //google.charts.load('current', { 'packages': ['corechart', 'controls'] });

    //this.RetrieveChemicalList('', 1, 10000, '', '', true);

    this.sub = this.route.params.subscribe(params => {
      this.k = params['k'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.type = params['type'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.urlChemicalId = params['urlChemicalId'];
    });

    this.dropdownSettings = {
      singleSelection: false,
      text: "Select ChemicalName",
      autoPosition: false,
      position: 'bottom',
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      enableCheckAll: false,
      tagToBody: true,
      classes: "form-control",
      badgeShowLimit: 3,
      searchBy: ['itemName', 'name', 'casnumber'],
      searchPlaceholderText: 'Search by Display Name, Synonyms, Cas-No',
      showCheckbox: false
    };
    this.selectedItems = [];

    //CommentOut
    //this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
    //this.getSocialURL();
    //this.RetrieveChemicalList('', 1, 1000, '', '', true);
    google.charts.load('current', { packages: ['corechart', 'table'] });
    $("#chart_noDate").html("No Chart Data Found.");
    $("#chart_multi").html("");
    isTourOpted = true;
    if (!isTourOpted) {
      //this.joyrideService.startTour(
      //  {
      //    steps: ['firstStep@analysis-dashboard', 'secondStep@analysis-dashboard', 'thirdStep@analysis-dashboard', 'fourthStep@analysis-dashboard', 'fifthStep@analysis-dashboard', 'sixthStep@analysis-dashboard', 'seventhStep@analysis-dashboard', 'eighthStep@analysis-dashboard', 'ninthStep@company-chemical-request'],
      //    logsEnabled: true
      //  } // Your steps order
      //);

      this.introJS.setOptions({
        steps: [
          {
            element: '#step1',
            intro: "Click on the Manage tab",
            position: 'top'
          },
          {
            element: '#srchChemical',
            intro: "Search by display name (just start typing), CAS#, or use category (for example, type fungicide and chemicals tagged as fungicides will be listed)",
            position: 'right'
          },
          {
            element: '#step3',
            intro: 'Select the chemicals you want to compare, and press Add To Chemical Group',
            position: 'right'
          },
          {
            element: '#step4',
            intro: "Click on the Weighting tab and toggle your weightings for Toxicity, Exposure, and Emerging Concern depending on your research priorities. We have preset these but you control what you want to emphasise.",
            position: 'top'
          },
          {
            element: '#step5',
            intro: "Click on the Prioritisation tab and toggle your analysis depending on your research priorities.",
            position: 'top'
          },
          {
            element: '#analysisVisualization',
            intro: "Close the manage tab and view the visualisation of your analysis. The X-axis shows relative toxicity (consider this to represent effects on human health), and the Y-axis shows the relative likelihood of exposure. If there are information gaps, some of your chemicals may not be visually represented here but will show up in the Rank tab.",
            position: 'right'
          },
          {
            element: '#step7',
            intro: "Click on the Rank tab and view the chemical prioritisation list generated using your toggle factors. You can choose to order these using each of the column headings.",
            position: 'right'
          },
          {
            element: '#step8',
            intro: "Click on the Table tab and have a look at the data available to populate each chemical in the database. If you know there is more information available but it is not in the tool, you can submit additional information to our administrators for addition to the database once it's been approved by our moderators. To do that, click on the Add chemical tab under Chemicals in the Manage Tray and use the templates provided.",
            position: 'right'
          },
          {
            element: 'a#a_companychemicalrequest',
            intro: "When you add a chemical to the database,  <br /> it remains private to your organisation unless <br /> you click Make Public, in which case it will be <br /> approved by our moderators and then added to the <br /> chemical database for all users to view. <br /> <div class='analysis-pb' *ngIf='!isTrial'> Do not show this again  <input type='checkbox' [(ngModel)]='tourcheck' id='chkIsTourSelected' style='float: left; margin- right: 10px;'></div>",
            position: 'left'
          }
        ],
        showStepNumbers: true,
        showBullets: false,
        showProgress: true,
        exitOnOverlayClick: false
      }).start();


      this.introJS.oncomplete(function () {
        document.getElementById("openUserMenu").classList.remove("show");
        document.getElementById("openUserMenu").classList.add("hide");
        document.getElementById("openUserMenu").style.display = "none";
        $("#hf_tourDone").trigger("click");
      }).onbeforechange(function (targetElement) {

        if (document.getElementById("openUserMenu").classList.contains("show")) {
          document.getElementById("openUserMenu").classList.remove("show");
          document.getElementById("openUserMenu").classList.add("hide");
          document.getElementById("openUserMenu").style.display = "none";
        }

        $('body').removeClass('helper-test');
        $(".introjs-tooltipReferenceLayer").removeClass('test-class');
        $(".test-class").parent().removeClass('helper-test');

        var stepId = $(targetElement).attr('id');

        if (this._direction == 'forward') {

          if (stepId == 'srchChemical' && this.defaultHide != true) {
            $(".Manage-btn").trigger("click");
            this.defaultHide = true;

            // setTimeout(function () {
            //   this.addDynamicTopPositionClass();
            // }, 5000);

            $(".introjs-tooltipReferenceLayer").addClass('test-class');
            $(".test-class").parent().addClass('helper-test');

          }

          if (stepId == 'step4') {
            $("#weightManage").trigger('click');
          }

          if (stepId == 'analysisVisualization' && this.defaultHide) {
            $(".Manage-btn").trigger("click");
            this.defaultHide = false;
          }

          if (this._currentStep == 3) {
            $("#weightManage").trigger('click');
          }

          if (this._currentStep == 4) {
            $("#prioritisationManage").trigger('click');
          }

          if (this._currentStep == 6) {
            $("#rankTabli").trigger('click');
          }

          if (this._currentStep == 7) {
            $("#tabletabli").trigger('click');
          }

          if (this._currentStep == 8) {

            $('body').removeClass('helper-test');
            $(".introjs-tooltipReferenceLayer").removeClass('test-class');
            $(".test-class").parent().removeClass('helper-test');
            if (document.getElementById("openUserMenu").classList.contains("hide")) {
              document.getElementById("openUserMenu").classList.remove("hide");
              document.getElementById("openUserMenu").classList.add("show");
              document.getElementById("openUserMenu").style.display = "block";
            }
          }

        } else {

          if (stepId == 'step5' && this.defaultHide != true) {
            $(".Manage-btn").trigger("click");
            this.defaultHide = true;

            $('body').addClass('helper-test-backward');
            $(".introjs-tooltipReferenceLayer").addClass('test-class-backward');
            $(".test-class").parent().addClass('test-class-backward');
          }

          if (this._currentStep <= 3) {
            $('body').removeClass('helper-test-backward');
            $(".introjs-tooltipReferenceLayer").removeClass('test-class-backward');
            $(".test-class").parent().removeClass('test-class-backward');
          }

          if (stepId == 'step3') {
            $("#a_Chemicals").trigger('click');
          }
          if (stepId == 'step1') {
          }
        }
      });

    }
  }

  addDynamicTopPositionClass() {
    $(".introjs-tooltipReferenceLayer").addClass('test-class');
    $(".test-class").parent().addClass('helper-test');
  }
  redirectTolast() {
    this.router.navigate(['/company-chemical-request'])
  }
  redirectToAnalysisDashboard() {
    this.router.navigate(['/analysis-dashboard']);
  }
  get f() { return this.analysisForm.controls; }

  onNext() {
    this.ClickManage();
  }

  stepDone() {

    var chkIsTourSelected = $("#chkIsTourSelected").prop("checked");
    if (chkIsTourSelected) {
      this.chemicalRequestService.updateTourChoice(true)
        .pipe(first())
        .subscribe(data => {
          if (data['status'] === true) {
            //this.toastr.success(data['message']);
            var currentUserData = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUserData) {
              currentUserData['isTourOpted'] = chkIsTourSelected;
              localStorage.setItem('currentUser', JSON.stringify(currentUserData));
            }
            this.router.navigate(['/analysis-dashboard']);
            //this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
          } else {
            //this.toastr.error(data['message']);
          }
        },
          error => {
            //this.toastr.error(error.error.message);
          });
    } else {
      //this.router.navigate(['/analysis-dashboard']);
    }
  }

  onThirdStepNext() {
    //this.ClickManage();
    $("#weightManage").trigger('click');
  };

  onFourthStepNext() {
    $("#prioritisationManage").trigger('click');
  }

  onSixthStepNext() {
    $("#rankTabli").trigger('click');
  }

  onSeventhStepNext() {
    $("#tabletabli").trigger('click');
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean) {

    this.SpinnerService.show();
    this.analysisService.getAllParameter(filter, startRow, endRow, sortColumn, sortOrder).subscribe(data => {
      this.chemicalList = data;
      this.EmergingList = data;
      this.getGoogle();
      $("#chart_single").html("");

      var chartWidth = $(".content_btm").width() + 50;
      var options = {
        Title: "Research Chart",
        tooltip: { isHtml: true },
        bubble: {
          textStyle: {
            fontSize: 10
          }
        },
        legend: { position: 'none' },
        width: chartWidth,
        height: 750,
        hAxis: {
          title: 'Toxicity'
        },
        vAxis: {
          title: 'Exposure'
        }
      };


      if (this.EmergingList.length < 1) {
        $("#chart_noDate").html("No Chart Data Found");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");
      }
      var data1 = new google.visualization.DataTable();
      data1.addColumn('string', "Name");
      data1.addColumn('number', "Toxicity");
      data1.addColumn('number', "Exposure");
      data1.addColumn('string', "Name");
      data1.addColumn('number', "Exposure");

      var Chart = new google.visualization.BubbleChart(document.getElementById('chart_single'));

      var recordData = [];
      $.each(this.EmergingList, function (key, value) {
        recordData.push([value["ChemicalName"], parseFloat(value["Toxicity Combined Score (Raw)"]), parseFloat(value["Exposure Combined Score (Raw)"]), value["ChemicalName"], parseFloat(value["Exposure Combined Score (Raw)"])]);
      });

      data1.addRows(recordData);
      if (this.EmergingList.length > 0) {
        Chart.draw(data1, options);
      }
      this.SpinnerService.hide();
    },
      error => {
      });
  }

  //fetchMore(event: any) {
  //  if (event.end === this.multiDropdownList.length - 1) {
  //    //this.loading = true;
  //    this.appService.getChunkData(this.multiDropdownList.length, this.bufferSize).then(chunk => {
  //      this.multiDropdownList = this.multiDropdownList.concat(chunk);
  //      this.loading = false;
  //    }, () => this.loading = false);
  //  }
  //}

  GetChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {
    this.SpinnerService.show();
    this.analysisService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.multiDropdownList = [];
      this.allChemicalDataList = data;
      //this.allChemicalDataList = this.allChemicalDataList.slice(0, 500);
      //for (var obj in this.allChemicalDataList) {
      //  let newName = {
      //    id: this.allChemicalDataList[obj].id,
      //    itemName: this.allChemicalDataList[obj].chemicalName,
      //    name: this.allChemicalDataList[obj].commonName,
      //    casnumber: this.allChemicalDataList[obj].casnumber,
      //    isChecked: false,
      //    isValid: this.allChemicalDataList[obj].isValid,
      //    categoryName: this.allChemicalDataList[obj].categoryName
      //  };
      //  this.multiDropdownList.push(newName);      
      //};

      this.multiDropdownList = this.allChemicalDataList.map((m) => {
        return {
          id: m.id,
          itemName: m.chemicalName,
          name: m.commonName,
          casnumber: m.casnumber,
          isChecked: false,
          isValid: m.isValid,
          categoryName: m.categoryName
        }
      });
      this.filteredChemicalOptions = Object.assign([], this.multiDropdownList);
      this.SpinnerService.hide();
    },
      error => {
        //alert("We faced some technical issues");
        this.SpinnerService.hide();
      });
  }

  backButton() {
    history.back();
  }

  DropdownBtnAdd(val) {
    this.showReportInput = true;
    this.editMode = true;
    this.defaultHide = true;
    var indexSel = this.selectedItems.indexOf(val);
    if (indexSel == -1) {
      this.selectedItems.push(val);
    }
  }

  RemoveDataFromList(item) {
    if (!environment.isTrial) {
      this.showReportInput = true;
      this.defaultHide = true;
      this.editMode = true;
    }
    var foundIndex;
    var fIndex;
    for (var i = 0; i < this.selectedChemicals.length; i++) {
      if (this.selectedChemicals[i].id == item.id) {
        fIndex = i;
      }
    }
    if (fIndex !== -1) {
      this.selectedChemicals.splice(fIndex, 1);
    }

    for (var i = 0; i < this.selectedItems.length; i++) {
      if (this.selectedItems[i].id == item.id) {
        foundIndex = i;
      }
    }
    if (foundIndex !== -1) {
      this.selectedItems.splice(foundIndex, 1);
    }
  }

  getCommonNameList(chemicalId: string = null) {
    this.chemicalService.getCommonNameList(chemicalId).pipe(first())
      .subscribe(p => {
        this.commonNameList = p;

        for (var obj in this.commonNameList) {
          this.AllCommonName = this.AllCommonName + this.commonNameList[obj].id + ",";
          let newName = {
            id: this.commonNameList[obj].id,
            itemName: this.commonNameList[obj].name
          };
          this.dropdownList.push(newName);
        };
        this.AllCommonName = this.AllCommonName.substring(0, this.AllCommonName.length - 1);

        if (this.commonNameList.length > 1) {
          this.SpinnerService.show();
          //this.GetMultiSocialDataList(chemicalId, this.AllCommonName.toString());
        }
        else {
          this.SpinnerService.show();
          //this.GetSocialDataList(chemicalId, this.AllCommonName.toString());
        }
      },
        error => {
          //this.toastr.error(error.message);
        });
  }

  search(event) {
    var keyword = event.target.value;
    $('.searchInput').val(keyword);
    if (keyword.length >= 3) {
      //this.RetrieveChemicalList(keyword, 1, 10, this.sortColumn, this.sortDirection, true);
      this.isShow = true;
    }
    else {
      this.chemicalList = null;
      this.isShow = false;
    }

  }

  //searchAnalysisReport(type) {
  //  var sortDirection = 'asc';
  //  var keyword = $('.searchAnalysisInput').val();
  //  if (type == "search") {
  //    if (keyword != null && keyword != "") {
  //      //this.RetrieveChemicalList(keyword, this.pager.startIndex, this.pager.startIndex + 10, this.sortColumn, this.sortDirection, true);
  //    }
  //    else if (keyword.length == 0) {
  //      //this.RetrieveChemicalList('', 1, 10, '', '', true);
  //    }
  //  }
  //  else {
  //    $('.searchInput').val('');
  //    //this.RetrieveChemicalList('', 1, 10, '', '', true);
  //  }
  //}

  SelectedChemical(id, chemicalName) {

    $("#chemicalName").val("");
    this.selectedChemicalId = null;
    this.selectedChemicalId = id;
    this.chemicalId = id;
    // this.GetEmergingDataList(id, null)
    $("#chemicalName").val(chemicalName);
    this.isShow = false;
    //this.GetEmergingDataList(id);
  }

  //onItemSelect(item: any) {
  //  $(".c-input").val("");
  //  this.multiDropdownList = [];
  //  for (var obj in this.allChemicalDataList) {
  //    let newName = {
  //      id: this.allChemicalDataList[obj].id,
  //      itemName: this.allChemicalDataList[obj].chemicalName,
  //      name: this.allChemicalDataList[obj].commonName,
  //      casnumber: this.allChemicalDataList[obj].casnumber
  //    };
  //    this.multiDropdownList.push(newName);
  //  };
  //}

  onSubmit() {
    let selectedChemicalId = "";
    //let fromDate = $('#fromDate').val();
    //let toDate = $('#toDate').val();
    if (this.selectedItems.length >= 1) {

      for (let i = 0; i < this.selectedItems.length; i++) {
        selectedChemicalId = selectedChemicalId + this.selectedItems[i]["id"] + ",";
      }
      selectedChemicalId = selectedChemicalId.substring(0, selectedChemicalId.length - 1);
      this.GetEmergingDataList(selectedChemicalId.toString());
    }
    else {
      if (this.selectedItems.length == 0) {
        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_multi").html("");
        $("#chart_single").html("");
        $("#chart_chemical").html("");
        selectedChemicalId = this.selectedItems[0]["id"];
        this.GetEmergingDataList(selectedChemicalId.toString());
      }


    }

  }

  onclear() {
    this.selectedItems = [];

    $("#chart_noDate").html("No Chart Data Found.");
    $("#chart_multi").html("");
    $("#chart_single").html("");
    $("#chart_chemical").html("");

    //this.GetSocialDataList(this.id, this.firstCommonName.toString(), null, null);
  }

  getSocialURL(): void {

    this.analysisService.getSocialURL().pipe(first()).subscribe(url => {
      this.socialURL = url;
    },
      error => {
        // this.toastr.error(error.message);
      });

  }

  public getWeightCategoryList() {
    this.weightCategoryService.getAll().pipe(first())
      .subscribe(p => {
        this.reportData.weightList = p;
        this.SetWeightView();
        //this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  SetWeightView() {
    for (var i = 0; i < this.reportData.weightList.length; i++) {
      if (this.reportData.weightList[i].code == "SC7") {
        this.weightageView.SC7_Id = this.reportData.weightList[i].id;
        this.weightageView.SC7_Code = this.reportData.weightList[i].code;
        this.weightageView.SC7_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC7_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC8") {
        this.weightageView.SC8_Id = this.reportData.weightList[i].id;
        this.weightageView.SC8_Code = this.reportData.weightList[i].code;
        this.weightageView.SC8_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC8_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC9") {
        this.weightageView.SC9_Id = this.reportData.weightList[i].id;
        this.weightageView.SC9_Code = this.reportData.weightList[i].code;
        this.weightageView.SC9_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC9_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC12") {
        this.weightageView.SC12_Id = this.reportData.weightList[i].id;
        this.weightageView.SC12_Code = this.reportData.weightList[i].code;
        this.weightageView.SC12_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC12_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC13") {
        this.weightageView.SC13_Id = this.reportData.weightList[i].id;
        this.weightageView.SC13_Code = this.reportData.weightList[i].code;
        this.weightageView.SC13_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC13_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC14") {
        this.weightageView.SC14_Id = this.reportData.weightList[i].id;
        this.weightageView.SC14_Code = this.reportData.weightList[i].code;
        this.weightageView.SC14_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC14_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC15") {
        this.weightageView.SC15_Id = this.reportData.weightList[i].id;
        this.weightageView.SC15_Code = this.reportData.weightList[i].code;
        this.weightageView.SC15_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC15_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC16") {
        this.weightageView.SC16_Id = this.reportData.weightList[i].id;
        this.weightageView.SC16_Code = this.reportData.weightList[i].code;
        this.weightageView.SC16_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC16_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC17") {
        this.weightageView.SC17_Id = this.reportData.weightList[i].id;
        this.weightageView.SC17_Code = this.reportData.weightList[i].code;
        this.weightageView.SC17_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC17_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC24") {
        this.weightageView.SC24_Id = this.reportData.weightList[i].id;
        this.weightageView.SC24_Code = this.reportData.weightList[i].code;
        this.weightageView.SC24_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC24_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC25") {
        this.weightageView.SC25_Id = this.reportData.weightList[i].id;
        this.weightageView.SC25_Code = this.reportData.weightList[i].code;
        this.weightageView.SC25_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC25_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SC26") {
        this.weightageView.SC26_Id = this.reportData.weightList[i].id;
        this.weightageView.SC26_Code = this.reportData.weightList[i].code;
        this.weightageView.SC26_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SC26_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG7") {
        this.weightageView.SG7_Id = this.reportData.weightList[i].id;
        this.weightageView.SG7_Code = this.reportData.weightList[i].code;
        this.weightageView.SG7_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG7_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG8") {
        this.weightageView.SG8_Id = this.reportData.weightList[i].id;
        this.weightageView.SG8_Code = this.reportData.weightList[i].code;
        this.weightageView.SG8_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG8_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG9") {
        this.weightageView.SG9_Id = this.reportData.weightList[i].id;
        this.weightageView.SG9_Code = this.reportData.weightList[i].code;
        this.weightageView.SG9_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG9_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG10") {
        this.weightageView.SG10_Id = this.reportData.weightList[i].id;
        this.weightageView.SG10_Code = this.reportData.weightList[i].code;
        this.weightageView.SG10_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG10_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG11") {
        this.weightageView.SG11_Id = this.reportData.weightList[i].id;
        this.weightageView.SG11_Code = this.reportData.weightList[i].code;
        this.weightageView.SG11_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG11_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG12") {
        this.weightageView.SG12_Id = this.reportData.weightList[i].id;
        this.weightageView.SG12_Code = this.reportData.weightList[i].code;
        this.weightageView.SG12_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG12_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG24") {
        this.weightageView.SG24_Id = this.reportData.weightList[i].id;
        this.weightageView.SG24_Code = this.reportData.weightList[i].code;
        this.weightageView.SG24_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG24_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SG25") {
        this.weightageView.SG25_Id = this.reportData.weightList[i].id;
        this.weightageView.SG25_Code = this.reportData.weightList[i].code;
        this.weightageView.SG25_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SG25_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK8") {
        this.weightageView.SK8_Id = this.reportData.weightList[i].id;
        this.weightageView.SK8_Code = this.reportData.weightList[i].code;
        this.weightageView.SK8_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK8_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK9") {
        this.weightageView.SK9_Id = this.reportData.weightList[i].id;
        this.weightageView.SK9_Code = this.reportData.weightList[i].code;
        this.weightageView.SK9_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK9_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK12") {
        this.weightageView.SK12_Id = this.reportData.weightList[i].id;
        this.weightageView.SK12_Code = this.reportData.weightList[i].code;
        this.weightageView.SK12_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK12_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK13") {
        this.weightageView.SK13_Id = this.reportData.weightList[i].id;
        this.weightageView.SK13_Code = this.reportData.weightList[i].code;
        this.weightageView.SK13_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK13_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK16") {
        this.weightageView.SK16_Id = this.reportData.weightList[i].id;
        this.weightageView.SK16_Code = this.reportData.weightList[i].code;
        this.weightageView.SK16_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK16_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK17") {
        this.weightageView.SK17_Id = this.reportData.weightList[i].id;
        this.weightageView.SK17_Code = this.reportData.weightList[i].code;
        this.weightageView.SK17_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK17_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK18") {
        this.weightageView.SK18_Id = this.reportData.weightList[i].id;
        this.weightageView.SK18_Code = this.reportData.weightList[i].code;
        this.weightageView.SK18_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK18_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK19") {
        this.weightageView.SK19_Id = this.reportData.weightList[i].id;
        this.weightageView.SK19_Code = this.reportData.weightList[i].code;
        this.weightageView.SK19_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK19_IsLock = false;
      }
      else if (this.reportData.weightList[i].code == "SK20") {
        this.weightageView.SK20_Id = this.reportData.weightList[i].id;
        this.weightageView.SK20_Code = this.reportData.weightList[i].code;
        this.weightageView.SK20_Weightage = this.reportData.weightList[i].weightage;
        this.weightageView.SK20_IsLock = false;
      }
    }
  }

  OnSliderChange(value, DependentSlider, currentModel) {
    if (!environment.isTrial) {
      this.showReportInput = true;
      this.editMode = true;
      this.defaultHide = true;
    }
    var deparray = [];
    var totalSum = 0;
    var splittedVal = DependentSlider.split(',');
    for (var j = 0; j < splittedVal.length; j++) {
      var modelVal = splittedVal[j].split('_')[0];
      if (this.weightageView[modelVal + "_IsLock"]) {
        totalSum = totalSum + this.weightageView[splittedVal[j]];
      }
      else {
        deparray.push(splittedVal[j]);
      }
    }
    if ((totalSum + value) > 100) {
      var depSliderSplit = DependentSlider.split(',');
      var totalValForRemainingSlider = depSliderSplit.reduce((acc, cur) => acc + (this.weightageView[cur]), 0);
      return this.weightageView[currentModel] = 100 - totalValForRemainingSlider;
    }
    else {
      var newArr = deparray.join(',');
      var remainingVal = 100 - (value + totalSum);
      var depSlider = newArr.split(',');
      var totalValForRemainingSlider;
      if (deparray.length == 0) {
        return this.weightageView[currentModel] = 100 - totalSum;
      }

      totalValForRemainingSlider = depSlider.reduce((acc, cur) => acc + this.weightageView[cur], 0);
      totalValForRemainingSlider = totalValForRemainingSlider == 0 ? 1 : totalValForRemainingSlider;
      var k = 0;
      var sum = 0;
      for (var i = 0; i < depSlider.length; i++) {
        var weightVal = this.weightageView[depSlider[i]] == 0 ? 1 : this.weightageView[depSlider[i]];
        var intVal = Number.isInteger((weightVal * remainingVal) / totalValForRemainingSlider);

        if (!intVal) {
          k++;
        }

        if (k == depSlider.length) {
          this.weightageView[depSlider[i]] = 100 - (totalSum + value + sum);
        }
        else {
          this.weightageView[depSlider[i]] = Math.round((weightVal * remainingVal) / totalValForRemainingSlider);
        }

        if (i <= depSlider.length - 2) {
          sum = sum + this.weightageView[depSlider[i]];
        }
      }

    }
  }

  ToggleLock(e, model) {
    if (!environment.isTrial) {
      this.showReportInput = true;
      this.editMode = true;
      this.defaultHide = true;
    }
    if ($(e.target).hasClass("fa-unlock")) {
      $(e.target).removeClass("fa-unlock");
      $(e.target).addClass("fa-lock");
      $(e.target.previousSibling).prop("disabled", true);
      $(e.target.previousSibling.previousSibling).prop("disabled", true);
      this.weightageView[model] = true;
    } else {
      $(e.target).removeClass("fa-lock");
      $(e.target).addClass("fa-unlock");
      $(e.target.previousSibling).prop("disabled", false);
      $(e.target.previousSibling.previousSibling).prop("disabled", false);
      this.weightageView[model] = false;
    }
  }

  ToggleLockExposure(e, model) {
    if (!environment.isTrial) {
      this.showReportInput = true;
      this.editMode = true;
      this.defaultHide = true;
    }
    if (this.reportData.threeWayPrioritisation) {
      if ($(e.target).hasClass("fa-unlock")) {
        $(e.target).removeClass("fa-unlock");
        $(e.target).addClass("fa-lock");
        $(e.target.previousSibling).prop("disabled", true);
        $(e.target.previousSibling.previousSibling).prop("disabled", true);
        this.weightageView[model] = true;
      } else {
        $(e.target).removeClass("fa-lock");
        $(e.target).addClass("fa-unlock");
        $(e.target.previousSibling).prop("disabled", false);
        $(e.target.previousSibling.previousSibling).prop("disabled", false);
        this.weightageView[model] = false;
      }
    }
  }

  CalculateWeightForTwoWay() {
    var totalSum = this.weightageView.SC24_Weightage + this.weightageView.SC25_Weightage;
    this.weightageView.SG24_Weightage = Math.round((this.weightageView.SC24_Weightage * 100) / totalSum);
    this.weightageView.SG25_Weightage = Math.floor((this.weightageView.SC25_Weightage * 100) / totalSum);
  }

  PrepareListForSaveWeight() {
    for (var i = 0; i < this.reportData.weightList.length; i++) {
      if (this.reportData.weightList[i].code == "SC7") {
        this.reportData.weightList[i].weightage = this.weightageView.SC7_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC8") {
        this.reportData.weightList[i].weightage = this.weightageView.SC8_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC9") {
        this.reportData.weightList[i].weightage = this.weightageView.SC9_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC12") {
        this.reportData.weightList[i].weightage = this.weightageView.SC12_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC13") {
        this.reportData.weightList[i].weightage = this.weightageView.SC13_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC14") {
        this.reportData.weightList[i].weightage = this.weightageView.SC14_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC15") {
        this.reportData.weightList[i].weightage = this.weightageView.SC15_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC16") {
        this.reportData.weightList[i].weightage = this.weightageView.SC16_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC17") {
        this.reportData.weightList[i].weightage = this.weightageView.SC17_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC24") {
        this.reportData.weightList[i].weightage = this.weightageView.SC24_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC25") {
        this.reportData.weightList[i].weightage = this.weightageView.SC25_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SC26") {
        this.reportData.weightList[i].weightage = this.weightageView.SC26_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG7") {
        this.reportData.weightList[i].weightage = this.weightageView.SG7_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG8") {
        this.reportData.weightList[i].weightage = this.weightageView.SG8_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG9") {
        this.reportData.weightList[i].weightage = this.weightageView.SG9_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG10") {
        this.reportData.weightList[i].weightage = this.weightageView.SG10_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG11") {
        this.reportData.weightList[i].weightage = this.weightageView.SG11_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG12") {
        this.reportData.weightList[i].weightage = this.weightageView.SG12_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG24") {
        this.reportData.weightList[i].weightage = this.weightageView.SG24_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SG25") {
        this.reportData.weightList[i].weightage = this.weightageView.SG25_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK8") {
        this.reportData.weightList[i].weightage = this.weightageView.SK8_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK9") {
        this.reportData.weightList[i].weightage = this.weightageView.SK9_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK12") {
        this.reportData.weightList[i].weightage = this.weightageView.SK12_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK13") {
        this.reportData.weightList[i].weightage = this.weightageView.SK13_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK16") {
        this.reportData.weightList[i].weightage = this.weightageView.SK16_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK17") {
        this.reportData.weightList[i].weightage = this.weightageView.SK17_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK18") {
        this.reportData.weightList[i].weightage = this.weightageView.SK18_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK19") {
        this.reportData.weightList[i].weightage = this.weightageView.SK19_Weightage;
      }
      else if (this.reportData.weightList[i].code == "SK20") {
        this.reportData.weightList[i].weightage = this.weightageView.SK20_Weightage;
      }
    }
  }

  RetrieveChemicalListForPrioritiseTab() {

    console.clear()
    console.log("Printing data for Rank....")
    console.log(this.chemicalList[0])
    this.dataMessageResults = this.chemicalList;
    this.SetColumns();

    const sortState: Sort = { active: 'Three way ranking (raw scores)', direction: 'desc' };
    this.sort(sortState);

  }

  getHeaders() {
    let headers: string[] = ["Chemical Name", "Toxicity Combined Score (Raw)", "Exposure Combined Score (Raw)", "Emerging Concern Score (raw)", "Chemical Ranking (Tox, Exp, Em Con) (weighted scores)", "Chemical ranking (Toxicity and Exposure) (weighted)", "Three way ranking (raw scores)"];
    //if (this.dataMessageResults) {
    //  this.dataMessageResults.forEach((value) => {
    //    Object.keys(value).forEach((key) => {
    //      if (!headers.find((header) => header == key)) {
    //        headers.push(key)
    //      }
    //    })
    //  })
    //}
    return headers;
  }

  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.dataMessageResults.length, page);
    this.pagedItems = this.dataMessageResults.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.dataMessageResults.slice();
    if (!sort.active || sort.direction === '') {
      this.dataMessageResults = data;
      return;
    }
    this.dataMessageResults = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Chemical Name':
          return compare(a["Chemical Name"].toLowerCase(), b["Chemical Name"].toLowerCase(), isAsc);
        case 'Toxicity Combined Score (Raw)':
          return compare(parseFloat(a["Toxicity Combined Score (Raw)"]), parseFloat(b["Toxicity Combined Score (Raw)"]), isAsc);
        case 'Exposure Combined Score (Raw)':
          return compare(parseFloat(a["Exposure Combined Score (Raw)"]), parseFloat(b["Exposure Combined Score (Raw)"]), isAsc);
        case 'Emerging Concern Score (raw)':
          return compare(parseFloat(a["Emerging Concern Score (raw)"]), parseFloat(b["Emerging Concern Score (raw)"]), isAsc);
        case 'Chemical Ranking (Tox, Exp, Em Con) (weighted scores)':
          return compare(parseFloat(a["Chemical Ranking (Tox, Exp, Em Con) (weighted scores)"]), parseFloat(b["Chemical Ranking (Tox, Exp, Em Con) (weighted scores)"]), isAsc);
        case 'Chemical ranking (Toxicity and Exposure) (weighted)':
          return compare(parseFloat(a["Chemical ranking (Toxicity and Exposure) (weighted)"]), parseFloat(b["Chemical ranking (Toxicity and Exposure) (weighted)"]), isAsc);
        case 'Three way ranking (raw scores)':
          return compare(parseFloat(a["Three way ranking (raw scores)"]), parseFloat(b["Three way ranking (raw scores)"]), isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

  GetEmergingDataList(chemicalId: string = null) {
    this.SpinnerService.show();
    this.analysisService.getAllEmerging(chemicalId, "1").subscribe(data => {

      $("#chart_multi").html("");
      $("#chart_single").html("");
      this.getGoogle();
      var chartWidth = $(".content_btm").width() - 50;
      var optionsEmerging = {
        tooltip: { isHtml: true },
        legend: { position: 'Left' },
        width: chartWidth,
        height: 600,
        hAxis: {
          title: 'Time'
        },
        vAxis: {
          title: 'Score'
        }
      };

      this.EmergingChemicalList = data;
      if (this.EmergingChemicalList.length < 1) {

        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_chemical").html("");
        $("#chart_multi").html("");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");

      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      this.EmergingChemicalList.forEach((value) => {

        Object.keys(value).forEach((key) => {

          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })

        count = count + 1;
      })

      let mainValue = [];
      let dataList: string[] = [];

      this.EmergingChemicalList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);

      })
      // Add data.
      data1.addRows(mainValue);
      var chartChemical = new google.visualization.LineChart(document.getElementById('chart_chemical'));
      if (this.EmergingChemicalList.length > 0) {
        chartChemical.draw(data1, optionsEmerging);
      }

      google.visualization.events.addListener(chartChemical, 'select', selectHandler);

      var cid = "";
      var currentClass = this;
      function selectHandler() {
        var selection = chartChemical.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(currentClass.allChemicalDataList, function (el) {

            return el.chemicalName == str;
          });
          cid = selectedDate[0].id;

        }
        currentClass.chemicalId = cid;
        currentClass.getCommonNameList(cid);
        //currentClass.GetSocialDataList(currentClass.id, cid.toString());

      }

      this.SpinnerService.hide();

    },
      error => {
        // alert("We faced some technical issues");
      });
    this.SpinnerService.hide();
  }

  onAddChemicalClick(id) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      if (id != '' && id != "" && id != null) {
        this.router.navigate(['/add-chemical', id, '']);
      }
      else {
        this.router.navigate(['/add-chemical', '', '']);
      }
    }
  }

  ShowHideColumns(eve) {
    var e = eve.target;
    var index = this.columnsToDisplay.indexOf(e.id);
    var atIndex = 5 + this.displayedColumns.findIndex(x => x.id == e.id);
    var objData = { "id": e.id, "isChecked": true };
    if (index != -1) {
      this.columnsToDisplay.splice(index, 1);
    }
    else {
      this.columnsToDisplay.splice(atIndex, 0, e.id);
    }
  }

  ShowAllColumnsMethod(eve) {
    var e = eve.target;
    var allcheckBoxes = $("#columnsForLoop").children().find(".chkInput");
    if (e.checked) {
      this.columnsToDisplay = ["Chemical Name", "CAS NO.", "Common Name", "Molecular Weight", "Category"];
      this.displayedColumns = [];
      for (var i = 0; i < allcheckBoxes.length; i++) {
        var objCheck = { "id": allcheckBoxes[i].id, "isChecked": true, "measure": allcheckBoxes[i].dataset["measure"] };
        this.displayedColumns.push(objCheck);
        this.columnsToDisplay.push(allcheckBoxes[i].id);
      }
    }
    else {
      this.columnsToDisplay = ["Chemical Name", "CAS NO.", "Common Name", "Molecular Weight", "Category"];
      this.displayedColumns = [];
      for (var i = 0; i < allcheckBoxes.length; i++) {
        var objCheck = { "id": allcheckBoxes[i].id, "isChecked": false, "measure": allcheckBoxes[i].dataset["measure"] };
        this.displayedColumns.push(objCheck);
      }
    }
  }

  ShowColumnsDiv() {
    if ($("#showHideDiv").hasClass("show")) {
      $("#showHideDiv").addClass("unshow");
      $("#showHideDiv").removeClass("show");
      this.showColumnDivVal = false;
    }
    else {
      $("#showHideDiv").removeClass("unshow");
      $("#showHideDiv").addClass("show");
      this.showColumnDivVal = true;
    }
  }

  SaveReportData(reportType) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      this.submitted = true;
      if (this.analysisForm.invalid) {
        return;
      }

      this.reportData.chemicalsList = this.selectedItems;
      this.reportData.socialMonitoringChemicalList = this.reportData.socialMonitoringChemicalList.filter(x => x.isChecked == true);
      this.PrepareListForSaveWeight();
      this.PrepareColumnList();
      this.reportService.create(this.reportData)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.GetAllReportList();
              var res = data;
              if (res['responseDetail']['id'] != "00000000-0000-0000-0000-000000000000") {
                this.reportData.id = res['responseDetail']['id'];
              }
              if (this.reportData.id != undefined && this.reportData.id != "00000000-0000-0000-0000-000000000000") {
                this.GetReportData(this.reportData, reportType, "");
              }
              else {
                this.LoadCompanyAnalysisDefaultData();
              }
              this.toastr.success(data['message']);
              this.getWeightCategoryList();
            } else {
              this.loading = false;
            }
          },
          error => {
            // this.toastr.error(error.message);
            this.loading = false;
            // this.dialogRef.close(false);
          });
    }
  }

  SaveAnalysisCopy() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      this.reportData.chemicalsList = this.selectedItems;
      this.reportData.socialMonitoringChemicalList = this.reportData.socialMonitoringChemicalList.filter(x => x.isChecked == true);
      this.PrepareListForSaveWeight();
      this.reportService.createCopy(this.reportData)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.GetAllReportList();
              var res = data;
              if (res['responseDetail']['id'] != "00000000-0000-0000-0000-000000000000") {
                this.reportData.id = res['responseDetail']['id'];
              }
              if (this.reportData.id != undefined && this.reportData.id != "00000000-0000-0000-0000-000000000000") {
                this.GetReportData(this.reportData, "nonedit", "");
              }
              else {
                this.LoadCompanyAnalysisDefaultData();
              }
              this.toastr.success(data['message']);
              this.getWeightCategoryList();
            }
            else if (data['status'] == false) {
              this.toastr.warning(data['message']);
              this.loading = false;
            }
          },
          error => {
            // this.toastr.error(error.message);
            this.loading = false;
            // this.dialogRef.close(false);
          });
    }

  }

  GetAllReportList() {
    this.SpinnerService.show();
    this.reportService.getAllByCompany().pipe(first())
      .subscribe(p => {
        if (p["message"] != "No record(s) found.") {
          this.reportList = p;
          this.reportList.forEach((rl: any) => rl.reportEditInput = false);
        }
        //this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  GetReportData(report, type, reportType) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      this.SpinnerService.show();
      this.showReportInput = true;
      this.defaultHide = false;
      if (type == "edit") {
        report.reportEditInput = true;
        this.editMode = true;
      }
      else if (type == "nonedit") {
        report.reportEditInput = false;
        this.editMode = false;
      }
      if (reportType == "History") {
        this.reportService.getHistoryById(report.id).pipe(first()).subscribe(Data => {
          this.reportData = Data;
          localStorage.setItem("reportId", this.reportData.id);
          this.setDefaultValueForSocialMonitoring();
          this.setDefaultDomain();
          this.SetWeightView();
          this.SetChemicalList();
          this.SpinnerService.hide();
        });
      } else {
        this.reportService.getById(report.id).pipe(first()).subscribe(Data => {
          this.reportData = Data;
          localStorage.setItem("reportId", this.reportData.id);
          this.setDefaultValueForSocialMonitoring();
          this.setDefaultDomain();
          this.SetWeightView();
          this.SetChemicalList();
          this.SpinnerService.hide();
        });
      }
    }

  }

  SetChemicalList() {
    this.selectedItems = [];
    for (var i = 0; i < this.reportData.chemicalsList.length; i++) {
      let newName = {
        id: this.reportData.chemicalsList[i].id,
        itemName: this.reportData.chemicalsList[i].chemicalName,
        casnumber: this.reportData.chemicalsList[i].casnumber
      };
      this.selectedItems.push(newName);
    }
  }

  DeleteReportById(report) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      this.SpinnerService.show();
      this.reportService.delete(report.id)
        .pipe(first())
        .subscribe(data => {
          if (data['status'] === true) {
            this.toastr.success(data['message']);
            this.GetAllReportList();
            this.LoadCompanyAnalysisDefaultData();
          } else {
            this.toastr.error(data['message']);
          }
        },
          error => {
            this.toastr.error(error.error.message);
          });
    }
  }

  UpdateAnalysisFav(eve, report) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      this.SpinnerService.show();
      var cls = eve.target.className;
      if (cls.indexOf("fav") != -1) {
        report.isFavourite = false;
      } else {
        report.isFavourite = true;
      }
      this.reportService.updateReportFav(report)
        .pipe(first())
        .subscribe(data => {
          if (data['status'] === true) {
            this.toastr.success(data['message']);
            this.GetAllReportList();
          } else {
            this.toastr.error(data['message']);
          }
          this.SpinnerService.hide();
        },
          error => {
            this.toastr.error(error.error.message);
          });
      this.SpinnerService.hide();
    }
  }

  LoadCompanyAnalysisDefaultData() {
    this.SpinnerService.show();
    this.reportService.getDefaultAnalysisData().pipe(first())
      .subscribe(p => {
        if (p['message'] != "No record(s) found.") {
          this.reportData = p;
          this.setDefaultValueForSocialMonitoring();
          this.setDefaultDomain();
          this.SetWeightView();
          this.LoadChartForReport();
        }
        if (this.reportData.chemicalsList != null) {
          $("#chartHide").hide();
          $("#chart_single_visualise").hide();
        } else {
          $("#chartHide").show();
          $("#chart_single_visualise").hide();
        }
        if (p['message'] == "No record(s) found.") {
          this.toastr.warning(p['message']);
          this.SpinnerService.hide();
        }
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  //@Output('ngModelChange') update = new EventEmitter();

  myCrazyCallback(e) {
    alert('dddd');
  }

  getHideButton(e) {
    this.defaultHide = e;
    this.editMode = e;
    this.showReportInput = e;
  }

  LoadChartForReport() {
    this.SpinnerService.show();
    this.isShowChart = false;
    localStorage.removeItem("base64Str");
    this.reportExportUrl = environment.apiUrl + "TestPdf/DownloadPdf?reportId=" + this.reportData.id;
    this.reportData.domainId = this.selectedDomainId != null && this.selectedDomainId != "" ? this.selectedDomainId : null;
    if (this.reportData.domainId == null && this.domainList && this.domainList.length > 0) {
      this.selectedDomainId = this.domainList[0].id;
      this.reportData.domainId = this.domainList[0].id;
    }
    localStorage.setItem("domainId", this.selectedDomainId);
    console.log("rp>>>>> ", this.reportData);
    // this.getSearchStatus = this.reportData.chemicalsList;

    //code for AI data
    this.reportService.getChemicalsECScore(this.reportData.chemicalsList).subscribe(data => {
      this.ecScoreForChartList = data;
    },
      error => {
      });
    //code end for AI data

    //code for regular chart data
    this.reportService.getAllParameterValues(this.reportData).subscribe(data => {
      this.chemicalList = data;
      this.EmergingList = data;
      //this.EmergingList = Object.assign([], this.chemicalList);
      this.BindRankTable();
      this.dataSource = new MatTableDataSource(this.chemicalList);
      //this.data1 = Object.assign([], this.chemicalList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sorting;
      this.displayedColumns = [];
      this.columnsToDisplay = ["Chemical Name", "CAS NO.", "Common Name", "Molecular Weight", "Category"];
      this.SetColumns();
      //this.getGoogle();
      $("#chart_single_visualise").html("");

      var testData = [];
      $.each(this.EmergingList, function (key, value) {
        testData.push(parseFloat(value["Exposure Combined Score (Raw)"]));
      });
      var ticksArray = testData.sort();
      var chartWidth = $(".content_btm").width() - 50;
      var options = {
        Title: "Research Chart",
        tooltip: { isHtml: true },
        bubble: {
          textStyle: {
            fontSize: 10
          }
        },
        legend: { position: 'none' },
        width: chartWidth,
        height: 750,
        hAxis: {
          title: 'Relative Toxicity'
        },
        vAxis: {
          title: 'Relative Likelihood of Exposure',
          //gridlines: { count: 4 },
          //ticks: ticksArray,
        }
      };
      //viewWindow: {
      //  //max: yAxisMaxVal
      //},

      if (this.EmergingList.length < 1) {
        $("#chart_noDate").html("No Chart Data Found");
        $("#chart_single_visualise").html("");
      }
      else {
        $("#chart_noDate").html("");
      }
      var data1 = new google.visualization.DataTable();
      data1.addColumn('string', "Name");
      data1.addColumn('number', "Toxicity");
      data1.addColumn('number', "Exposure");
      data1.addColumn('string', "Name");
      data1.addColumn('number', "Exposure");

      var container = document.getElementById('chart_single_visualise');
      //var container1 = document.getElementById('chart_single_visualise1');
      var Chart = new google.visualization.BubbleChart(container);
      //var Chart1 = new google.visualization.BubbleChart(container1);


      let headers: string[] = [];

      for (var k in this.EmergingList[0]) {
        headers.push(k);
      }

      this.RetrieveChemicalListForPrioritiseTab();


      var recordData = [];
      var tempRecordData = [];
      $.each(this.EmergingList, function (key, value) {
        if (parseFloat(value["Toxicity Combined Score (Raw)"]) && parseFloat(value["Exposure Combined Score (Raw)"])) {
          recordData.push([value["Chemical Name"], parseFloat(value["Toxicity Combined Score (Raw)"]), parseFloat(value["Exposure Combined Score (Raw)"]), value["Chemical Name"], parseFloat(value["Exposure Combined Score (Raw)"])]);

          tempRecordData.push([value["Chemical Name"], parseFloat(value["Toxicity Combined Score (Weighted)"]), parseFloat(value["Exposure Combined (Weighted Score)"]), parseFloat(value["Emerging Concern Score (weighted)"])]);
        }
      });
      data1.addRows(recordData);
      // console.log('tempRecordData');
      // console.log(tempRecordData);
      var base64str;
      google.visualization.events.addListener(Chart, 'ready', function () {
        container.className = '';
        base64str = Chart.getImageURI();
      });
      if (this.EmergingList.length > 0) {
        Chart.clearChart();
        Chart.draw(data1, options);
        var axis = Chart.getChartLayoutInterface().getVAxisValue();
        console.log(recordData);
        //console.log(axis);
        //Chart1.draw(data1, options);

      }

      google.visualization.events.addListener(Chart, 'select', selectHandler);

      var cid = "";
      var currentClass = this;
      function selectHandler() {
        var selection = Chart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = currentClass.EmergingList[item.row];
          cid = str.Id;
        }
        var currentAbsoluteUrl = window.location.href;
        var currentRelativeUrl = currentClass.router.url;
        var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
        var baseUrl = currentAbsoluteUrl.substring(0, index);
        const url = baseUrl + "/view-chemical-details/" + cid;
        window.open(url, "_blank");
        //currentClass.router.navigate(['/view-chemical-details', cid]);

      }

      this.showReportInput = true;
      if (!this.directAnalysis) {
        this.SetChemicalList();
      }
      let formData: FormData;
      formData = new FormData();
      formData.append('imageFile', base64str);
      formData.append('reportId', this.reportData.id);
      //this.reportExcelExportUrl = base64str;
      $("#chartHide").hide();
      $("#chart_single_visualise").hide();
      this.SpinnerService.hide();


      /* Start */
      this.bubbleFillColor = [];
      this.seriesData = [];
      this.seriesTooltipData = [];
      this.partialDataChemicalList = [];
      this.EmergingList.forEach(item => {
        var toxicityValue = item["Toxicity Combined Score (Raw)"];
        var exposureValue = item["Exposure Combined Score (Raw)"];
        var toolTipMsg = "";
        if (item["Normalised (Acute Oral Toxicity (Mammal, LD50)"] == null && Number(item["Normalised (Acute Oral Toxicity (Mammal, LD50)"]) == 0
          && item["Normalised (Acute Dermal Toxicity (Mammal, LD50))"] == null && Number(item["Normalised (Acute Dermal Toxicity (Mammal, LD50))"]) == 0
          && item["Normalised (Acute Inhalation Toxicity (Mammalian Gas, LC50))"] == null && Number(item["Normalised (Acute Inhalation Toxicity (Mammalian Gas, LC50))"]) == 0
          && item["Normalised (Acute Inhalation Toxicity (Mammalian Vapour, LC50))"] == null && Number(item["Normalised (Acute Inhalation Toxicity (Mammalian Vapour, LC50))"]) == 0
          && item["Normalised (Acute Inhalation Toxicity (Mammalian Dusts and Mists, LC50))"] == null && Number(item["Normalised (Acute Inhalation Toxicity (Mammalian Dusts and Mists, LC50))"]) == 0
          && item["Normalised (OSHA PEL, TWA))"] == null && Number(item["Normalised (OSHA PEL, TWA))"]) == 0
          && item["Normalised (NIOSH REL, TWA)"] == null && Number(item["Normalised (NIOSH REL, TWA)"]) == 0
          && item["Normalised (NIOSH IDLH)"] == null && Number(item["Normalised (NIOSH IDLH)"]) == 0
          && item["Normalised (TOST (SE) (Oral, Rat))"] == null && Number(item["Normalised (TOST (SE) (Oral, Rat))"]) == 0
          && item["Normalised (TOST (RE) (Oral, Rat))"] == null && Number(item["Normalised (TOST (RE) (Oral, Rat))"]) == 0
          && item["Normalised (NOEL (Oral, Rat))"] == null && Number(item["Normalised (NOEL (Oral, Rat))"]) == 0
          && item["Normalised (Australian Drinking Water Guidelines)"] == null && Number(item["Normalised (Australian Drinking Water Guidelines)"]) == 0
          && item["Normalised (LC50, freshwater fish, 96hr)"] == null && Number(item["Normalised (LC50, freshwater fish, 96hr)"]) == 0
          && item["Normalised (EC50, Daphnia sp, 48hr test)"] == null && Number(item["Normalised (EC50, Daphnia sp, 48hr test)"]) == 0
          && item["Normalised (EC50, freshwater algae, 72-96 hr test)"] == null && Number(item["Normalised (EC50, freshwater algae, 72-96 hr test)"]) == 0
          && item["Normalised (US EPA CMC freshwater)"] == null && Number(item["Normalised (US EPA CMC freshwater)"]) == 0
          && item["Normalised (US EPA CMC saltwater)"] == null && Number(item["Normalised (US EPA CMC saltwater)"]) == 0
          && item["Normalised (Invertebrate (NOEC, 21d))"] == null && Number(item["Normalised (Invertebrate (NOEC, 21d))"]) == 0
          && item["Normalised (Fish (NOEC, 28-40d))"] == null && Number(item["Normalised (Fish (NOEC, 28-40d))"]) == 0
          && item["Normalised (Algae, NOEC)"] == null && Number(item["Normalised (Algae, NOEC)"]) == 0
          && item["Normalised (ANZECC freshwater)"] == null && Number(item["Normalised (ANZECC freshwater)"]) == 0
          && item["Normalised (ANZECC saltwater)"] == null && Number(item["Normalised (ANZECC saltwater)"]) == 0
          && item["Normalised (US EPA CCC freshwater)"] == null && Number(item["Normalised (US EPA CCC freshwater)"]) == 0
          && item["Normalised (US EPA CCC saltwater)"] == null && Number(item["Normalised (US EPA CCC saltwater)"]) == 0
          && item["Normalised (Soil DT50)"] == null && Number(item["Normalised (Soil DT50)"]) == 0
          && item["Normalised (Water DT50)"] == null && Number(item["Normalised (Water DT50)"]) == 0
          && item["Normalised (Ready Biodegradable)"] == null && Number(item["Normalised (Ready Biodegradable)"]) == 0
          && item["Normalised (Koc)"] == null && Number(item["Normalised (Koc)"]) == 0
          && item["Normalised (Henry’s Law Constant)"] == null && Number(item["Normalised (Henry’s Law Constant)"]) == 0
          && item["Normalised (BCF)"] == null && Number(item["Normalised (BCF)"]) == 0
          && item["Normalised (Kow)"] == null && Number(item["Normalised (Kow)"]) == 0
          && item["Normalised (Volume Imp/Manu Australia)"] == null && Number(item["Normalised (Volume Imp/Manu Australia)"]) == 0
          && item["Normalised (Total \"toxicology\" research)"] == null && Number(item["Normalised (Total \"toxicology\" research)"]) == 0
          && item["Weighted Score (Total \"toxicology\" research)"] == null && Number(item["Weighted Score (Total \"toxicology\" research)"]) == 0
          && item["Normalised (Publication growth rate \"toxicology\")"] == null && Number(item["Normalised (Publication growth rate \"toxicology\")"]) == 0
          && item["Normalised (Total \"environmental studies\" research)"] == null && Number(item["Normalised (Total \"environmental studies\" research)"]) == 0
          && item["Weighted Score (Total \"environmental studies\" research)"] == null && Number(item["Weighted Score (Total \"environmental studies\" research)"]) == 0
          && item["Normalised (Publication growth rate \"environmental studies\")"] == null && Number(item["Normalised (Publication growth rate \"environmental studies\")"]) == 0
          && item["Weighted Score (Publication growth rate \"environmental studies\")"] == null && Number(item["Weighted Score (Publication growth rate \"environmental studies\")"]) == 0) {
          toolTipMsg = "Too few parameters to display meaningfully";
          this.partialDataChemicalList.push({ name: item["Chemical Name"], id: item["Id"], toolTip: toolTipMsg });
        }
        else if (toxicityValue == null || Number(toxicityValue) == 0 || exposureValue == null || Number(exposureValue) == 0) {
          if ((toxicityValue == null && exposureValue == null) || (Number(toxicityValue) == 0 && Number(exposureValue) == 0)) {
            toolTipMsg = "Insufficient toxicity and exposure data";
          }
          else if ((toxicityValue == null && exposureValue != null) || (Number(toxicityValue) == 0 && Number(exposureValue) != 0)) {
            toolTipMsg = "Insufficient toxicity data";
          }
          else if ((toxicityValue != null && exposureValue == null) || (Number(toxicityValue) != 0 && Number(exposureValue) == 0)) {
            toolTipMsg = "Insufficient exposure data";
          }
          this.partialDataChemicalList.push({ name: item["Chemical Name"], id: item["Id"], toolTip: toolTipMsg });
        }
        else {
          this.seriesData.push({
            name: item["Chemical Name"],
            data: [[parseFloat(item["Toxicity Combined Score (Raw)"]), parseFloat(item["Exposure Combined Score (Raw)"]),
            parseFloat(item["Emerging Concern Score (raw)"]) + .1]], id: item["Id"], numberoftoxpara: item["ToxicityCount"], numberofexppara: item["ExposureCount"]
          });
          this.seriesTooltipData.push({
            name: item["Chemical Name"],
            data: [[parseFloat(item["Toxicity Combined Score (Raw)"]), parseFloat(item["Exposure Combined Score (Raw)"]),
            parseFloat(item["Emerging Concern Score (raw)"])]], id: item["Id"], numberoftoxpara: item["ToxicityCount"], numberofexppara: item["ExposureCount"]
          });

          var calculatedFormulaValue = (parseFloat(item["Toxicity Combined Score (Raw)"]) * parseFloat(item["Exposure Combined Score (Raw)"]));

          if (calculatedFormulaValue >= environment.bubbleChartValue1 && calculatedFormulaValue <= environment.bubbleChartValue2) { this.bubbleFillColor.push(environment.bubbleChartColorValue1); }
          else if (calculatedFormulaValue > environment.bubbleChartValue2 && calculatedFormulaValue <= environment.bubbleChartValue3) { this.bubbleFillColor.push(environment.bubbleChartColorValue2); }
          else if (calculatedFormulaValue > environment.bubbleChartValue3 && calculatedFormulaValue <= environment.bubbleChartValue4) { this.bubbleFillColor.push(environment.bubbleChartColorValue3); }
          else if (calculatedFormulaValue > environment.bubbleChartValue4 && calculatedFormulaValue <= environment.bubbleChartValue5) { this.bubbleFillColor.push(environment.bubbleChartColorValue4); }
          else { this.bubbleFillColor.push(environment.bubbleChartColorValue4); }
        }
      });
      console.log(this.seriesData);


      this.seriesDataTemp = [];
      this.seriesTooltipDataTemp = [];
      this.seriesDataTemp = this.seriesData;
      this.seriesTooltipDataTemp = this.seriesTooltipData;
      var self = this;
      if (this.EmergingList != null && this.EmergingList.length > 0) {
        this.isShowChart = true;
        //this.noOfTick = this.seriesData.length;
        this.xAxisMaxVal = (Math.max.apply(Math, this.EmergingList.map(function (o) { return o["Toxicity Combined Score (Raw)"]; })));
        this.yAxisMaxVal = (Math.max.apply(Math, this.EmergingList.map(function (o) { return o["Exposure Combined Score (Raw)"]; })));

        console.log(" Max value and count xAxisMaxVal >>> : ", this.xAxisMaxVal, "  Max value and count yAxisMaxVal >>> ", this.yAxisMaxVal);

        this.seriesData.push({ name: "", data: [this.xAxisMaxVal + (this.xAxisMaxVal * 0.1), this.yAxisMaxVal + (this.yAxisMaxVal * 0.1), 0], id: "", numberoftoxpara: 0, numberofexppara: 0 });

        this.isChartSimple = false;
        this.isAIData = false;
        this.xAxisMaxVal = this.xAxisMaxVal + (this.xAxisMaxVal * 0.1);
        this.yAxisMaxVal = this.yAxisMaxVal + (this.yAxisMaxVal * 0.1);
        this.xNoOfTick = Math.round(this.xAxisMaxVal * 10);
        this.yNoOfTick = Math.round(this.yAxisMaxVal * 10);

        console.log("this.xNoOfTick >>> ", this.xNoOfTick, "  this.yNoOfTick >>>  ", this.yNoOfTick);
        console.log(" Max value and count xAxisMaxVal >>> : ", this.xAxisMaxVal, "  Max value and count yAxisMaxVal >>> ", this.yAxisMaxVal);

        this.EnhancedChartOption();

        // this.chartOptions = {
        //   series: this.seriesData,
        //   chart: {
        //     height: 550,
        //     type: "bubble",
        //     toolbar: {
        //       show: false
        //     },
        //     zoom: {
        //       enabled: false
        //     },
        //     events: {
        //       click: function (event, chartContext, config) {
        //         if (self.seriesData[config.seriesIndex]) {
        //           var cid = self.seriesData[config.seriesIndex].id;
        //           var baseUrl = window.location.origin;
        //           const url = baseUrl + "/view-chemical-details/" + cid;
        //           window.open(url, "_blank");
        //         }
        //       },
        //       animationEnd: function (chartContext, options) {
        //         window.setTimeout(function () {
        //           chartContext.dataURI().then((uri) => {
        //             this.reportExcelExportUrl = uri.imgURI;
        //             localStorage.setItem("base64Str", uri.imgURI)
        //           });
        //         }, 1000);
        //       }
        //     }
        //   },
        //   legend: {
        //     show: false
        //   },
        //   dataLabels: {
        //     enabled: true,
        //     textAnchor: 'start',
        //     style: {
        //       fontSize: '10px',
        //       fontWeight: 'normal',
        //       colors: ['#000']
        //     },
        //     formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        //       if (value) {
        //         return w.config.series[seriesIndex].name
        //       }
        //       else if (Number.isNaN(value) || Number(value) == 0) {

        //         return 'X ' + w.config.series[seriesIndex].name
        //       }
        //     }
        //   },
        //   fill: {
        //     opacity: 0.8
        //   },
        //   xaxis: {
        //     min: this.xAxisMinVal,
        //     max: this.xAxisMaxVal,
        //     tickAmount: this.noOfTick,
        //     labels: {
        //       show: true,
        //       formatter: function (value, timestamp) {
        //         return parseFloat(value).toFixed(1)
        //       }
        //     },
        //     title: {
        //       text: 'Relative Toxicity',
        //       style: {
        //         fontWeight: 500,
        //         fontSize: "16px",
        //       }
        //     },
        //   },
        //   yaxis: {
        //     min: this.yAxisMinVal,
        //     max: this.yAxisMaxVal,
        //     tickAmount: this.noOfTick,
        //     title: {
        //       text: 'Relative Likelihood of Exposure',
        //       style: {
        //         fontWeight: 500,
        //         fontSize: "16px",
        //       }
        //     },
        //     labels: {
        //       formatter: function (val, index) {
        //         return val.toFixed(3);
        //       }
        //     }
        //   },
        //   grid: {
        //     show: true,
        //     position: 'back',
        //     xaxis: {
        //       lines: {
        //         show: true
        //       }
        //     },
        //     yaxis: {
        //       lines: {
        //         show: true
        //       }
        //     }
        //   },
        //   tooltip: {
        //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //       var data = self.seriesData[seriesIndex].data[0];
        //       return '<div class="tooltip-box" style="margin: 10px; display: block; line-height: 2;">' +
        //         '<span> <b>' + self.seriesData[seriesIndex].name + '</b></span><br/>' +
        //         '<span> Toxicity: <b>' + parseFloat(data[0]).toFixed(3) + '</b></span><br/>' +
        //         '<span> Exposure: <b>' + parseFloat(data[1]).toFixed(3) + '</b></span><br/>' +
        //         '<span> Emerging Concern: <b>' + parseFloat(data[2]).toFixed(3) + '</b></span>' +
        //         '</div>'
        //     }
        //   }
        // };
      }
      /* End */

      this.getSearchStatus.next(this.reportData);

    },
      error => {
        this.SpinnerService.hide();
      });
  }

  UpdateECScoreData() {
    if (this.isAIData) {
      var cloneSeriesDataArray = JSON.parse(JSON.stringify(this.seriesData));
      var cloneSeriesTooltipDataArray = JSON.parse(JSON.stringify(this.seriesTooltipData));

      cloneSeriesDataArray.forEach(element => {
        var obj = this.ecScoreForChartList.find(x => x.id == element.id);
        if (obj) {
          element.data[0][2] = obj.ecScore;
        }
      });
      cloneSeriesTooltipDataArray.forEach(element => {
        var obj = this.ecScoreForChartList.find(x => x.id == element.id);
        if (obj) {
          element.data[0][2] = obj.ecScore;
        }
      });
      this.seriesData = cloneSeriesDataArray;
      this.seriesTooltipData = cloneSeriesTooltipDataArray;
    }
    else {
      this.seriesData = this.seriesDataTemp;
      this.seriesTooltipData = this.seriesTooltipDataTemp;
    }
    this.EnhancedChartOption();
  }

  EnhancedChartOption() {
    var isLegendShow = false;
    var isDataLabelShow = true;
    if (this.isChartSimple) {
      var isLegendShow = true;
      var isDataLabelShow = false;
    }

    var self = this;
    this.chartOptions = {
      series: this.seriesData,
      chart: {
        height: 550,
        type: "bubble",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        events: {
          click: function (event, chartContext, config) {
            if (self.seriesData[config.seriesIndex]) {
              var cid = self.seriesData[config.seriesIndex].id;
              // var baseUrl = window.location.origin;
              // const url = baseUrl + "/view-chemical-details/" + cid;
              // window.open(url, "_blank");
              self.router.navigate(['/view-chemical-details/', cid, '']);
            }
          },
          animationEnd: function (chartContext, options) {
            window.setTimeout(function () {
              chartContext.dataURI().then((uri) => {
                this.reportExcelExportUrl = uri.imgURI;
                localStorage.setItem("base64Str", uri.imgURI)
              });
            }, 1000);
          },
          updated: function (chartContext, config) {
            setLegendTooltip();
          },
          mounted: function (chartContext, config) {
            setLegendTooltip();
          }
        }
      },
      legend: {
        show: isLegendShow,
        showForZeroSeries: false,
        height: 465,
        width: 220,
        position: "right",
        markers: {
          fillColors: this.bubbleFillColor
        }
      },
      dataLabels: {
        enabled: isDataLabelShow,
        textAnchor: 'start',
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
          colors: ['#000']
        },
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          if (value) {
            return w.config.series[seriesIndex].name
          }
          else if (Number.isNaN(value) || Number(value) == 0) {

            return 'X ' + w.config.series[seriesIndex].name
          }
        }
      },
      fill: {
        opacity: 0.8
      },
      xaxis: {
        min: this.xAxisMinVal,
        max: this.xAxisMaxVal,
        tickAmount: this.xNoOfTick,
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (value, timestamp) {
            return parseFloat(value).toFixed(1)
          }
        },
        title: {
          text: 'Relative Toxicity',
          style: {
            fontWeight: 500,
            fontSize: "16px",
          }
        },
      },
      yaxis: {
        min: this.yAxisMinVal,
        max: this.yAxisMaxVal,
        tickAmount: this.yNoOfTick,
        title: {
          text: 'Relative Likelihood of Exposure',
          style: {
            fontWeight: 500,
            fontSize: "16px",
          }
        },
        labels: {
          formatter: function (val, index) {
            return val.toFixed(1);
          }
        },
        axisBorder: {
          show: true
        }
      },
      grid: {
        show: false,
        position: 'back',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      markers: {
        strokeColors: '#000',
        strokeWidth: 0,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        colors: this.bubbleFillColor
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var data = self.seriesTooltipData[seriesIndex].data[0];
          var toolTip = "<div class='tooltip-box' style='margin: 10px; display: block; line-height: 2;'>" +
            "<span> <b>" + self.seriesTooltipData[seriesIndex].name + "</b></span><br/>" +
            "<span> Toxicity: <b>" + parseFloat(data[0]).toFixed(3) + "</b></span><br/>" +
            "<span> Exposure: <b>" + parseFloat(data[1]).toFixed(3) + "</b></span><br/>" +
            "<span> Emerging Concern: <b>" + parseFloat(data[2]).toFixed(3) + "</b></span><br/>";

          // if (self.isAIData) {
          var toxClassName = self.getClass(self.seriesTooltipData[seriesIndex].numberoftoxpara, "Toxicity");
          var expClassName = self.getClass(self.seriesTooltipData[seriesIndex].numberofexppara, "Exposure");
          toolTip += "<span> Tox Parameters: <b>" + self.seriesTooltipData[seriesIndex].numberoftoxpara + "</b><div class='light " + toxClassName + "'></div></span><br/>" +
            "<span> Exp Parameters: <b>" + self.seriesTooltipData[seriesIndex].numberofexppara + "</b><div class='light " + expClassName + "'></div></span>";
          // }

          toolTip += "</div>";
          return toolTip;
        }
      }
    };

    var setLegendTooltip = function () {

      self.seriesTooltipData.forEach(function (item, i) {
        var data = item.data[0];
        let idx = i + 1;
        var toolTip = "";
        // if (self.isAIData) {
        var toxClassName = self.getClass(item.numberoftoxpara, "Toxicity");
        var expClassName = self.getClass(item.numberofexppara, "Exposure");
        toolTip = "<span> Tox Parameters: <b>" + item.numberoftoxpara + "</b><div class='light " + toxClassName + "'></div></span><br/>" +
          "<span> Exp Parameters: <b>" + item.numberofexppara + "</b><div class='light " + expClassName + "'></div></span>";
        // }

        let id = '.apexcharts-legend-series[rel="' + idx + '"]';
        let tooltipHtml = "<div class='legend-tooltip-" + idx + "'>" +
          "<span> <b>" + item.name + "</b></span><br/>" +
          "<span> Toxicity: <b>" + parseFloat(data[0]).toFixed(3) + "</b></span><br/>" +
          "<span> Exposure: <b>" + parseFloat(data[1]).toFixed(3) + "</b></span><br/>" +
          "<span> Emerging Concern: <b>" + parseFloat(data[2]).toFixed(3) + "</b></span><br/>" +
          "" + toolTip +
          "</div>"

        '$("legend-tooltip-"' + idx + ').tooltip({' +
          'position: {' +
          ' my: "center bottom - 20",' +
          ' at: "center top",' +
          ' using: function(position, feedback) {' +
          ' console.log(feedback);' +
          ' $(this).css(position);' +
          ' $(this).addClass(feedback.vertical);' +
          '}' +
          '},'
          ;
        let tooltipCss =
          '<style type="text/css">' +
          '.legend-tooltip-' + idx + '{' +
          'display: none;' +
          'position: absolute;' +
          'left: 15%;' +
          'top: 10%;' +
          'border: 1px solid #d3d3d3;' +
          'border-radius: 5px;' +
          'background-color: #fff;' +
          'z-index: 1500;' +
          'font-size: 14px;' +
          'text-overflow: ellipsis;' +
          'line-height: 18px;' +
          'white-space: nowrap;' +
          'overflow: hidden;' +
          'width: 170px;' +
          'padding: 5px;' +
          '}' +

          '.apexcharts-legend-series[rel="' + idx + '"] {' +
          'position: relative;' +
          '}' +

          '.apexcharts-legend-series[rel="' + idx + '"]:not(.apexcharts-inactive-legend):hover > .legend-tooltip-' + idx + '{' +
          'display: block' +
          '}' +
          '</style>';

        $(id).append(tooltipCss + tooltipHtml);
      })

      $(".apexcharts-legend").addClass("apexcharts-legend-default-overflow");

    };
  }

  CreateDownloadExcel() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      if (this.reportData != null && this.reportData.id != null) {
        this.toastr.success("Report generation started. You will receive the report by email shortly.");
        //this.SpinnerService.show();
        this.reportExcelExportUrl = localStorage.getItem("base64Str");
        this.reportService.downloadExcel(this.reportData.id, this.reportExcelExportUrl).subscribe(paraData => {

          this.reportService.downloadExcelBlob(this.reportData.id)
            .pipe(first())
            .subscribe(data => {
              //this.blobData = data;
              //let blob = new Blob([this.blobData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              //var downloadURL = window.URL.createObjectURL(data);
              //var link = document.createElement('a');
              //link.href = downloadURL;
              //link.download = this.reportData.reportName + ".xlsx";
              //link.click();
              //this.SpinnerService.hide();
              //this.reportData.isFavourite = data.isFavourite;
            },
              error => {
                //this.toastr.error(error.error.message);
              });

          //var element = document.createElement('a');
          //var urlReport = environment.apiUrl + "TestPdf/DownloadExcel?reportId=" + this.reportData.id
          //element.setAttribute('href', urlReport);
          ////element.setAttribute('target', '_blank');
          ////element.setAttribute('download', "abc");
          //element.style.display = 'none';
          //document.body.appendChild(element);

          //element.click();
          //document.body.removeChild(element);
          //this.SpinnerService.hide();

        },
          error => {
          });
      }
      else {
        this.toastr.info("No analysis available to generate report.");
      }
    }
  }

  CreateDownloadPdf() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      if (this.reportData != null && this.reportData.id != null) {
        this.toastr.success("Report generation started. You will receive the report by email shortly.");
        //this.SpinnerService.show();
        var base64Str = localStorage.getItem("base64Str");
        var reportData: ReportDownloadPdfVM = new ReportDownloadPdfVM();
        reportData.reportId = this.reportData.id;
        reportData.base64Str = base64Str;
        this.reportService.downloadPdf(reportData)
          //.pipe(first())
          //.subscribe(data => {
          .then(data => {
            //this.blobData = data;
            //let blob = new Blob([this.blobData], { type: 'application/pdf' });
            //var downloadURL = window.URL.createObjectURL(data);
            //var link = document.createElement('a');
            //link.href = downloadURL;
            //link.download = this.reportData.reportName + ".pdf";
            //link.click();
            //this.SpinnerService.hide();
            //this.reportData.isFavourite = data.isFavourite;
          },
            error => {
              //this.toastr.error(error.error.message);
            });
        //var element = document.createElement('a');
        //var urlReport = this.reportExportUrl;
        //element.setAttribute('href', urlReport);
        ////element.setAttribute('target', '_blank');
        ////element.setAttribute('download', "abc");
        //element.style.display = 'none';
        //document.body.appendChild(element);
        //element.click();
        //document.body.removeChild(element);
        //this.SpinnerService.hide();
      }
      else {
        this.toastr.info("No analysis available to generate report.");
      }
    }
  }

  LoadParameters() {
    //this.SpinnerService.show();
    this.reportService.getAllParameter().subscribe(paraData => {
      this.columnList = paraData.filter(para => para.parameterType == 1 && para.parameterCode != "EC4" && para.parameterCode != "EC12");
      this.BindColumns();
      //this.SpinnerService.hide();
    },
      error => {
      });
  }

  SetColumns() {
    //this.displayedColumns = [];
    this.SpinnerService.show();
    if (this.reportData.columnList != undefined && this.reportData.columnList.length > 0) {
      this.LoadColumnFromReportData();
    } else {
      this.LoadParameters();
    }
    //this.columnsToDisplay = Object.assign([], this.displayedColumns);
  }

  BindColumns() {
    this.displayedColumns = [];
    this.dragdropColumnList = [];
    this.columnsToDisplay = ["Chemical Name", "CAS NO.", "Common Name", "Molecular Weight", "Category"];
    for (var i = 0; i < this.columnList.length; i++) {
      var columnVal = this.columnList[i];
      if (columnVal.parameterName != "Chemical Name" && columnVal.parameterName != "CAS NO." && columnVal.parameterName != "Common Name" && columnVal.parameterName != "Id" && columnVal.parameterName != "Molecular Weight" && columnVal.parameterName != "Category" && columnVal.parameterName != "TotalRecord" && columnVal.parameterCode != "EP40") {
        this.displayedColumns.push({ "id": columnVal.parameterName, "isChecked": true, "index": i, "measure": columnVal.unitOfMeasureStr });
        this.dragdropColumnList.push({ field: columnVal.parameterName });
        this.columnsToDisplay.push(columnVal.parameterName);
        //this.dragdropColumnList = Object.assign([], this.columnsToDisplay);
      }
    }
  }

  ClickManage() {
    $(".Manage-btn").trigger("click");
    this.defaultHide = true;
  }

  BindRankTable() {
    this.dataMessageResults = Object.assign([], this.chemicalList);
    //this.customrSort.active = "Three way ranking (raw scores)";
    //this.customrSort.direction = "desc"
    const sortState: Sort = { active: 'Three way ranking (raw scores)', direction: 'desc' };
    this.sort(sortState);
  }

  CalculateAnalysis() {
    if (this.multiDropdownList.length <= 0)
      this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);

    this.reportData.chemicalsList = this.selectedItems;
    this.PrepareListForSaveWeight();
    if (!$(".Manage-btn").hasClass("collapsed")) {
      this.SpinnerService.show();
      this.directAnalysis = true;
      this.LoadChartForReport();
      //this.RetrieveChemicalListForPrioritiseTab();
      //this.SpinnerService.hide();
    }
  }

  RevertAnalysis() {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      //this.SpinnerService.show();
      this.showReportInput = true;
      this.editMode = false;
      this.reportService.getById(this.reportData.id).pipe(first()).subscribe(Data => {
        this.reportData = Data;
        this.setDefaultValueForSocialMonitoring();
        this.SetWeightView();
        this.SetChemicalList();
        this.LoadChartForReport();
        //this.SpinnerService.hide();
      });
    }

  }

  SetThreeWaysValue() {
    if (this.reportData.threeWayPrioritisation) {
      var totalVal = this.weightageView.SC24_Weightage + this.weightageView.SC25_Weightage;
      this.weightageView.SC26_Weightage = 100 - totalVal;
    }
    else {
      this.weightageView.SC26_Weightage = 0;
      this.OnSliderChange(this.weightageView.SC26_Weightage, 'SC24_Weightage,SC25_Weightage', 'SC26_Weightage');
      this.OnSliderChange(this.weightageView.SC26_Weightage, 'SC24_Weightage,SC25_Weightage', 'SC26_Weightage');
    }
  }

  PrepareColumnList() {
    //var allcheckBoxes = $("#columnsForLoop").children().find(".chkInput");
    this.reportData.columnList = [];
    var j = 1;
    for (var i = 0; i < this.displayedColumns.length; i++) {
      this.reportColumn = new Column();
      var columnData = this.columnList.find(x => x.parameterName === this.displayedColumns[i].id);
      // var column = allcheckBoxes.find(x => x.id === this.displayedColumns[i].id);
      if (this.displayedColumns[i].isChecked) {
        this.reportColumn.parameterId = columnData.id;
        this.reportColumn.reportId = this.reportData.id;
        this.reportColumn.columnOrder = j;
        this.reportColumn.id = "00000000-0000-0000-0000-000000000000";
        this.reportData.columnList.push(this.reportColumn);
        j++;
      }
    }

    //for (var i = 0; i < allcheckBoxes.length; i++) {
    //  this.reportColumn = new Column();
    //  if (allcheckBoxes[i].checked) {
    //    var columnData = this.columnList.find(x => x.parameterName === allcheckBoxes[i].id);
    //    this.reportColumn.parameterId = columnData.id;
    //    this.reportColumn.reportId = this.reportData.id;
    //    var orderId =
    //      this.reportColumn.columnOrder = j;
    //    this.reportColumn.id = "00000000-0000-0000-0000-000000000000";
    //    this.reportData.columnList.push(this.reportColumn);
    //    j++;
    //  }
    //}
  }

  LoadColumnFromReportData() {
    //this.columnList = this.reportData.columnList;
    var checkedList = [];
    var uncheckedList = [];

    for (var i = 0; i < this.columnList.length; i++) {
      var newData = this.reportData.columnList.find(x => x.parameterId === this.columnList[i].id);
      if (newData != null) {
        this.columnList[i].isChecked = true;
        checkedList.push(this.columnList[i]);
      } else {
        this.columnList[i].isChecked = false;
        uncheckedList.push(this.columnList[i]);
      }
    }

    var orderedCheckedList = [];
    for (var i = 0; i < this.reportData.columnList.length; i++) {
      var foundData = checkedList.find(x => x.id === this.reportData.columnList[i].parameterId);
      if (foundData != null) {
        orderedCheckedList.push(foundData);
      }
    }

    this.reportColumnList = orderedCheckedList.concat(uncheckedList);
    this.columnList = [];
    this.columnList = Object.assign([], this.reportColumnList);
    this.displayedColumns = [];
    this.dragdropColumnList = [];
    this.columnsToDisplay = ["Chemical Name", "CAS NO.", "Common Name", "Molecular Weight", "Category"];
    for (var i = 0; i < this.reportColumnList.length; i++) {
      var columnVal = this.reportColumnList[i];
      if (columnVal.parameterName != "Chemical Name" && columnVal.parameterName != "CAS NO." && columnVal.parameterName != "Common Name" && columnVal.parameterName != "Id" && columnVal.parameterName != "Molecular Weight" && columnVal.parameterName != "Category" && columnVal.parameterName != "TotalRecord" && columnVal.parameterCode != "EP40") {
        this.displayedColumns.push({ "id": columnVal.parameterName, "isChecked": columnVal.isChecked, "index": i, "measure": columnVal.unitOfMeasureStr });
        this.dragdropColumnList.push({ field: columnVal.parameterName });
        this.columnsToDisplay.push(columnVal.parameterName);
        //this.dragdropColumnList = Object.assign([], this.columnsToDisplay);
      }
    }
  }

  NewAnalysis() {

    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      this.SpinnerService.show();
      this.isShowChart = false;
      localStorage.removeItem("base64Str");
      this.defaultHide = false;
      this.newAnalysis = true;
      this.SetToDefaultTab();
      this.reportData = new reportVM();
      this.dataMessageResults = [];
      this.selectedChemicals = [];
      this.dataSource = new MatTableDataSource();
      this.reportData.threeWayPrioritisation = true;
      this.selectedDomainId = null;
      if (this.domainList && this.domainList.length > 0) {
        this.selectedDomainId = this.domainList[0].id;
        localStorage.setItem("domainId", this.selectedDomainId);
      }
      this.SetColumns();
      this.GetAllReportList();
      this.ShowColumnsDiv();
      this.getGoogle();
      this.getWeightCategoryList();
      this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
      this.SpinnerService.hide();
      if (this.reportData.chemicalsList != null) {
        $("#chartHide").hide();
        $("#chart_single_visualise").hide();
      } else {
        $("#chartHide").show();
        $("#chart_single_visualise").html("");
        $("#chart_single_visualise").hide();
      }
      this.firstFormGroup = this.formBuilder.group({
        firstCtrl: ['', Validators.required]
      });
      this.secondFormGroup = this.formBuilder.group({
        secondCtrl: ['', Validators.required]
      });
      this.thirdFormGroup = this.formBuilder.group({
        thirdCtrl: ['', Validators.required]
      });

      if (!this.authService.isLoggednIn()) {
        this.authService.logout();
        return;
      }
      this.companyId = localStorage.getItem("CompanyId");
      if (this.companyId && this.companyId != "null") {
        this.isCompanyAdmin = true;
      }
      //google.charts.load('current', { 'packages': ['corechart', 'table'] });
      //google.charts.load('current', { 'packages': ['corechart', 'controls'] });

      //New Code Added
      if (!this.authService.isLoggednIn()) {
        this.authService.logout();
        return;
      }
      this.sub = this.route.params.subscribe(params => {
        this.k = params['k'];
      });
      this.sub = this.route.params.subscribe(params => {
        this.type = params['type'];
      });
      this.sub = this.route.params.subscribe(params => {
        this.urlChemicalId = params['urlChemicalId'];
      });

      this.companyId = localStorage.getItem("CompanyId");
      if (this.companyId && this.companyId != "null") {
        this.isCompanyAdmin = true;
      }
      this.dropdownSettings = {
        singleSelection: false,
        text: "Select ChemicalName",
        //selectAllText: 'Select All',
        //unSelectAllText: 'UnSelect All',
        enableCheckAll: false,
        enableSearchFilter: true,
        classes: "form-control",
        badgeShowLimit: 3,
        searchBy: ['itemName', 'name', 'casnumber'],
        searchPlaceholderText: 'Search by Display Name, Synonyms, Cas-No',
        showCheckbox: false
      };
      this.selectedItems = [];
      //this.getSocialURL();
      //this.RetrieveChemicalList('', 1, 1000, '', '', true);
      google.charts.load('current', { 'packages': ['corechart', 'table'] });
      google.charts.load('current', { packages: ['corechart', 'table'] });
      $("#chart_noDate").html("No Chart Data Found.");
      $("#chart_multi").html("");
      this.showReportInput = false;
      this.getSearchStatus.next(this.reportData);
    }
  }

  dragStarted(index: number) {
    this.tabPreviousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.dragdropColumnList, this.tabPreviousIndex, index);
      this.setDragDropColumns();
    }
  }

  setDragDropColumns() {
    let newcolumnList = Object.assign([], this.displayedColumns);
    this.dragdropColumnList.forEach((column, index) => {
      column.index = index;
      let matchObj = newcolumnList.find(x => x.id === column.field);
      this.displayedColumns[index] = { "id": matchObj.id, "isChecked": matchObj.isChecked, "index": index, "measure": matchObj.unitOfMeasureStr };
    });
    this.columnsToDisplay = ["Chemical Name", "CAS NO.", "Common Name", "Molecular Weight", "Category"];

    this.displayedColumns.forEach((column, index) => {
      if (column.isChecked) {
        this.columnsToDisplay.push(column.id);
      }
    });
  }

  setupFilter(column: string) {
    this.dataSource.filterPredicate = (d: any[], filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  applyFilter1(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSearch(searchTerm: string) {
    if (searchTerm.trim().length == 0) {
      this.filteredChemicalOptions = this.multiDropdownList.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) || (item.categoryName && item.categoryName.toLowerCase().includes(searchTerm.toLowerCase())) || item.casnumber.includes(searchTerm.toLowerCase()) || (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    else {
      let searchTermSplit = searchTerm.split(";").map(item => item.trim());
      var tempFilteredChemicalOptions = [];
      for (var i = 0; i < searchTermSplit.length; i++) {
        var value = searchTermSplit[i];
        if (value) {
          var tmpFilteredChemicalOptions = this.multiDropdownList.filter(item =>
            item.itemName.toLowerCase().includes(value.toLowerCase()) || (item.categoryName && item.categoryName.toLowerCase().includes(value.toLowerCase())) || item.casnumber.includes(value.toLowerCase()) || (item.name && item.name.toLowerCase().includes(value.toLowerCase()))
          );
          tempFilteredChemicalOptions = tempFilteredChemicalOptions.concat(tmpFilteredChemicalOptions);
        }
      }
      this.filteredChemicalOptions = tempFilteredChemicalOptions;
    }
  }

  SelectAllChemicals() {
    for (var i = 0; i < this.filteredChemicalOptions.length; i++) {
      this.filteredChemicalOptions[i].isChecked = true;
    }

    for (var i = 0; i < this.filteredChemicalOptions.length; i++) {
      if (this.filteredChemicalOptions[i].isChecked) {
        this.selectedChemicals.push(this.filteredChemicalOptions[i]);
      } else {
        var chemIndex = this.selectedChemicals.findIndex(x => x.itemName === this.filteredChemicalOptions[i].itemName);
        if (chemIndex != -1) {
          this.selectedChemicals.splice(chemIndex);
        }
      }
    }

  }

  DeSelectAllChemicals() {
    for (var i = 0; i < this.filteredChemicalOptions.length; i++) {
      this.filteredChemicalOptions[i].isChecked = false;
    }
  }

  CreateChemicalGroupList(eve, chemicalData) {
    if (eve.target.checked) {
      this.selectedChemicals.push(chemicalData);
    } else {
      var chemIndex = this.selectedChemicals.findIndex(x => x.itemName === chemicalData.itemName);
      if (chemIndex != -1) {
        this.selectedChemicals.splice(chemIndex);
      }
    }
  }

  AddChemicalsToGroup() {
    this.chemicalsCheckedList = [];
    this.SelectAllChemicals();

    //this.selectedItems = [];
    $("#srchChemical").val('');
    if (environment.isTrial) {
      var defaultChemicals = ["Chlorothalonil", "Epoxiconazole", "Captan", "Acetochlor", "Alachlor", "Paraquat", "acetamiprid", "bifenthrin", "cypermethrin", "Fenbutatin oxide", "Diquat"];
      //var defaultChemicals = ["crystalline silica", "Creosote", "cyanide", "clothianidin","Cobalt"];
      var selList = [];
      var addedChem = [];
      for (var i = 0; i < this.selectedChemicals.length; i++) {
        if (defaultChemicals.indexOf(this.selectedChemicals[i].itemName) == -1) {
          selList.push(this.selectedChemicals[i]);
        } else if (this.selectedItems.findIndex(x => x.itemName === this.selectedChemicals[i].itemName) == -1) {
          this.selectedItems.push(this.selectedChemicals[i]);
        }
      }
      this.DeSelectAllChemicals();

      for (var i = 0; i < this.selectedItems.length; i++) {
        if (defaultChemicals.indexOf(this.selectedItems[i].itemName) == -1) {
          addedChem.push(this.selectedItems[i]);
        }
      }

      this.selectedChemicals = [];
      if (addedChem.length >= 1) {
        this.toastr.warning("You can only add one chemical other than default chemicals in demo version");
        return;
      }
      else {
        this.selectedItems.push(selList[0]);
        return;
      }
    }
    //for (var i = 0; i < this.filteredChemicalOptions.length; i++) {
    //  if (this.filteredChemicalOptions[i].isChecked && (this.selectedItems.findIndex(x => x.itemName === this.filteredChemicalOptions[i].itemName) == -1)) {
    //    this.selectedItems.push(this.filteredChemicalOptions[i]);
    //  }
    //}
    else {
      this.showReportInput = true;
      this.defaultHide = true;
      this.editMode = true;
      for (var i = 0; i < this.selectedChemicals.length; i++) {
        if (this.selectedItems.findIndex(x => x.itemName === this.selectedChemicals[i].itemName) == -1) {
          this.selectedItems.push(this.selectedChemicals[i]);
        }
      }
      this.DeSelectAllChemicals();
    }
  }

  SelectedChemicalToAddChemicalsGroup(data) {
    if (environment.isTrial) {
      if (this.selectedTrialChemical.length >= 1) {
        this.toastr.warning("You can only add one chemical other than default chemicals in demo version");
        return;
      }
      if (this.selectedItems.findIndex(x => x.itemName === data.itemName) == -1) {
        this.selectedItems.push(data);
        this.selectedTrialChemical.push(data);
      }
      return;
    }
    this.showReportInput = true;
    this.defaultHide = true;
    this.editMode = true;
    if (this.selectedItems.findIndex(x => x.itemName === data.itemName) == -1) {
      this.selectedItems.push(data);
    }
  }

  UpdateReportShare(event, report) {
    if (environment.isTrial) {
      this.toastr.warning("This functionality is limited.  Contact us for full functionality");
    }
    else {
      if (report.id != undefined && report.id != "00000000-0000-0000-0000-000000000000") {
        this.SpinnerService.show();
        report.isPublic = event.target.checked;
        this.reportService.updateReportShare(report)
          .pipe(first())
          .subscribe(data => {
            if (data['status'] === true) {
              this.toastr.success(data['message']);
              this.GetAllReportList();
            } else {
              this.toastr.error(data['message']);
            }
            this.SpinnerService.hide();
          },
            error => {
              this.toastr.error(error.error.message);
            });
        this.SpinnerService.hide();
      }
    }
  }

  ChangeHistoryClass(e) {
    if ($(e.target).hasClass("fa-angle-down")) {
      $(e.target).removeClass("fa-angle-down");
      $(e.target).addClass("fa-angle-up");
    } else {
      $(e.target).removeClass("fa-angle-up");
      $(e.target).addClass("fa-angle-down");
    }
  }

  //checkedItemsList(lstItem) {
  //  if (lstItem.isChecked) {
  //    this.chemicalsCheckedList.push(lstItem);
  //  } else {
  //    var chemIndex = this.chemicalsCheckedList.findIndex(x => x.itemName === lstItem.itemName);
  //    if (chemIndex != -1) {
  //      this.chemicalsCheckedList.splice(chemIndex);
  //    }
  //  }
  //}

  SetToDefaultTab() {
    var activeTabId = $(".navTabs_Sec_New").find('.active').children()[0].hash;
    $(".navTabs_Sec_New").find('.active').removeClass('active');
    $(activeTabId).removeClass('active');
    $("#visualizationTab").addClass('active');
    $("#analysisVisualization").addClass("active");
    $("#visualTab").trigger("click");
  }

  groupBy(arrayData, key) {
    return arrayData.reduce((result, currentValue) => {
      (result[currentValue.categoryName] = result[currentValue.categoryName] || []).push(
        currentValue
      );
      console.log(result);

      return result;
    }, {});
  };

  TestFunction() {
    //this.reportService.testApi()
    //  .pipe(first())
    //  .subscribe(data => {
    //    //this.reportData.isFavourite = data.isFavourite;
    //  },
    //    error => {
    //      this.toastr.error(error.error.message);
    //    });
    this.router.navigate(['http://TestPdf/Testing?reportId=27594126-bda7-4e56-a6eb-36eb65adf8ea']);
  }

  getDomain() {
    this.SpinnerService.show();
    this.domainService.getAll().pipe(first())
      .subscribe(res => {
        this.domainList = res;
        if (this.domainList && this.domainList.length > 0) {
          this.selectedDomainId = this.domainList[0].id;
          localStorage.setItem("domainId", this.selectedDomainId);
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  setDefaultDomain() {
    if (this.reportData.domainId == null && this.domainList && this.domainList.length > 0) {
      this.selectedDomainId = this.domainList[0].id;
      localStorage.setItem("domainId", this.selectedDomainId);
    }
    else {
      this.selectedDomainId = this.reportData.domainId;
      localStorage.setItem("domainId", this.selectedDomainId);
    }
  }

  onChange(e) {
    this.selectedDomainId = e.target.value;
    localStorage.setItem("domainId", this.selectedDomainId);
    this.showReportInput = true;
    this.editMode = true;
    this.defaultHide = true;
  }

  updateMonitoringData(monitoringList) {

    //this.reportData.socialMonitoringChemicalList = monitoringList;
  }

  setDefaultValueForSocialMonitoring() {
    if (this.reportData.socialMonitoringChemicalList) {
      this.reportData.socialMonitoringChemicalList.map(x => {
        x.isChecked = true;
      });
    }
  }

  getClass(count: number, type: string) {
    if (type === 'Toxicity') {
      if (count <= this.toxicityRed) {
        return 'redCircle'
      }
      else if (this.toxicityRed < count && count <= this.toxicityOrange) {
        return 'orangeCircle'
      }
      else if (this.toxicityOrange < count && count <= this.toxicityGreen) {
        return 'greenCircle'
      }
    }
    else if (type === 'Exposure') {
      if (count <= this.exposureRed) {
        return 'redCircle'
      }
      else if (this.exposureRed < count && count <= this.exposureOrange) {
        return 'orangeCircle'
      }
      else if (this.exposureOrange < count && count <= this.exposureGreen) {
        return 'greenCircle'
      }
    }
    return 'redCircle'
  }

  getTooltip(count: number, type: string): string {
    if (type === 'Toxicity') {
      if (count <= this.toxicityRed) {
        return 'High uncertainty';
      } else if (this.toxicityRed < count && count <= this.toxicityOrange) {
        return 'Moderate uncertainty';
      } else if (this.toxicityOrange < count && count <= this.toxicityGreen) {
        return 'Low uncertainty';
      }
    } else if (type === 'Exposure') {
      if (count <= this.exposureRed) {
        return 'High uncertainty';
      } else if (this.exposureRed < count && count <= this.exposureOrange) {
        return 'Moderate uncertainty';
      } else if (this.exposureOrange < count && count <= this.exposureGreen) {
        return 'Low uncertainty';
      }
    }
    return ''; // Default tooltip text if no condition matches
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

