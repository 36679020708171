import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PostCompanyAlertData } from '../models/postCompanyAlertData';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneratedAlertsVM } from '../models/generatedAlertsVM';
@Injectable({ providedIn: 'root' })

export class CompanyAlertDataService {
  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get<PostCompanyAlertData[]>(`${environment.apiUrl}companyalert/getall`);
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}CompanyAlert/getCompanyAlertDataById/` + id);
  }
  viewAllCompanyAlert(companyName: string) {
    return this.http.get<PostCompanyAlertData[]>(`${environment.apiUrl}companyalert/viewCompanyAlert/` + companyName);
  }

  getCommonNameList() {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/CommonNameList`);
  }

  getCitationType() {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/CitationType`);
  }

  getCitationSentimentList() {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/CitationSentiment`);
  }
  getThresholdList() {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/ThresholdList`);
  }
  getPeriodList() {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/PeriodList`);
  }

  getSentimentTypeList() {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/GetSentimentTypeList`);
  }

  getDeviationTypeList() {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/GetDeviationTypeList`);
  }

  create(data: FormData) {
    return this.http.post<any>(`${environment.apiUrl}CompanyAlert/CreateCompanyAlert`, data);
  }

  update(data: FormData) {
    return this.http.post(`${environment.apiUrl}CompanyAlert/UpdateCompanyAlert`, data);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}CompanyAlert/deletebyid/` + id);
  }

  getAllGeneratedAlert(keyword: string) {
    return this.http.get<GeneratedAlertsVM[]>(`${environment.apiUrl}companyalert/getallgeneratedalert/` + keyword);
  }

  getAllByCompanyUser(companyId: string) {
    return this.http.get<any[]>(`${environment.apiUrl}CompanyAlert/GetAllByCompanyUser/` + companyId);
  }

  getAllGeneratedAlertsByCompany(companyId: string) {
    return this.http.get<GeneratedAlertsVM[]>(`${environment.apiUrl}CompanyAlert/GetAllGeneratedAlertsByCompany/` + companyId);
  }
}
