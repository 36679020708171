import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AlertService, AuthService } from '../services';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyAlertDataService } from '../../app/company-alert/company-alert.service';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PostCompanyAlertData } from '../models/postCompanyAlertData';
declare var $: any;

@Component({
  selector: 'app-view-company-alert',
  templateUrl: './view-company-alert.component.html',
  styleUrls: ['./view-company-alert.component.css']
})
export class ViewCompanyAlertComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public companyAlertList: PostCompanyAlertData[];
  loading = false;
  companyName: any;
  sub: any;
  id: any;
  public noData: boolean = false;
  public withData: boolean = false;
  constructor(
    private companyAlertservice: CompanyAlertDataService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private pagerService: PagerService
  ) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.sub = this.route.params.subscribe(params => {
      this.companyName = params['id'];
    });

    this.viewCompanyAlertList(this.companyName);
  }

  private viewCompanyAlertList(companyName: string = null): void {
    this.SpinnerService.show();
      this.companyAlertservice.viewAllCompanyAlert(companyName).pipe(first())
        .subscribe(p => {
          this.companyAlertList = p;
          if (this.companyAlertList != null && this.companyAlertList.length > 0) {
            this.withData = true;
            this.noData = false;
            if (this.companyAlertList.length > 0) {
              this.setPage(1);
            }
            else {
              this.pagedItems = null;
            }
          }
          else {
            this.companyAlertList = null;
            this.withData = false;
            this.noData = true;
          }
          this.SpinnerService.hide();
        },
          error => {
            this.toastr.error(error.message);
            this.SpinnerService.hide();
          });
    
  }

  setPage(page: number) {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      this.SpinnerService.show();
      this.pager = this.pagerService.getPager(this.companyAlertList.length, page);
      this.pagedItems = this.companyAlertList.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.currentPage = page;
      this.SpinnerService.hide();
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }

  sort(sort: Sort) {
    const data = this.companyAlertList.slice();
    if (!sort.active || sort.direction === '') {
      this.companyAlertList = data;
      return;
    }
    this.companyAlertList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'companyName': return compare(a.companyName, b.companyName, isAsc);
        case 'commonName': return compare(a.commonName, b.commonName, isAsc);
        case 'citatioTypeName': return compare(a["citatioTypeName"], b["citatioTypeName"], isAsc);
        case 'citationSentimentName': return compare(a["citationSentimentName"], b["citationSentimentName"], isAsc);
        case 'thresholdName': return compare(a.thresholdName, b.thresholdName, isAsc);
        case 'periodName': return compare(a.periodName, b.periodName, isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

  search(type) {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      var keyword = $('.searchInput').val();
      if (type == "search") {
        if (keyword != null && keyword != "") {
          this.viewCompanyAlertList(keyword);
        }
        else if (keyword.length == 0) {
          this.viewCompanyAlertList();
        }
      }
      else {
        $('.searchInput').val('');
        this.viewCompanyAlertList();
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
