export class weightCategoryVM {
  id: string;
  categoryName: string;
  description: string;
  parentCategoryId: string;
  weights: weightVM[];
}

export class weightVM {
  id: string;
  title: string;
  description: string;
  weightCategoryId: string;
  weightage: number;
}

export class parentWeightCategoryVM {
  id: string;
  categoryName: string;
}

export class companyWeightVM {
  id: string;
  companyId: string;
  weightId: string;
  weightage: number;
}

export class postWeightVM {
  id: string;
  title: string;
  description: string;
  weightCategoryId: string;
  weightage: number;
  companyId: string;
}

