import { Component, OnInit } from '@angular/core';
import { AlertVM } from '../models/alertVM';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDataService } from './alerts.service';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PostAlertData } from '../models/postAlertData';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public alertList: PostAlertData[];
  loading = false;

  constructor(
    private alertservice: AlertDataService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private pagerService: PagerService
  ) { }

  ngOnInit() {
    this.getAlertList();
  }

  private getAlertList() {
    this.SpinnerService.show();
    this.alertservice.getAll().pipe(first())
      .subscribe(p => {
        this.alertList = p;
        if (this.alertList.length > 0) {
          this.setPage(1);
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deletealertById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
        //(e) =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  deletealertById(id: any) {
    this.alertservice.delete(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getAlertList();
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.alertList.length, page);
    this.pagedItems = this.alertList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.alertList.slice();
    if (!sort.active || sort.direction === '') {
      this.alertList = data;
      return;
    }
    this.alertList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title': return compare(a.title, b.title, isAsc);
        case 'chemicalName': return compare(a["chemicalName"], b["chemicalName"], isAsc);
        case 'parameterName': return compare(a["parameterName"], b["parameterName"], isAsc);
        case 'conditionType': return compare(a.conditionType, b.conditionType, isAsc);
        case 'thresholdValue': return compare(a.thresholdValue, b.thresholdValue, isAsc);
        default: return 0;
      }
    });
    this.setPage(this.currentPage);
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
