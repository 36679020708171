import { Component, OnInit } from '@angular/core';
import { AlertService, AuthService } from '../services';
import { UserService } from '../users/user.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DashboardService } from './dashboard.service';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PostCompanyAlertData } from '../models/postCompanyAlertData';
import { ChemicalSocialVM } from '../models/socialfeedVM';
import { UserRoles } from '../../app/models/enum';
import { GeneratedAlertsVM } from '../models/generatedAlertsVM';
import { ManageChemicalService } from '../managechemicals/managechemicalsservice';
import { AnalysisService } from '../analysis/analysis.service';
declare var google: any;
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public companyAlertList: PostCompanyAlertData[];
  public socialFeedList: ChemicalSocialVM[];
  public chemicalVM: any;
  loading = false;
  sub: any;
  id: any;
  casnumber: any;
  cwidth: number = 1200;
  ewidth: number = 550;
  public filter: string = '';
  public chemicalList: any;
  public generatedAlertList: any;
  public currentUserCode: string
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public userRole = UserRoles;
  public allChemicalDataList: any;
  public EmergingList: any;
  public EmergingChemicalList: any;
  public MultiEmergingList: any;
  AllCommonName: string = null;
  public commonNameList: any[] = [];
  chemicalId: any;
  dropdownList = [];
  public socialURL: string = null;
  getGoogle() {
    return google;
  }

  constructor(private userService: UserService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private analysisService: AnalysisService,
    private chemicalService: ManageChemicalService,
    private pagerService: PagerService,) {
    //google.charts.load('current', { packages: ['corechart', 'table'] });
  }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.SpinnerService.show();

    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
    }
    //google.charts.load('current', { packages: ['corechart', 'table'] });
    //this.getCompanyAlertList();
    this.getSocialURL();
    this.viewGeneratedAlertList();
    this.RetrieveChemicalList('', 1, 1000, '', '', true);
    //this.RetrieveChemicalList(this.filter, 1, 10, '', '', true);
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean) {
    this.SpinnerService.show();
    this.dashboardService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder).subscribe(data => {

      if (endRow == 1000) {
        this.allChemicalDataList = data;
      }
      this.chemicalList = data;
      this.GetEmergingDataList(null);
    },
      error => {
        //alert("We faced some technical issues");
      });

  }
  private getCompanyAlertList() {
    this.SpinnerService.show();
    this.dashboardService.getAlerts().pipe(first())
      .subscribe(p => {
        this.companyAlertList = p;
        this.SpinnerService.hide();
      },
        error => {
          if (error.status == 401) {
            this.authService.logout();
          } else {
            this.toastr.error(error.message);
            this.SpinnerService.hide();
          }
        });
  }

  private getSocialFeedList() {
    this.SpinnerService.show();
    this.dashboardService.getSocialFeed().pipe(first())
      .subscribe(p => {
        this.socialFeedList = p;
        this.SpinnerService.hide();
      },
        error => {
          if (error.status == 401) {
            this.authService.logout();
          } else {
            this.toastr.error(error.message);
            this.SpinnerService.hide();
          }
        });
  }

  private viewGeneratedAlertList(): void {
    this.SpinnerService.show();
    this.dashboardService.getAllGeneratedAlert().pipe(first())
      .subscribe(p => {
        this.generatedAlertList = p;
        this.SpinnerService.hide();
      },
        error => {
          if (error.status == 401) {
            this.authService.logout();
          } else {
            this.toastr.error(error.message);
            this.SpinnerService.hide();
          }
        });
  }

  GetEmergingDataList(chemicalId: string = null) {
    this.SpinnerService.show();
    this.analysisService.getAllEmerging(chemicalId, "2").subscribe(data => {
      this.getGoogle();
      $("#chart_multi").html("");
      $("#chart_single").html("");

      var chartWidth = $("#chart_chemical").width() + 70;
      var optionsEmerging = {
        tooltip: { isHtml: true },
        legend: { position: 'top', maxLines: 3 },
        width: chartWidth,
        height: 465,
        hAxis: {
          title: 'Time'
        },
        vAxis: {
          title: 'Score'
        }
      };

      this.EmergingChemicalList = data;
      if (this.EmergingChemicalList.length < 1) {

        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_chemical").html("");
        $("#chart_multi").html("");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");

      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      this.EmergingChemicalList.forEach((value) => {
        Object.keys(value).forEach((key) => {
          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })

        count = count + 1;
      })

      let mainValue = [];
      let dataList: string[] = [];

      this.EmergingChemicalList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);

      })
      // Add data.
      data1.addRows(mainValue);
      var chartChemical = new google.visualization.LineChart(document.getElementById('chart_chemical'));
      if (this.EmergingChemicalList.length > 0) {
        chartChemical.draw(data1, optionsEmerging);
      }

      google.visualization.events.addListener(chartChemical, 'select', selectHandler);

      var cid = "";
      var currentClass = this;
      function selectHandler() {
        var selection = chartChemical.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(currentClass.allChemicalDataList, function (el) {
            return el.chemicalName == str;
          });
          cid = selectedDate[0].id;

        }
        currentClass.chemicalId = cid;
        currentClass.getCommonNameList(cid);
        //currentClass.GetSocialDataList(currentClass.id, cid.toString());

      }

      this.SpinnerService.hide();

    },
      error => {
        // alert("We faced some technical issues");
      });
    this.SpinnerService.hide();
  }

  getCommonNameList(chemicalId: string = null) {
    this.chemicalService.getCommonNameList(chemicalId).pipe(first())
      .subscribe(p => {
        this.commonNameList = p;

        for (var obj in this.commonNameList) {
          this.AllCommonName = this.AllCommonName + this.commonNameList[obj].id + ",";
          let newName = {
            id: this.commonNameList[obj].id,
            itemName: this.commonNameList[obj].name
          };
          this.dropdownList.push(newName);
        };
        this.AllCommonName = this.AllCommonName.substring(0, this.AllCommonName.length - 1);

        if (this.commonNameList.length > 1) {
          this.SpinnerService.show();
          this.GetMultiSocialDataList(chemicalId, this.AllCommonName.toString());
        }
        else {
          this.SpinnerService.show();
          this.GetSocialDataList(chemicalId, this.AllCommonName.toString());
        }
      },
        error => {
          //this.toastr.error(error.message);
        });
  }

  GetSocialDataList(chemicalId: string = null, commonNameId: string = null, fromDate: string = null, toDate: string = null): void {
    this.SpinnerService.show();
    this.analysisService.getAllSocial(chemicalId, commonNameId, fromDate, toDate).subscribe(data => {
      this.getGoogle();
      $("#chart_multi").html("");
      $("#chart_chemical").html("");
      var chartWidth = $("#chart_chemical").width() + 70;
      var optionsEmerging = {
        tooltip: { isHtml: true },

        legend: { position: 'bottom' },
        width: chartWidth,
        height: 463,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Value'
        },
        colors: ['#F5190B', '#080808', '#AFFF33']
      };

      this.EmergingList = data;
      if (this.EmergingList.length < 1) {
        $("#chart_noDate").html("No Chart Data Found");
        $("#chart_chemical").html("");
        $("#chart_multi").html("");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");
      }
      var data1 = new google.visualization.DataTable();
      data1.addColumn('string', "date");
      data1.addColumn('number', "Negative");
      data1.addColumn('number', "Neutral");
      data1.addColumn('number', "Positive");

      var Chart = new google.visualization.LineChart(document.getElementById('chart_single'));

      var recordData = [];
      var currentDate = null;
      $.each(this.EmergingList, function (key, value) {
        if (currentDate != value.date) {
          let currentData = _.filter(data, function (el) {
            return el.date == value.date;
          });
          let positiveValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 3;
          });
          let negativeValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 1;
          });
          let nutralValue = _.filter(currentData, function (el) {
            return el.citationSentimentId == 2;
          });
          currentDate = value.date;
          var pCount = positiveValue.length > 0 ? positiveValue[0].positiveCount : 0;
          var neCount = negativeValue.length > 0 ? negativeValue[0].negativeCount : 0;
          var nuCount = nutralValue.length > 0 ? nutralValue[0].neutralCount : 0;
          recordData.push([value.date, neCount, nuCount, pCount]);
        }
      });

      data1.addRows(recordData);
      if (this.EmergingList.length > 0) {
        Chart.draw(data1, optionsEmerging);
      }
      //google.visualization.events.addListener(Chart, 'select', selectHandler);
      var currentThis = this;
      function selectHandler() {
        var selection = Chart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];

          let currentData = _.filter(data, function (el) {
            var str = data1.getFormattedValue(item.row, 0);
            return el.date == str;
          });

          if (currentData != null && currentData.length > 0) {
            let lineData = _.filter(currentData, function (el) {
              return el.citationSentimentId == item.column;
            });
            if (lineData != null && lineData.length > 0) {
              var commonNameKeyWordId = lineData[0].commonNameKeywordId;
              var citationTypeId = lineData[0].citationTypeId;
              var citationSentimentId = lineData[0].citationSentimentId;
              var recordDate1 = lineData[0].date;
              var date = recordDate1.split("/");
              var newDate = date[2] + '-' + date[1] + '-' + date[0];

              var href = currentThis.socialURL.replace("{0}", commonNameKeyWordId).replace("{1}", citationTypeId).replace("{2}", citationSentimentId).replace("{3}", newDate);
            }
            else {
              var commonNameKeyWordId = currentData[0].commonNameKeywordId;
              var citationTypeId = currentData[0].citationTypeId;
              var citationSentimentId = item.column;
              var recordDate1 = currentData[0].date;
              var date = recordDate1.split("/");
              var newDate = date[2] + '-' + date[1] + '-' + date[0];

              var href = currentThis.socialURL.replace("{0}", commonNameKeyWordId).replace("{1}", citationTypeId).replace("{2}", citationSentimentId).replace("{3}", newDate);
            }

          }
        }
        if (message == '') {
          message = 'nothing';
        }

        window.open(href, '_blank');
      }


      //if (this.EmergingList.length > 0) {
      //  this.singleChart.draw(data1, optionsEmerging);
      //}

      this.SpinnerService.hide();
    },
      error => {
        // alert("We faced some technical issues");
      });
  }

  GetMultiSocialDataList(chemicalId: string = null, commonNameId: string = null, fromDate: string = null, toDate: string = null): void {

    this.analysisService.getMultiSocial(chemicalId, commonNameId, fromDate, toDate).subscribe(data => {
      this.getGoogle();
      $("#chart_single").html("");
      $("#chart_chemical").html("");
      var chartWidth = $("#chart_chemical").width() + 70;
      var optionsMultiEmerging = {
        width: chartWidth,
        legend: { position: 'bottom' },
        height: 463,
        hAxis: {
          title: 'Date'
        },
        vAxis: {
          title: 'Value'
        }
      };

      this.MultiEmergingList = data;
      var cnList = this.dropdownList;
      if (this.MultiEmergingList.length < 1) {

        $("#chart_noDate").html("No Chart Data Found.");
        $("#chart_chemical").html("");
        $("#chart_multi").html("");
        $("#chart_single").html("");
      }
      else {
        $("#chart_noDate").html("");

      }

      var data1 = new google.visualization.DataTable();
      let headers: string[] = [];
      var count = 0;

      this.MultiEmergingList.forEach((value) => {

        Object.keys(value).forEach((key) => {

          if (count < 1) {
            if (!headers.find((header) => header == key)) {
              headers.push(key);
              if (key == 'date') {
                data1.addColumn('string', 'Date');
              }
              else {
                if (key != 'Title') {
                  data1.addColumn('number', key);
                }

              }
            }
          }
        })

        count = count + 1;
      })

      let mainValue = [];
      let dataList: string[] = [];

      this.MultiEmergingList.forEach((value) => {
        let SubValue = [];
        Object.values(value).forEach((value) => {
          if (!dataList.find((data) => data == value)) {
            SubValue.push(value);
          }
        })
        mainValue.push(SubValue);

      })
      // Add data.
      data1.addRows(mainValue);
      var multiChart = new google.visualization.LineChart(document.getElementById('chart_multi'));
      if (this.MultiEmergingList.length > 0) {
        multiChart.draw(data1, optionsMultiEmerging);
      }

      google.visualization.events.addListener(multiChart, 'select', selectHandler);

      var cid = "";
      var currentClass = this;
      function selectHandler() {
        var selection = multiChart.getSelection();
        var message = '';
        for (var i = 0; i < selection.length; i++) {
          var item = selection[i];
          var str = headers[item.column];
          let selectedDate = _.filter(cnList, function (el) {

            return el.itemName == str;
          });
          cid = selectedDate[0].id;

        }
        currentClass.GetSocialDataList(currentClass.chemicalId, cid.toString());

      }

      this.SpinnerService.hide();

    },
      error => {
        // alert("We faced some technical issues");
      });
  }

  getSocialURL(): void {

    this.analysisService.getSocialURL().pipe(first()).subscribe(url => {

      this.socialURL = url;
    },
      error => {
        // this.toastr.error(error.message);
      });

  }

  refreshChart(): void {
    this.RetrieveChemicalList('', 1, 1000, '', '', true);
  }

}
