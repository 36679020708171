import { Component } from '@angular/core';
import { AuthenticationService, AuthService } from '../services';
import { UserRoles } from '../../app/models/enum';
import { userloginVM } from '../models/userloginVM';
import { environment } from '../../environments/environment';
import { ReleaseHistoryService } from '../release-history/release-history.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent {
  isExpanded = false;
  companyId: any;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  currentUser: userloginVM;
  public userRole = UserRoles;
  public isTrial: boolean = false;
  public isShowReleaseHistory: boolean = false;


  constructor(public auth: AuthService, private authenticationService: AuthenticationService,
    private releaseHistoryService: ReleaseHistoryService, private route: ActivatedRoute,
    private router: Router) {
    if (environment.isTrial) {
      this.isTrial = environment.isTrial;
    }
    if (this.auth.isLoggednIn()) {

      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }

      this.companyId = localStorage.getItem('CompanyId');

      if (this.companyId != null && this.companyId != "00000000-0000-0000-0000-000000000000") {
        this.checkNewReleaseHistory();
      }
    }
  }

  //collapse() {
  //  this.isExpanded = false;
  //}

  //toggle() {
  //  this.isExpanded = !this.isExpanded;
  //}

  checkNewReleaseHistory() {
    this.releaseHistoryService.checkNewReleaseHistory().pipe(first()).subscribe(data => {
      if (data != null) {
        this.isShowReleaseHistory = data['status'];
      }
    },
      error => {
      });
  }

  onShowReleaseHistoryClick() {
    this.isShowReleaseHistory = false;
    this.router.navigate(['/view-release-history']);
  }

  onFaqClick() {
    this.router.navigate(['/faq']);
  }

  logout() {
    this.auth.logout();
  }

}
