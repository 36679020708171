import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AuthService } from '../services/auth.service';
import { TrimValidators } from '../helpers/custom-validators'
import { first } from 'rxjs/operators';
import { UserRoles } from '../../app/models/enum';
import { userloginVM } from '../models/userloginVM';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public user: userloginVM;
  ChangePasswordForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error: string = '';
  isPasswordMatch: boolean = false;
  loading: boolean;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  currentUser: userloginVM;
  public userRole = UserRoles;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private toastr: ToastrService
  ) {
    if (this.auth.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }
  }

  ngOnInit() {
    this.ChangePasswordForm = this.formBuilder.group({
      password: ['', TrimValidators.required],
      cpassword: ['', TrimValidators.required]
    });
  }

  get cp() { return this.ChangePasswordForm.controls; }


  onChangePasswordSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.ChangePasswordForm.invalid) {
      return;
    }

    if (this.cp.cpassword.value != this.cp.password.value) {
      this.isPasswordMatch = true;
      return;
    }

    this.loading = true;
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user) {
      this.authenticationService.ChangePassword(this.cp.password.value, this.user.id.toString())
        .pipe(first())
        .subscribe(
          data => {
            if (data.issuccess) {
              this.toastr.success(data.message);
              if (this.isSuperAdmin || this.isElemental) {
                this.router.navigate(['/dashboard']);
              }
              else {
                this.router.navigate(['/analysis-dashboard']);
              }
              //this.auth.sendToken(this.fp.forgotemail.value);
            }
            else {
              this.toastr.error(data.message);
              this.loading = false;
            }

          },
          error => {
            this.error = error;
            this.loading = false;
          });
    }
  }

  backButton() {
    if (this.isSuperAdmin || this.isElemental) {
      this.router.navigate(['/dashboard']);
    }
    else {
      this.router.navigate(['/analysis-dashboard']);
    }
  }

  onCancelClick() {
    if (this.isSuperAdmin || this.isElemental) {
      this.router.navigate(['/dashboard']);
    }
    else {
      this.router.navigate(['/analysis-dashboard']);
    }
  }

}
