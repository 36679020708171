import { Component, OnInit } from '@angular/core';
import { weightCategoryVM, weightVM, parentWeightCategoryVM } from '../models/weightCategoryVM';
import { AlertService } from '../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { WeightCategoryService } from './sdm-management.service';
import { parse } from 'url';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-sdm-management',
  templateUrl: './sdm-management.component.html',
  styleUrls: ['./sdm-management.component.css']
})

export class SdmManagementComponent implements OnInit {

  public weightCategoryList: weightCategoryVM[];
  public weightList: weightVM[] = [];
  public tempId = "";
  loading = false;

  constructor(
    private weightCategoryService: WeightCategoryService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
  ) { }


  ngOnInit() {
    this.SpinnerService.show();
    this.getWeightCategoryList();
  }

  private getWeightCategoryList() {

    this.weightCategoryService.getAll().pipe(first())
      .subscribe(p => {
        this.weightCategoryList = p;
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  SaveRecord(data: [], id) {

    var total = 0;
    var markers = [];
    this.weightList = [];
    for (var i = 0; i < data.length; i++) {
      markers[i] = $("#" + data[i]["id"]).val();
      total += parseFloat($("#" + data[i]["id"]).val());
      let dataList = new weightVM();
      dataList.id = data[i]["id"];
      dataList.weightage = parseFloat(markers[i]);
      this.weightList.push(dataList);
    }
    if (total == 100) {
      this.weightCategoryService.create(this.weightList)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.showHideOption(id);
              this.toastr.success(data['message']);
              this.getWeightCategoryList();

            } else {
              this.loading = false;
            }
          },
          error => {
            //  this.toastr.error(error.message);
            this.loading = false;
            // this.dialogRef.close(false);
          });
      console.log(this.weightList);
    }
    else {
      this.resetValue(data);
      this.toastr.error("Your Weightage total not equal to 100,Please check.");

    }

  }

  CancelRecord(id, data: []) {
    this.resetValue(data);
    this.showHideOption(id);
  }

  ShowEdit(id) {

    $(".valueClm_" + id).hide();
    $(".insertClm_" + id).show();
    $(".btnEdit_" + id).hide();
    $(".btnSave_" + id).show();

  }

  showHideOption(id) {
    $(".valueClm_" + id).show();
    $(".insertClm_" + id).hide();
    $(".btnEdit_" + id).show();
    $(".btnSave_" + id).hide();
  }

  resetValue(data: []) {
    for (var i = 0; i < data.length; i++) {
      $("#" + data[i]["id"]).val(data[i]["weightage"]);
    }
  }


}
