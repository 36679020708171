import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../helpers/custom-validators';
import { UserRoles } from '../models/enum';
import { userloginVM } from '../models/userloginVM';
import { AuthenticationService, AuthService } from '../services';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public currentUser: userloginVM;
  public userRole = UserRoles;

  feedbackForm: FormGroup;
  submitted = false;
  loading: boolean;
  selectedFile: any;
  isFileTypeAndSizeExceed: any;
  msgFileTypeAndSizeValidation: any;
  fileExtensionList: any = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'png', 'jpg', 'jpeg', 'pdf'];

  constructor(private auth: AuthService, private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder, private router: Router, private feedbackService: FeedbackService,
    private toastr: ToastrService, private spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    if (this.auth.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }

    this.feedbackForm = this.formBuilder.group({
      subject: ['', TrimValidators.required],
      description: ['', TrimValidators.required],
      // attachment: ['']
    });
  }

  get fb() { return this.feedbackForm.controls; }

  onFileClick(event: any) {
    this.isFileTypeAndSizeExceed = false;
    event.currentTarget.value = null;
  }

  onFileChange(event: any) {
    let file = event.target.files;
    this.selectedFile = file[0];

    this.onFileValidate();
  }

  onFileValidate() {
    if (this.selectedFile) {
      let fileExtension = this.selectedFile.name.split(".").pop();
      if (!this.fileExtensionList.includes(fileExtension)) {
        this.msgFileTypeAndSizeValidation = "Only .doc, .docx, .xls, .xlsx, .txt, .png, .jpg, .jpeg and .pdf file are allowed!";
        this.isFileTypeAndSizeExceed = true;
        return true;
      }

      let fileSize = this.selectedFile.size / 1024 / 1024;
      if (fileSize > 10) {
        this.msgFileTypeAndSizeValidation = "Upload file size upto 10 MB";
        this.isFileTypeAndSizeExceed = true;
        return true;
      }
    }
    return false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      return;
    }
    if (this.onFileValidate()) {
      return;
    }

    let formData = new FormData();
    formData.append("subject", this.feedbackForm.value.subject);
    formData.append("description", this.feedbackForm.value.description);
    formData.append("file", this.selectedFile);

    this.spinnerService.show();
    this.loading = true;
    this.feedbackService.sendFeedbackEmail(formData).pipe(first())
      .subscribe(
        data => {
          this.spinnerService.hide();
          if (data['status'] === true) {
            if (this.isSuperAdmin || this.isElemental) {
              this.router.navigate(['/dashboard']).then(() => {
                this.toastr.success(data['message']);
              });
            }
            else {
              this.router.navigate(['/analysis-dashboard']).then(() => {
                this.toastr.success(data['message']);
              });
            }
          } else {
            this.toastr.error(data['message']);
            this.loading = false;
          }
        },
        error => {
          this.toastr.error(error.message);
          this.loading = false;
          this.spinnerService.hide();
        });
  }

  onBack() {
    if (this.isSuperAdmin || this.isElemental) {
      this.router.navigate(['/dashboard']);
    }
    else {
      this.router.navigate(['/analysis-dashboard']);
    }
  }

}
