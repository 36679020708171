import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthService } from '../../services/auth.service';
import { TrimValidators } from '../../helpers/custom-validators'
import { first } from 'rxjs/operators';
import { UserRoles } from '../../models/enum';
import { userloginVM } from '../../models/userloginVM';
import { ToastrService } from 'ngx-toastr';
import { changePasswordVM } from 'src/app/models/changePasswordVM';

@Component({
  selector: 'app-first-time-login-change-password',
  templateUrl: './first-time-login-change-password.component.html',
  styleUrls: ['./first-time-login-change-password.component.css']
})
export class FirstTimeLoginChangePasswordComponent implements OnInit {

  //public user: userloginVM;
  public changePasswordModel: changePasswordVM = {} as changePasswordVM;
  changePasswordForm: FormGroup;
  submitted = false;
  //returnUrl: string;
  //error: string = '';
  isPasswordMatch: boolean = false;
  loading: boolean;
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  currentUser: userloginVM;
  public userRole = UserRoles;

  constructor(
    private activatedRoute: ActivatedRoute, private router: Router,
    private authenticationService: AuthenticationService, private formBuilder: FormBuilder,
    private auth: AuthService, private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.auth.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }

    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', TrimValidators.required],
      password: ['', TrimValidators.required],
      confirmPassword: ['', TrimValidators.required]
    });
  }

  get cp() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    if (this.changePasswordModel.password != this.changePasswordModel.confirmPassword) {
      this.isPasswordMatch = true;
      return;
    }

    this.loading = true;
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.changePasswordModel.userId = user.id.toString();
    if (this.changePasswordModel) {
      this.authenticationService.updatePasswordForFirstTimeLogin(this.changePasswordModel)
        .pipe(first())
        .subscribe(
          data => {
            if (data.issuccess) {
              this.toastr.success(data.message);
              if (this.isSuperAdmin || this.isElemental) {
                this.router.navigate(['/dashboard']);
              }
              else {
                this.router.navigate(['/analysis-dashboard']);
              }
            }
            else {
              this.toastr.error(data.message);
              this.loading = false;
            }

          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

}
