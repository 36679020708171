import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { releaseHistoryVM } from '../models/releaseHistoryVM';

@Injectable({
  providedIn: 'root'
})

export class ReleaseHistoryService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}ReleaseHistory/GetAll`);
  }

  getAllByPublish() {
    return this.http.get<any[]>(`${environment.apiUrl}ReleaseHistory/GetAllByPublish`);
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}ReleaseHistory/GetById/` + id);
  }

  create(data: releaseHistoryVM) {
    return this.http.post<any>(`${environment.apiUrl}ReleaseHistory/CreateReleaseHistory`, data);
  }

  update(data: releaseHistoryVM) {
    return this.http.post(`${environment.apiUrl}ReleaseHistory/UpdateReleaseHistory`, data);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}ReleaseHistory/DeleteById/` + id);
  }

  publish(id: string) {
    return this.http.get(`${environment.apiUrl}ReleaseHistory/PublishReleaseHistory/` + id);
  }

  checkNewReleaseHistory() {
    return this.http.get(`${environment.apiUrl}ReleaseHistory/CheckNewReleaseHistory/`);
  }

  visitReleaseHistoryByUser() {
    return this.http.get(`${environment.apiUrl}ReleaseHistory/VisitReleaseHistoryByUser/`);
  }

}
