import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ChemicalValueRequestVM } from '../models/chemicalValueRequestVM';
import { CitationRequestVM } from '../models/citationRequestVM';

@Injectable({ providedIn: 'root' })

export class ChemicalValueRequestService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<ChemicalValueRequestVM[]>(`${environment.apiUrl}datarequest`);
  }

 
  getParameterList(cid: string) {
    return this.http.get<any[]>(`${environment.apiUrl}datarequest/ParameterList/` + cid);
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}datarequest/GetById/` + id);
  }

  public RetrieveChemicalList(filter: string, StartRow: number, EndRow: number, SortColumn: string, SortOrder: string, CompanyId: string, IsPrivate) {
    return this.http.post(`${environment.apiUrl}ManageChemical/chemicalList`,
      {
        filter: filter, StartRow: StartRow, EndRow: EndRow, SortColumn: SortColumn, SortOrder: SortOrder, CompanyId: CompanyId, IsPrivate: IsPrivate
      }
    );
  }
  create(data: FormData) {
    return this.http.post<any>(`${environment.apiUrl}datarequest/CreateDataRequest`, data);
    //{
    //  title: data["title"],
    //  conditionType: data["conditionType"],
    //  thresholdValue: data["thresholdValue"],
    //  chemicalId: data["chemicalId"],
    //  parameterId: data["parameterId"],
    //  companyId: data["companyId"]
    //}

  }

  update(data: FormData) {
    return this.http.post(`${environment.apiUrl}datarequest/updateDataRequest`, data);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}datarequest/deletebyid/` + id);
  }

  approve(id: string) {
    return this.http.get(`${environment.apiUrl}datarequest/approve/` + id);
  }

  reject(id: string) {
    return this.http.get(`${environment.apiUrl}datarequest/reject/` + id);
  }
  GetPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    let startIndex = ((currentPage - 1) * pageSize) + 1;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1) + 1;
    // create an array of pages to ng-repeat in the pager control
    let pages = Array();
    for (let i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
