import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { CompaniesService } from '../companies.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyBranchVM } from '../../models/companyBranchVM';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service'
import { UserService } from '../../users/user.service';
import { UserRoles } from '../../../app/models/enum';

@Component({
  selector: 'app-view-branch',
  templateUrl: './view-branch.component.html',
  styleUrls: ['./view-branch.component.css']
})
export class ViewCompanyOntarioComponent implements OnInit {
  public companyBranchVM: CompanyBranchVM[];
  companiesViewForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public currentUserCode: string;
  public userRole = UserRoles;

  constructor(
    private formBuilder: FormBuilder,
    private companiesService: CompaniesService,
    private userService: UserService,

    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,

  ) { }

  ngOnInit() {
    this.SpinnerService.show();

    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
    }

    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id) {
      this.editBranchDetails(this.id)
    }
  }
  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  deleteById(id: any) {

    this.userService.deleteBranchUser(id).pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          let companyId = this.getCompanyId();
          this.router.navigate(['/company-branch', companyId]).then(() => {
            this.toastr.success(data['message']);
          });
        } else {
          this.toastr.error(data['message']);
          this.loading = false;
        }
      },
        error => {
          this.toastr.error(error.message);
          this.loading = false;
        });

  }

  editBranchDetails(branchId: string = null): void {
    if (branchId) {
      this.companiesService.getByBranchId(branchId).pipe(first()).subscribe(branchData => {
        this.companyBranchVM = branchData;
        this.SpinnerService.hide();
      });
    }
  }

  backButton() {
    let cId = localStorage.getItem("AdminCompanyId");
    if (cId != '' && cId != "" && cId != null) {
      this.router.navigate(['/company-branch', cId]);
    }
    else {
      let companyId = localStorage.getItem("CompanyId");
      this.router.navigate(['/company-branch', companyId]);
    }
  }

  getCompanyId() {
    let adminCompanyId = localStorage.getItem("AdminCompanyId");
    let companyId = adminCompanyId != '' && adminCompanyId != "" && adminCompanyId != null ? adminCompanyId : localStorage.getItem("CompanyId");
    return companyId;
  }
}
