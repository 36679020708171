import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services';
import { AuthenticationService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  public currentUserCode: string;  
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private auth: AuthService,
        private myRoute: Router
    ) { }

    //canActivate(
    //    next: ActivatedRouteSnapshot,
    //    state: RouterStateSnapshot) {
    //    if (this.auth.isLoggednIn()) {
    //        return true;
    //    } else {
    //        this.myRoute.navigate(['login']);
    //        return false;
    //    }
    //}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  
    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.auth.isLoggednIn() && this.currentUserCode) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(this.currentUserCode) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }


}
