export class weightCategory {
  id: string;
  title: string;
  description: string;
  weightCategoryId: string;
  weightage: number;
  code: string;
  companyId: string;
  isLocked: boolean;
}

export class weightageView {
  SC7_Id: string;
  SC7_Code: string;
  SC7_Weightage: number;
  SC7_IsLock: boolean;
  SC8_Id: string;
  SC8_Code: string;
  SC8_Weightage: number;
  SC8_IsLock: boolean;
  SC9_Id: string;
  SC9_Code: string;
  SC9_Weightage: number;
  SC9_IsLock: boolean;
  SC12_Id: string;
  SC12_Code: string;
  SC12_Weightage: number;
  SC12_IsLock: boolean;
  SC13_Id: string;
  SC13_Code: string;
  SC13_Weightage: number;
  SC13_IsLock: boolean;
  SC14_Id: string;
  SC14_Code: string;
  SC14_Weightage: number;
  SC14_IsLock: boolean;
  SC15_Id: string;
  SC15_Code: string;
  SC15_Weightage: number;
  SC15_IsLock: boolean;
  SC16_Id: string;
  SC16_Code: string;
  SC16_Weightage: number;
  SC16_IsLock: boolean;
  SC17_Id: string;
  SC17_Code: string;
  SC17_Weightage: number;
  SC17_IsLock: boolean;
  SC24_Id: string;
  SC24_Code: string;
  SC24_Weightage: number;
  SC24_IsLock: boolean;
  SC25_Id: string;
  SC25_Code: string;
  SC25_Weightage: number;
  SC25_IsLock: boolean;
  SC26_Id: string;
  SC26_Code: string;
  SC26_Weightage: number;
  SC26_IsLock: boolean;
  SG7_Id: string;
  SG7_Code: string;
  SG7_Weightage: number;
  SG7_IsLock: boolean;
  SG8_Id: string;
  SG8_Code: string;
  SG8_Weightage: number;
  SG8_IsLock: boolean;
  SG9_Id: string;
  SG9_Code: string;
  SG9_Weightage: number;
  SG9_IsLock: boolean;
  SG10_Id: string;
  SG10_Code: string;
  SG10_Weightage: number;
  SG10_IsLock: boolean;
  SG11_Id: string;
  SG11_Code: string;
  SG11_Weightage: number;
  SG11_IsLock: boolean;
  SG12_Id: string;
  SG12_Code: string;
  SG12_Weightage: number;
  SG12_IsLock: boolean;
  SG24_Id: string;
  SG24_Code: string;
  SG24_Weightage: number;
  SG24_IsLock: boolean;
  SG25_Id: string;
  SG25_Code: string;
  SG25_Weightage: number;
  SG25_IsLock: boolean;
  SK8_Id: string;
  SK8_Code: string;
  SK8_Weightage: number;
  SK8_IsLock: boolean;
  SK9_Id: string;
  SK9_Code: string;
  SK9_Weightage: number;
  SK9_IsLock: boolean;
  SK12_Id: string;
  SK12_Code: string;
  SK12_Weightage: number;
  SK12_IsLock: boolean;
  SK13_Id: string;
  SK13_Code: string;
  SK13_Weightage: number;
  SK13_IsLock: boolean;
  SK16_Id: string;
  SK16_Code: string;
  SK16_Weightage: number;
  SK16_IsLock: boolean;
  SK17_Id: string;
  SK17_Code: string;
  SK17_Weightage: number;
  SK17_IsLock: boolean;
  SK18_Id: string;
  SK18_Code: string;
  SK18_Weightage: number;
  SK18_IsLock: boolean;
  SK19_Id: string;
  SK19_Code: string;
  SK19_Weightage: number;
  SK19_IsLock: boolean;
  SK20_Id: string;
  SK20_Code: string;
  SK20_Weightage: number;
  SK20_IsLock: boolean;
}
