import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { UserService } from '../../users/user.service';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { MustMatch } from '../../helpers/must-match.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { userloginVM } from '../../models/userloginVM';
import { UserRoleListVM } from '../../models/userRoleList';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-add-branch-user',
  templateUrl: './add-branch-user.component.html',
  styleUrls: ['./add-branch-user.component.css']
})
export class AddBranchUserComponent implements OnInit {

  private userVM: userloginVM;
  public userRoleList: UserRoleListVM[];
  public userPostVM: any;
  userForm: FormGroup;
  loading = false;
  submitted = false;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  sub: any;
  branchId: any;
  id: any;
  userRoleId: any;
  CompanyId: any;
  public currentUserRoleOrder: string;
  public isPasswordMatch: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.SpinnerService.show();

    this.CompanyId = localStorage.getItem("CompanyId");
    if (this.CompanyId == "00000000-0000-0000-0000-000000000000") {
      this.CompanyId = localStorage.getItem("AdminCompanyId");
    }
    this.userRoleId = localStorage.getItem("UserRoleId");


    this.sub = this.route.params.subscribe(params => {
      this.branchId = params['branchId'];
      this.id = params['id'];
    });
    this.userForm = this.formBuilder.group({
      id: [''],
      firstName: ['', TrimValidators.required],
      email: ['', [TrimValidators.required, Validators.pattern(this.emailPattern)]],
      password: ['', this.id ? '' : TrimValidators.required],
      confirmPassword: ['', this.id ? '' : TrimValidators.required],
      userRoleId: [''],
      companyId: [''],
      branchId: ['']
    }, { validator: MustMatch('password', 'confirmPassword') });

    if (this.id) {
      this.editBranchUser(this.id)
    }
    this.SpinnerService.hide();

  }



  get f() { return this.userForm.controls; }


  editBranchUser(userId: number = null): void {
    if (userId) {
      this.userService.getByBranchUserId(userId).pipe(first()).subscribe(userData => {
        if (userData != null) {
          this.userPostVM = userData["responseDetail"];

          this.userForm.setValue({
            id: this.userPostVM.id,
            firstName: this.userPostVM.firstName,
            email: this.userPostVM.email,
            password: '',//this.userVM.password,
            confirmPassword: '',// this.userVM.password,
            userRoleId: this.userPostVM.userRoleId,
            companyId: this.userPostVM.companyId,
            branchId: this.userPostVM.branchId
          })
        }
      });
    }
  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.id) {
      this.userForm.value.userRoleId = this.userRoleId;
      this.userForm.value.companyId = this.CompanyId;
      this.userForm.value.branchId = this.branchId;
      this.userService.updatecompanybranchuser(this.userForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/company-branch/', this.CompanyId]).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    } else {
      
      this.userForm.value.userRoleId = this.userRoleId;
      this.userForm.value.companyId = this.CompanyId;
      this.userForm.value.branchId = this.branchId;
      this.userService.createBranchUser(this.userForm.value).pipe(first()).
        subscribe(data => {
        if (data['status'] === true) {
          this.router.navigate(['/company-branch', this.CompanyId]).then(() => {
            this.toastr.success(data['message']);
          });
        } else {
          this.toastr.error(data['message']);
          this.loading = false;
        }
      },
        error => {
          this.toastr.error(error.message);
          this.loading = false;
        });
    }
  }

  onAddUserCancelClick() {
    this.router.navigate(['/company-branch',this.CompanyId]);
  }

  backButton() {
    this.router.navigate(['/company-branch', this.CompanyId]);
  }
  

}
