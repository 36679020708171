import { Component, OnInit } from '@angular/core';
import { companyBranchVM } from '../../models/companiesVM';
import { AlertService } from '../../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '../companies.service';
import { PagerService } from '../../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-branch',
  templateUrl: './company-branch.component.html',
  styleUrls: ['./company-branch.component.css']
})
export class CompanyBranchComponent implements OnInit {

  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public branchList: companyBranchVM[];
  loading = false;
  companyId: any;
  sub: any;
  id: any;

  constructor(
    private companiesService: CompaniesService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private pagerService: PagerService

  ) { }
  ngOnInit() {
    this.SpinnerService.show();
    this.companyId = localStorage.getItem("CompanyId");
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id != '' && this.id != "" && this.id != null) {
      localStorage.setItem("AdminCompanyId", this.id);
      this.companyId = this.id;
      this.getCompaniesList(this.companyId);
    }
    else {
      this.getCompaniesList(this.companyId);
    }
    
  }


  private getCompaniesList(companyId) {
    this.companiesService.getAllCompanyBranch(companyId).pipe(first())
      .subscribe(p => {
        this.branchList = p;
        if (this.branchList.length > 0) {
          this.setPage(1);
        }
        else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  onAddBranchClick() {
    this.router.navigate(['/add-company-branch']);
  }


  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
        //(e) =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  deleteById(id: any) {
    this.companiesService.deletebranch(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.getCompaniesList(this.companyId);
          this.toastr.success(data['message']);

        } else {
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  //Pagging
  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.branchList.length, page);
    this.pagedItems = this.branchList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.branchList.slice();
    if (!sort.active || sort.direction === '') {
      this.branchList = data;
      return;
    }
    this.branchList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'branchName': return compare(a.branchName, b.branchName, isAsc);
        //case 'email': return compare(a.email, b.email, isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

  backButton() {
    let cId = localStorage.getItem("AdminCompanyId");
    if (cId != '' && cId != "" && cId != null) {
      let companyId = localStorage.getItem("CompanyId");
      if (companyId != '' && companyId != "" && companyId != null && companyId != "00000000-0000-0000-0000-000000000000") {
        this.router.navigate(['/analysis-dashboard']);
      }
      else {
        this.router.navigate(['/companies']);
      }
    }
    else {
      this.router.navigate(['/analysis-dashboard']);
    }
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
