export class userActivityFilterVM {
  companyId: string;
  userId: string;
  fromDate: string;
  toDate: string;
}
export class UserPreferencesVM {
  id?: number;
  userId?: string;
  period: number;
  frequency: number;
  span: number;
  createdDate?: Date;
  modifiedDate?: Date;
}
