import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonNameRequestService } from '../common-name-request-service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service'
import { PagerService } from '../../helpers/pager.service'
import { NgxSpinnerService } from "ngx-spinner";
import { CitationRequestVM } from '../../models/citationRequestVM';
declare var $: any;

@Component({
  selector: 'app-add-common-name-request',
  templateUrl: './add-common-name-request.component.html',
  styleUrls: ['./add-common-name-request.component.css']
})
export class AddCommonNameRequestComponent implements OnInit {
  public commonNameRequestList: CitationRequestVM[];
  private postCommonNameRequestVM: any;
  public chemicalList: any;
  public pager: any = {};
  public sortColumn: string = 'chemicalName';
  public sortDirection: string = 'asc';
  public pagedItems: any[];
  public filter: string = '';
  isShow: boolean = false;
  selectedChemicalId = "";
  isEdit = false;

  crequestForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  constructor(
    private citationRequestService: CommonNameRequestService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.SpinnerService.show();


    this.RetrieveChemicalList('', 1, 10, '', '', true);
    this.crequestForm = this.formBuilder.group({
      id: [''],
      citationName: ['', TrimValidators.required],
      companyId: [],
      chemicalId: ['', TrimValidators.required],
      comment: [''],
      reference: ['']
    }
    );

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id) {
      this.isEdit = true;
      this.editCitationRequest(this.id)
    }
  }

  get f() { return this.crequestForm.controls; }

  editCitationRequest(Id: string = null): void {

    if (Id) {
      this.citationRequestService.getById(Id).pipe(first()).subscribe(requestData => {

        if (requestData != null) {

          this.postCommonNameRequestVM = requestData;
          this.crequestForm.setValue({
            id: this.postCommonNameRequestVM.id,
            chemicalId: this.postCommonNameRequestVM.chemicalId,
            citationName: this.postCommonNameRequestVM.citationName,
            companyId: this.postCommonNameRequestVM.companyId,
            comment: this.postCommonNameRequestVM.comment,
            reference: this.postCommonNameRequestVM.reference,
          })

          $("#chemicalName").val("");

          $("#chemicalName").val(this.postCommonNameRequestVM.selectedChemicalName);
          this.selectedChemicalId = null;
          this.selectedChemicalId = this.postCommonNameRequestVM.chemicalId;
        }
      });
    }
  }

  onSubmit() {

    this.submitted = true;
    if (this.crequestForm.invalid) {
      return;
    }
    this.loading = true;
    this.crequestForm.value.companyId = localStorage.getItem('CompanyId');
    this.crequestForm.value.chemicalId = this.selectedChemicalId;

    if (this.id) {

      this.citationRequestService.update(this.crequestForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/common-name-request']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });

    } else {
      this.citationRequestService.create(this.crequestForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/common-name-request']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  onAddRequestCancelClick() {

    this.router.navigate(['/common-name-request']);
  }

  RetrieveChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean) {
    this.SpinnerService.hide();
    this.citationRequestService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder).subscribe(data => {
      this.chemicalList = data
    },
      error => {
        //alert("We faced some technical issues");
      });
  }


  search(event) {

    var keyword = event.target.value;
    $('.searchInput').val(keyword);
    if (keyword.length >= 3) {
      this.RetrieveChemicalList(keyword, 1, 10, this.sortColumn, this.sortDirection, true);
      this.isShow = true;
    }
    else {
      this.chemicalList = null;
      this.isShow = false;
    }

  }

  SelectedChemical(id, chemicalName) {

    $("#chemicalName").val("");
    this.selectedChemicalId = null;
    this.selectedChemicalId = id;
    $("#chemicalName").val(chemicalName);
    this.isShow = false;
  }
}
