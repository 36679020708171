import { Component, OnInit } from '@angular/core';
import { companiesVM } from '../models/companiesVM';
import { AlertService } from '../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from './companies.service';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public companiesList: companiesVM[];
  loading = false;

  constructor(
    private companiesService: CompaniesService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private pagerService: PagerService

  ) { }
  ngOnInit() {
    this.SpinnerService.show(); 
    this.getCompaniesList();
    
  }

  private getCompaniesList() { 
    this.companiesService.getAll().pipe(first())
      .subscribe(p => {
        this.companiesList = p;
        if (this.companiesList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide();  
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();  
        });
  }

  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteCategoryById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
        //(e) =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  deleteCategoryById(id: any) {
    
    this.companiesService.delete(id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['status'] === true) {
            this.toastr.success(data['message']);
            this.getCompaniesList();
          }
          else {
            this.toastr.error(data['message']);
            this.loading = false;
          }
        },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  //Pagging
  setPage(page: number) { 
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.companiesList.length, page);
    this.pagedItems = this.companiesList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) { 
    const data = this.companiesList.slice();
    if (!sort.active || sort.direction === '') {
      this.companiesList = data;
      return;
    }
    this.companiesList = data.sort((a,b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'companyName': return compare(a.companyName, b.companyName, isAsc);
        //case 'email': return compare(a.email, b.email, isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  } 
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
