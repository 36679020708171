import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appThreeDigitDecimal]'
})
export class ThreeDigitDecimalDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?\d+(\.\d{0,3})?$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, Tab, End, Home, ArrowLeft, ArrowRight, Del, Delete 
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Allow only two decimal places
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  // @HostListener('blur', ['$event'])
  // onBlur(event: FocusEvent) {
  //   // Limit the input value to 2 decimal places on blur
  //   const currentValue: string = this.el.nativeElement.value;
  //   if (currentValue && !isNaN(parseFloat(currentValue))) {
  //     this.el.nativeElement.value = parseFloat(currentValue).toFixed(2);
  //   }
  // }

}
