import { Component, OnInit } from '@angular/core';
import { AuthService, AuthenticationService } from '../services';
import { UserRoles } from '../../app/models/enum';
import { userloginVM } from '../models/userloginVM';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-sidebarmenu',
  templateUrl: './nav-sidebarmenu.component.html',
  styleUrls: ['./nav-sidebarmenu.component.css']
})
export class NavSidebarMenuComponent implements OnInit {
  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  currentUser: userloginVM;
  public userRole = UserRoles;

  constructor(public auth: AuthService, private authenticationService: AuthenticationService, private router: Router) {
  }

  ngOnInit() {

    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
      this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
    }
  }

}
