import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ECDataService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}ECData/GetAll`);
  }

  saveECData(data: any) {
    return this.http.post<any>(`${environment.apiUrl}ECData/SaveECData`, data);
  }

  saveECDataList(data: any) {
    return this.http.post<any>(`${environment.apiUrl}ECData/SaveECDataList`, data);
  }

  deleteECDataList(data: any) {
    return this.http.post<any>(`${environment.apiUrl}ECData/DeleteECDataList`, data);
  }

  ignore(id: string) {
    return this.http.get(`${environment.apiUrl}ECData/IgnoreById/` + id);
  }

  importChemicalsFromECData() {
    return this.http.get(`${environment.apiUrl}ECData/ImportChemicalsFromECData`);
  }
}
