import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
//import { companiesVM } from '../models/companiesVM';

@Injectable({ providedIn: 'root' })

export class CompaniesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}companies`);
  }

  getById(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}companies/GetById/` + id);
  }

  getByBranchId(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}companies/GetByBranchId/` + id);
  }

  create(Data:FormData) {
    return this.http.post<any>(`${environment.apiUrl}companies/createcompany`,Data);
  }

  update(company: FormData) {
    return this.http.post(`${environment.apiUrl}companies/updatecompany`, company);
  }

  getCountryList() {
    return this.http.get<any[]>(`${environment.apiUrl}companies/CountryList`);
  }


  getAllCompanyBranch(companyId:string) {
    return this.http.get<any[]>(`${environment.apiUrl}companies/companyBranchList/` + companyId);
  }

  createCompanyBranch(Data: FormData) {
    return this.http.post<any>(`${environment.apiUrl}companies/createbranch`, Data);
  }

  updateCompanyBranch(Data: FormData) {
    return this.http.post(`${environment.apiUrl}companies/updatecompanybranch`, Data);
  }

  getBranchById(id: string) {
    return this.http.get<any>(`${environment.apiUrl}companies/GetBranchById/` + id);
  }

  //GetByCategoryId(id: string) {
  //  return this.http.get<companiesVM[]>(`${environment.apiUrl}ChemicalCategories/ParameterList/` + id)
  //}

  delete(id: string) {
    return this.http.get<any>(`${environment.apiUrl}companies/deletebyid/` + id);
  }

  deletebranch(id: string) {
    return this.http.get<any>(`${environment.apiUrl}companies/deletebranch/` + id);
  }

}
