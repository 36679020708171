import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PostCompanyAlertData } from '../models/postCompanyAlertData';
import { BehaviorSubject, Observable } from 'rxjs';
import { SocialFeedVM } from '../models/socialfeedVM';
import { GeneratedAlertsVM } from '../models/generatedAlertsVM';

@Injectable({ providedIn: 'root' })

export class DashboardService {
  constructor(private http: HttpClient) { }

  getAlerts() {
    return this.http.get<PostCompanyAlertData[]>(`${environment.apiUrl}dashboard/getalerts`);
  }

  getSocialFeed() {
    return this.http.get<SocialFeedVM[]>(`${environment.apiUrl}dashboard/getsocialfeed`);
  }
  public RetrieveChemicalList(filter: string, StartRow: number, EndRow: number, SortColumn: string, SortOrder: string) {
    return this.http.post(`${environment.apiUrl}dashboard/chemicalList`,
      { filter: filter, StartRow: StartRow, EndRow: EndRow, SortColumn: SortColumn, SortOrder: SortOrder }
    );
  }
  getAllGeneratedAlert() {
    return this.http.get<GeneratedAlertsVM[]>(`${environment.apiUrl}dashboard/getallgeneratedalert`);
  }
}
