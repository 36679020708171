import { Component, OnInit } from '@angular/core';
import { userloginVM } from '../models/userloginVM';
import { AlertService, AuthService } from '../services';
import { UserService } from '../users/user.service';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {
  public usersList: userloginVM[];
  public currentUserRoleOrder: number;
  loading = false;  
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  //sortData: Sort;

  constructor(private userService: UserService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router, private pagerService: PagerService, private SpinnerService: NgxSpinnerService, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.SpinnerService.show();  
    this.getUsers();
  }

  private getUsers() {
    this.currentUserRoleOrder = JSON.parse(localStorage.getItem('orderBy'));
    this.userService.getAll(this.currentUserRoleOrder).pipe(first())
      .subscribe(users => {
        this.usersList = users;
        if (this.usersList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide();  
      },
      error => {
        this.toastr.error(error.message);
        this.SpinnerService.hide();  
      });
  }

  public openDeleteUserConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteUserById(id) : ""        
      )
      .catch(
        //(e) =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
      );
  }

  deleteUserById(id: any) {   
    this.userService.delete(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
         this.getUsers();
        }
        else { 
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  onAddUserClick() {
    this.router.navigate(['/add-user']);
  }

  //Pagging
  setPage(page: number) {
    this.SpinnerService.show(); 
    this.pager = this.pagerService.getPager(this.usersList.length, page);    
    this.pagedItems = this.usersList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.usersList.slice();
    if (!sort.active || sort.direction === '') {
      this.usersList = data;
      return;
    } 
    this.usersList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.firstName, b.firstName, isAsc);
        case 'email': return compare(a.email, b.email, isAsc); 
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  } 
 
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
