import { Component, OnInit, Input, Directive, ElementRef } from '@angular/core';
import { AlertService, AuthService } from '../services';
import { CommonNameRequestService } from '../common-name-request/common-name-request-service';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { CitationRequestVM } from '../models/citationRequestVM';
import { UserRoles } from '../../app/models/enum';

@Component({
  selector: 'app-common-name-request',
  templateUrl: './common-name-request.component.html',
  styleUrls: ['./common-name-request.component.css']
})
export class CommonNameRequestComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;

  public citationRequestList: CitationRequestVM[];
  loading = false;

  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public userRole = UserRoles;
  isShow: boolean = true;
  isMainShow: boolean;
  constructor(
    private citationRequestService: CommonNameRequestService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
    }
    this.getCitationRequest();
  }

  private getCitationRequest() {
    this.SpinnerService.show();
    this.citationRequestService.getAll().pipe(first())
      .subscribe(citationRequest => {
        this.citationRequestList = citationRequest;
        if (this.citationRequestList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  deleteById(id: any) {
    this.citationRequestService.delete(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getCitationRequest();
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  public openActiveConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Approve ?')
      .then((confirmed) =>
        confirmed === true ? this.activeById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  activeById(id: any) {

    this.citationRequestService.approve(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getCitationRequest();
        } else {
          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  public openRemoveConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Reject ?')
      .then((confirmed) =>
        confirmed === true ? this.rejectById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  rejectById(id: any) {
    this.citationRequestService.reject(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getCitationRequest();
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.citationRequestList.length, page);
    this.pagedItems = this.citationRequestList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.citationRequestList.slice();
    if (!sort.active || sort.direction === '') {
      this.citationRequestList = data;
      return;
    }
    this.citationRequestList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'casnumber': return compare(a["casnumber"], b["casnumber"], isAsc);
        case 'chemicalName': return compare(a["chemicalName"], b["chemicalName"], isAsc);
        case 'citationName': return compare(a["citationName"], b["citationName"], isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

  GridHideShow(type) {
    if (type == 1) {
      this.router.navigate(['/data-request'])
    }
    if (type == 3) {
      this.router.navigate(['/company-chemical-request'])
    }
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
