import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { userloginVM } from '../models/userloginVM';

@Injectable()
export class AuthService {
    currentUser: userloginVM;
    constructor(private myRoute: Router) { }
    sendToken(token: string) {
        localStorage.setItem('LoggedInUser', token);
    }
    getFullName() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser) {
            return this.currentUser['userDetail']['firstname'] + ' ' + this.currentUser['userDetail']['lastname'];
        }
    }

    getCurrentUser() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser) {
            return this.currentUser['userDetail'];
        }
        return undefined;
    }

    updateCurrentUser(updatedUserDetails: userloginVM) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser) {
            this.currentUser['userDetail'] = updatedUserDetails;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        }
    }
    getToken() {
        return localStorage.getItem('LoggedInUser');
    }
    isLoggednIn() {
        return this.getToken() !== null;
    }

    logout() {
   
        localStorage.removeItem('currentUser');
        localStorage.removeItem('LoggedInUser');
        localStorage.clear();
      //this.myRoute.navigate(['login']);
      this.myRoute.navigateByUrl('/login')
  }
  Errorlogout(url) {

    localStorage.removeItem('currentUser');
    localStorage.removeItem('LoggedInUser');
    localStorage.clear();
    //window.location.href = "login?returnUrl=" + url;
    //this.myRoute.navigateByUrl("/login?returnUrl=" + url);
   // window.location.href = "/login?returnUrl=" + url;

    this.myRoute.navigate(['/login'], { queryParams: { returnUrl: url } });
   // window.location.reload();
  }
}
