import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services';
import { UserActivityService } from './user-activity.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PagerService } from '../helpers/pager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '../companies/companies.service';
import { companiesVM } from '../models/companiesVM';
import { UserService } from '../users/user.service';
import { userloginVM } from '../models';
import { userActivityFilterVM } from '../models/userActivityFilterVM';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})
export class UserActivityComponent implements OnInit {

  public companiesList: companiesVM[];
  public userList: userloginVM[];
  public userActivityFilter: userActivityFilterVM = {} as userActivityFilterVM;
  public userActivityList: any[];
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;

  constructor(private authService: AuthService, private userActivityService: UserActivityService,
    private companiesService: CompaniesService, private userService: UserService,
    private spinnerService: NgxSpinnerService, private toastr: ToastrService, private pagerService: PagerService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.resetValues();
    this.getCompaniesList();
  }

  private getCompaniesList() {
    this.spinnerService.show();
    this.companiesList = null;
    this.companiesService.getAll().pipe(first())
      .subscribe(res => {
        this.companiesList = res;
        this.spinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.spinnerService.hide();
        });

    this.getAllUserActivity();
  }

  onChangeHandler(event: any) {
    this.userActivityFilter.userId = "";
    this.userList = null;
    if (event.target.value == null || event.target.value == '') {
      return;
    }
    else {
      this.spinnerService.show();
      this.userService.getAllByCompanyId(event.target.value).pipe(first())
        .subscribe(res => {
          this.userList = res;
          this.spinnerService.hide();
        },
          error => {
            this.toastr.error(error.message);
            this.spinnerService.hide();
          });
    }
  }

  private getAllUserActivity(): void {
    this.spinnerService.show();
    this.userActivityService.getAllByFilter(this.userActivityFilter).pipe(first())
      .subscribe(res => {
        this.userActivityList = res;
        if (this.userActivityList != null && this.userActivityList.length > 0) {
          this.setPage(1);
        }
        else {
          this.pagedItems = null;
          this.userActivityList = null;
        }
        this.spinnerService.hide();
      },
        error => {
          if (error.status == 401) {
            this.authService.logout();
          } else {
            this.toastr.error(error.message);
          }
          this.spinnerService.hide();
        });
  }

  search(type: any) {
    if (type == 'search') {
      if (this.userActivityFilter.fromDate == "") {
        this.userActivityFilter.fromDate = null;
      }
      if (this.userActivityFilter.toDate == "") {
        this.userActivityFilter.toDate = null;
      }
      this.getAllUserActivity();
    }
    else {
      this.ngOnInit();
    }
  }

  setPage(page: number) {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      this.spinnerService.show();
      this.pager = this.pagerService.getPager(this.userActivityList.length, page);
      this.pagedItems = this.userActivityList.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.currentPage = page;
      this.spinnerService.hide();
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }

  resetValues() {
    var date = new Date();
    var toDate = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2);
    date.setDate(date.getDate() - 7);
    var fromDate = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2);

    this.userList = null;
    this.userActivityFilter.companyId = "";
    this.userActivityFilter.userId = "";
    this.userActivityFilter.fromDate = fromDate;
    this.userActivityFilter.toDate = toDate;
    this.userActivityList = null;
  }
}
