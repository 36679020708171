import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { ActivatedRoute, Router } from '@angular/router';
import { ChemicalVM } from '../../models/chemicalVM';
import { parametersVM } from '../../models/ParameterVM';
import { ToastrService } from 'ngx-toastr';
import { AlertDataService } from './../alerts.service';

@Component({
  selector: 'app-add-new-alert',
  templateUrl: './add-new-alert.component.html',
  styleUrls: ['./add-new-alert.component.css']
})
export class AddNewAlertComponent implements OnInit {
  private postAlertVM: any;
  public chemicalList: ChemicalVM[];
  public parameterList: parametersVM[];
  public conditionList: any;
  alertsForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;

  constructor(
    private alertservice: AlertDataService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.getChemicalList()
    this.getParameterList();
    this.getConditionList();

    this.alertsForm = this.formBuilder.group({
      id: [''],
      title: ['', TrimValidators.required],
      chemicalId: ['', TrimValidators.required],
      parameterId: ['', TrimValidators.required],
      conditionType: ['', TrimValidators.required],
      thresholdValue: ['', TrimValidators.required],
      companyId: []
    }
    );

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id) {
      this.editalerts(this.id)
    }
  }
  get f() { return this.alertsForm.controls; }

  private getChemicalList() {
    this.alertservice.getChemicalList().pipe(first())
      .subscribe(p => {
        this.chemicalList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getParameterList() {
    this.alertservice.getParameterList().pipe(first())
      .subscribe(p => {
        this.parameterList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getConditionList() {
    this.alertservice.getConditionList().pipe(first())
      .subscribe(p => {
        this.conditionList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  editalerts(Id: string = null): void {
    if (Id) {
      this.alertservice.getById(Id).pipe(first()).subscribe(alertData => {
        if (alertData != null) {
          this.postAlertVM = alertData;
          this.alertsForm.setValue({
            id: this.postAlertVM.id,
            title: this.postAlertVM.title,
            chemicalId: this.postAlertVM.chemicalId,
            parameterId: this.postAlertVM.parameterId,
            conditionType: this.postAlertVM.conditionType,
            thresholdValue: this.postAlertVM.thresholdValue,
            companyId: this.postAlertVM.companyId
          })
        }
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.alertsForm.invalid) {
      return;
    }
    this.loading = true;
    this.alertsForm.value.companyId = localStorage.getItem('CompanyId');

    if (this.id) {
      this.alertservice.update(this.alertsForm.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.router.navigate(['/alerts']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;

          });
    } else {
      this.alertservice.create(this.alertsForm.value)
        .pipe(first())
        .subscribe(
          data => {

            if (data['status'] === true) {
              this.router.navigate(['/alerts']).then(() => {
                this.toastr.success(data['message']);
              });
            } else {
              this.toastr.error(data['message']);
              this.loading = false;
            }
          },
          error => {
            this.toastr.error(error.message);
            this.loading = false;
          });
    }
  }

  onAddalertCancelClick() {
    this.router.navigate(['/alerts']);
  }
}
