import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AnalysisService } from '../analysis/analysis.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { socialMonitoringBlacklistChemicalsVM } from '../models/socialMonitoringBlacklistChemicalsVM';
import { socialMonitoringDefaultChemicalsVM } from '../models/socialMonitoringDefaultChemicalsVM';
import { AuthService } from '../services';
import { MonitoringManagementService } from './monitoring-management.service';

@Component({
  selector: 'app-monitoring-management',
  templateUrl: './monitoring-management.component.html',
  styleUrls: ['./monitoring-management.component.css']
})
export class MonitoringManagementComponent implements OnInit {

  selectedItems: [];
  dropdownSettings = {};
  companyId: any;
  multiDropdownList = [];
  public allChemicalDataList: any;
  filteredChemicalOptions: any[] = [];
  public selectedChemicalId: string;
  public socialMonitoringDefaultChemicalList: socialMonitoringDefaultChemicalsVM[];
  public socialMonitoringBlacklistChemicalList: socialMonitoringBlacklistChemicalsVM[];

  constructor(private authService: AuthService, private analysisService: AnalysisService,
    private monitoringManagementService: MonitoringManagementService,
    private spinnerService: NgxSpinnerService, private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select Chemical",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "form-control"
    };

    this.companyId = localStorage.getItem("CompanyId");
    this.selectedChemicalId = "";
    this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
    this.getDefaultChemical();
    this.getBlacklistChemical();
  }

  GetChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {
    this.spinnerService.show();
    this.analysisService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.multiDropdownList = [];
      this.allChemicalDataList = data;
      this.multiDropdownList = this.allChemicalDataList.map((m) => {
        return {
          id: m.id,
          itemName: m.chemicalName,
          name: m.commonName,
          casnumber: m.casnumber,
          isChecked: false,
          isValid: m.isValid,
          categoryName: m.categoryName
        }
      });
      this.filteredChemicalOptions = Object.assign([], this.multiDropdownList);
      this.selectedChemicalId = this.filteredChemicalOptions[0].id;
      this.spinnerService.hide();
    },
      error => {
        this.spinnerService.hide();
      });
  }

  getDefaultChemical() {
    this.spinnerService.show();
    this.monitoringManagementService.getDefaultChemicalsAll().pipe(first())
      .subscribe(res => {
        this.socialMonitoringDefaultChemicalList = res;
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

  getBlacklistChemical() {
    this.spinnerService.show();
    this.monitoringManagementService.getBlacklistChemicalsAll().pipe(first())
      .subscribe(res => {
        this.socialMonitoringBlacklistChemicalList = res;
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

  addToDefaultChemical() {
    if (this.socialMonitoringDefaultChemicalList.findIndex(x => x.chemicalId == this.selectedChemicalId) != -1) {
      this.toastr.error("Selected chemical already exists in the default chemical list.");
      return;
    }

    this.spinnerService.show();
    let model = {} as socialMonitoringDefaultChemicalsVM;
    model.chemicalId = this.selectedChemicalId;
    this.monitoringManagementService.createDefaultChemical(model).pipe(first())
      .subscribe(res => {
        this.toastr.success(res['message']);
        this.getDefaultChemical();
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

  addToBlacklistChemical() {
    if (this.socialMonitoringBlacklistChemicalList.findIndex(x => x.chemicalId == this.selectedChemicalId) != -1) {
      this.toastr.error("Selected chemical already exists in the denylist chemical list.");
      return;
    }

    this.spinnerService.show();
    let model = {} as socialMonitoringBlacklistChemicalsVM;
    model.chemicalId = this.selectedChemicalId;
    this.monitoringManagementService.createBlacklistChemical(model).pipe(first())
      .subscribe(res => {
        this.toastr.success(res['message']);
        this.getBlacklistChemical();
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

  onDefaultChemicalDeleteConfirmationDialog(data) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteDefaultChemical(data) : ""
      )
      .catch(
    );
  }

  deleteDefaultChemical(data) {
    this.spinnerService.show();
    this.monitoringManagementService.deleteDefaultChemical(data.id).pipe(first())
      .subscribe(res => {
        this.toastr.success(res['message']);
        this.getDefaultChemical();
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

  onBlacklistChemicalDeleteConfirmationDialog(data) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteBlacklistChemical(data) : ""
      )
      .catch(
    );
  }

  deleteBlacklistChemical(data) {
    this.spinnerService.show();
    this.monitoringManagementService.deleteBlacklistChemical(data.id).pipe(first())
      .subscribe(res => {
        this.toastr.success(res['message']);
        this.getBlacklistChemical();
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

}
