import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}UserActivity/GetAll`);
  }

  getAllByFilter(model: any) {
    return this.http.post<any[]>(`${environment.apiUrl}UserActivity/GetAllByFilter`, model);
  }

}
