import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { CompanyAlertDataService } from '../company-alert/company-alert.service';
import { PagerService } from '../helpers/pager.service';
import { AuthService } from '../services';

@Component({
  selector: 'app-view-company-user-alerts',
  templateUrl: './view-company-user-alerts.component.html',
  styleUrls: ['./view-company-user-alerts.component.css']
})
export class ViewCompanyUserAlertsComponent implements OnInit {

  public pager: any = {};
  public pagedItems: any[];
  public currentPage: any = 1;
  public generatedAlertsList: any[];
  public companyId: any;

  constructor(private authService: AuthService, private companyAlertservice: CompanyAlertDataService,
    private spinnerService: NgxSpinnerService, private pagerService: PagerService,
    private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.companyId = localStorage.getItem("CompanyId");
    this.getGeneratedAlertsList();
  }

  private getGeneratedAlertsList(): void {
    this.spinnerService.show();
    this.companyAlertservice.getAllGeneratedAlertsByCompany(this.companyId).pipe(first())
      .subscribe(res => {
        this.generatedAlertsList = res;
        if (this.generatedAlertsList != null && this.generatedAlertsList.length > 0) {
          this.setPage(1);
        }
        else {
          this.pagedItems = null;
          this.generatedAlertsList = null;
        }
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

  setPage(page: number) {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      this.spinnerService.show();
      this.pager = this.pagerService.getPager(this.generatedAlertsList.length, page);
      this.pagedItems = this.generatedAlertsList.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.currentPage = page;
      this.spinnerService.hide();
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }

  sort(sort: Sort) {
    const data = this.generatedAlertsList.slice();
    if (!sort.active || sort.direction === '') {
      this.generatedAlertsList = data;
      return;
    }
    this.generatedAlertsList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'chemicalName': return compare(a.chemicalName, b.chemicalName, isAsc);
        case 'sentimentTypeName': return compare(a["sentimentType"], b["sentimentType"], isAsc);
        case 'deviationTypeName': return compare(a["deviationTypeName"], b["deviationTypeName"], isAsc);
        case 'alertDate': return compare(a.alertDate, b.alertDate, isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
