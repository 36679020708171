import { Component, OnInit, Input, Directive, ElementRef } from '@angular/core';
import { ChemicalValueRequestVM } from '../models/chemicalValueRequestVM';
import { AlertService, AuthService } from '../services';
import { ChemicalValueRequestService } from '../data-request/data-request.service';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { UserRoles } from '../../app/models/enum';

@Component({
  selector: 'app-data-request',
  templateUrl: './data-request.component.html',
  styleUrls: ['./data-request.component.css']
})
export class DataRequestComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;

  public dataRequestList: ChemicalValueRequestVM[];
  loading = false;
  isShow: boolean = false;
  isMainShow: boolean = true;

  public currentUserCode: string;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public userRole = UserRoles;

  constructor(
    private chemicalValueRequestService: ChemicalValueRequestService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
    if (this.currentUserCode != '' && this.currentUserCode != null) {
      this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
      this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
      this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
    }

    this.getChemicalValueRequest();
  }
  private getChemicalValueRequest() {
    this.SpinnerService.show();
    this.chemicalValueRequestService.getAll().pipe(first())
      .subscribe(chemicalValueRequest => {
        this.dataRequestList = chemicalValueRequest;
        if (this.dataRequestList.length > 0) {
          this.setPage(1);
        }
        else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  public openDeleteConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) =>
        confirmed === true ? this.deleteById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  deleteById(id: any) {
    this.chemicalValueRequestService.delete(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getChemicalValueRequest();
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  public openActiveConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Approve ?')
      .then((confirmed) =>
        confirmed === true ? this.activeById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  activeById(id: any) {
    this.chemicalValueRequestService.approve(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getChemicalValueRequest();
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  public openRemoveConfirmationDialog(id: any) {
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to Reject ?')
      .then((confirmed) =>
        confirmed === true ? this.rejectById(id) : ""
        //console.log('User confirmed:', confirmed)       
      )
      .catch(
      //(e) =>
      // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)' + e)
    );
  }

  rejectById(id: any) {
    this.chemicalValueRequestService.reject(id)
      .pipe(first())
      .subscribe(data => {
        if (data['status'] === true) {
          this.toastr.success(data['message']);
          this.getChemicalValueRequest();
        } else {

          this.toastr.error(data['message']);
        }
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  setPage(page: number) {
    this.SpinnerService.show();
    this.pager = this.pagerService.getPager(this.dataRequestList.length, page);
    this.pagedItems = this.dataRequestList.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.currentPage = page;
    this.SpinnerService.hide();
  }

  sort(sort: Sort) {
    const data = this.dataRequestList.slice();
    if (!sort.active || sort.direction === '') {
      this.dataRequestList = data;
      return;
    }
    this.dataRequestList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'casnumber': return compare(a["casnumber"], b["casnumber"], isAsc);
        case 'chemicalName': return compare(a["chemicalName"], b["chemicalName"], isAsc);
        case 'parameterName': return compare(a["parameterName"], b["parameterName"], isAsc);
        case 'proposedValue': return compare(a["proposedValue"], b["proposedValue"], isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  }

  GridHideShow(type) {
    if (type == 2) {
      this.router.navigate(['/common-name-request'])
    }
    if (type == 3) {
      this.router.navigate(['/company-chemical-request'])
    }
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
