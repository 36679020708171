import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: 'input[numberOnlyWithDecimal]'
})
export class CustomeDirective  {
  constructor(private _el: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 45 || charCode > 57 || charCode == 47)) {
      return false;
    }
    else {
      var len = event.currentTarget["value"].length;
      var index = event.currentTarget["value"].indexOf('.');
      if (index > 0) {
        if (len > 5) {
          return false;
        }
        else {
          var CharAfterdot = (len + 1) - index;
          if (CharAfterdot > 4) {
            return false;
          }
          else {
            return true;
          }
        }
      }
      else {
        if (len > 2) {
          return false;
        }
      }
      return true;
    }
  }

  @HostListener('blur', ['$event']) blur(event: KeyboardEvent) {
    let s = event.currentTarget["value"];
    var rgx = /^[0-9]*\.?[0-9]*$/;
    if (s != "") {
      if (s.match(rgx)) {
        return true;
      }
      else {
        event.currentTarget["value"] = "";
        return false;
      }
    }
    else {
      event.currentTarget["value"] = "";
      return false;
    }
  }
 
}
