// import { validation } from '@angular/forms';

export class TrimValidators {
    static required(control) {
      
      if (control.value != null && control.value != "") {
            return control.value.trim() == "" ? { "required": true } : null;
        }
        else{
            return { "required": true }
        }
    }
}
