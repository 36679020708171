import { Component, OnInit } from '@angular/core';
import { parametersVM } from '../models/ParameterVM';
import { chemicalcategoryListVM } from '../models/chemicalcategoryListVM';
import { AlertService } from '../services';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ChemicalParameterService } from './chemical-parameter.service';
import { ToastrService } from 'ngx-toastr';
import { PagerService } from '../helpers/pager.service'
import { Sort } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-chemical-parameter',
  templateUrl: './chemical-parameter.component.html',
  styleUrls: ['./chemical-parameter.component.css']
})
export class ChemicalParameterComponent implements OnInit {
  pager: any = {};
  pagedItems: any[];
  currentPage: any = 1;
  public parameterList: parametersVM[];
  public categoryList: chemicalcategoryListVM[];

  loading = false;
  constructor(
    private chemicalParameterService: ChemicalParameterService,
    private alertService: AlertService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private pagerService: PagerService,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getParameter();
    this.getCategoryList();
  }

  private getParameter() {
    this.SpinnerService.show(); 
    this.chemicalParameterService.getAll().pipe(first())
      .subscribe(parameter => {
        this.parameterList = parameter;
        if (this.parameterList.length > 0) {
          this.setPage(1);
        } else {
          this.pagedItems = [];
        }
        this.SpinnerService.hide(); 
      },
        error => {
          //this.toastr.error(error.message);
       this.SpinnerService.hide();  
        });
  }

  private getCategoryList() {
    this.chemicalParameterService.getCategoryList().pipe(first())
      .subscribe(p => {
        this.categoryList = p;

      },
    error => {
      //this.toastr.error(error.message);
    });
  }


  selectChangeHandler(event: any) {
   
    this.parameterList = null;
    if (event.target.value == null || event.target.value=='') {
      this.getParameter();
    }
    else {
      this.chemicalParameterService.GetByCategoryId(event.target.value).pipe(first())
        .subscribe(p => {
          this.parameterList = p;
          if (this.parameterList.length > 0) {
            this.setPage(1);
          }
        },
          error => {
            this.toastr.error(error.message);
          });
    }
    
  }
  setPage(page: number) {
      this.SpinnerService.show();
      this.pager = this.pagerService.getPager(this.parameterList.length, page);
      this.pagedItems = this.parameterList.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.currentPage = page;
      this.SpinnerService.hide();
  }

  sort(sort: Sort) {

    const data = this.parameterList.slice();
    if (!sort.active || sort.direction === '') {
      this.parameterList = data;
      return;
    }
 

    this.parameterList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
          case 'parameterName': return compare(a["parameterName"], b["parameterName"], isAsc);
       // case 'parentCategories': return compare(a.parentCategoryName, b.parentCategoryName, isAsc);
        default: return 0;
      }
    });

    this.setPage(this.currentPage);
  } 
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
