import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { CompaniesService } from '../companies.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { companiesVM } from '../../models/companiesVM';
import { countryVM } from '../../models/countryListVM';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.css']
})
export class ViewCompanyComponent implements OnInit {

  public companiesVM: companiesVM[];
  public countryList: countryVM[];
  companiesViewForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  selectedCompnyId: any;

  constructor(
    private formBuilder: FormBuilder,
    private companiesService: CompaniesService,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.SpinnerService.show();
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id) {
      this.editCompanies(this.id)
    }
  }

  editCompanies(companyId: string = null): void {
    if (companyId) {
      this.companiesService.getById(companyId).pipe(first()).subscribe(userData => {
        this.companiesVM = userData;
        this.selectedCompnyId = userData[0].id;
        this.SpinnerService.hide();
      });
    }
  }

  backButton() {
    this.router.navigate(['/companies']);
  }

}
