import { Component, Input, OnInit } from '@angular/core';
import { AuthService, AuthenticationService } from '../services';
import { FaqService } from './faq.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserRoles } from '../models/enum';
import { userloginVM } from '../models';
import { first } from 'rxjs/operators';
import { faqVM } from '../models/faqVM';


// interface AccordionItem {
//   title: string;
//   content: string;
//   isActive: boolean; // Flag to track active state
// }

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {

  // items: AccordionItem[] = [
  //   { title: 'FAQ 1', content: 'Content for FAQ 1.', isActive: false },
  //   { title: 'FAQ 2', content: 'Content for FAQ 2.', isActive: false },
  //   { title: 'FAQ 3', content: 'Content for FAQ 3.', isActive: false },
  //   { title: 'FAQ 4', content: 'Content for FAQ 4.', isActive: false },
  //   { title: 'FAQ 5', content: 'Content for FAQ 5.', isActive: false }
  // ];

  public faqList: faqVM[];
  public currentUserCode: string;
  currentUser: userloginVM;
  public isSuperAdmin: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isElemental: boolean = false;
  public isUsers: boolean = false;
  public userRole = UserRoles;

  constructor(private authService: AuthService, private authenticationService: AuthenticationService,
    private faqService: FaqService, private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    if (this.authService.isLoggednIn()) {
      this.currentUserCode = JSON.parse(localStorage.getItem('userType'));
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUserCode != '' && this.currentUserCode != null) {
        this.isSuperAdmin = this.currentUserCode.toLowerCase() == this.userRole[0].toLowerCase() ? true : false;
        this.isCompanyAdmin = this.currentUserCode.toLowerCase() == this.userRole[1].toLowerCase() ? true : false;
        this.isElemental = this.currentUserCode.toLowerCase() == this.userRole[2].toLowerCase() ? true : false;
        this.isUsers = this.currentUserCode.toLowerCase() == this.userRole[3].toLowerCase() ? true : false;
      }
    }

    this.getFAQList();
  }

  getFAQList() {
    this.spinnerService.show();
    this.faqService.getAll().pipe(first())
      .subscribe(res => {
        this.faqList = res;
        this.faqList.forEach(i => i.isActive = false);
        // console.log(this.faqList);
        this.spinnerService.hide();
      },
        error => {
          this.spinnerService.hide();
        });
  }

  toggleItem(item: faqVM) {
    this.faqList.forEach(i => i.isActive = false); // Deactivate all items
    item.isActive = !item.isActive; // Toggle active state for clicked item
  }

  // toggleItem(item: AccordionItem) {
  //   this.items.forEach(i => i.isActive = false); // Deactivate all items
  //   item.isActive = !item.isActive; // Toggle active state for clicked item
  // }

}
