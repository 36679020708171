export enum UserRoles {
  SuperAdmin,
  CompanyAdmin,
  Elemental,
  Users
}

export enum PortfolioAction {
  ADDPORTFOLIO = "ADDPORTFOLIO",
  ADDCHEMICALTOPORTFOLIO = "ADDCHEMICALTOPORTFOLIO",
  RENAMEPORTFOLIO = "RENAMEPORTFOLIO"
}

export enum MenuOptionsAction {
  RESEARCHINTEREST = "Research Interest",
  EMERGINGCONCERN = "HEAT - Emerging Concern",
  NONE = "None"
}

export enum AnnualProgramAction {
  PREVIOUSYEAR = "PreviousYear",
  CURRENTYEAR = "CurrentYear",
  NEXTYEAR = "NextYear"
}
