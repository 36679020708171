import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthService } from '../../services';
import { first } from 'rxjs/operators';
import { TrimValidators } from '../../helpers/custom-validators'
import { ActivatedRoute, Router } from '@angular/router';
import { CommonNameKeywordVM } from '../../models/commonNameKeywordVM';
import { CitationSentimentVM } from '../../models/citationSentimentVM';

import { CitationTypeVM } from '../../models/citationTypeVM';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { CompanyAlertDataService } from './../company-alert.service';
import { AnalysisService } from 'src/app/analysis/analysis.service';

@Component({
  selector: 'app-add-company-alert',
  templateUrl: './add-company-alert.component.html',
  styleUrls: ['./add-company-alert.component.css']
})
export class AddCompanyAlertComponent implements OnInit {
  private postCompanyAlertVM: any;
  public commonNameList: CommonNameKeywordVM[];
  public citationSentimentList: CitationSentimentVM[];
  public citationTypeList: CitationTypeVM[];
  public thresholdList: any;
  public periodList: any;
  public sentimentTypeList: any;
  public deviationTypeList: any;
  companyAlertsForm: FormGroup;
  loading = false;
  submitted = false;
  sub: any;
  id: any;
  isEdit = false;
  companyId: any;
  multiDropdownList = [];
  public allChemicalDataList: any;
  filteredChemicalOptions: any[] = [];
  public chemicalList: any;

  constructor(
    private companyAlertservice: CompanyAlertDataService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private analysisService: AnalysisService) { }

  ngOnInit() {
    this.SpinnerService.show();
    if (!this.authService.isLoggednIn()) {
      this.authService.logout();
      return;
    }

    //this.getCommonNameList()
    //this.getCitationSentimentList();
    //this.getCitationType();
    //this.getPeriodList();
    //this.getThresholdList();

    this.companyId = localStorage.getItem("CompanyId");
    this.GetChemicalList('', 1, 1000, '', '', true, this.companyId, true);
    this.getSentimentTypeList();
    this.getDeviationTypeList();

    this.companyAlertsForm = this.formBuilder.group({
      id: [''],
      //commonNameKeywordId: ['', TrimValidators.required],
      //citationTypeId: ['', TrimValidators.required],
      //citationSentimentId: ['', TrimValidators.required],
      //threshold: ['', TrimValidators.required],
      //period: ['', TrimValidators.required],
      companyId: [],
      chemicalId: ['', TrimValidators.required],
      sentimentType: ['', TrimValidators.required],
      deviationType: ['', TrimValidators.required],
    }
    );

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    if (this.id) {
      this.isEdit = true;
      this.editcompanyalerts(this.id)
    }
  }
  get f() { return this.companyAlertsForm.controls; }

  private getCommonNameList() {
    this.SpinnerService.hide();
    this.companyAlertservice.getCommonNameList().pipe(first())
      .subscribe(p => {
        this.commonNameList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getCitationSentimentList() {
    this.companyAlertservice.getCitationSentimentList().pipe(first())
      .subscribe(p => {
        this.citationSentimentList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getCitationType() {
    this.companyAlertservice.getCitationType().pipe(first())
      .subscribe(p => {
        this.citationTypeList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getPeriodList() {
    this.companyAlertservice.getPeriodList().pipe(first())
      .subscribe(p => {
        this.periodList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  private getThresholdList() {
    this.companyAlertservice.getThresholdList().pipe(first())
      .subscribe(p => {
        this.thresholdList = p;
      },
        error => {
          this.toastr.error(error.message);
        });
  }

  GetChemicalList(filter: string, startRow: number, endRow: number, sortColumn: string, sortOrder: string, isPager: boolean, companyId: string, isPrivate: boolean) {
    this.SpinnerService.show();
    this.analysisService.RetrieveChemicalList(filter, startRow, endRow, sortColumn, sortOrder, companyId, isPrivate).subscribe(data => {
      this.chemicalList = data;
      this.SpinnerService.hide();
    },
      error => {
        this.SpinnerService.hide();
      });
  }

  private getSentimentTypeList() {
    this.SpinnerService.show();
    this.companyAlertservice.getSentimentTypeList().pipe(first())
      .subscribe(res => {
        this.sentimentTypeList = res;
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  private getDeviationTypeList() {
    this.SpinnerService.show();
    this.companyAlertservice.getDeviationTypeList().pipe(first())
      .subscribe(res => {
        this.deviationTypeList = res;
        this.SpinnerService.hide();
      },
        error => {
          this.toastr.error(error.message);
          this.SpinnerService.hide();
        });
  }

  editcompanyalerts(Id: string = null): void {
    if (Id) {
      this.companyAlertservice.getById(Id).pipe(first()).subscribe(calertData => {

        if (calertData != null) {
          this.postCompanyAlertVM = calertData;
          this.companyAlertsForm.setValue({
            id: this.postCompanyAlertVM.id,
            //commonNameKeywordId: this.postCompanyAlertVM.commonNameKeywordId,
            //citationTypeId: this.postCompanyAlertVM.citationTypeId,
            //citationSentimentId: this.postCompanyAlertVM.citationSentimentId,
            //threshold: this.postCompanyAlertVM.threshold,
            //period: this.postCompanyAlertVM.period,
            companyId: this.postCompanyAlertVM.companyId,
            chemicalId: this.postCompanyAlertVM.chemicalId,
            sentimentType: this.postCompanyAlertVM.sentimentType,
            deviationType: this.postCompanyAlertVM.deviationType
          })
        }
      });
    }
  }


  onSubmit() {
    var isLogin = localStorage.getItem('LoggedInUser');
    if (isLogin != "" && isLogin != null) {
      this.submitted = true;
      if (this.companyAlertsForm.invalid) {
        return;
      }
      this.loading = true;
      this.companyAlertsForm.value.companyId = localStorage.getItem('CompanyId');

      if (this.id) {
        this.companyAlertservice.update(this.companyAlertsForm.value)
          .pipe(first())
          .subscribe(
            data => {
              if (data['status'] === true) {
                this.router.navigate(['/company-alert']).then(() => {
                  this.toastr.success(data['message']);
                });
              } else {
                this.toastr.error(data['message']);
                this.loading = false;
              }
            },
            error => {
              this.toastr.error(error.message);
              this.loading = false;

            });
      } else {
        this.companyAlertservice.create(this.companyAlertsForm.value)
          .pipe(first())
          .subscribe(
            data => {
              if (data['status'] === true) {
                this.router.navigate(['/company-alert']).then(() => {
                  this.toastr.success(data['message']);
                });
              } else {
                this.toastr.error(data['message']);
                this.loading = false;
              }
            },
            error => {
              this.toastr.error(error.message);
              this.loading = false;
            });
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }

  onAddalertCancelClick() {
    this.router.navigate(['/company-alert']);
  }
}
