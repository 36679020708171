import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { domainVM } from '../models/domainVM';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}Domain/GetAll`);
  }

  getParameterDomainAll() {
    return this.http.get<any[]>(`${environment.apiUrl}ParameterDomain/GetAll`);
  }

  getById(id: string) {
    return this.http.get(`${environment.apiUrl}Domain/GetById/` + id);
  }

  create(data: domainVM) {
    return this.http.post<any>(`${environment.apiUrl}Domain/CreateDomain`, data);
  }

  update(data: domainVM) {
    return this.http.post(`${environment.apiUrl}Domain/UpdateDomain`, data);
  }

  delete(id: string) {
    return this.http.get(`${environment.apiUrl}Domain/DeleteById/` + id);
  }

}
